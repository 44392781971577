/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
// ------------ TRADUCTION -------------------
import { useTranslation } from "react-i18next";
//------------ Components ---------------------
import PaginationTable from "../../../components/Pagination/PaginationTable";
import Loader from "../../../components/shared/Loader";
import ErrorAlert from "../../../others/ErrorAlert";
import StockElements from "./StockElements";
//-------------- WEB SERVICES ------------------
import { getAllStock } from "../../../services/ventes/venteApi";
import { getListDistributeur } from "../../../services/commande/commandeApi";
// -------------- UTILS ------------------------
import { numberWithSpaces } from "../../../utils/NumberWithSpaces";
import { sortPricettcAsc, sortPricettcDesc, TrierAZlibelle } from "../../../utils/SearchUtils";
import { toast, ToastContainer } from "react-toastify";


export default function StockTotal() {
  // -------------- Historiques ---------------
  const history = useHistory();
  // -------------- References ---------------
  const inputEl = useRef("")
  // ------------------- Loading --------------
  const [loading, setLoading] = useState(true);
  // ----------------- Languages --------------
  const [t, i18n] = useTranslation("global");
  // ---------------- ERROR -------------------
  const [error, setError] = useState(false);
  //--------------- Liste Stock  -------------
  const [list, setList] = useState([]);
  const [searchList, setSearchlist] = useState([])
  const [saveList, setSaveList] = useState([])
  // -------------- FILTRE + SEARCH ----------
  const [search, setSearch] = useState('')
  const [value, setValue] = useState('0')
  // ---------------- TOTAL  -----------------
  const [qte, setqte] = useState(0)
  const [total, settotal] = useState(0)
  // ---------- LIST DISTRIBUTEURS -----------
  const [distributeurs, setDistributeur] = useState([])
  //---------- PAGINATION ----------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [stocksPerPage, setStockPerPage] = useState(10);
  const indexOfLastPost = currentPage * stocksPerPage;
  const indexOfFirstPost = indexOfLastPost - stocksPerPage;
  const currentPosts = search.length < 1 ? list?.slice(indexOfFirstPost, indexOfLastPost) : searchList?.slice(indexOfFirstPost, indexOfLastPost);

 

    useEffect(() => {
        // ----------- GET LIST DISTRIBUTEURS ---------------
        getListDistributeur()
        .then((res) => {
          if(res === 404){
            setError(true);
          }
          else if (res?.data) {
            setDistributeur(res.data)
            if(res?.data.length=== 1){
              getAllStock(res.data[0].code)
              .then((res) => {
                if(res === 422){
                  toast(t('messages.autorisation'),{position:'bottom-center',type:'warning'})
                }
                else if (res?.data) {
                  setList(res?.data);
                  setSaveList(res?.data)
                  const x = calculQteStock(res.data)
                  const y = calculTotalStock(res.data)
                  setqte(x)
                  settotal(y)
                }
              })
            }
            setLoading(false);
          }
        }).catch((error)=>console.log(error))
    
    }, []);

    //---------- SELECT SOUS SOCIETE --------------
    const onSelectSousSociete = (e) => {
      setValue(e.target.value)
      if(e.target.value !== "0"){
        getAllStock(e.target.value)
        .then((res) => {
          if(res === 422){
            toast(t('messages.autorisation'),{position:'bottom-center',type:'warning'})
          }
          else if (res?.data) {
            setList(res?.data);
            setSaveList(res?.data)
            const x = calculQteStock(res.data)
            const y = calculTotalStock(res.data)
            setqte(x)
            settotal(y)
          }
        }).catch((error)=>console.log(error))
      }
    
    }
    //------- CALCUL (Qte.) STOCK  ------------------
    const calculQteStock = (liste) => {
      const total = liste.reduce(
        (totalsum, { quantite }) => totalsum + parseFloat(quantite), 0);
      return total
    }
    //------- CALCUL (Total.) STOCK  ------------------
    const calculTotalStock = (liste) => {
      const total = liste.reduce(
        (totalsum, { quantite, prix_ttc }) => totalsum + (parseFloat(quantite) * parseFloat(prix_ttc)),0);
      return total
    }
    //------- FILTRE ELEMENTS  ------------------
    function ChangeElement (e) {
      setLoading(true)   
      if(e.target.value==="1"){
        if(search.length <2){
          sortPricettcAsc(list) 
        }else{
          sortPricettcAsc(searchList) 
        }
      }
      else if(e.target.value==="2"){
        if(search.length <2){
          sortPricettcDesc(list) 
        }else{
          sortPricettcDesc(searchList) 
        }
      }
      else if(e.target.value==="3"){
        if(search.length <2){
          TrierAZlibelle(list) 
        }else{
          TrierAZlibelle(searchList) 
        }
      }
      setLoading(false)
    }
    // ---------- SEARCH -------------- 
    const getSearchTerm = () => {
      setSearch(inputEl.current.value)
      if (search !== "") {
        const newList = list?.filter((el) => {
          return Object.values(el)
            .join(" ")
            .toLowerCase()
            .includes(inputEl.current.value.toLowerCase())
        })
        setSearchlist(newList)

      } else {
        setList(saveList)
      }
    }

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="section-title" style={{paddingBottom:"12px"}}>
            <button
              className="btn bg-primary "
              onClick={() => history.goBack()}>
              <i className="fe fe-arrow-left" />
              {t("stock.retour")}
            </button>
          </div>
          <ToastContainer />
          <div className="row">
            <div className="col-md-12">
              <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                  <div className="panel-heading " role="tab" id="headingOne">
                    <h4 className="panel-title">
                      <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <i className="icon icon-grid text-primary mr-2 " /> {t('todayCommercial.stockTotal')}
                        <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                      </a>
                    </h4>
                  </div>
                  <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                    <div className="panel-body">
                      {/** ---------------- SEARCH FORM ------------------  */}
                    <div className="search1 search-elements-sm" dir={i18n.dir()}>
                      <div className="form row no-gutters">
                        <div className="form-group  col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5 mb-0">
                          <input
                            type="text"
                            ref={inputEl}
                            className="form-control br-tr-md-0 br-br-md-0"
                            value={search}
                            onChange={() => getSearchTerm()}
                            placeholder={t("stock.search")}
                          />
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-3 mb-0">
                          <select className="form-control select2 text-primary w-100" value={value} onChange={(e) => onSelectSousSociete(e)}>
                            <option value={"0"}>{t('stock.distributeurs')}</option>
                            {distributeurs?.map((items, i) => (
                              <option value={items.code}>{items.nom}</option>
                            ))}
                          </select>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-3 mb-0">
                        <select name="item" className="form-control select2 text-primary w-100 "
                           value={value} 
                           onChange={(e) =>{
                           setValue(e.target.value)
                           ChangeElement(e)}}>
                           <option value={0}>------- {t('stock.trierpar')} -----</option>
                           <option value={1}>{t('stock.prixcroissant')}</option>
                           <option value={2}>{t('stock.prixdecroissant')}</option>
                           <option value={3}> A-Z</option>
                       </select>
                        </div>
                        <div className="col-xl-1 col-lg-1 col-md-1 col-12 mb-0">
                          <button
                            onClick={getSearchTerm}
                            className="br-tl-md-0 br-bl-md-0 btn btn-block btn-primary fs-16">
                            <i className="fe fe-search" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {/** ------------ CARD (qte / total / nbr produits) -----  */}
                    </div>
                  </div>
                </div>
              </div>
               {/** ------------ CARD (qte / total / nbr produits) -----  */}
              <div >
                {currentPosts.length === 0 ? (
                  <div>
                    <div>
                      <div className="alert bg-purple mb-5 py-4" role="alert" > 
                        <div className="d-flex">
                          <i className="fe fe-check-circle mr-3 fs-30" />
                          <div  dir={i18n.dir()}><h4 class="alert-heading">{t('stock.message')}</h4>
                            <p>{t('stock.detailmessage')} </p></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
            <div className="row mb-2" dir={i18n.dir()}>
            <div className="col-lg-4 col-md-4 col-sm-4 col-4  text-center mobilecard">
            <div className="card box-shadow-0 mb-lg-0">
                <div className="card-body">
                  <div className="item-box text-center">
                    <div className="bg-warning-transparent icon-bg icon-service text-warning">
                      <i className="fa fa-money " />
                    </div>
                    <div className="item-box-wrap">
                      <p className="text-muted  pmobiletext mb-0">{numberWithSpaces(parseFloat(total).toFixed(3))}</p>
                      <h5 className="mobiletext mb-2"> {t('stock.TotalVentettc')}</h5>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-4 text-center mobilecard">
              <div className="card box-shadow-0 mb-lg-0">
                <div className="card-body ">
                  <div className="item-box text-center">
                    <div className="bg-purple-transparent icon-bg icon-service text-purple">
                      <i className="fa fa-wpforms" />
                    </div>
                    <div className="item-box-wrap">
                      <p className="text-muted  pmobiletext mb-0">{qte}</p>
                      <h5 className=" mobiletext mb-2">{t('stock.quantitetotal')}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="col-lg-4 col-md-4 col-sm-4 col-4 text-center  ">
              <div className="card box-shadow-0 mb-0 ">
                <div className="card-body ">
                  <div className="item-box text-center">
                    <div className="bg-pink-transparent icon-bg icon-service text-pink">
                      <i className="fa fa-cubes" />
                    </div>
                    <div className="item-box-wrap">
                      <p className="text-muted  pmobiletext mb-0">{list.length}</p>
                      <h5 className=" mobiletext mb-2">{t('stock.nbrproduits')}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              </div>     
  
                    <div className="row">
                      {currentPosts.map((items, key) => (
                        <div className="col-md-4 col-lg-3 col-sm-12 col-12" key={key}>
                          <StockElements
                            t={t} i18n={i18n} el={items} key={key}
                          />
                        </div>
                      ))}
                    </div>
                    <div
                      className="col-12"
                      style={{ paddingTop: "15px", paddingBottom: "15px" }}
                    >
                      <PaginationTable
                        postsPerPage={stocksPerPage}
                        totalPosts={search.length < 3 ? list.length : searchList.length }
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage} 
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {error? <ErrorAlert t={t} /> : <></>}
    </div>
  );
}
