import axios from "axios";
import { toast } from "react-toastify";
import { compareTwoDatee } from "../../utils/DateUtils";
require("dotenv").config()


/**
 * Access to APP SERVICE CODE
 * @param {string} code
 * @returns response
 */

export async function getCode(code,t) {

  try {
    const response = await axios.get(process.env.REACT_APP_API_URL+code,{
      
        SameSite: "None",
          secure: true,
          IsEssential: true,
          withCredentials: true,
      
    });
    var value = Object.keys(response.data).length;
    if (value === 0) {
      toast(t('widget.messageerreur'), {
        type: "error",
        position: "bottom-center"
      });
      return false
    } else {
      if (/* response.data.client_code.toLowerCase() === code.toLowerCase()  && */ response.data.isactif === "1" && compareTwoDatee(response.data.date_fin, response.data.date_debut)) {
        toast(
         t('widget.messagebienveue'),
          {
            type: "success", position: "bottom-center"
          }
        );  
        localStorage.setItem("url_nomadis", response.data.url);
        return true
      } else if (compareTwoDatee(response.data.date_fin, response.data.date_debut) === false) {
        toast(t("widget.messageExpiration"), {
          type: "error",
          position: "bottom-center"
        });
        return false
      } else if (response.data.isactif === '0') {
        toast(t("widget.messageAutorisation"), {
          type: "error",
          position: "bottom-center"
        });
        return false
      }
    }
  } catch (error) {

    if (error.response === undefined){
      return undefined
    }
  }
}

/* export async function getTemperatureApi(lat,long,language){
  try {
    const response = await axios.get('http://api.openweathermap.org/data/2.5/weather?lat=' +lat + '&lon=' + long + '&lang=' + language + '&appid=1afb9855b0a0094d21c11e2cc93b187d');
    return response;
  } catch (error) {
    console.error(error);
  }
} */
export async function getTemperatureApi(city,language){
  try {
    const response = await axios.get('https://api.openweathermap.org/data/2.5/weather?q='+city+'&lang=' + language + '&appid=1afb9855b0a0094d21c11e2cc93b187d',{
      SameSite: "None",
        secure: true,
        IsEssential: true,
        withCredentials: true,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function getTemperatureApi2(lat,lon,language){
  try {
    const response = await axios.get('https://api.openweathermap.org/data/2.5/weather?lat='+lat+'&lon='+lon+'&lang=' + language +'&appid=1afb9855b0a0094d21c11e2cc93b187d');
    return response;
  } catch (error) {
    console.error(error);
  }
}