/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, {  useRef, useState } from 'react'
import { OverlayTrigger } from 'react-bootstrap'
import { popover } from '../../../components/Popover/Popovertab';
import { numberWithSpaces } from '../../../utils/NumberWithSpaces';
import {  sortdate, sortdatedesc } from '../../../utils/SearchUtils';

export default function CardVenteCommercial({ ventes,setlignes, visite, client, t, sendToClientProfile,saveVente,commercial }) {
   
    // -------------- References ---------------
    const inputEl = useRef("")
    // ---------- Loading ------------------
    const [loading, setLoading] = useState(true)

       // -------------- FILTRE + SEARCH ----------
    const [search, setSearch] = useState('')
    const [searchList, setSearchlist] = useState([])
    const [value, setValue] = useState(-1);
    
    const currentPosts = search.length < 1 ? ventes : searchList

 
     // ---------- SEARCH -------------- 
     const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (search !== "") {
            const newList = ventes?.filter((el) => {
                return Object.values(el)
                    .join(" ")
                    .toLowerCase()
                    .includes(inputEl.current.value.toLowerCase())
            })
            setSearchlist(newList)

        } else {
            setlignes(saveVente)
        }
    }
    //------- FILTRE ELEMENTS  ------------------
    function ChangeElement(e) {
    setLoading(true)
    if (e.target.value === "0") {
        setlignes(saveVente)
    }
 
    else if (e.target.value === "3") {
        if (search.length < 2) {
            sortdate(ventes)
        } else {
            sortdate(searchList)
        }
    }else if (e.target.value ==="4"){
        if (search.length < 2) {
            sortdatedesc(ventes)
        } else {
            sortdatedesc(searchList)
        }
    }
    setLoading(false)
    }
    //---------- Calculer Total Ventes
    function totalVentes () {
        const total = currentPosts.reduce(
        (totalsum, { net_a_payer }) => totalsum + parseFloat(net_a_payer), 0);
        return total
    }
    return (
        <>
            {ventes.length !== 0 ? (
                <>
                    <div className="row mb-2">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4 text-center mobilecard">
                            <div className="card box-shadow-0 mb-lg-0">
                                <div className="card-body">
                                    <div className="item-box text-center">
                                        <div className="stamp text-center stamp-lg bg-primary "><i className="fa fa-bandcamp" /></div>
                                        <div className="item-box-wrap">
                                            <p className="text-muted  pmobiletext mb-0">{visite}</p>
                                            <h6 className=" mobiletext mb-2">{t("suivi.visiteplanifiee")}</h6>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4  text-center mobilecard">
                            <div className="card  box-shadow-0 mb-lg-0">
                                <div className="card-body">
                                    <div className="item-box text-center">
                                        <div className="stamp text-center stamp-lg bg-success"><i className="fa fa-eercast" /></div>
                                        <div className="item-box-wrap">
                                            <p className="text-muted  pmobiletext mb-0">{client}</p>
                                            <h6 className="mobiletext mb-2">{t("listecmdvente.clientvisite")}</h6>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4  col-4 text-center ">
                            <div className="card  box-shadow-0 mb-0 ">
                                <div className="card-body ">
                                    <div className="item-box text-center">
                                        <div className="stamp text-center stamp-lg bg-info"><i className="fa fa-adjust" /></div>
                                        <div className="item-box-wrap">
                                            <p className="text-muted  pmobiletext mb-0">{visite !== 0 ? parseFloat((client / visite) * 100).toFixed(2) : 0} %</p>
                                            <h6 className=" mobiletext mb-2">{t("listecmdvente.tauxvisite")}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-3 col-sm-3  col-3 text-center ">
                            <div className="card  box-shadow-0 mb-0 ">
                                <div className="card-body ">
                                    <div className="item-box text-center">
                                        <div className="stamp text-center stamp-lg bg-warning"><i className="fa fa-sort-numeric-asc" /></div>
                                        <div className="item-box-wrap">

                                        <a className="btn btn-secondary  mr-1 mt-2" href={"/Top10client/" + commercial}>
                                        <h6 className=" mobiletext mb-2">{t("listecmdvente.top10clients")}</h6>
                                        </a>
                                         
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>

                    <div className="card">
                        {/**--------------------SLIDE ACCORDION FILTRE AND SEARCH  ------------------------- */}
                        <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true">
                            <div className="panel panel-default mt-2">
                                <div className="panel-heading" role="tab" id="headingTwo">
                                    <h4 className="panel-title">
                                        <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            <i className="icon icon-equalizer text-primary mr-2" />  {t("suivi.Reportingventes")}
                                            <span className="float-right"> 
                                             {currentPosts.length !== 0 ? <h6> {t("releve.totalbl")+" : "+(totalVentes().toFixed(3))}  / {t("client.nbBl")+" : "+(ventes?.length)}  <i className="fe fe-search  text-primary" />  </h6> :  <i className="fe fe-search  text-primary" />  }
                                           </span>
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                                    <div className="panel-body">
                                        <div className="row" >
                                            <div className="col-lg-6 col-xl-6 col-12">
                                                {/* -------------------- RECHERCHE + FILTRE -------------------------------------- */}
                                                <div className="d-flex">
                                                    <label className="mr-2 mt-1 mb-sm-1">{t('stock.trierpar')} <i className="ti-exchange-vertical" /></label>
                                                    <select name="item" className="form-control nice-select mb-0 nice-select-md w-250 "
                                                    value={value}
                                                    onChange={(e) => {
                                                        setValue(e.target.value)
                                                        ChangeElement(e)
                                                    }}
                                                   >
                                                        <option value={0}>------- {t('stock.trierpar')} -----</option>
                                                      {/*   <option value={1}> {t('suivi.planifiee')} </option>
                                                        <option value={2}>{t('suivi.nonplanifiee')} </option> */}
                                                        <option value={3}> {t('suivi.datecroissante')}</option>
                                                        <option value={4}> {t('suivi.daterecente')}</option>

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-6 col-12">
                                                <div className="search1 search-elements-sm ">
                                                    <div className="form row no-gutters ">
                                                        <div className="form-group  col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 mb-0">
                                                            <input
                                                                type="text"
                                                                className="form-control br-tr-md-0 br-br-md-0"
                                                                ref={inputEl}
                                                                onChange={() => getSearchTerm()}
                                                                placeholder={t("stock.search")}
                                                            />
                                                        </div>
                                                        <div className="col-xl-2 col-lg-2 col-md-2 col-2 mb-0">
                                                            <button
                                                                className="br-tl-md-0 br-bl-md-0 btn btn-block btn-success fs-16"
                                                            >
                                                                <i className="fe fe-search" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/**------------------END OF SLIDE ACCORDION FILTRE AND SEARCH  ------------------ */}

                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table card-table table-striped tutorial-table" cellSpacing={0} width="100%">
                                    <thead>
                                        <tr className="text-center" >
                                            <th className="priority-1" width="15%">{t('stock.date')}</th>
                                            <th className="priority-3" width="15%">{t('suivi.societe')}</th>
                                            <th className="priority-1" width="15%" >{t('releve.client')}</th>
                                            <th className="priority-3" width="15%">{t('suivi.bondelivraison')}</th>
                                            <th className="priority-3" width="15%">{t('releve.remise')}</th>
                                            <th className="priority-1" width="15%">{t('historique.montant')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentPosts.map((items, keys) => (
                                        <>
                                            <tr className="text-center" key={keys}>
                                                <td className="priority-1" width="15%">{moment(items.date).format("DD-MM-YYYY")}</td>
                                                <td className="priority-3" width="15%">{items.soussociete}</td>
                                                <td className="priority-1" width="15%"> <b onClick={() => sendToClientProfile(items.client_code)} className="text-warning" style={{ cursor: 'pointer' }}>{items.client}</b></td>
                                                <td className="priority-3" width="15%"><a href={'/detail_releve/' + items.code}><u className="text-primary">{items.code}</u></a></td>
                                                <td className="priority-3" width="15%">{items.remise}</td>
                                                <td className="priority-1" width="15%"  ><a href={'/detail_releve/' + items.code}><u className="text-primary">{parseFloat(items.net_a_payer).toFixed(3)}</u></a>
                                                    <OverlayTrigger  rootClose trigger="click" placement="left" overlay={popover(items.soussociete, items.code, items.remise,t)}>
                                                        <i className="icon icon-plus text-success ml-2" />
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>
                                        </>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                        <div className="alert bg-purple mb-5 py-4" role="alert">
                            <div className="d-flex">
                                <i className="icon icon-info mr-3 fs-30"></i>
                                <div>
                                    <h4 className="alert-heading"> {t('todayCommercial.aucunevente')}</h4>
                                    <p>{t('todayCommercial.traiteparcommercial')+" : "+commercial} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
