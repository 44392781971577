import axios from "axios";

//GET URL FROM LOCALSTORAGE
const url = localStorage.getItem("url_nomadis");

const instance = axios.create({
    baseURL: url,
    withCredentials: true,
   /*  SameSite: "None",
    Secure: true, */
    IsEssential: true,
});

/**
 * get LIST COMMERCIALS
 * @returns
 */
export async function getNomCommercials() {
    try {
        const response = await instance.get(
            "users/selectUsers"
        );
        return response;
    } catch (error) {
        if (error?.response?.status === 401) {
            console.log(error?.response?.status)
            return error?.response?.status
        } else {
            console.log(error?.response)
        }
    }
}
/**
 * Get SUIVI Commercial
 * @param {string} datedebut 
 * @param {string} datefin 
 * @param {string} code 
 * @returns response
 */
export async function getSuiviCommercial(datedebut, datefin, code) {

    try {
        const response = await instance.get(
            "rapports/filterSuiviCommercial/" + code + "/" + datedebut + "/" + datefin + "/bl"
        );
        return response;
    } catch (error) {
        if (error.response.status === 401) {
            console.log(error.response.status)
            return error.response.status
        } else {
            console.log(error.response)
        }
    }
}
/**
 * Get Reporting Commercial
 * @param {string} datedebut 
 * @param {string} datefin 
 * @param {string} code 
 * @returns Response
 */
export async function getReportingCommercial(datedebut, datefin, code) {
    try {
        const response = await instance.get(
            "rapports/getTourneeByInterval?date_debut=" + datedebut + "&date_fin=" + datefin + "&user_code=" + code
        );
        return response;
    } catch (error) {
        if (error.response.status === 401) {
            console.log(error.response.status)
            return error.response.status
        } else {
            console.log(error.response)
        }
    }
}
/**
 * GET REGELEMENTS COMMERCIAL
 * @param {*} datedebut 
 * @param {*} datefin 
 * @param {*} code 
 * @returns 
 */
export async function getReglementsCommercial(datedebut, datefin, code) {
    try {
        const response = await instance.get(
            "rapports/getReglement?date_debut=" + datedebut + "&date_fin=" + datefin + "&user_code=" + code
        );
        return response;
    } catch (error) {
        if (error.response.status === 401) {
            console.log(error.response.status)
            return error.response.status
        } else {
            console.log(error.response)
        }
    }
}
    /**
     * Get veille commercial
     * @param {*} datedebut 
     * @param {*} datefin 
     * @param {*} code 
     * @returns 
     */
    export async function getVeilleCommercial(datedebut, datefin, code) {
        try {
            const response = await instance.get(
                "rapports/getNotesByTheme?date_debut=" + datedebut + "&date_fin=" + datefin + "&theme=INFORMATION&user_code=" + code
            );
            return response;
        } catch (error) {
            if (error.response.status === 401) {
                console.log(error.response.status)
                return error.response.status
            } else {
                console.log(error.response)
            }
        }
    }
    /**
     * get Tournee Commercials
     * @param {*} datedebut 
     * @param {*} datefin 
     * @param {*} code 
     * @returns 
     */
    export async function getTourneeCommercials ( datedebut, datefin, code){
        try {
            const response = await instance.get(
                "rapports/getTourneeByIntervalUsers?date_debut=" + datedebut + "&date_fin=" + datefin + "&user_code=" + code
            );
            return response;
        } catch (error) {
            if (error.response.status === 401) {
                console.log(error.response.status)
                return error.response.status
            } else {
                console.log(error.response)
            }
        }
    }
    /**
     * get Rapport Commercial Client
     * @param {*} month 
     * @param {*} commercial 
     */
    export async function getRapportCommercialClient (month, commercial){
        try {
            const response = await instance.get(
                "rapports/rapport_42_listClient?date="+month+"&user_code="+commercial
            );
            return response;
        } catch (error) {
            if (error?.response?.status === 401) {
                console.log(error?.response?.status)
                return error?.response?.status
            } else {
                console.log(error?.response)
            }
        }
    }

    export async function getRapportMobileCommercial (month, commercial){
        //rapports/rapport_42_listClientForMobile?date=2021-06&user_code=V2
    }
    /**
     * GET TOTAL BL
     * @param {*} datedebut 
     * @param {*} datefin 
     * @param {*} code 
     * @returns 
     */
    export async function getTOTALVentes(datedebut, datefin, code) {
        try {
            const response = await instance.get("bl/getByDate/"+datedebut+"/"+datefin+"/bl/"+code
            );
            return response;
        } catch (error) {
            if (error.response.status === 401) {
                console.log(error.response.status)
                return error.response.status
            } else {
                console.log(error.response)
            }
        }
    }
     /**
     * GET TOTAL Commandes
     * @param {*} datedebut 
     * @param {*} datefin 
     * @param {*} code 
     * @returns 
     */
      export async function getTotalCommandes(datedebut, datefin, code) {
        try {
            const response = await instance.get("bl/getByDate/"+datedebut+"/"+datefin+"/commande/"+code
            );
            return response;
        } catch (error) {
            if (error.response.status === 401) {
                console.log(error.response.status)
                return error.response.status
            } else {
                console.log(error.response)
            }
        }
    }
     /**
     * GET TOTAL Recouvrement
     * @param {*} datedebut 
     * @param {*} datefin 
     * @param {*} code 
     * @returns 
     */
      export async function getTotalRecouvrement(datedebut, datefin, code) {
        try {
            const response = await instance.get("recouvrements/get/"+datedebut+"/"+datefin+"/"+code
            );
            return response;
        } catch (error) {
            if (error.response.status === 401) {
                console.log(error.response.status)
                return error.response.status
            } else {
                console.log(error.response)
            }
        }
    }