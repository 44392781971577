import React, { useEffect, useRef, useState } from 'react'
//Web services
import { demande_chargement } from '../../services/Notification/notificationApi'
//Loader
import Loader from '../../components/shared/Loader'
import { SearchItems } from '../../utils/SearchUtils'
import { useTranslation } from 'react-i18next'
import ErrorAlert from '../../others/ErrorAlert'

export default function DemandeChargement() {

    // --- Chargement --------
    const [chargement, setchargement] = useState([])
    const [searchList, setSearchlist] = useState([])
    const [saveList, setSaveList] = useState([])
    // --- Loading -----------
    const [loading, setloading] = useState(true)
    //----- ALERTE -----------------
    const [alerte, setAlerte] = useState(false)
    // ---- Search ------
    const [search, setSearch] = useState("");
    const inputEl = useRef("")
    //Traduction
    const [t, i18n] = useTranslation("global");
    //CURRENT LIST RESULT
    const currentPosts = search.length <= 2 ? chargement : searchList

    useEffect(() => {
     demande_chargement()
      .then((res) => {
       if (res?.data !== null) {
           console.log(res?.data)
           setchargement(res?.data)
           setSaveList(res?.data)
       }
      })
      .catch((error) => {
          console.log(error)
      })
      setloading(false)

    }, [])

    // FONCTION DE RECHERCHE 
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (search !== "") {
            const newList = SearchItems(chargement, search)
            setSearchlist(newList)
        } else {
            setchargement(saveList)
        }
    }

    return (
        <div dir={i18n.dir()}>
            {loading ? (<Loader />) : (<>
                {/** ---------------- SEARCH FORM (input+boutton) ----------------------------------  */}
                <div className="search1 search-elements-sm" style={{ marginBottom: '10px' }}>
                    <div className="form row no-gutters">
                        <div className="form-group  col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 mb-0">
                            <input
                                type="text"
                                ref={inputEl}
                                className="form-control br-tr-md-0 br-br-md-0"
                                id="text1"
                                placeholder={t("stock.search")}
                                value={search}
                                onChange={() => getSearchTerm()}
                            />
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-2 col-2 mb-0">
                            <button
                                onChange={() => getSearchTerm()}
                                className="br-tl-md-0 br-bl-md-0 btn btn-block btn-primary fs-16">
                                <i className="fe fe-search" />
                            </button>
                        </div>
                    </div>
                </div>
                {/** ---------- CARD LIST ----------------------------------  */}
                <div className="row">
                    {currentPosts?.map((items, keys) => (
                        <div className="col-md-12 col-lg-4 col-xl-4 col-sm-12 col-12" key={keys} >
                            <div className="card" >
                                <div className="card-status bg-primary br-tr-7 br-tl-7" />
                                {/** ------------ TITRE HEADER ---------------------------- */}
                                <div className="card-header">
                                    <h3 className="card-title" style={{ marginTop: "10px" }}> {t('stock.commercial') + ' ' + items.nom}</h3>
                                </div>
                                {/** ---------- Tableau ----------------------------------  */}
                                <div className="card-body">
                                    <table className="table table-bordered table-striped">
                                        <tbody>
                                            <tr>
                                                <th scope="row" className='text-justify'> {t('stock.code')}</th>
                                                <td> <a className='text-success' href={"/chargement/" + items.numero}><b>{items.numero}</b></a> </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className='text-justify'>{t('stock.date')}</th>
                                                <td >{items.date} </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className='text-justify'> {t('releve.depot')} </th>
                                                <td >{items.depot_code} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </>)}
            {alerte ? <ErrorAlert t={t} /> : <></>}
        </div>
    )
}
