/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getStatus } from '../../../services/commande/commandeApi';
import { numberWithSpaces } from '../../../utils/NumberWithSpaces';

export default function CardVentes({ t, keys, i18n, items, getRecouvrementCommercial, getVentesCommercial, getCreditCommercial, getNbrBLCommercial, getVisiteCommercial, getCongeCommercial, tournee, visite }) {

    //Date of today Format (yyyy-mm-dd)
    const [status, setStatus] = useState(items.last_sync === null ? false : true)

    //Chercher le type tournée (bl , visite , etc.)
    const findElement = (items) => {
        const element = visite?.filter(
            (element) => element.client_code === items.code
        );
        return element;
    };
    // Fonction de synchronisation 
    const synch = (code) => {
        // Synchronisation last_syncw
        getStatus(code)
            .then((res) => {
                toast(t("todayCommercial.synchronisation") + " " + code, { type: "success", position: "bottom-center" })
            })
            .catch((err) => console.log(err));
        window.location.reload();
    };


    return (

        <div className="col-lg-4 col-12" dir={i18n.dir()} key={keys}>
            <div className="card box-widget widget-user">
                
                {/** ----------------- SECTION 1 : CARD HEADER --------------------- */}
                <div className="widget-user-header bg-purple">
                    <div className='row'>
                        <div className='col-7'>
                            <h6 className=" text-white mt-2"> {items.code} : {items.nom + " " + items.prenom}</h6>

                        </div>
                        <div className='col-5 float-right'>
                            <h6 className="widget-user-username ">
                                <label className="custom-switch">
                                    {t("todayCommercial.synch")}
                                    {/** ----------- SYNC EQUIPEMENT ------------------------ */}
                                    <input
                                        type="checkbox"
                                        name="custom-switch-checkbox"
                                        className="custom-switch-input"
                                        checked={status}
                                        onChange={(e) => {
                                            synch(items.code);
                                            setStatus(e.target.checked)
                                        }}
                                    />
                                    <span className="custom-switch-indicator" />
                                </label>
                            </h6>
                        </div>

                    </div>
                </div>
                {getCongeCommercial(items.code) === 1 ? (
                    <div className="arrow-ribbon bg-warning mt-8"><h6 className="mt-2" style={{ fontSize: "11px" }}>{t('contact.enconge')}</h6></div>
                ) : (<></>)}
                {/**  ----------- AVATAR IMAGE : Commerical ------------------------*/}
                <div className="widget-user-image">
                    {items.isconnected === 1 ? <img
                        alt="User Avatar"
                        style={{ height: '90px', padding: "0px", display: 'inline-block', borderLeftColor: '#D5EDDA', border: ' solid 3px #54BE69', borderRadius: '50%' }}
                        src={items.photo !== null && items.photo !== undefined? items.photo : "../assets/images/users/2.png"}
                    /> : <img
                        alt="User Avatar"
                        style={{ height: '90px', padding: "0px", display: 'inline-block', borderLeftColor: '#D5EDDA', border: ' solid 3px #f34336', borderRadius: '50%' }}
                        src={items.photo !== null && items.photo !== undefined? items.photo : "../assets/images/users/2.png"}
                    />}

                </div>
                {/** ------------------- BODY CARD ---------------------------- */}
                <div className="card-body text-center" style={{ paddingBottom: '0px' }} dir={i18n.dir('fr')}> 

                    {/** ------------------- TABLE CARD ---------------------------- */}

                    <div className="card-body text-center">
                        <div className="d-flex align-items-center justify-content-between mt-4"></div>
                        <div className="row">
                            <div className="col-sm-4 col-4 border-right border-top">
                                <div className="description-block">
                                    <h6 className=" text-primary">
                                        {/** Libelle CODE */}
                                        {getVentesCommercial(items.code) !== undefined ? getVentesCommercial(items.code) : 0}                                </h6>
                                    <span className="text-muted "> {t('todayCommercial.vente')}</span>
                                </div>
                            </div>
                            <div className="col-sm-4 col-4 border-right border-top">
                                <div className="description-block">
                                    <h6 className=" text-primary">
                                        {/** TOTAL VENTE */}
                                        {getNbrBLCommercial(items.code) !== undefined ? <a href={'/detailsbl/' + items.code} className="text-success"><u>{getNbrBLCommercial(items.code)}</u></a> : <a href={'/detailsbl/' + items.code} className="text-success"><u>0</u></a>}
                                    </h6>
                                    <span className="text-muted ">{t('todayCommercial.bl')}</span>
                                </div>
                            </div>
                            <div className="col-sm-4 col-4 border-top">
                                <div className="description-block">
                                    <h6 className=" text-primary">
                                        {/** TOTAL VENTE */}
                                        {getVisiteCommercial(items.code) !== undefined ? <a className="text-green" href={"/detailVisite/" + items.code}>
                                            <u style={{ cursor: "pointer" }}>
                                                {getVisiteCommercial(items.code)}
                                            </u>
                                        </a> : 0}
                                    </h6>
                                    <span className="text-muted "> {t("todayCommercial.visites")}</span>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-6 border-top border-right  border-bottom">
                                <div className="description-block">
                                    <h6 className=" text-primary">
                                        {/** Date DEBUT */}
                                        {getRecouvrementCommercial(items.code) !== undefined ? getRecouvrementCommercial(items.code) : 0}
                                    </h6>
                                    <span className="text-muted ">{t("todayCommercial.recouvrement")}</span>
                                </div>
                            </div>
                            <div className="col-sm-6  col-6 border-top border-bottom ">
                                <div className="description-block">
                                    <h6 className=" text-primary" dir={i18n.dir('fr')}>
                                        {/** Date Fin */}
                                        {getCreditCommercial(items.code) !== undefined ? numberWithSpaces(parseFloat(getCreditCommercial(items.code)).toFixed(3)) : 0}
                                    </h6>
                                    <span className="text-muted "> {t("todayCommercial.creditadate")}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="panel-group" id="accordion2" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                                <div className="panel panel-default active">
                                    <div className="panel-heading mt-0" role="tab" id={"heading" + keys}>
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion2" href={"#collapse" + keys} aria-expanded="true" aria-controls={"collapse" + keys}>
                                                <i className="ti-truck text-primary mr-2 " />  <u className=" text-primary"> {t('tournee.detailtournee')} </u>

                                            </a>
                                        </h4>
                                    </div>
                                    <div id={"collapse" + keys} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"heading" + keys}>
                                        <div className="panel-body">
                                            {/** ---------------- SEARCH FORM ------------------  */}
                                            <div className="description-block">
                                                {tournee.map((i, ke) => (
                                                    i.route.user_code === items.code && i.tournees.length !== 0 ? (
                                                        //
                                                        <>
                                                            <Link key={ke} to={"/tournee/" + i.route.routing_code + "/" + i.tournees[0].code + "/" + i.route.user_code + "/" + i.tournees[0].code_jour}>
                                                                <u className="text-primary"> Consulter détails </u>
                                                            </Link>
                                                            <div
                                                                className="align-content-center mb-3 mt-3"
                                                                style={{ justifyContent: "center", display: "flex", alignItems: "baseline" }}>
                                                                {i.tournees[0]?.clients.length !== 0 ?
                                                                    //SI tournees clients non vide 
                                                                    i.tournees[0]?.clients.map((o, x) => (
                                                                        <>
                                                                            {findElement(items).length === 0 ? (
                                                                                <div
                                                                                    className="w-4 h-4 bg-danger mr-1"
                                                                                    data-bs-toggle="tooltip"
                                                                                    data-bs-placement="top"
                                                                                    title="Non Visité"
                                                                                    key={x}
                                                                                />
                                                                            ) : (
                                                                                <>
                                                                                    {findElement(o).map((e, i) =>
                                                                                        e.type === "bl" ? (
                                                                                            <div
                                                                                                className="w-4 h-4 bg-lime mr-1"
                                                                                                data-bs-toggle="tooltip"
                                                                                                data-bs-placement="top"
                                                                                                title="BL"
                                                                                                key={i}
                                                                                               
                                                                                            />
                                                                                        ) : e.type === "visite" ? (
                                                                                            <div
                                                                                                className="w-4 h-4 bg-primary mr-1"
                                                                                                data-bs-toggle="tooltip"
                                                                                                data-bs-placement="top"
                                                                                                title="Visite"
                                                                                            />
                                                                                        ) : e.type === "commande" ? (
                                                                                            <div
                                                                                                className="w-4 h-4 bg-success mr-1"
                                                                                                data-bs-toggle="tooltip"
                                                                                                data-bs-placement="top"
                                                                                                title="Commande"
                                                                                            />
                                                                                        ) : e.type === "recouvrement" ? (
                                                                                            <div
                                                                                                className="w-4 h-4 bg-cyan mr-1"
                                                                                                data-bs-toggle="tooltip"
                                                                                                data-bs-placement="top"
                                                                                                title="Recouvrement"
                                                                                            />
                                                                                        ) : (
                                                                                            <></>
                                                                                        )
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    )) : (<></>)}
                                                            </div>
                                                        </>) : <>


                                                    </>
                                                ))}
                                                <div
                                                    className="align-content-center mb-4 mt-4"
                                                    style={{ justifyContent: "center", display: "flex", alignItems: "baseline" }}>
                                                    {tournee.map((i, ke) => (

                                                        i.route.user_code === items.code && i.tournees.length === 0 ? (
                                                            <h4 className="w-4 h-4 text-primary mr-1" key={ke}>?</h4>
                                                        ) : <> </>
                                                    ))}
                                                </div>
                                            </div>{/** ------------ CARD (qte / total / nbr produits) -----  */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                {/** ------------------- BOUTTON Stock CAMION ---------------------------------- */}
                <div className="card-body" style={{ marginTop: "0px" }} dir={i18n.dir('fr')}>
                    <a href={"/stocks/" + items.code}
                        className="btn bg-primary col-sm-12"
                    > <i className="ti-package mr-2" /> {t('todayCommercial.StockCamion')}
                    </a>
                </div>
            </div>
        </div>

    )
}
