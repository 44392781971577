/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { getLastVisiteClient } from "../../services/clients/ClientApi";
import moment from "moment";

export default function CardDerniereVisite({ code, t }) {
  //LAST VISITES
  const [lastVisite, setLastVisite] = useState({});
  useEffect(() => {
    //Derniere Visite
    getLastVisiteClient(code)
      .then((res) => {
        if (res?.data) {
          //console.log(res?.data)
          setLastVisite(res.data);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="card">
      <div className="card-header">
        <h4 className=" text-primary text-center mt-3 my-3 ">
          {t("detailClient.derniereVisite")} : {lastVisite.date_visite !== "" ? lastVisite.date_visite: t('historique.indisponible')}
        </h4>
        
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="form-group">
              <label htmlFor="exampleInputname">{t("detailClient.type")}</label>
              <input
                type="text"
                className="form-control text-primary"
                id="exampleInputname"
                placeholder="Type"
                readOnly
                value={
                  lastVisite.type_visite !== ""
                    ? lastVisite.type_visite
                    : t('historique.indisponible')
                }
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-6 col-6">
            <div className="form-group">
              <label htmlFor="exampleInputname">
                {t("detailClient.dernierAchat")}
              </label>
              <input
                type="text"
                className="form-control text-primary"
                id="exampleInputname"
                placeholder="Dernier Achat"
                readOnly
                value={
                  lastVisite.last_chiffre === null ||
                  lastVisite.last_chiffre === ""
                    ? "00"
                    : parseFloat(lastVisite.last_chiffre).toFixed(3)
                }
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-6">
            <div className="form-group">
              <label htmlFor="exampleInputname">
                {t("detailClient.maxachat")}
              </label>
              <input
                type="text"
                className="form-control text-primary"
                id="exampleInputname"
                placeholder="Maximum d'achat"
                readOnly
                value={
                  lastVisite.max_chiffre === null ||
                  lastVisite.max_chiffre === ""
                    ? "00"
                    : parseFloat(lastVisite.max_chiffre).toFixed(3)
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-4">
            <div className="form-group">
              <label htmlFor="exampleInputname">{t('detailClient.chiffreA')}</label>
              <input
                type="text"
                className="form-control text-primary"
                id="exampleInputname"
                placeholder="Chiffre d'affaire du mois"
                readOnly
                value={
                  lastVisite.totalchiffreMois === null ||
                  lastVisite.totalchiffreMois === ""
                    ? "00"
                    : parseFloat(lastVisite.totalchiffreMois).toFixed(3)
                }
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-4">
            <div className="form-group">
              <label htmlFor="exampleInputname">{t('detailClient.chiffreATot')}</label>
              <input
                type="text"
                className="form-control text-primary"
                id="exampleInputname"
                placeholder="Chiffre d'affaire TOTAL"
                readOnly
                value={
                  lastVisite.totalchiffre === null ||
                  lastVisite.totalchiffre === ""
                    ? "00"
                    : parseFloat(lastVisite.totalchiffre).toFixed(3)
                }
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-4">
            <div className="form-group">
              <label htmlFor="exampleInputname">CA ({moment().format("YYYY")})</label>
              <input
                type="text"
                className="form-control text-primary"
                id="exampleInputname"
                placeholder="Chiffre d'affaire TOTAL"
                readOnly
                value={
                  lastVisite?.totalchiffre_annee === null ||
                  lastVisite?.totalchiffre_annee === ""
                    ? "00"
                    : (isNaN(lastVisite?.totalchiffre_annee) ? 0.00  : parseFloat(lastVisite?.totalchiffre_annee).toFixed(3))
                }
              />
            </div>
          </div>
        </div>
        <div className="form-label text-primary">
         <i className="ti-pencil-alt mr-2 text-primary"></i>
         {t("client.derniereCommande")}                      
         </div>
         <div className="row">
          <div className="col-lg-6 col-md-6 col-6">
            <div className="form-group">
              <label htmlFor="exampleInputname">
              {t("client.derniereCommande")}
              </label>
              <input
                type="text"
                className="form-control text-primary"
                id="exampleInputname"
                placeholder="Derniere Commande"
                readOnly
                value={
                  lastVisite?.last_chiffre_commande === null ||
                  lastVisite?.last_chiffre_commande === ""
                    ? "00"
                    :(isNaN(lastVisite?.last_chiffre_commande) ? 0.00 : parseFloat(lastVisite?.last_chiffre_commande).toFixed(3))
                }
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-6">
            <div className="form-group">
              <label htmlFor="exampleInputname">
              {t("client.MaxCMD")}
              </label>
              <input
                type="text"
                className="form-control text-primary"
                id="exampleInputname"
                placeholder="Maximum d'achat"
                readOnly
                value={
                  lastVisite?.max_chiffre_commande === null ||
                  lastVisite?.max_chiffre_commande === ""
                    ? "00"
                    :(isNaN(lastVisite?.max_chiffre_commande) ? 0.00 : parseFloat(lastVisite?.max_chiffre_commande).toFixed(3))
                }
              />
            </div>
          </div>
        </div>
         <div className="row">
         <div className="col-lg-6 col-md-6 col-6">
            <div className="form-group">
              <label htmlFor="exampleInputname">
              {t("client.caCmd")+" ("+moment().format('MMMM')+")"}
               </label>
              <input
                type="text"
                className="form-control text-primary"
                id="exampleInputname"
                placeholder="Chiffre d'affaire du mois"
                readOnly
                value={
                  lastVisite?.totalchiffreMois_commande === null ||
                  lastVisite?.totalchiffreMois_commande === ""
                    ? "00"
                    : (isNaN(lastVisite?.totalchiffreMois_commande) ? 0.00 : parseFloat(lastVisite?.totalchiffreMois_commande).toFixed(3))
                }
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-6">
            <div className="form-group">
              <label htmlFor="exampleInputname">
              {t("client.caCmd")+" ("+moment().format('YYYY')+")"}

               </label>
              <input
                type="text"
                className="form-control text-primary"
                id="exampleInputname"
                placeholder="Chiffre d'affaire du mois"
                readOnly
                value={
                  lastVisite?.totalchiffre_annee_commande === null ||
                  lastVisite?.totalchiffre_annee_commande === ""
                    ? "00"
                    : (isNaN(lastVisite?.totalchiffre_annee_commande) ? 0.00 : parseFloat(lastVisite?.totalchiffre_annee_commande).toFixed(3))
                }
              />
            </div>
          </div>
        
        </div>
      </div>
    </div>
  );
}
