import axios from "axios";

//GET URL FROM LOCALSTORAGE
const url = localStorage.getItem("url_nomadis");

//INSTANCE
const instance = axios.create({
  baseURL: url,
  withCredentials: true,
/*   SameSite: "None",
  Secure: true, */
  IsEssential: true,
});

/**
 * GET Liste Congée
 * @returns response
 */
export async function getListeCongee() {
  try {
    const response = await instance.get(
      "demande_conge/valide"
    );
    return response;
  } catch (error) {
    if (error.response === undefined) {
      return false
    } else if (error.response.status === 422) {
      return 422
    } else if (error.response.status === 404) {
      return 404
    }
    else {
      console.error(error);
      return error.response
    }
  }
}