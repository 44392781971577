import React from 'react'
import Loader from '../components/shared/Loader'

export default function PageUnderConstruction() {
  return (
    <div className='h-75vh'>
      {/*Loader*/}
    
      <div className="limiter construction-limiter border-top-transparent">
        <div className="container">
          <div className="container-login">
            <div className="pt-3 pb-3 pt-sm-5 pb-sm-5 pl-0 pr-0 pl-sm-5 pr-sm-5">
              <div className="construction text-center">
                <h3 className="display-4 mt-5 font-weight-semibold mb-3">
                 Cette page est en cours de construction
                </h3>
                <h4> Revenez bientôt :-) et merci pour votre compréhension</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
