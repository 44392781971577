/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAllObjectifs, getObjectifsBeetween } from "../../services/Objectifs/ObjectifApi";
import Loader from "../../components/shared/Loader";
import { toast, ToastContainer } from "react-toastify";
import CardObjectifs from "./CardObjectifs";
import ErrorAlert from "../../others/ErrorAlert";

export default function Objectifs() {

  //List Objectifs
  const [list, setList] = useState([]);
  //DATE SEARCH (debut / fin)
  const [datedebut, setDateDebut] = useState("");
  const [dateFin, setDateFin] = useState("");
  //Traduction Object
  const [t, i18n] = useTranslation("global");
  //ERROR
  const [error, setError] = useState(false)
  //Loader Object
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    //Return list of all objectifs
    getAllObjectifs()
      .then((res) => {
        if (res?.data) {
          console.log(res.data)
          setList(res.data);
        }
      })
      .catch((error) => {
        console.log(error)
        setError(true)
      })
      setLoading(false);
  }, []);

  //Search function by date deb and date fin
  const searchByDate = () => {
    //Recherche par date ( faire app au web service getObjectifs)
    if (datedebut !== "" && dateFin !== "") {
      if (datedebut <= dateFin) {
        getObjectifsBeetween(datedebut, dateFin).then((res) => {
          if (res?.data) {
            setList(res.data);
          }
        });
      } else {
        toast(t('messages.datedebut_fin'),{type:'warning',position:'bottom-center'});
      }
    }
  };

  //Refresh List ( back to initial List)
  const backtolist = () => {
    getAllObjectifs()
      .then((res) => {
        if (res?.data) {
          setList(res.data);
        }
      })
      .catch((error) => setError(true))
      setLoading(false);
  };

  return (
    <div>
      {loading ? (<Loader />) : (
        <>
          {/**----------------Section Serch + Title--------------- */}
          <div className="row">
            <div className="col-md-12">
            <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                  <div className="panel-heading " role="tab" id="headingOne">
                    <h4 className="panel-title">
                      <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <i className="icon icon-grid text-primary mr-2 " />  {t("objectifs.cardtitre")}

                        <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                      </a>
                    </h4>
                  </div>
                  <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                    <div className="panel-body">
                      {/** ---------------- SEARCH FORM ------------------  */}
                      <div className="search1 search-elements-sm">
                    <div className="form row no-gutters">
                      <div className="form-group  col-xl-5 col-lg-5 col-md-6 col-6 mb-0">
                        <input
                          className="form-control fc-datepicker"
                          type="date"
                          value={datedebut}
                          onChange={(e) => setDateDebut(e.target.value)}
                        />
                      </div>
                      <div className="form-group  col-xl-5 col-lg-5 col-md-6 col-6 mb-0">
                        <input
                          className="form-control fc-datepicker"
                          type="date"
                          value={dateFin}
                          onChange={(e) => setDateFin(e.target.value)}
                        />
                      </div>
                      <div className="col-xl-1 col-lg-1 col-md-10 col-10 mb-0">
                        <button
                          className="br-tl-md-0 br-bl-md-0 btn btn-block btn-primary fs-16"
                          onClick={() => searchByDate()}
                        >
                          <i className="fe fe-search" />
                        </button>
                      </div>
                      <div className="col-xl-1 col-lg-1 col-md-2 col-2 mb-0">
                        <button
                          className="br-tl-md-0 br-bl-md-0 btn btn-block btn-success fs-16 align-center"
                          onClick={() => backtolist()}
                        >
                          <i className="fe fe-refresh-ccw" />
                        </button>
                      </div>
                    </div>
                  </div>
                {/** ------------ CARD (qte / total / nbr produits) -----  */}
                    </div>
                  </div>
                </div>
              </div>
            
            </div>
          </div>
          
          {/**Section LIST OF CARD objectifs + Graphe */}
          <div className="row">
          <ToastContainer />
            {list?.map((items, keys) => (
              <CardObjectifs el={items} keys={keys} t={t} />
            ))}
          </div>
        </>
      )}
      {error ? (<ErrorAlert t={t} />) : (<></>)}
    </div>
  );
}
