/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Loader from "../../../components/shared/Loader";
import { getVisitesClients } from "../../../services/commande/commandeApi";
import { getCurrentDate } from "../../../utils/DateUtils";
import MapCommande from "./MapCommande";

export default function MapVisites() {
  const { code } = useParams();
  const history = useHistory();

  const [visites, setVisites] = useState([]);
  //ERROR
  const [error, setError] = useState(false);
  //Loader
  const [loading, setLoading] = useState(false);
  //Date of today Format (yyyy-mm-dd)
  const today = getCurrentDate();
  //Languages
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
  setLoading(true);
  //GET LIST OF VISITES by commercials
  getVisitesClients(code, today)
    .then((res) => {
      if (res?.data) {
        setVisites(res.data.visites);
      }
    })
    .catch((error) => {
      console.log(error)
      setError(true);
    }).finally(()=>{
      setLoading(false);
    })
  
  }, []);

  return (
    <div>
      {/** RETOUR PAGE PRECEDENTE SECTION */}
      <div className="section-title">
        <button className="btn bg-primary " onClick={() => history.goBack()}>
          <i className="fe fe-arrow-left" />
          {t("stock.retour")}
        </button>
      </div>
      {/**END SECTION RETOUR EN ARRIERE */}
      {loading ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div className="card">
            <div className='card-header'>
            <div className="float-left">
            <h3 className="card-block mb-0"> {t('todayCommercial.listeVisite')} </h3>
            </div>
            </div>
              <div className="card-body">
                <div className="clearfix">
                  {visites?.length === 0 ? (
                    <div className="alert alert-info" role="alert">
                      <span className="alert-inner--icon">
                        <i className="fe fe-bell" />
                      </span>
                      <span className="alert-inner--text">
                        <strong>{t('todayCommercial.informationvisite')} </strong>{t('todayCommercial.messageVisite')}
                      </span>
                    </div>
                  ) : (<>
                   <MapCommande visites={visites} />
                   </>     
                   )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
