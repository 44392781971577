/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import Loader from '../../../components/shared/Loader'
import { gestionCommadesBydate } from '../../../services/commande/commandeApi'

export default function GestionCommandes() {
  //------ History -----------------
  const history = useHistory()
  //Traduction
  const [t, i18n] = useTranslation('global')
  const { date } = useParams()
  //---------- FORMULAIRE --------------
  const [dateCmd, setDateCmd] = useState(date)
  const [listeCurrentData, setListeCurrentData] = useState([])
  //---------- Loader  ----------------
  const [loading, setloading] = useState(false)
  // -------- ERROR ------------------
  /*   const [error, setError] = useState(false)
    const [ok, setOk] = useState(false)
    const [numBl, setNumBl] = useState('') */

  const data = {
    Chargé: 'bg-lime',
    Sortie: 'bg-azure',
    Livré: 'bg-success',
    Annulé: 'bg-danger',
    Preparation: 'bg-yellow',
  }

  useEffect(() => {
    if (date !== "") {
      setloading(true)
      gestionCommadesBydate(dateCmd)
        .then((result) => {
          if (result?.data) {
            //---- data ----
            let group = result?.data.cmd_current_day.reduce((r, a) => {
              r[a.libelle] = [...(r[a.libelle] || []), a]
              return r
            }, {})
            setListeCurrentData(Object.values(group))
            setloading(false)
          }
        })
        .catch((err) => console.log(err))
    }
  }, [])

  const onSearchDate = () => {
    if (dateCmd !== '') {
      history.push({
        pathname: '/gestion_commandes/date=' + dateCmd,
      })
      gestionCommadesBydate(dateCmd)
        .then((result) => {
          if (result?.data) {
            //---- data ----
            let group = result?.data.cmd_current_day.reduce((r, a) => {
              r[a.libelle] = [...(r[a.libelle] || []), a]
              return r
            }, {})
            setListeCurrentData(Object.values(group))
          }
        })
        .catch((err) => console.log(err))
    }
  }

  const goToDetailsCamions = (camion) => {
    ///details_camions/camion=:codecamion&date=:date
    history.push({
      pathname: '/details_camions/camion=' + camion + '&date=' + date,
    })
  }

  return (
    <div>
      <div
        className="panel-group"
        id="accordion1"
        role="tablist"
        aria-multiselectable="true"
        style={{ marginBottom: '10px' }}
      >
        <div className="panel panel-default active">
          <div className="panel-heading " role="tab" id="headingOne">
            <h4 className="panel-title">
              <a
                role="button"
                data-toggle="collapse"
                data-parent="#accordion1"
                href="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <i className="fa fa-cubes text-primary mr-2" /> {t("commandes.suivi_cmd")}
              </a>
            </h4>
          </div>
          <div
            id="collapseOne"
            className="panel-collapse collapse"
            role="tabpanel"
            aria-labelledby="headingOne"
          >
            <div className="panel-body mb-2">
              <div className="form row no-gutters">
                <div className="form-group  col-xl-10 col-lg-10 col-md-10   col-10 mb-0">
                  <div className="d-flex">
                    <input
                      className="form-control fc-datepicker"
                      type="date"
                      value={dateCmd}
                      onChange={(e) => setDateCmd(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-2 col-2 mb-0">
                  <button
                    className="br-tl-md-0 br-bl-md-0 btn btn-block btn-success fs-16"
                    onClick={() => onSearchDate()}
                  >
                    <i className="fe fe-search" />
                  </button>
                </div>
              </div>
              <p className="mt-2 text-info text-center">
                {t('commandes.message_suivicmd')}
              </p>
              <div className="row push container">
                <div className="d-flex align-items-center mb-3 mt-3 mr-2">
                  <div className="w-4 h-4 bg-lime  mr-2" />
                  <div>
                    <strong>{t('commandes.charge')}</strong>
                    <br />
                  </div>
                </div>{' '}
                <div className="d-flex align-items-center mb-3 mt-3 mr-2">
                  <div className="w-4 h-4 bg-yellow  mr-2" />
                  <div>
                    <strong> {t('commandes.preparation')} </strong>
                    <br />
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3 mt-3 mr-2">
                  <div className="w-4 h-4 bg-cyan  mr-2" />
                  <div>
                    <strong>{t('commandes.sortie')}</strong>
                    <br />
                  </div>
                </div>
                <div className="d-flex align-items-center  mb-3 mt-3 mr-2">
                  <div className="w-4 h-4 bg-success  mr-2" />
                  <div>
                    <strong>{t('commandes.livre')}</strong>
                    <br />
                  </div>
                </div>
                <div className="d-flex align-items-center  mb-3 mt-3 mr-2">
                  <div className="w-4 h-4 bg-danger  mr-2" />
                  <div>
                    <strong> {t('commandes.annule')} </strong>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {listeCurrentData.length !== 0 ? (
        <div className="row ">
          {listeCurrentData?.map((items, keys) => (
            <div className="col-md-6 col-xl-6 col-lg-6 col-12" key={keys}>
              {loading ? (
                <Loader />
              ) : (
                <div className="card box-widget widget-user">
                  <div className="widget-user-header bg-purple">
                    <h4 className="widget-user-username text-center">
                      <u onClick={() => goToDetailsCamions(items[0].libelle)}>
                        {items[0].libelle}
                      </u>
                    </h4>
                  </div>
                  <div className="widget-user-image">
                    <img
                      alt="User Avatar"
                      className="rounded-circle"
                      src="../assets/images/users/2.png"
                    />
                  </div>
                  <div className="box-footer">
                    <div className="row">
                      {/**Code TOURNEE BLOC */}
                      <div className="col-sm-6 col-6 border-right border-top">
                        <div className="description-block">
                          <h5 className="description-header text-primary">
                            {/**Nom Utilisateur */}
                            {items[0].user}
                          </h5>
                          <span className="text-muted ">{t('stock.commercial')}</span>
                        </div>
                      </div>
                      {/**ROUTING CODE BLOC */}
                      <div className="col-sm-6 col-6 border-right border-top">
                        <div className="description-block">
                          <h5 className="description-header text-primary">
                            {items.length}
                          </h5>
                          <span className="text-muted ">{t('client.nbCmd')}</span>
                        </div>
                      </div>

                    </div>

                    <div className="row">
                      <div className="col-sm-12  border-top">
                        <div className="description-block">
                          <h5 className="description-header">
                            <div
                              className="align-content-center mb-3 mt-3"
                              style={{
                                justifyContent: 'center',
                                display: 'flex',
                                alignItems: 'baseline',
                              }}
                            >
                              {items.map((i,keys) => (
                                <div onClick={() => goToDetailsCamions(items[0].libelle)}
                                  className={'w-4 h-4 mr-1 ' + data[i.status]}
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="BL"
                                  key={keys}
                                />
                              ))}
                            </div>
                          </h5>
                          <span className="text-muted ">{t('suivi.Suivi')}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}


            </div>
          ))}
        </div>
      ) : date !== '' && listeCurrentData.length === 0 ? (
        <div>
          <div>
            <div className="alert bg-info mb-5 py-4" role="alert">
              <div className="d-flex">
                <i className="fe fe-check-circle mr-3 fs-30" />
                <div dir={i18n.dir()}>
                  <h4 className="alert-heading">{t('messages.aucunecommande')}</h4>
                  <p>{t('messages.aucunecmdauj')} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div>
            <div className="alert bg-purple mb-5 py-4" role="alert">
              <div className="d-flex">
                <i className="fe fe-check-circle mr-3 fs-30" />
                <div dir={i18n.dir()}>
                  <h4 className="alert-heading">{t('messages.selectionnerdate')} </h4>
                  <p>{t('messages.messageselect')} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
