/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import ReactJsAlert from "reactjs-alert";

export default function ErrorCnx({t}) {
    const [status, setstatus] = useState(true);
    return (
        <div>
             <ReactJsAlert
      type="warning" // success, warning, error, info
      title="Connexion !" // title you want to display
      status={true} // true or false
      color="#FA3434"
      quotes={true}
      quote="Veuillez Vérifier votre connexion internet !"
      Close={() => setstatus(false)}
      // callback method for hide
    />
        </div>
    )
}
