/**
 * compare two time
 * @param {*} d1
 * @param {*} d2
 */
export function compareTwoTime(t1, t2) {
  if (t1 > t2) {
    return true;
  } else {
    return false;
  }
}

/**
 * Comparer date debut et date fin 
 * @param {date} d1 => DATE FIN
 * @param {date} d2 => DATE DEBUT
 */
export function compareTwoDatee(d1, d2) {
  const date1 = new Date(d1);
  const date2 = new Date(d2);
  const date3 = new Date();

  if (date1 >= date3 && date3 >= date2) {
    return true;
  } else {
    return false;
  }
}

/**
 * soustraire date from now date
 */
export function soustractDateFromToday(d1) {
  const date1 = new Date(d1);
  const date2 = Date.now();
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return Math.round(diffDays / 365);
}

/**
 * get date name
 */
export function getMonthName(month) {
  const monthNames = [
    "Jan",
    "Fév",
    "Mar",
    "Apr",
    "May",
    "Juin",
    "Juil",
    "août",
    "Sept",
    "Oct",
    "Nov",
    "Déc",
  ];
  return monthNames[month - 1];
}

/**
 * get date for objectifs month ( Frensh version)
 */
export function getMonthNameFrensh(month) {
  const monthNames = [
    "janvier",
    "fevrier",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "aout",
    "septembre",
    "octobre",
    "novembre",
    "decembre",
  ];
  return monthNames[month - 1];
}
/**
 * get date for objectifs month ( Frensh version)
 */
export function getMonthNameArabic(month) {
  const monthNames = [
    "يناير",
    "فبراير",
    "مارس",
    "ابريل",
    "مايو",
    "يونيو",
    "يوليو",
    "أغسطس",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
  ];
  return monthNames[month - 1];
}
/**
 * get date for objectifs month ( Frensh version)
 */
export function getMonthNameVente(month) {
  const monthNames = [
    "r_janvier",
    "r_fevrier",
    "r_mars",
    "r_avril",
    "r_mai",
    "r_juin",
    "r_juillet",
    "r_aout",
    "r_septembre",
    "r_octobre",
    "r_novembre",
    "r_decembre",
  ];
  return monthNames[month - 1];
}
/**
 * get date for objectifs month ( Frensh version)
 */
export function getMonthNameCmd(month) {
  const monthNames = [
    "c_janvier",
    "c_fevrier",
    "c_mars",
    "c_avril",
    "c_mai",
    "c_juin",
    "c_juillet",
    "c_aout",
    "c_septembre",
    "c_octobre",
    "c_novembre",
    "c_decembre",
  ];
  return monthNames[month - 1];
}
/**
 * add number to date
 * @param {INTEGER} number
 * @param {DATE} date
 * @returns
 */
export function addNumberToDate(number, date) {
  var someDate = new Date(date);
  someDate.setDate(someDate.getDate() + number);
  var dd = someDate.getDate();
  var mm = someDate.getMonth() + 1;
  var y = someDate.getFullYear();

  var someFormattedDate = y + "-" + mm + "-" + dd;
  return someFormattedDate;
}

/**
 * Date between tow date
 * @param {DATE} start
 * @param {DATE} end
 * @param {ARRAY} arrayObject
 * @returns
 */
export function dateBetweenTwoDates(start, end, arrayObject) {
  var from = new Date(start);
  var to = new Date(end);
  const newList = arrayObject.filter(
    (el) => new Date(el.date) > from && new Date(el.date) < to
  );
  return newList;
}

/**
 * get current date formated
 * @returns
 */
export function getCurrentDate() {
  var today = new Date();
  return (
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate()
  );
}

/**
 * get current Month
 * @returns
 */
export function getCurrentMonth() {
  var today = new Date();
  return today.getMonth() + 1;
}

/**
 * get current years
 * @returns
 */
export function getCurrentYears() {
  var today = new Date();
  return today.getFullYear();
}

export function sameDay(d1, d2) {
  var date1 = new Date(d1);
  var date2 = new Date(d2);
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}
