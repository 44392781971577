/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import SquareLoader from '../../../components/Loader/SquareLoader';
//import { getEtatPaiementToday, getRecouvrementByMonth } from '../../../services/commande/commandeApi';

export default function Recouvrement({ t, url }) {

  const [recouvrement, setRecouvrement] = useState("00");
  const [recouMonth, setRecouvrementMonth] = useState("00")// Recouvrement mont
  //Loader
  const [loading, setLoading] = useState(true);
  //ERROR
  const [error, setError] = useState(false);
  //GET URL FROM LOCALSTORAGE

  useEffect(() => {
    setLoading(true)
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var date1 = firstDay.getFullYear() + "-" + (firstDay.getMonth() + 1) + "-" + firstDay.getDate()

    var date2 = lastDay.getFullYear() + "-" + (lastDay.getMonth() + 1) + "-" + lastDay.getDate()


    // 1- SERVICE : GET RECOUVREMENT
    axios.get(url + "paiements/getEtatPaiementAujourdhui/recouvrement",
      {
        SameSite: "None",
        secure: true,
        IsEssential: true,
        withCredentials: true,
      })
      .then((res) => {
        if (res?.data[0].recouvrement !== null) {
          setRecouvrement(parseFloat(res?.data[0]?.recouvrement).toFixed(3));
        }
      })
      .catch((error) => console.log(error));

    // 2- GET ALL RECOUVREMENTS
    axios.get(url + "recouvrements/get/" + date1 + "/" + date2,
      {
        SameSite: "None",
        secure: true,
        IsEssential: true,
        withCredentials: true
      })
      .then((res) => {
        if (res.data !== null) {
          const total = res?.data
            .reduce(
              (totalsum, { montant }) => totalsum + parseFloat(montant), 0);
          setRecouvrementMonth(total)
          setLoading(false)
        }
      })
      .catch((error) => console.log(error))

  }, [])

  return (
    <>
      {loading ? (
        <SquareLoader />
      ) : (
        <div className="card overflow-hidden ">
          <div className="card-body">
            <div className="d-flex">
              <div >
                <p className="mb-2 h6">{t("listecmdvente.totalrecouvrement")}</p>
                <h5 className="mb-1 ">
                  {parseFloat(recouvrement).toFixed(3)}
                  <span className="font-weight-normal text-white fs-13">
                    / {t("commandes.today")}
                  </span></h5>
                <hr style={{ marginTop: "10px", marginBottom: "10px", borderTop: "1px solid transparent" }} />
                <p className="mb-0 text-muted mr-2">
                  <span className="text-success">
                    ({recouMonth === null ? "0.00" : parseFloat(recouMonth).toFixed(3)}) ({moment().format("MMMM")})
                  </span></p>
              </div>
              <div className=" my-auto ml-auto">
                <div className="chart-wrapper text-center">
                  <i className="ti-layers text-primary  fa-3x overflow-hidden mx-auto"
                    width={100} height={100} />
                </div>
              </div>
            </div>
          </div>
        </div>)}
    </>
  )
}
