import React from 'react'
import { numberWithSpaces } from '../../../utils/NumberWithSpaces'

export default function CardVente({t,vente}) {
  return (
    <div className="card box-shadow-0 mb-lg-0">
      <div className="card-body">
        <div className="item-box text-center">
        <div className="bg-purple-transparent icon-bg icon-service text-purple">
        <i className="fa fa-dollar" />
       </div>
          <div className="item-box-wrap">
            <p className="text-muted  pmobiletext mb-0">{isNaN(parseFloat(vente)) ? 0 : numberWithSpaces(parseFloat(vente).toFixed(3))}</p>
            <h6 className=" mobiletext mb-2"  style={{fontSize:"12px"}}> {t('todayCommercial.vente')} </h6>
          </div>
        </div>
      </div>
    </div>
  )
}
