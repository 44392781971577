/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Loader from "react-spinners/BeatLoader";

export default function CardTournee({t, el, tab, visite }) {
  //Loader
  const [loading, setLoading] = useState(false);

  //Chercher le type tournée (bl , visite , etc.)
  const findElement = (items) => {
    const element = visite?.filter(
      (element) => element.client_code === items.code
    );
    return element;
  };

  return (
    <div className="col-xl-6 col-lg-6 col-md-12">
      {loading ? (
        <Loader />
      ) : (
        <div className="card box-widget widget-user">
          <div className="widget-user-header bg-purple">
            <h4 className="widget-user-username">{el.route.user}</h4>
          </div>
          <div className="widget-user-image">
            <img
              alt="User Avatar"
              className="rounded-circle"
              src="../assets/images/users/2.png"
            />
          </div>
          <div className="box-footer">
            <div className="row">
              {/**Code TOURNEE BLOC */}
              <div className="col-sm-4 border-right border-top">
                <div className="description-block">
                  <h5 className="description-header text-primary">
                    {/**CODE TOURNEE */}
                    <Link to={"/tournee/" +el.route.routing_code +"/" +tab.code +"/" + el.route.user_code +"/" +tab.code_jour}>
                    <u className="text-primary">{tab.code}</u>
                    </Link>
                  </h5>
                  <span className="text-muted ">{t('tournee.codetournee')}</span>
                </div>
              </div>
              {/**ROUTING CODE BLOC */}
              <div className="col-sm-4 border-right border-top">
                <div className="description-block">
                  <h5 className="description-header text-primary">
                    {/** Routing CODE */}
                    {el.route.routing_code}
                  </h5>
                  <span className="text-muted ">{t('detailClient.coderouting')}</span>
                </div>
              </div>
              {/**Fréquence BLOC */}
              <div className="col-sm-4 border-top">
                <div className="description-block">
                  <h5 className="description-header text-primary">
                    {/** Fréquence*/}
                    {tab.frequence}
                  </h5>
                  <span className="text-muted ">{t('tournee.frequence')}</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12  border-top">
                <div className="description-block">
                  <h5 className="description-header">
                    {/**CODE TOURNEE */}
                    <div
                      className="align-content-center mb-3 mt-3"
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "baseline",
                      }}
                    >
                      {tab.clients.map((items, key) => (
                        <div  className="align-content-center mb-3 mt-3"
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "baseline",
                        }}>
                          {findElement(items).length === 0 ? (
                            <div
                              className="w-4 h-4 bg-danger mr-1"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Non Visité"
                            />
                          ) : (
                            <>
                              {findElement(items).map((e, i) =>
                                e.type === "bl" ? (
                                  <div
                                    className="w-4 h-4 bg-lime mr-1"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="BL"
                                  />
                                ) : e.type === "visite" ? (
                                  <div
                                    className="w-4 h-4 bg-primary mr-1"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Visite"
                                  />
                                ) : e.type === "commande" ? (
                                  <div
                                    className="w-4 h-4 bg-success mr-1"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Commande"
                                  />
                                ) : e.type === "recouvrement" ? (
                                  <div
                                    className="w-4 h-4 bg-cyan mr-1"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Recouvrement"
                                  />
                                ) : (
                                  <></>
                                )
                              )}
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </h5>
                  <span className="text-muted ">{t('todayCommercial.visites')}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
