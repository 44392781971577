/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getNotes } from '../../services/Notification/notificationApi'
import Loader from '../../components/shared/Loader'
import { useTranslation } from 'react-i18next'
import ErrorAlert from '../../others/ErrorAlert'


// --------- PAGE QUI CONTIENT LE MESSAGE DE LA NOTE EDITé PAR LE COMMERCIAL
//----- PAR ID DANS LE PARAMETRE

export default function ListeNotes() {

    const { id } = useParams()
    // ------------- LOADER --------------------
    const [loading, setloading] = useState(true)
    // ----------- LISTE DES NOTES ------------- 
    const [notes, setnotes] = useState([])
    //------------- STATUS -------------------
    const [etat, setEtat] = useState(false)
    //------- Alerte -------------
    const [alerte, setalerte] = useState(false)
    // ----- Traduction ----------
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        //GET NOTE BY ID 
        getNotes(id)
            .then((res) => {
                //console.log(res.data)
                setnotes(res?.data)
                if (res?.data.etat === 1) {
                    setEtat(true)
                }
            })
            .catch((error) => {
                console.log(error)
                //setalerte(true)
            })
        setloading(false)

    }, [])

    return (
        <div className="col-12" dir={i18n.dir()}>
            {loading ?
                <Loader />
                : (
                    <div className="card">
                        {/** ---------- CARD HEADER : TITLE ------------- */}
                        <div className="card-header">
                            <div className="row">
                                <div className="col-6">
                                    <div className="card-title">
                                        {t('notes.notes')} : {notes.id}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label className="custom-switch float-right" >
                                        {t('notes.etat')}
                                        {/**SYNC EQUIPEMENT */}
                                        <input
                                            type="checkbox"
                                            name="custom-switch-checkbox"
                                            className="custom-switch-input"
                                            checked={etat}
                                            onChange={(e) => {
                                                setEtat(e.target.checked)
                                            }}
                                        />
                                        <span className="custom-switch-indicator" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/** -------------- CARD BODY  ------------- */}
                        <div className="card-body">
                            <div className="form-group">
                                <label htmlFor="exampleInputnumber">{t('notes.objet')}</label>
                                <input type="text" readOnly value={notes.objet} className="form-control text-primary" />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('notes.message')}</label>
                                <textarea className="form-control text-primary" rows={6} readOnly defaultValue={notes.message} />
                            </div>
                            <div className="row">
                                <div className=" col-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputname">{t('releve.depot')}</label>
                                        <input type="text" value={notes.depot} readOnly className="form-control text-primary" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputname"> {t('stock.date')} </label>
                                        <input type="text" className="form-control text-primary" value={notes.date} readOnly />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputname"> {t('releve.client')} </label>
                                        <input type="text" className="form-control text-primary" value={notes.client !== null ? notes.client : " Aucun "} readOnly />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            {alerte ? <ErrorAlert t={t} /> : <></>}
        </div>


    )
}
