/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
//--------- ALERTE PACKAGE ------------------
import { toast, ToastContainer } from "react-toastify";
//------------ COMPONENTS ---------------------
import Loader from "../../components/shared/Loader";
import ErrorAlert from "../../others/ErrorAlert";
//-----------WEB SERVICES ----------------------
import { getUserByCode } from "../../services/clients/ClientApi";

export default function AccessClient() {
  //----------HISTORY---------
  const history = useHistory();
  //------------ CODE-----------------
  const [code, setCode] = useState("");
  //------------ ALERTE ---------------
  const [alert, setAlert] = useState(false);
  //-------- CONTENUE ALERT -----------
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  //---------- Languages -----------------
  const [t, i18n] = useTranslation("global");

  const verifAutorisation = () => {

    setAlert(false);
    if (code !== "") {
      getUserByCode(code)
        .then((res) => {
          if (res.data.id !== null) {
            localStorage.setItem("codeclient", code);
            history.push({
              pathname: "/clients",
            });
          } else {
            toast(t('messages.aucunclient'), {
              type: "error",
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch((error) => {
          setAlert(true);
        });
    }
  };
  return (
    <div className="h-75vh">
      <ToastContainer />
      {/* Page opened */}
      <div className="page">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-10 justify-content-center mx-auto text-center">
              <div className="card">
                <div className="row">
                  <div className="col-md-12 col-xl-6 col-lg-6 pr-0 d-none d-lg-block">
                    <img
                      src="../assets/images/photos/login3.jpg"
                      alt="img"
                      className="br-tl-2 br-bl-2 "
                    />
                  </div>
                  <div className="col-md-12 col-xl-6 col-lg-6 pl-0">
                    <div className="card-header">
                      <hr className='col-md-12 col-xl-12 col-lg-12' style={{ borderColor: "transparent", marginTop: '0px', marginBottom: '0px' }} />
                    </div>
                    <div className="card-body p-6 about-con pabout">


                      <div className="card-title text-center mb-5">
                        {t("client.textaccess")}
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("client.inputtext")}
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                        />
                      </div>
                      {loading ? (
                        <div className="form-footer mt-1">
                          <button
                            onClick={() => verifAutorisation()}
                            className="btn btn-success btn-block"
                          >
                            {t("client.valider")} <Loader></Loader>
                          </button>
                        </div>
                      ) : (
                        <div className="form-footer mt-1">
                          <button
                            onClick={() => verifAutorisation()}
                            className="btn btn-success btn-block"
                          >
                            {t("client.valider")}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {alert ? <ErrorAlert t={t} /> : <></>}
    </div>
  );
}
