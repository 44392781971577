import React from 'react'
import { numberWithSpaces } from '../../../utils/NumberWithSpaces'

export default function CardRecouvrement({t,recouvrement}) {
  return (
    <div className="card box-shadow-0 mb-lg-0">
      <div className="card-body">
        <div className="item-box text-center">
        <div className="bg-pink-transparent icon-bg icon-service text-pink">
        <i className="fa fa-gg-circle" />
       </div>
          <div className="item-box-wrap">
            <p className="text-muted  pmobiletext mb-0">{isNaN(recouvrement) ? 0 : numberWithSpaces(parseFloat(recouvrement).toFixed(3))}</p>
            <h6 className=" mobiletext mb-2" style={{fontSize:"12px"}} >  {t('todayCommercial.recouvrement')}  </h6>
          </div>
        </div>
      </div>
    </div>
  )
}
