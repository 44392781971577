/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "react-spinners/BeatLoader";
import ErrorAlert from "../../others/ErrorAlert";
import { getAllVisite,getTourneeComerciaux} from "../../services/Tournee/TourneeApi";
import CardTournee from "./CardTournee";

export default function ListeTournee() {
  //Liste des Tournee
  const [liste, setListe] = useState([]);
  //Tournee
  const [tournee, setTournee] = useState([]);
  //Liste des Visites EFFECTUEE
  const [visite, setVisites] = useState([]);
  //Search
  const [search, setSearch] = useState("");
  //Error + Loader
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  //Traduction
  const [t, i18n] = useTranslation("global");

  //Search Function
  const searchList = () => {
    setLoading(true);
    // Create a regex object (a constructor)
    let regex = new RegExp(search.toUpperCase());
    if (search !== "") {
      const el = liste?.map((items, key) => {
        const i = items.tournees?.filter(({ code, routing_code, frequence }) =>
          code !== null
            ? regex.test(code.toUpperCase())
            : "" || routing_code !== null
            ? regex.test(routing_code.toUpperCase())
            : "" || frequence !== null
            ? regex.test(frequence.toUpperCase())
            : ""
        );
        {
          i.length !== 0 ? tournee.push(items) : <></>;
        }
      });
      setListe(tournee);
    }
    setLoading(false);
  };

  //Back to initial List
  const backList = () => {
    setLoading(true);
    setTournee([]);
      //GET Tournee par comerciaux
      getTourneeComerciaux()
        .then((res) => {
          setListe(res.data);
          setLoading(false);

        })
        .catch((error) => setError(true));

  };

  useEffect(() => {
  
      //GET Tournee par comerciaux
      getTourneeComerciaux()
        .then((res) => {
          setListe(res.data);
        })
        .catch((error) => setError(true));
      //GET Visites
      getAllVisite()
        .then((res) => {
          if (res?.data?.length !== 0) {
            const listeVisite = res?.data;
            setVisites(listeVisite);
            setLoading(false);

          }
        })
        .catch((error) => setError(true));
   
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
        {/** ----------------- LIGNE (row) filtre et recherche ---------------- */}
          <div className="row">
            <div className="col-md-12">
              {/**  ------------------------ CARD Recherche & Filtre Tournee ---------------- */}
              <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                  <div className="panel-heading " role="tab" id="headingOne">
                    <h4 className="panel-title">
                      <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <i className="icon icon-grid text-primary mr-2 " />  {t("tournee.visitecommerciaux")}
                        <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                      </a>
                    </h4>
                  </div>
                  <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                    <div className="panel-body">
                      {/** ---------------- SEARCH FORM ------------------  */}
                      <div className="search1 search-elements-sm">
                    <div className="form row no-gutters">
                      <div className="form-group  col-xl-9 col-lg-9 col-md-12 mb-0">
                        <input
                          type="text"
                          className="form-control br-tr-md-0 br-br-md-0"
                          id="text1"
                          placeholder={t('stock.search')}
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>

                      <div className="col-xl-2 col-lg-2 col-md-10 col-10 mb-0">
                        <button
                          className="br-tl-md-0 br-bl-md-0 btn btn-block btn-primary fs-16"
                          onClick={() => searchList()}
                        >
                         {t('stock.search')}
                        </button>
                      </div>
                      <div className="col-xl-1 col-lg-1 col-md-2 col-2 mb-0">
                        <button
                          className="br-tl-md-0 br-bl-md-0 btn btn-block btn-primary fs-16"
                          onClick={() => backList()}
                        >
                          <i className="fe fe-refresh-ccw" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row push container">
                    <div className="d-flex align-items-center mb-3 mt-3 mr-2">
                      <div className="w-4 h-4 bg-success  mr-2" />
                      <div>
                        <strong>{t('commandes.title')}</strong>
                        <br />
                      </div>
                    </div>{" "}
                    <div className="d-flex align-items-center mb-3 mt-3 mr-2">
                      <div className="w-4 h-4 bg-danger  mr-2" />
                      <div>
                        <strong>{t('todayCommercial.nonVisite')}</strong>
                        <br />
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-3 mt-3 mr-2">
                      <div className="w-4 h-4 bg-cyan  mr-2" />
                      <div>
                        <strong>{t('todayCommercial.recouvrement')}</strong>
                        <br />
                      </div>
                    </div>
                    <div className="d-flex align-items-center  mb-3 mt-3 mr-2">
                      <div className="w-4 h-4 bg-lime  mr-2" />
                      <div>
                        <strong>{t('todayCommercial.bl')}</strong>
                        <br />
                      </div>
                    </div>
                    <div className="d-flex align-items-center  mb-3 mt-3 mr-2">
                      <div className="w-4 h-4 bg-primary  mr-2" />
                      <div>
                        <strong>{t('todayCommercial.visites')}</strong>
                        <br />
                      </div>
                    </div>
                  </div>
               {/** ------------ CARD (qte / total / nbr produits) -----  */}
                    </div>
                  </div>
                </div>
              </div>
         
            </div>
          </div>
               
          {/** -------------- LISTE DES TOURNEE CLIENTS  --------------*/}
          <div className="row">
            {liste?.length !== 0 ? (
              liste?.map((items, key) =>
                items.tournees.length !== 0 ? (
                  items.tournees.map((tab, i) => (
                    <CardTournee t={t} el={items} tab={tab} visite={visite} />
                  ))
                ) : (
                  <></>
                )
              )
            ) : (
              <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-12">
                  <div className="alert alert-info">
                    <button
                      type="button"
                      className="close text-white"
                      data-dismiss="alert"
                      aria-hidden="true">
                      ×
                    </button>
                    <strong>{t('stock.vide')}</strong>
                    <hr className="message-inner-separator" />
                  <button
                   className=" btn btn-block btn-primary"
                  onClick={() => backList()}>
                  {t("stock.reeseyer")} <i className="fe fe-refresh-ccw" />
                  </button> 
                  </div>
                </div>
              </div>
              </div>
            )}
          </div>
        </>
      )}
      {error ? <ErrorAlert t={t} /> : <></>}
    </div>
  );
}
