import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import ErrorAlert from '../../others/ErrorAlert';
import { numberWithSpaces } from '../../utils/NumberWithSpaces'
import Loader from '../../components/shared/Loader'
import axios from 'axios';

export default function TopClients() {
    //------ History -----------------
    const history = useHistory();
    //--------- LISTE CLIENTS --------------
    const [top, setTop] = useState([])
    //Loader
    const [loading, setloading] = useState(true)
    //Error Alert
    const [error, seterror] = useState(false)
    //Traduction
    const [t] = useTranslation("global");

    useEffect(() => {
        const url = localStorage.getItem("url_nomadis");
        axios.get(url + `bl/getTopClient/bl`, {
            SameSite: "None",
            secure: true,
            IsEssential: true,
            withCredentials: true
        })
            .then((res) => {
                //console.log(res.data )
                if (res.data !== null) {
                    setTop(res.data)
                    setloading(false)
                }
            })
            .catch((error) => console.log(error))
    }, [])

    //Envoyer vers la page client par le code saisie
    const sendToClientProfile = (code) => {
        if (code !== null) {
            localStorage.setItem("codeclient", code);
            history.push({
                pathname: "/clients",
            });
        }
    }
    return (<>
        {loading ? (<Loader />) : (
            <div className="card">
                {/** ---------------- CARD HEADER -------------------- */}
                <div className="card-header">
                    <h4 className="card-title" style={{ marginTop: '10px' }}>{t('suivi.top5clients')}</h4>
                </div>
                {/** ---------------- CARD BODY -------------------- */}
                <div className="card-body">
                    <ul className="visitor users-images list-unstyled list-unstyled-border ">
                        {top.map((items, keys) => (
                            <li className="media mt-0 pt-0 mb-3 mt-1" key={keys}>
                                <img className="mr-3 rounded-circle" src="../assets/images/users/2.png" alt="avatar" width={45} />
                                <div className="media-body mb-1">
                                    <div className="float-right"><small>
                                        <u style={{ cursor: "pointer" }} onClick={() => sendToClientProfile(items.code)} className="text-success">{items.code}</u></small></div>
                                    <h5 className="media-title mb-0">{items.nomclient}</h5>
                                    <small className="text-muted">{items.magasin} </small>
                                    <small className="text-warning"> {t('historique.montant') + " " + numberWithSpaces(parseFloat(items.net_a_payer).toFixed(3))} </small>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>)}
        {error ? <ErrorAlert t={t} /> : <></>}

    </>
    )
}
