import axios from "axios";
import { toast } from "react-toastify";
require("dotenv").config();


/**
 * Authentification SERVICE
 * @param {string} url
 * @param {string} email
 * @param {string} password
 * @returns response
 */
export async function Login(email, password,t) {

  const api_url = localStorage.getItem("url_nomadis");

  axios.defaults.withCredentials = true;

  const form = new FormData();
  form.append("email", email);
  form.append("password", password);

  try {
    const response = await axios.post(api_url + "loginSup", form, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      SameSite: "None",
      secure: true,
      IsEssential: true,
      withCredentials: true,
    });
    if (
      response?.data?.status === true &&
      response?.data.user.email === email  &&
      (response?.data.user.role_code === "superviseur" || response?.data.user.role_code === "admin") 
    ) {
      toast(t("widget.messagebienveue"), { type: "success" ,position: "bottom-center" });
      localStorage.setItem(
        "authentification_nomadis",
        JSON.stringify(response.data)
      );
      console.log(response.data)
   
    }
    return response;
  } catch (error) {
    console.log(error.status)
    if(error.response===undefined){      
      return false
    }
    else if (error.response.data.message==="Vous ete deja connecté."){
      toast(
        t('widget.messageconnecte'),
        { type: "error",position: "bottom-center" }
      );   
      return error.response.data
 
    }else if (error.response.data.message==="Login et/ou mot de passe invalide."){
      toast(
        t('widget.mailetmotdepasse'),
        { type: "error",position: "bottom-center" }
      ); 
      return error.response.data

    }
  } 
}

/**
 * LogOut
 */

export async function Logout(){
  const url = localStorage.getItem("url_nomadis");
  const code = JSON.parse(localStorage.getItem("authentification_nomadis")).user.code
   try {
    const response = await axios.get(url+'logoutSmart/'+code);
    return response;
  } catch (error) {
    console.error(error);
  } 
}