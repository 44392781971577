/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
// --------------- WEB SERVICES --------------------------
import { getStockByCommer } from "../../../services/ventes/venteApi";
// --------------- COMPONENTS ----------------------------
import PaginationTable from "../../../components/Pagination/PaginationTable";
import Loader from "../../../components/shared/Loader";
import ErrorAlert from "../../../others/ErrorAlert";
import CardStockCommercial from "./CardStockCommercial";
//---------------- TRADUCTION --------------------------------
import { useTranslation } from "react-i18next";
// ----------------- UTILS (trie + recherche) ----------------
import { SearchItems, sortPriceAsc, sortPriceDesc, TrierAZ } from "../../../utils/SearchUtils";
import { numberWithSpaces } from "../../../utils/NumberWithSpaces";

//--------------------- Page Stock Camion par code user --------------------

export default function Stock() {

  //------------- CODDE URL PARAMETRE ------------------
  let { code } = useParams();
  // ------------ HISTORY TO GO BACK ------------------
  const history = useHistory();
  // -------------- INPUT REFERENCE ------------------
  const inputEl = useRef("")
  // --------------- TRADUCTION OUTILS --------------- 
  const [t, i18n] = useTranslation("global");
  //--------------- ERROR ----------------
  const [error, setError] = useState(false);
  //----------- STATE DE RECHERCHE -------------------
  const [search, setSearch] = useState("");
  // --------- VALEUR DU FILTRE (TRIER) -----------------
  const [value, setValue] = useState(0)
  //------------ Loader Page -------------------------
  const [loading, setLoading] = useState(true);
  // ------------ LISTE STOCK ---------------------
  const [Stock, setStock] = useState({});
  const [listStock, setListStock] = useState([]);
  const [searchList, setSearchlist] = useState([])
  const [saveList, setSaveList] = useState([])
  //--------------- PAGINATION -----------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [stocksPerPage] = useState(50);
  const indexOfLastPost = currentPage * stocksPerPage;
  const indexOfFirstPost = indexOfLastPost - stocksPerPage;
  // ---------------- CURRENT POSTS ----------------------
  const currentPosts = search.length < 2 ? listStock?.slice(indexOfFirstPost, indexOfLastPost) : searchList?.slice(indexOfFirstPost, indexOfLastPost)


  // --------------  Fonction de recherche (SearchItems => UTILS ) ----------------- 
  const getSearchTerm = () => {
    setSearch(inputEl.current.value)
    if (search !== "") {
      const newList = SearchItems(listStock, search)
      setSearchlist(newList)
    } else {
      setListStock(saveList)
    }
  }

  useEffect(() => {

      setLoading(true);
      // ------- Get stock by commercial --------------
      getStockByCommer(code)
        .then((res) => {
          if (res === false) {
            setError(true) // Activer Alerte Error
          } else if (res?.data) {
            setStock(res?.data); // stock general 
            setListStock(res?.data.stocks); // Lignes stock : ARRAY
            setSaveList(res?.data.stocks) // Lignes stock Sauvegarde  : ARRAY
            setLoading(false);

          }
        })
        .catch((err) => console.error(err));
   
  }, []);

  // --------- changeElement de value pour faire appel au fct de trie A-Z / par prix etc .  ------------
  const ChangeElement = (e) => {
    //  Activer ===> Loader
    setLoading(true)

    if (e.target.value === "1") {
      if (search.length < 2) {
        sortPriceAsc(listStock)
      } else {
        sortPriceAsc(searchList)
      }
    }
    else if (e.target.value === "2") {
      if (search.length < 2) {
        sortPriceDesc(listStock)
      } else {
        sortPriceDesc(searchList)
      }
    }
    else if (e.target.value === "3") {
      if (search.length < 2) {
        TrierAZ(listStock)
      } else {
        TrierAZ(searchList)
      }
    }
    //Désactiver Le Loader
    setLoading(false);
  }
  return (
    <div>

      {/** ------------------ LISTE STOCK CAMION --------------------------- */}
      {loading ? (
        <Loader /> // Loading TRUE
      ) : (
        <>
          {/** -------- BOUTON DE RETOUR EN ARRIERE --------------------------- */}
          <div className="section-title mb-0">
            <button className="btn bg-primary " onClick={() => history.goBack()}>
              <i className="fe fe-arrow-left" />
              {t("stock.retour")}
            </button>
          </div>
          <div className="row mt-1 ">
            {/* -------------------- 3 CARDS : TOTAL BOX / NET / Qte -------------------------------------- */}
            <div className="col-lg-4 col-md-4 col-sm-4 col-4 text-cente mobilecard ">
              <div className="card box-shadow-0 mb-lg-0">
                <div className="card-body ">
                  <div className="item-box text-center">
                    <div className="bg-purple-transparent icon-bg icon-service text-purple">
                      <i className="ti-pie-chart " />
                    </div>
                    <div className="item-box-wrap">
                      <p className="text-muted  pmobiletext mb-0">{isNaN(parseFloat(Stock?.total_stocks?.total_box)) === true ? 0 : numberWithSpaces(parseFloat(Stock?.total_stocks?.total_box).toFixed(3))}</p>
                      <h6 className=" mobiletext mb-2">{t("stock.totalBox")}</h6>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-4  text-center mobilecard">
              <div className="card  box-shadow-0 mb-lg-0">
                <div className="card-body">
                  <div className="item-box text-center">
                    <div className="bg-warning-transparent icon-bg icon-service text-warning">
                      <i className="ti-credit-card " />
                    </div>
                    <div className="item-box-wrap">
                      <p className="text-muted  pmobiletext mb-0">{isNaN(parseFloat(Stock?.total_stocks?.total_chiffre)) === true ? 0 : numberWithSpaces(parseFloat(Stock?.total_stocks?.total_chiffre).toFixed(3))}</p>
                      <h6 className="mobiletext mb-2"> {t("stock.totalchiffre")}</h6>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4  col-4 text-center ">
              <div className="card  box-shadow-0 mb-0 ">
                <div className="card-body ">
                  <div className="item-box text-center">
                    <div className="bg-pink-transparent icon-bg icon-service text-pink">
                      <i className="ti-server " />
                    </div>
                    <div className="item-box-wrap">
                      <p className="text-muted  pmobiletext mb-0">{isNaN(parseFloat(Stock?.total_stocks?.total_quantite)) === true ? 0 : numberWithSpaces(parseFloat(Stock?.total_stocks?.total_quantite).toFixed(3))}</p>
                      <h6 className=" mobiletext mb-2">{t("stock.totalQte")}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ---------------------------- OBJET STOCK (objet {} ) -------------------------------------- */}
            {/* ---------------------------- LISTE DES LIGNES STOCK CAMION PRODUITS-------------------------------------- */}


            <div className="col-md-12 mt-2">
              <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                  <div className="panel-heading " role="tab" id="headingOne">
                    <h4 className="panel-title">
                      <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <i className="icon icon-grid text-primary mr-2 " />  {t('todayCommercial.StockCamion')}
                        <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                      </a>
                    </h4>
                  </div>
                  <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                    <div className="panel-body">
                      <div className="row bg-white" style={{ marginBottom: '20px' }}>
                        <div className="col-md-4 col-lg-4 col-sm-4 col-12 mb-2">
                          <div className="form-group overflow-hidden">
                        <select name="item" className="form-control select2 text-primary w-100 "
                           value={value} 
                           onChange={(e) =>{
                           setValue(e.target.value)
                           ChangeElement(e)}}>
                           <option value={0}>------- {t('stock.trierpar')} -----</option>
                           <option value={1}>{t('stock.prixcroissant')}</option>
                           <option value={2}>{t('stock.prixdecroissant')}</option>
                           <option value={3}> A-Z</option>
                       </select>
                          </div>
                        </div>
                        <div className="col-md-2  mb-2"> </div>
                        <div className="col-md-6 col-lg-6  col-12">
                          <div className="search1 search-elements-sm ">
                            <div className="form row no-gutters ">
                              <div className="form-group  col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 mb-0">
                                <input
                                  type="text"
                                  ref={inputEl}
                                  className="form-control br-tr-md-0 br-br-md-0"
                                  id="text1"
                                  placeholder={t("stock.search")}
                                  value={search}
                                  onChange={() => getSearchTerm()} />
                              </div>
                              <div className="col-xl-2 col-lg-2 col-md-2 col-2 mb-0">
                                <button
                                  className="br-tl-md-0 br-bl-md-0 btn btn-block btn-success fs-16"
                                  onClick={() => getSearchTerm()}>
                                  <i className="fe fe-search" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </div>
            {/* -------------------- LISTE DES LIGNES STOCK CAMION PRODUITS-------------------------------------- */}
            <div className="col-md-12">
              <div className="row">
                {currentPosts.length !== 0 ? 
                currentPosts.map((items, index) => (
                    <div className="col-lg-4 col-sm-6 col-12 col-md-6" >
                      <CardStockCommercial index={index} t={t} el={items} i18n={i18n}  />
                    </div>
                )) : (
                  <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                    <div className="alert bg-purple mb-5 py-4" role="alert">
                      <div className="d-flex">
                        <i className="icon icon-info mr-3 fs-30"></i>
                        <div>
                          <h4 className="alert-heading">{t('todayCommercial.stockcamionvide')}</h4>
                          <p>{t('todayCommercial.messagestockcamion')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* ------------  PAGINATION LISTE DES LIGNES STOCK CAMION PRODUITS ---------------- */}
              <div className="col-12" style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                <PaginationTable
                  postsPerPage={stocksPerPage}
                  totalPosts={ search.length < 2 ? listStock?.length: searchList?.length}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          </div>

        </>
      )}
      {/** ----------- ERROR --------------  */}
      {error ? <ErrorAlert t={t} /> : <></>}
    </div>
  );
}
