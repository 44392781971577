/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { numberWithSpaces } from '../../../utils/NumberWithSpaces'

export default function StockElements({ el,i18n, t }) {
    return (
            <div className="card box-widget widget-user testimonial-card mb-3" dir={i18n.dir()}>
                <div className="widget-user-header bg-purple">
					<h6>{el.libelle}</h6>
					<h6>{t('stock.reference')+" : "+el.code}</h6>
				</div>
                {/** ------------ IMAGES ------------------------- */}
                <div className="test-img mx-auto white">
                    <img src={el.image !== null ? el.image : '../assets/images/users/1.jpg'} style={{ width: "120px", height: '120px' }} className="img-thumbnail rounded-circle"   />
                </div>
                {/** ------------ CARD BODY (LIBELLE, code ,colisage , quantite ) ------------------------- */}
                <div className="card-body pt-3 text-center">
                    <div className="row">
                        <div className="col-sm-6  col-6 border-right border-left border-top">
                            <div className="description-block">
                                <h6 className="description-header text-primary" style={{ fontSize: '12px' }}>{el.colisage}</h6>
                                <span className="text-muted">{t('stock.colisage')}</span>
                            </div>
                        </div>
                        <div className="col-sm-6 col-6  border-right  border-left border-top">
                            <div className="description-block">
                                <h6 className="description-header text-primary" style={{ fontSize: '12px' }}>{el.quantite}</h6>
                                <span className="text-muted">{t('stock.qte')}</span>
                            </div>
                        </div>
                    </div>
                   {/** ------------ CARD BODY (prix achat , prix_ttc  , total ) ------------------------- */}
                    <div className="row">
                        <div className="col-sm-4 col-4 border-right border-left border-top border-bottom">
                            <div className="description-block">
                                <h6 className="description-header text-primary" style={{ fontSize: '12px' }}>{el.prix_achat_ttc}</h6>
                                <span className="text-muted">{t('stock.prixachatttc')}</span>
                            </div>
                        </div>
                        <div className="col-sm-4 col-4 border-right border-top border-bottom">
                            <div className="description-block">
                                <h6 className="description-header text-primary" style={{ fontSize: '12px' }}>{el.prix_ttc}</h6>
                                <span className="text-muted">{t('stock.prixventettc')}</span>
                            </div>
                        </div>
                        <div className="col-sm-4 col-4  border-top border-right border-bottom">
                            <div className="description-block">
                                <h6 className="description-header text-primary" style={{ fontSize: '12px' }}>
                                    {numberWithSpaces((parseFloat(el.quantite) * parseFloat(el.prix_ttc)).toFixed(2))}
                                </h6>
                                <span className="text-muted">{t('stock.prixtotalVente')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}
