import axios from 'axios'

//GET URL FROM LOCALSTORAGE
const url = localStorage.getItem('url_nomadis')

const instance = axios.create({
  baseURL: url,
  withCredentials: true,
  IsEssential: true,
})

/**
 * GET USER INFORMATIONS BY CODE
 * @param {*} code
 * @returns
 */
export async function getUserByCode(code) {
  try {
    const response = await instance.get('clients/getByCodeabarre/' + code)
    return response
  } catch (error) {
    console.error(error)
  }
}
export async function getHistoriqueByMonth(code) {
  try {
    const response = await instance.get('visites/rapportVisiteParMois/'+code)
    return response
  } catch (error) {
    console.error(error)
  }
}

export async function getVenteByMarques(code) {
  try {
    const response = await instance.get('clients/venteByMarque/'+code)
    return response
  } catch (error) {
    console.error(error)
  }
}

export async function getListMarque() {
  try {
    const response = await instance.get('marques')
    return response
  } catch (error) {
    console.error(error)
  }
}
export async function getUserListes() {
  try {
    const response = await instance.get(url + "users/userLists", {
      withCredentials: true,
      SameSite: "None",
      secure: true,
      IsEssential: true,
    });
    return response;
  } catch (error) {
    if (error.response === undefined) {
      return false
    } else if (error.response.status === 401) {
      return 401
    }
  }
}
/**
 * Calcul Montant Total Ventes
 * @returns
 */
export async function montantVenteClient(code) {
  try {
    const response = await instance.get(
      'entetecommercial/getMontantTotalByClient/' + code,
    )
    return response
  } catch (error) {
    console.error(error)
  }
}
/**
 * Livraison Clients
 * @returns
 */
export async function livraisonClient(code) {
  try {
    const response = await instance.get(
      'clients/getLivraisonHistorique/' + code,
    )
    return response
  } catch (error) {
    console.error(error)
  }
}
/**
 * Calculer tx succée par le nombre de visites
 * @param {*} code
 */
export async function nbrVisites(code) {
  try {
    const response = await instance.get('visites/getNbrBlNbrVisite/' + code)
    return response
  } catch (error) {
    console.error(error)
  }
}
/**
 * GET LIST CATEGORY clients
 * @returns
 */
export async function getListCategorie() {
  try {
    const response = await instance.get('categoriescommerciale')
    return response
  } catch (error) {
    console.error(error)
  }
}
/**
 * GET Image CLIENTS
 * @returns
 */
export async function getImageClient(code) {
  try {
    const response = await instance.get('clients/getImagesByCodeABarre/' + code)
    return response
  } catch (error) {
    console.error(error)
  }
}
/**
 * GET LIST Activités clients
 * @returns
 */
export async function getListActivity() {
  try {
    const response = await instance.get('activites')
    return response
  } catch (error) {
    console.error(error)
  }
}
/**
 * UPDATE CLIENT BY CODE
 * @param {*} code
 */
export async function updateClientByCode(code, el) {
  const data = {
    adresse_facturation: el.adresse,
    autorisation: el.autorisation,
    autorisation_cheque: el.autorisationCheque,
    autorisation_traite: el.autorisationTraite,
    plafond_credit: el.plafondCredit,
    plafond_credit_cheque: el.plafondCheque,
    plafond_credit_traite: el.plafondTraite,
    delai_paiement: el.delaisPaiement,
    categoriecommercial_code: el.categorie,
    activite: el.activite,
    mobile: el.mobile,
    longitude: el.longitude,
    latitude: el.latitude,
    bloque: el.bloque,
    isactif: el.isActif,
    zone: el.zone,
    region: el.region,
  }

  try {
    const response = await instance.put(
      'clients/updateByCodeabarre/' + code,
      data,
    )
    return response
  } catch (error) {
    if (error.response === undefined) {
      return false
    } else {
      return error.response
    }
  }
}

/**GET MOUVEMENT CLIENTS */
export async function getMouvementClient(code) {
  try {
    const response = await instance.get(
      'mouvement_clients/getMouvementsByCode/' + code,
    )
    return response
  } catch (err) {
    console.error()
  }
}
export async function getTop10ClientBycommercial(date_debut,date_fin,code,type) {
  try {
    const response = await instance.get(
      'entetecommercial/getTop10ClientByCommercial/' + date_debut+'/' + date_fin+'/' + code+'/bl',
    )
    return response
  } catch (err) {
    console.error()
  }
}
/**
 * GET HISTORIQUES
 */
export async function getHistoriques(code) {
  try {
    const response = await instance.get(
      'rapports/rapportvisitemensuelleByCode/' + code,
    )
    return response
  } catch (err) {
    console.error()
  }
}

/**
 * GET LAST VISITES COMMERCIALS
 */
export async function getLastVisiteClient(code) {
  try {
    const response = await instance.get('clients/getLastVisite/' + code)
    return response
  } catch (err) {
    console.error()
  }
}
/** GET CLIENT RECRUTEE TODAY */
export async function getRecrutementClient() {
  try {
    const response = await instance.get('clients/recrutement/day')
    return response
  } catch (err) {
    console.error()
  }
}
/**Entete Commercial Client */
export async function getEntetComm(code) {
  try {
    const response = await instance.get('entetecommercial/code/' + code)
    return response
  } catch (error) {
    console.error()
    console.log(error.response)
  }
}
/**Update actif / bloque */
export async function updateActif(id) {
  try {
    const response = await instance.put('clients/' + id)
    return response
  } catch (error) {
    console.error()
  }
}
/**
 * Top 5 Commercials
 *
 */
export function topCommercials() {
  try {
    const response = axios.get(url + 'entetecommercial/top5commercial/bl', {
      baseURL: url,
      withCredentials: true,
      IsEssential: true,
    })
    return response
  } catch (error) {
    if (error.response === undefined) {
      return false
    } else if (error.response.status === 422) {
      return 422
    } else if (error.response.status === 404) {
      return 404
    } else {
      console.error(error)
      return error.response
    }
  }
}
export function getSousSociete() {
  try {
    const response = axios.get(url + 'soussocietes/selectSoussocietes', {
      baseURL: url,
      SameSite: "None",
      secure: true,
      IsEssential: true,
      withCredentials: true,
    })
    return response
  } catch (error) {
    console.log(error.response.status)
    if (error.response === undefined) {
      return false
    } else if (error.response.status === 422) {
      return 422
    } else if (error.response.status === 404) {
      return 404
    } else {
      console.error(error)
      return error.response
    }
  }
}
export function getDelegations() {
  try {
    const response = axios.get(url + 'delegations/selectDelegations', {
      baseURL: url,
      withCredentials: true,
      IsEssential: true,
    })
    return response
  } catch (error) {
    if (error.response === undefined) {
      return false
    } else if (error.response.status === 422) {
      return 422
    } else if (error.response.status === 404) {
      return 404
    } else {
      console.error(error)
      return error.response
    }
  }
}
//
/**
 * GET LISTE OF ROUTES
 * @returns
 */
export async function addClient(data) {
  try {
    console.log(data)
    const response = await instance.post(url + 'clients/saveWeb', data)
    return response
  } catch (error) {
    if (error.response === undefined) {
      return false
    } else if (error.response.status === 422) {
      return 422
    } else if (error.response.status === 404) {
      return 404
    } else {
      console.error(error)
      return error.response
    }
  }
}

/**
 * GET LISTE OF ROUTES
 * @returns
 */
export function getListeRoutes() {
  try {
    const response = axios.get(url + 'routing/selectRoutes', {
      baseURL: url,
      withCredentials: true,
      IsEssential: true,
    })
    return response
  } catch (error) {
    if (error.response === undefined) {
      return false
    } else if (error.response.status === 422) {
      return 422
    } else if (error.response.status === 404) {
      return 404
    } else {
      console.error(error)
      return error.response
    }
  }
}

/**
 * GET LSITE  Secteurs
 * @returns
 */
export function getListeSecteurs() {
  try {
    const response = axios.get(url + 'secteurs', {
      baseURL: url,
      withCredentials: true,
      IsEssential: true,
    })
    return response
  } catch (error) {
    if (error.response === undefined) {
      return false
    } else if (error.response.status === 422) {
      return 422
    } else if (error.response.status === 404) {
      return 404
    } else {
      console.error(error)
      return error.response
    }
  }
}
/**
 * GET LISTE REGIONS
 * @returns
 */
export function GetListeGouvernorats() {
  try {
    const response = axios.get(url + 'gouvernorats', {
      baseURL: url,
      withCredentials: true,
      IsEssential: true,
    })
    return response
  } catch (error) {
    if (error.response === undefined) {
      return false
    } else if (error.response.status === 422) {
      return 422
    } else if (error.response.status === 404) {
      return 404
    } else {
      console.error(error)
      return error.response
    }
  }
}
/**
 * GET LISTE REGIONS
 * @returns
 */
export function GetListeRegions() {
  try {
    const response = axios.get(url + 'regions', {
      baseURL: url,
      withCredentials: true,
      IsEssential: true,
    })
    return response
  } catch (error) {
    if (error.response === undefined) {
      return false
    } else if (error.response.status === 422) {
      return 422
    } else if (error.response.status === 404) {
      return 404
    } else {
      console.error(error)
      return error.response
    }
  }
}
/**
 * GET Liste Zone
 * @returns
 */
export function getListeZone() {
  try {
    const response = axios.get(url + 'zones', {
      baseURL: url,
      withCredentials: true,
      IsEssential: true,
    })
    return response
  } catch (error) {
    if (error.response === undefined) {
      return false
    } else if (error.response.status === 422) {
      return 422
    } else if (error.response.status === 404) {
      return 404
    } else {
      console.error(error)
      return error.response
    }
  }
}
/**
 * GET LISTE LOCALITE
 * @returns
 */
export function getListeLocalite() {
  try {
    const response = axios.get(url + 'localites/selectLocalites', {
      baseURL: url,
      withCredentials: true,
      IsEssential: true,
    })
    return response
  } catch (error) {
    if (error.response === undefined) {
      return false
    } else if (error.response.status === 422) {
      return 422
    } else if (error.response.status === 404) {
      return 404
    } else {
      console.error(error)
      return error.response
    }
  }
}
/**
 * Get TOP CLIENT
 */
export function topClients() {
  try {
    const response = axios.get(url + 'bl/getTopClient/bl', {
      baseURL: url,
      withCredentials: true,
      IsEssential: true,
    })
    return response
  } catch (error) {
    console.error()
  }
}
/**
 * GET LISTE Clients
 * @returns
 */
export function getListeClients(page,perPage,search) {
  try {
    //const response = axios.get(url+'clients/paginate?activite_code=&adv_search='+search+'&categoriecommercial_code=&credit=&delegation_code=&etat=&gouvernorat_code=&page='+page+'&perpage='+perPage+'&region_code=&routing_code=&specialite=&type_notif=&user_code=&zone_code=', {
      const response = axios.get(url+'clients/clientsformobileapp?adv_search='+search+'&page='+page+'&perpage='+perPage+'', { 
      baseURL: url,
      withCredentials: true,
      IsEssential: true,
    })
    return response
  } catch (error) {
    if (error.response === undefined) {
      return false
    } else if (error.response.status === 422) {
      return 422
    } else if (error.response.status === 404) {
      return 404
    } else {
      console.error(error)
      return error.response
    }
  }
}
