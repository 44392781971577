/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
//------------------ HISTORY --------------------
import { useHistory } from "react-router-dom";
//------------------ COMPONENTS --------------------
import Loader from "../../components/shared/Loader";
import ErrorAlert from "../../others/ErrorAlert";
//------------------ WEB SERVICES --------------------
import { getCode } from "../../services/Access/Access";
//------------------- ALERTE -----------------------
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//------------- TRADUCTION ------------------------
import { useTranslation } from "react-i18next";


function Code() {
  //---------- HISTORY ------------------
  const history = useHistory();
  //---------- CODE NOMADIS ------------
  const [code, setCode] = useState("");
  //------------ ALERTE ----------------
  const [alert, setAlert] = useState(false);
  //----------- CONTENUE ALERT ----------
  const [loading, setLoading] = useState(true);
  //-----------  Traduction -------------
  const [t] = useTranslation("global");

  const verifAutorisation = () => {
    setAlert(false);
    try {
      if (code !== "") {
        const text = code.toLowerCase()
        setLoading(true);
        getCode(text,t)
          .then((res) => {
            if(res===undefined){
              setAlert(true)
            }else
            if(res){
              console.log(res)
              history.push({
                pathname: "login",
              });
            }
          })
          .catch((error) => {
            console.error(error)
          });
      } else {
        toast(t('widget.messageerreur'), {
          type: "error",
          position:"bottom-center"
        });
      }
    } catch (e) {
      setAlert(true);
    } finally {
      setLoading(false);
    }
  };
  useEffect(()=>{
    if(t){
      setLoading(false)
    }
  },[t])
  
  return (
    <div className="h-100vh">
      <ToastContainer />
      {/* Page opened */}
      {loading ? ( <Loader/>):(
        <>
        <div className="page">
        <div>
         {/*  <div className="col col-login mx-auto">
            <div className="text-center">
              <img
                src="../assets/images/brand/logo.png"
                className="header-brand-img desktop-logo  h-100 mb-5"
                alt="Dashlot logo"
              />
              <img
                src="../assets/images/brand/logo1.png"
                className="header-brand-img dark-theme  h-100 mb-5 "
                alt="Dashlot logo"
              />
            </div>
          </div> */}
        </div>
        <div className="container" >
          <div className="row">
            <div className="col-xl-8 col-lg-10 justify-content-center mx-auto text-center">
              <div className="card">
                <div className="row">
                  <div className="col-md-12 col-xl-6 col-lg-6 pr-0 d-none d-lg-block">
                    <img
                      src="../assets/images/photos/login2.jpg"
                       alt="img"
                      className="br-tl-2 br-bl-2 "
                    />
                  </div>
                  <div className="col-md-12 col-xl-6 col-lg-6 pl-0">
                    <div className="card-body p-6 about-con pabout">
                      <div className="card-title text-center mb-5">
                      {t("widget.code")}
                      {/* <p className='text-muted'> {t('widget.versiongratuite')} </p> */}
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("widget.codetext")}
                          value={code}
                          
                          onChange={(e) => setCode(e.target.value)}
                        />
                       
                      </div>
                      {loading ? (
                        <div className="form-footer mt-1">
                          <button
                            onClick={() => verifAutorisation()}
                            className="btn btn-success btn-block"
                          >
                            {t("widget.valider")} <Loader />
                          </button>
                        </div>
                      ) : (
                        <div className="form-footer mt-1">
                          <button
                            onClick={() => verifAutorisation()}
                            className="btn btn-success btn-block"
                          >
                            {t("widget.valider")}
                          </button>
                        </div>
                      )}

                      <div className="text-center text-muted mt-3 ">
                      {t("widget.licence")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {alert ? <ErrorAlert  t={t}/> : <></>}
        </>
      )}
      
    </div>
  );
}

export default Code;
