/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
//Components
import { useTranslation } from "react-i18next";
//import ChequierCalendar from "../Calendrier/ChequierCalendar";
import Cards from "../commandes/Informations/Cards";
import TopClients from '../Top/TopClients'
import TopCommercial from '../Top/TopCommercial'
import HeaderWeather from "../../components/Widget/HeaderWeather";
//import InfoFlash from "./InfoFlash";
import Recouvrement from "./CardsDashboard/Recouvrement";
import Espece from "./CardsDashboard/Espece";
import Cheque from "./CardsDashboard/Cheque";
import Recette from "./CardsDashboard/Recette";
import CardsVente from "../ventes/Informations/CardsVente";


function VenteMain() {
  //Languages
  const [t, i18n] = useTranslation("global");
  const url = localStorage.getItem("url_nomadis");


  return (
    <div>
      <HeaderWeather />
      <CardsVente t={t} />
      <Cards t={t} />
      <div className="row">
        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-3">
          <Recouvrement t={t} url={url} />
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-3">
          <Espece t={t}  url={url} />
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-3">
          <Cheque  t={t}  url={url}/>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-3">
          <Recette   t={t} url={url}/>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-7 col-lg-6 col-md-12 mt-5 mt-lg-0">
          <TopCommercial />
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 col-xl-5">
          <TopClients />
        </div>
      </div>


    </div>
  );
}
export default VenteMain;
