/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import ReactJsAlert from "reactjs-alert";

export default function ErrorAlert({t}) {
  const [status, setstatus] = useState(true);
  return (
    <ReactJsAlert
      type="error" // success, warning, error, info
      title="Erreur !" // title you want to display
      status={true} // true or false
      color="#FA3434"
      quotes={true}
      quote={t('dashboard.erreur')}
      Close={() => setstatus(false)}
      // callback method for hide
    />
  );
}
