/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import SquareLoader from '../../../components/Loader/SquareLoader';
import { numberWithSpaces } from '../../../utils/NumberWithSpaces';

export default function Cheque({ t, url }) {

    const [cheque, setCheque] = useState("00");
    const [chequeMonth, setchequeMonth] = useState("00")
    //Loader
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true)
        // 1- SERVICE : GET MONTANT CHEQUE
        axios.get(url + "paiements/getEtatPaiement/recouvrement",
            {
                SameSite: "None",
                secure: true,
                IsEssential: true,
                withCredentials: true,
            })
            .then((res) => {
                const liste = res?.data;
                if (liste.lenght !== 0) {
                    const total_cheque = liste?.reduce(
                        (totalsum, { cheque }) => totalsum + parseFloat(cheque), 0);
                    setCheque(total_cheque)
                }
            })
            .catch((error) => console.log(error));

        axios.get(url + "paiements/sum_paiements_mois", {
            SameSite: "None",
            secure: true,
            IsEssential: true,
            withCredentials: true,
        })
            .then((result) => {
                if (result?.data !== null) {
                    setchequeMonth(result?.data[0]?.cheque)
                    setLoading(false)
                }
            }).catch((err) => {
                console.log(err)
            })
    }, [])

    return (
        <>
            {loading ? (
                <SquareLoader />
            ) : (
                <div className="card overflow-hidden">
                    <div className="card-body">
                        <div className="d-flex">
                            <div >
                                <p className="mb-2 h6">{t("listecmdvente.totalcheque")}</p>
                                <h5 className="mb-1 ">{isNaN(cheque) ? 0 : parseFloat(cheque).toFixed(3)}
                                    <span className="font-weight-normal text-white fs-13">
                                        / {t("commandes.today")}
                                    </span>
                                </h5>
                                <hr style={{ marginTop: "10px", marginBottom: "10px", borderTop: "1px solid transparent" }} />
                                <p className="mb-0 text-muted mt-2">
                                    <span className="text-success">( {chequeMonth === null ? "0.00" : numberWithSpaces(parseFloat(chequeMonth).toFixed(3))})</span>
                                    ({moment().format("MMMM")})
                                </p>
                            </div>
                            <div className="my-auto ml-auto">
                                <div className="chart-wrapper">
                                    <i
                                        className="ti-stats-up text-primary fa-3x overflow-hidden mx-auto"
                                        width={100}
                                        height={100}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
        </>
    )
}
