/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import PaginationClients from '../../../components/Pagination/PaginationClients'
import { getListeClients } from '../../../services/clients/ClientApi'
import Loader from '../../../components/shared/Loader'

export default function ListeClients() {
  //------ History -----------------
  const history = useHistory();
  //USE REF
  const inputEl = useRef("")
  //Search 
  const [search, setSearch] = useState('')
  //Liste client
  const [listeClients, setlisteClients] = useState([])
  const [saveListe, setSaveListe] = useState([])
  //PAGINATION
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage, setPerPage] = useState(15)
  const [totalClients, setTotalClients] = useState(0)
  const [t, i18n] = useTranslation('global')

  const [loading, setloading] = useState(true)



  const getColorClass = (lastVisitDate) => {
    const oneDay = 24 * 60 * 60 * 1000; // Nombre de millisecondes en une journée
    const today = new Date(); // Date d'aujourd'hui
    const lastVisit = new Date(lastVisitDate); // Date de la dernière visite
    const diffDays = Math.round(Math.abs((today - lastVisit) / oneDay)); // Nombre de jours écoulés
    
    if (diffDays > 30) {
      return 'text-danger'; // Plus de 30 jours, couleur rouge
    } else if (diffDays >= 15 && diffDays <= 30) {
      return 'text-warning'; // Entre 15 et 30 jours, couleur orange
    } else {
      return 'text-success'; // Moins de 15 jours, couleur verte
    }
  };




  const traitement = (currentPage, perPage, search) => {
    getListeClients(currentPage, perPage, search)
      .then((result) => {
        if (result?.data !== undefined && result?.data !== null) {
          setTotalClients(result?.data?.clients?.total)
          setlisteClients(result?.data?.clients?.data)
          setSaveListe(result?.data?.clients?.data)
          setloading(false)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {

    traitement(currentPage, perPage, search)
  }, [])

  //Pagination Function =>Ajouter un nombre
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber)
    getListeClients(pageNumber, perPage, search)
      .then((result) => {
        //console.log(result.data)
        if (result?.data !== undefined && result?.data !== null) {
          setTotalClients(result?.data?.clients?.total)
          setlisteClients(result?.data?.clients?.data)
          setSaveListe(result?.data?.clients?.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  //SEARCH ITEMS
  const getSearchTerm = () => {
    setSearch(inputEl.current.value)
    if (search !== "") {
      getListeClients(currentPage, perPage, search)
        .then((result) => {
          //console.log(result.data)
          if (result?.data !== undefined && result?.data !== null) {

            setTotalClients(result?.data?.clients?.total)
            setlisteClients(result?.data?.clients?.data)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      setlisteClients(saveListe)
    }
  }
  const refresh = () => {
    traitement(currentPage, perPage, "")
  }
  //Envoyer vers la page client par le code saisie
  const sendToClientProfile = (code) => {
    if (code !== null) {
      localStorage.setItem("codeclient", code);
      history.push({
        pathname: "/clients",
      });
    }
  }
  return (
    <div className="card">

      {loading ? (
        <Loader />
      ) : (
        <>
          {/**--------------------SLIDE ACCORDION FILTRE AND SEARCH  ------------------------- */}
          <div
            className="panel-group"
            id="accordion1"
            role="tablist"
            aria-multiselectable="true"
          >
            <div className="panel panel-default mt-2">
              <div className="panel-heading" role="tab" id="headingthree">
                <h4 className="panel-title">
                  <a
                    className="collapsed"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordion1"
                    href="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <i className="icon icon-people text-primary mr-2" />
                    {t('client.listeClients')}
                    <span className="float-right">
                      <i className="fe fe-search  text-primary" />
                    </span>
                  </a>
                </h4>
              </div>
              <div
                id="collapseThree"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingthree"
              >
                <div className="panel-body">
                  <div className="row">
                    <div className="col-lg-12 col-xl-12 col-12">
                      {/* -------------------- RECHERCHE + FILTRE -------------------------------------- */}
                      <div className="search1 search-elements-sm ">
                        <div className="form row no-gutters ">
                          <div className="form-group  col-xl-10 col-lg-10 col-md-10 col-sm-10 col-8 mb-0">
                            <input
                              type="text"
                              ref={inputEl}
                              className="form-control br-tr-md-0 br-br-md-0"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                            />
                          </div>
                          <div className="col-xl-1 col-lg-1 col-md-1 col-2 mb-0">
                            <button className="br-tl-md-0 br-bl-md-0 btn btn-block btn-success fs-16"
                              onClick={() => getSearchTerm()}
                            >
                              <i className="fe fe-search" />
                            </button>
                          </div>
                          <div className="col-xl-1 col-lg-1 col-md-1 col-2 mb-0">
                            <button className="br-tl-md-0 br-bl-md-0 btn btn-block btn-primary fs-16"
                              onClick={() => refresh()}
                            >
                              <i className="icon-refresh" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table
                className="table card-table table-striped tutorial-table"
                cellSpacing={0}
                width="100%"
              >
                <thead>
                  <tr className="text-center">
                    <th className="priority-1" width="15%">{t('client.codeclient')}</th>
                    <th className="priority-1" width="15%">{t('client.nom')} / {t('releve.Magasin')} </th>
                    <th className="priority-3" width="15%">{t('releve.adresse')}</th>
                    <th className="priority-3" width="15%">{t('detailClient.mobile')}</th>
                    <th className="priority-3" width="15%">{t('detailClient.derniereVisite')}</th>
                    <th className="priority-3" width="15%">{t('detailClient.dernierAchat')}</th>
                    <th className="priority-3" width="15%">{t('detailClient.encours')}</th>
                   
                  </tr>
                </thead>
                <tbody>
                  {listeClients?.map((items, keys) => (
                 <tr className={`text-center ${getColorClass(items.last_visite)}`}>
                      <td className="priority-1" width="15%">
                        {<small>  
                          <u style={{ cursor: "pointer" }} onClick={() => sendToClientProfile(items.code)} className="text-success">{items.code}</u>
                        </small>}
                      </td>
                     
                      <td className="priority-1" width="15%">{items.nom + " " + items.prenom +" " +items.magasin}</td>
                      <td className="priority-1" width="15%">{items.adresse_facturation }</td>
                      <td className="priority-3" width="15%">{items.mobile !== "" ? items.mobile : t('detailClient.aucun')}</td>
                      <td className="priority-1" width="15%">{items.last_visite }</td>
                      <td className="priority-1" width="15%">{items.last_vente }</td>
                      <td className="priority-1" width="15%">{items.encours }</td>
                     
                    </tr>
                  ))}
                </tbody>
              </table>
              {/**-----------------  Pagination ------------  */}
              <div className="col-12"
                style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                <PaginationClients
                  postsPerPage={perPage}
                  totalPosts={totalClients}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  paginate={paginate}
                />
              </div>
            </div>
          </div>
        </>)}

    </div>
  )
}
