/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import SquareLoader from "../../../components/Loader/SquareLoader";
import ErrorAlert from "../../../others/ErrorAlert";
import { getChequeMonth, getEtatPaiementToday, getRecouvrementByMonth, getTauxVisites } from "../../../services/commande/commandeApi";
import { getcheques, getTauxVente } from "../../../services/ventes/venteApi";
import { numberWithSpaces } from "../../../utils/NumberWithSpaces";

export default function Recouvrement({ t }) {
  //RESULTS VALUES
  const [cheque, setCheque] = useState("00");
  const [chequeMonth, setchequeMonth] = useState("00")
  const [recouvrement, setRecouvrement] = useState("00");
  const [tx, setTx] = useState("00");
  const [txVente, setTxVente] = useState("00");
  const [recouMonth, setRecouvrementMonth] = useState("00")// Recouvrement mont

  //Loader
  const [loading, setLoading] = useState(true);
  const [loadingRecouvrement, setloadingRecouvrement] = useState(true);
  const [loadingTx, setloadingTx] = useState(true);

  //ERROR
  const [error, setError] = useState(false);
  
  useEffect(() => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var date1 = firstDay.getFullYear() + "-" + (firstDay.getMonth() + 1) + "-" + firstDay.getDate()
    var date2 = lastDay.getFullYear() + "-" + (lastDay.getMonth() + 1) + "-" + lastDay.getDate()
    // 1- SERVICE : GET MONTANT CHEQUE
    getcheques()
      .then((res) => {
        const liste = res?.data;
        if (liste?.length !== 0) {
          const total_cheque = liste?.reduce(
            (totalsum, { cheque }) =>
              totalsum + parseFloat(cheque),
            0
          );
          setCheque(total_cheque)
        }
        getChequeMonth()
          .then((result) => {
            if (result?.data !== null) {
              setchequeMonth(result?.data[0]?.cheque)
            }
            setLoading(false)
          }).catch((err) => {
            console.log(err)
          })
      })
      .catch((error) => console.log(error))

    // 2- SERVICE : GET RECOUVREMENT
    getEtatPaiementToday()
      .then((res) => {
        if (res?.data[0]?.recouvrement !== null) {
          setRecouvrement(parseFloat(res?.data[0]?.recouvrement).toFixed(3));
        }
        getRecouvrementByMonth(date1, date2)
          .then((res) => {
            //console.log(res.data)
            if (res?.data !== null) {
              const total = res?.data
                .reduce(
                  (totalsum, { montant }) => totalsum + parseFloat(montant),
                  0
                );
              setRecouvrementMonth(total)
            }
            setloadingRecouvrement(false)
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error))

    // 5- GET ALL RECOUVREMENTS


    // 3- SERVICE : GET TAUX VISITE
    getTauxVisites()
      .then((res) => {
        if (res?.data[0]?.percent !== null) {
          var value = parseFloat(res?.data[0]?.percent).toFixed(2);
          setTx(value);
        }
        // 4- SERVICE : GET TAUX de VENTE
        getTauxVente()
          .then((res) => {
            setTxVente(res?.data[0]?.percent)

          })
          .catch((error) => console.log(error));
        setloadingTx(false)
      })
      .catch((error) => console.log(error))

  }, []);

  return (
    <div>
      {/** INFORMATIONS PAIEMENTS CREDIT / RECETTE/ ESPECE */}
      <div className="row">

        {/** CHEQUE */}
        <div className=" col-md-4 col-lg-4 col-xl-4">
          <div className="card bg-azure ">
            <div className="card-body">
              {loading ? <SquareLoader /> : (
                <>
                  <h4 className="card-title mb-4 text-white"> {t("listecmdvente.totalcheque")}</h4>
                  <div className="d-flex  align-items-center">
                    <h5 className="text-white mb-0">
                      {isNaN(cheque) ? 0 : parseFloat(cheque).toFixed(3)}
                      <span className="font-weight-normal text-white fs-13">
                        / {t("commandes.today")}
                      </span>
                    </h5>
                    <div className="text-white mt-0 mb-2  ml-auto">
                      <i
                        className="ti-stats-up fa-3x overflow-hidden mx-auto"
                        width={100}
                        height={100}
                      />
                    </div>
                  </div>
                  <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                  <p className="text-white  mb-4  ">
                    {numberWithSpaces(parseFloat(chequeMonth).toFixed(3))}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        {/** RECOUVREMENT */}
        <div className=" col-md-4 col-lg-4 col-xl-4">
          <div className="card bg-azure ">
            <div className="card-body">
              {loadingRecouvrement ? <SquareLoader /> : (
                <>
                  <h4 className="card-title mb-4 text-white">
                    {t("listecmdvente.totalrecouvrement")}
                  </h4>
                  <div className="d-flex  align-items-center">
                    <h5 className="text-white mb-0">
                      {parseFloat(recouvrement).toFixed(3)}
                      <span className="font-weight-normal text-white fs-13">
                        / {t("commandes.today")}
                      </span>
                    </h5>
                    <div className="text-white mt-0 mb-2  ml-auto">
                      <i
                        className="ti-layers  fa-3x overflow-hidden mx-auto"
                        width={100}
                        height={100}
                      />
                    </div>
                  </div>
                  <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                  <p className="text-white  mb-4  ">
                    {t("listecmdvente.totalrecouvrement") + " : " + parseFloat(recouMonth).toFixed(3)}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        {/**Taux de vente */}
        <div className=" col-md-4 col-lg-4 col-xl-4">
          <div className="card bg-azure ">
            <div className="card-body">
              {loadingTx ? <SquareLoader /> : (
                <>
                  <h4 className="card-title mb-4 text-white">{t("listecmdvente.tauxvente")}</h4>
                  <div className="d-flex  align-items-center">
                    <h5 className="text-white mb-0">
                      {parseFloat(txVente).toFixed(2)}
                    </h5>
                    <div className="text-white mt-0 mb-2  ml-auto">
                      <i
                        className="fe fe-percent  fa-3x overflow-hidden mx-auto"
                        width={100}
                        height={100}
                      />
                    </div>
                  </div>
                  <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                  <p className="text-white  mb-4  ">
                    {t("listecmdvente.tauxvisite")} : {parseFloat(tx).toFixed(2)} (%)
                  </p>
                </>
              )}
            </div>
          </div>
        </div>

      </div>
      {error ? <ErrorAlert t={t} /> : <></>}
    </div>
  );
}
