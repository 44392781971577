import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
//Traduction LIBRARY
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
//Traduction File
import translation_ar from "./locales/ar/translation.json";
import translation_fr from "./locales/fr/translation.json";
//Cookies Files
import Cookies from "js-cookie"; // DEFAULT LANGUAGE INTO COOKIES
import backend from 'i18next-http-backend'
import LanguageDetecor from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'


i18next.use(backend).use(LanguageDetecor).use(initReactI18next).init({
  fallbackLng: 'fr',
  debug :true,
  detection :{
      order : ['queryString','cookie'],
      caches :['cookie']
  },
  lng: Cookies.get("locale") || Cookies.set("locale", "fr"), //DEFINE THE DEFAULT LANGUAGE

  resources: {
    ar: {
      global: translation_ar, // CALLING THE AR DICTIONNAIRE
    },
    fr: {
      global: translation_fr, // CALLING THE FR DICTIONNAIRE
    },
  },
  interpolation :{
      escapeValue : false
  },
  react:{
    useSuspense: false,

  }
})
ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
