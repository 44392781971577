/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import ErrorAlert from '../../others/ErrorAlert';
import { topCommercials } from '../../services/clients/ClientApi'
import { getAllGammeMonth } from '../../services/Objectifs/ObjectifApi';
import Loader from '../../components/shared/Loader'
import { Doughnut } from 'react-chartjs-2';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';

export default function TopCommercial() {

    //--------- LISTE CLIENTS --------------
    const [top, setTop] = useState([])
    //--------- LOADER --------------
    const [loading, setloading] = useState(true)
    //--------- ERROR --------------
    const [error, seterror] = useState(false)
    //--------- Traduction --------------
    const [t, i18n] = useTranslation("global");


    useEffect(() => {
        //URL NOMADIS
        const url = localStorage.getItem("url_nomadis");
        axios.get(url + `entetecommercial/top5commercial/bl`, {
            SameSite: "None",
            secure: true,
            IsEssential: true,
            withCredentials: true
        })
            .then((res) => {

                if (res === false) {
                    seterror(true)
                }
                else if (res === 422) {
                    toast(t('messages.autorisation'), { position: 'bottom-center', type: 'warning' })
                }
                else if (res === 404) {
                    seterror(true)
                }
                else if (res.data !== null) {
                    setTop(res.data)
                    setloading(false)
                }
            })
            .catch((error) => console.log(error))

    }, [])
    return (
        <>
            {loading ? (<Loader />) : (
                <div>
                    {top.length !== 0 ? (
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title" style={{ marginTop: '10px' }}>{t('suivi.topcommerciaux')} </h3>
                            </div>
                            <ToastContainer />
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 mt-5 mt-lg-0">
                                        <div className="table-responsive" dir={i18n.dir()}>
                                            <table className="table table-hover mb-0 text-muted text-nowrap">
                                                <tbody>
                                                    <tr className="border-bottom bg-light">
                                                        <th className="p-2">{t('stock.code')}</th>
                                                        <th className="p-2">{t('stock.commercial')}</th>
                                                        <th className="p-2">{t('suivi.ca')}</th>

                                                    </tr>
                                                    {top?.map((items, keys) => (
                                                        <tr className="border-bottom" key={keys}>
                                                            <td className="p-2">{items.code}</td>
                                                            <td className="p-2">{items.nom}</td>
                                                            <td className="p-2">{items.CA}</td>
                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    ) : (<></>)}

                </div>)}
            {error ? <ErrorAlert t={t} /> : <></>}

        </>
    )
}
