import axios from "axios";

//GET URL FROM LOCALSTORAGE
const url = localStorage.getItem("url_nomadis");

const instance = axios.create({
  baseURL: url,
  withCredentials: true,
  IsEssential: true,
});

/**
 * CONNAITRE LES INFORMATIONS BL / COMMANDES GENERALE DE TOUTE L'année
 * @returns  (montant total ttC et net a payer )
 */
export async function getEnteteCommercial() {
  try {
    const response = await instance.get("entetecommercial/getChiffre", {
      SameSite: "None",
      secure: true,
      IsEssential: true,
      withCredentials: true,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * CONNAITRE LE TOTAL DES COMMANDES PAR JOUR
 * @returns
 */

export async function getTodayCommande() {
  try {
    const response = await instance.get("paiements/getetatCredit/all");
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * lISTE COMMERCIAUX
 */
export async function getAllCommerciaux() {
  try {
    const response = await instance.get("users/selectCashvanAndLiv");
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      console.log(error.response.status)
      return error.response.status
    } else {
      console.log(error.response)
    }
  }
}
/**
 * CONNAITRE LE TOTAL DES COMMANDES PAR MOIS
 * @returns
 */

export async function getMonthCommande() {
  try {
    const response = await instance.get("bl/byCommercial/commande/month/null");
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Recrutement Client
 * @returns
 */
export async function getRecrutementClient() {
  try {
    const response = await instance.get("clients/recrutement/day");
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Recette du jour
 * @returns
 */
export async function getRecetteDay() {
  try {
    const response = await instance.get("paiements/recettetoday");
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**COUNT VISITE CLIENT TODAY */
export async function countVisite() {
  try {
    const response = await instance.get("visites/countVisiteDay");
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**COUNT VISITE CLIENT BY MONTH */
export async function countVisiteMonth() {
  try {
    const response = await instance.get("visites/countVisiteMonth");
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Paiement ESPECE
 * @returns
 */
export async function getPaiementEspece() {
  try {
    const response = await instance.get("paiements/TotalespecesAujourdhui");
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * GET ETAT PAIEMENT TODAY
 * @returns LISTE
 */
export async function getEtatPaiementToday() {
  try {
    const response = await instance.get(
      "paiements/getEtatPaiementAujourdhui/recouvrement"
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * GET ETAT CREDIT TOTAL
 * @returns LISTE
 */
export async function getCredit() {
  try {
    const response = await instance.get("paiements/getetatCredit/all");
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function getCreditToday() {
  try {
    const response = await instance.get("dashboard/credits");
    return response;
  } catch (error) {
    console.log(error);
  }
}
/**
 * GET Recouvrement
 * @returns LISTE
 */
export async function getRecouvrement() {
  try {
    const response = await instance.get(
      "paiements/TotalRecouvrementChequeToday"
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * GET TOTAL CREDIT COMMERCIAL BY DATE
 * @returns LISTE
 */
export async function getTotalCreditCommercial() {
  try {
    const response = await instance.get("paiements/creditByCommercialByDate");
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * GET RECOUVREMENT PAR MONTH
 * @returns 
 * 
 */
export async function getRecouvrementByMonth(date1, date2) {
  try {
    const response = await instance.get("recouvrements/get/" + date1 + "/" + date2);
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * GET Taux de Visites
 * @returns LISTE
 */
export async function getTauxVisites() {
  try {
    const response = await instance.get("entetecommercial/calcul/tauxdevisite");
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * GET CLIENTS VISITES
 * @returns NUMBER
 */
export async function getClientVisite() {
  try {
    const response = await instance.get("clients/count/isactif/1");
    return response;
  } catch (error) {
    console.error();
  }
}
/** GET ESPECE TODAY */
export async function getEspeceToday() {
  try {
    const response = await instance.get(
      "paiements/TotalRecouvrementespecesToday"
    );
    return response;
  } catch (error) {
    console.error();
  }
}
/**
 * GET COUNT BL
 * @returns
 */
export async function getCountBL(date1,date2) {
  try {
    const response =  axios.get(url + `entetecommercial/getEntetetoNomadis/vente/` + date1 + "/" + date2, {
      withCredentials: true,
      SameSite: "None",
      secure: true,
      IsEssential: true,
    })
    return response;
  } catch (error) {
    console.error();
  }
}
/**
 * NB VISITES par VENDEUR
 */
export async function getNbrVisites() {
  try {
    const response = await instance.get("visites/byCommercial/count/date");
    return response;
  } catch (error) {
    console.error();
  }
}
/** NB Recouvrement par vendeur */
export async function getNbrRecouvrement() {
  try {
    const response = await instance.get("paiements/recouvrementByCommercial");
    return response;
  } catch (error) {
    console.error();
    return error.response
  }
}
/** total vente par vendeur  */
export async function totalVenteVendeur() {
  try {
    const response = await instance.get("dashboard/VenteAndRecetteForKpi/date");
    return response;
  } catch (error) {
    console.error();
    return error.response

  }
}
/** NBr BL OU COMMANDES PAR user  */
export async function getNbrBLCMD() {
  try {
    const response = await instance.get("commandes/byCommercialcount/date");
    return response;
  } catch (error) {
    console.error();
  }
}
/** GET STATUS */
export async function getStatus(code) {
  try {
    const response = await instance.get("equipements/commercial/" + code);
    return response;
  } catch (error) {
    console.error();
  }
}
/**GET ANAYLYES COMMANDES */
export  function getCommandes(date1,date2) {
  try {
    const response =   axios.get(url + `entetecommercial/getEntetetoNomadis/commande/` + date1 + "/" + date2, {
      SameSite: "None",
      secure: true,
      IsEssential: true,
      withCredentials: true,
    })
    return response;
  } catch (error) {
    if (error.response === undefined) {
      return false
    } else if (error.response.status === 401) {
      return 401
    }
  }
}
/**
 * GET CREDIT BY COMMERCIAL
 * @returns
 */
export async function getCreditCommercial() {
  try {
    const response = await instance.get("paiements/credits/byCommercial/date");
    return response;
  } catch (error) {
    console.error();
  }
}
/** COUNT BL COMMERCIAL BY DATE (today) */
export async function getCountBlToday() {
  try {
    const response = await instance.get("bl/byCommercialcount/date");
    return response;
  } catch (error) {
    console.error();
  }
}
/**
 * Get List ROUTING
 * @returns
 */
export async function getListRouting() {
  try {
    const response = await instance.get("routing");
    return response;
  } catch (error) {
    console.error();
  }
}
/**
 * Visites COmmercials TO CLIENTS
 */
export async function getVisitesClients(code, date) {
  try {
    const response = await instance.get(
      "visites/byCommercial/" + code + "/" + date
    );
    return response;
  } catch (err) {
    console.error();
  }
}

/**
 * GET NB COMMANDES By COMMERCIAL
 */
export async function getNbCMDCommercial() {
  try {

    const response = await instance.get("commandes/byCommercialcount/date");
    return response;
  } catch (error) {
    console.error();

    return error.response.data

  }
}

/**
 * GET TOTAL COMMANDES By COMMERCIAL
 * @param {string} code
 * @param {date} today
 * @returns
 */
export async function getTotalCMDCommercial(code, today) {
  try {
    const response = await instance.get(
      "commandes/byCommercialcode/" + code + "/" + today
    );
    return response;
  } catch (error) {
    console.error();
  }
}


/**
 * GET All ELEMENTS in one liste
 */
export async function getListeCommerciaux(today) {
  try {
    const response = await instance.get("entetecommercial/VenteByDate?date=" + today);
    return response;
  } catch (error) {
    console.error();
    if (error.response === undefined) {
      return false
    }
    else if (error.response.status === 401) {
      return 401
    } else {
      return error.response.data
    }
  }
}

/**
 * GET LIST OF ALL DISTRIBUTEURS
 */
export async function getListDistributeur() {
  try {
    const response = await instance.get("distributeurs/selectedSousSocietes");
    return response;
  } catch (error) {
    if (error.response === undefined) {
      return false
    }
    else if (error.response.status === 401){
      return 401
    }
    else if (error.response.status === 422){
      return 422
    }
    else if (error.response.status === 401) {
      return 401
    } else {
      return error.response.data
    }
  }
}
/**
 * GET LIST OF ALL SOUS SOCIETE 
 */
 export  function getListeSousSociete() {
  try {
    const response =  axios.get(url+"soussocietes/GlobalfilterSousSociete",{
      withCredentials: true,
      IsEssential: true,
    });
    return response;
  } catch (error) {
    if (error.response === undefined) {
      return false
    }
    else if (error.response.status === 401){
      return 401
    }
    else if (error.response.status === 422){
      return 422
    }
    else if (error.response.status === 401) {
      return 401
    } else {
      return error.response.data
    }
  }
}
/**
 * GET LIST OF ALL SOUS SOCIETE 
 */
 export  function getAllSousSociete() {
  try {
    const response =  axios.get(url+"soussocietes",{
      withCredentials: true,
      IsEssential: true,
    });
    return response;
  } catch (error) {
    if (error.response === undefined) {
      return false
    }
    else if (error.response.status === 401){
      return 401
    }
    else if (error.response.status === 422){
      return 422
    }
    else if (error.response.status === 401) {
      return 401
    } else {
      return error.response.data
    }
  }
}
/**
 * POST AFFECTATION SOUS SOCIETE 
 * @param {*} codesoussociete 
 * @returns 
 */
export async function affectationSousSociete (data){
  try {
    const response = await instance.post("soussocietes/manage_affectationSS",data);
    return response;
  } catch (error) {
    if (error.response === undefined) {
      return false
    }
    else if (error.response.status === 401){
      return 401
    }
    else if (error.response.status === 422){
      return 422
    }
    else if (error.response.status === 401) {
      return 401
    } else {
      return error.response.data
    }
  }
}
/**
 * getListeProduit
 * @param {*} codesoussociete 
 * @returns 
 */
export async function getListeProduit (codesoussociete){
  try {
    const response = await instance.get("mouvements/names?soussociete_code="+codesoussociete);
    return response;
  } catch (error) {
    if (error.response === undefined) {
      return false
    }
    else if (error.response.status === 422){
      return 422
    }
    else if (error.response.status === 401) {
      return 401
    } else {
      return error.response.data
    }
  }
}
/**
 * GET TYPE RETOUR ---> CHARGEMENT QTE
 * 
 */
export async function getListeRetour (code){
  try {
    const response = await instance.get("chargements/change_qte_by_type?commercial="+code+"&type=retour");
    return response;
  } catch (error) {
    if (error.response === undefined) {
      return false
    }
    else if (error.response.status === 422){
      return 422
    }
    else if (error.response.status === 401) {
      return 401
    } else {
      return error.response.data
    }
  }
}
/**
 * GET LIST USERS
 * @returns 
 */
export async function getListeUsers () {
  try {
    const response = await instance.get("users/cashvanprevendeur");
    return response;
  } catch (error) {
    if (error.response === undefined) {
      return false
    }
    else if (error.response.status === 422){
      return 422
    }
    else if (error.response.status === 401) {
      return 401
    } else {
      return error.response.data
    }
  }
}
/*** RECETTE BY MONTH  */
export async function getRecetteMonth (){
  try {
    const response = await instance.get("paiements/recetteMonth")
    return response;
  } catch (error) {
    if (error.response === undefined) {
      return false
    }
    else if (error.response.status === 401) {
      return 401
    } else {
      return error.response.data
    }
}}
/*** Cheque + Espece BY MONTH  */
export async function getChequeMonth (){
  try {
    const response = await instance.get("paiements/sum_paiements_mois")
    return response;
  } catch (error) {
    if (error.response === undefined) {
      return false
    }
    else if (error.response.status === 401) {
      return 401
    } else {
      return error.response.data
    }
}}
/**
 * Get List Stock Produit
 * @param {*} code 
 * @returns 
 */
export async function getListStockProduit(code) {
  try {
    const response = await instance.get("stock_depot/getStockDepot?soussociete_code=" + code)
    return response;
  } catch (error) {
    if (error.response === undefined) {
      return false
    }
    else if (error.response.status === 401) {
      return 401
    } else {
      return error.response.data
    }
  }
}
/**
 * GET SOLDE CONGEE
 * @param {*} code 
 * @returns 
 */
export async function getSoldeCongee() {
  try {
    const response = await instance.get("demande_conge")
    return response;
  } catch (error) {
    if (error.response === undefined) {
      return false
    }
    else if (error.response.status === 401) {
      return 401
    } else {
      return error.response.data
    }
  }
}

//gestion_commandes?date=2021-10-27
export async function gestionCommadesBydate (date){
  try {
    const response = await instance.get("gestion_commandes?date="+date);
    return response;
  } catch (error) {
    if (error.response === undefined) {
      return false
    }
    else if (error.response.status === 422){
      return 422
    }
    else if (error.response.status === 401) {
      return 401
    } else {
      return error.response.data
    }
  }
}

/**
 *  Get ticket Resto
 */

 export async function getTicketRestau (){
  try {
    const response = await instance.get("ticket_cadeaux");
    return response;
  } catch (error) {
    if (error.response === undefined) {
      return false
    }
    else if (error.response.status === 422){
      return 422
    }
    else if (error.response.status === 401) {
      return 401
    } else {
      return error.response.data
    }
  }
 }

 /** Get Situation Paiement Client  */
 export async function getSituationPaiement (client,client_id,code,commercial_code,date,depot_code,depot_id,libelle,num_bl,num_cmd,user_code){
  try {
    const response = await instance.get("getCreditAndPlafondForCmd?client=SALHI+AHMED&client_id=82&code=CMD-2021102711464&commercial_code=V2&date=2021-10-27&depot_code=C2&depot_id=65&libelle=camion2&num_bl=BLN-1-3C20390&num_cmd=1-3C20390&user_code=V2")
    //const response = await instance.get("getCreditAndPlafondForCmd?client="+client+"&client_id="+client_id+"&code="+code+"&commercial_code="+commercial_code+"&date="+date+"&depot_code="+depot_code+"&depot_id="+depot_id+"&libelle="+libelle+"&num_bl="+num_bl+"&num_cmd="+num_cmd+"&user_code="+user_code);
    return response;
  } catch (error) {
    if (error.response === undefined) {
      return false
    }
    else if (error.response.status === 422){
      return 422
    }
    else if (error.response.status === 401) {
      return 401
    } else {
      return error.response.data
    }
  }
 }