/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
//-------------- UTILS -----------------------
import { numberWithSpaces } from "../../../utils/NumberWithSpaces";
import { getCurrentDate, getCurrentMonth, getMonthName, getMonthNameCmd, getMonthNameFrensh, sameDay } from "../../../utils/DateUtils";
// ----------- components ---------------------
import SquareLoader from "../../../components/Loader/SquareLoader";
// ------------ ERROR  ------------------------
import ErrorAlert from "../../../others/ErrorAlert";
// -------------MOMENT --------------------
import moment from "moment";
// ------------ TRADUCTION ---------------------
import { useTranslation } from "react-i18next";
//---------------- ALERTE --------------------
import { toast } from "react-toastify";
// -------------- AXIOS pour fetch api -------------------------------
import axios from "axios";
import { getObjectifVente } from "../../../services/ventes/venteApi";
import { getCommandes, getEnteteCommercial } from "../../../services/commande/commandeApi";


// ----------- CARD : (total commande aujourd'hui / par mois / par année ) ----------------------

export default function Cards() {
  //DATE TODAY
  const today = getCurrentDate();
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  var date1 = firstDay.getFullYear() + "-" + (firstDay.getMonth() + 1) + "-" + firstDay.getDate()
  var date2 = lastDay.getFullYear() + "-" + (lastDay.getMonth() + 1) + "-" + lastDay.getDate()
  const month = getMonthName(getCurrentMonth());
  //LODER
  const [loading, setLoading] = useState(true);
  const [loadingCMD, setloadingCMD] = useState(true);

  //DATE COMMANDE
  const [dayCommande, setDayCommande] = useState("00");
  const [monthCommande, setmonthCommande] = useState("00");
  const [yearCommande, setyearCommande] = useState("00");
  //ALERT
  const [alert, setAlert] = useState(false);
  //Objectif commande
  const [objCmd, setObjCmd] = useState(0); // annee
  const [objCmdMonth, setObjCmdMonth] = useState(0); // Month
  //Values
  const [valueMonth, setValueMonth] = useState(0);
  const [valueMonthCmd, setValueMonthCmd] = useState(0);
  //Languages
  const [t] = useTranslation("global");
  //URL 
  const url = localStorage.getItem("url_nomadis");


  useEffect(() => {
    //GET OBJECTIF VENTE
    getObjectifVente()
      .then((res) => {
        const result = res.data;
        //Calcul Objectif Annee
        const obj_annee =
          ((result.c_janvier +
            result.c_fevrier +
            result.c_mars +
            result.c_avril +
            result.c_mai +
            result.c_juin +
            result.c_juillet +
            result.c_aout +
            result.c_septembre +
            result.c_octobre +
            result.c_novembre +
            result.c_decembre +
            result.c_decembre) *
            100) /
          (result.janvier +
            result.fevrier +
            result.mars +
            result.avril +
            result.mai +
            result.juin +
            result.juillet +
            result.aout +
            result.septembre +
            result.octobre +
            result.novembre +
            result.decembre +
            result.decembre);
        setObjCmd(obj_annee);
        //Calcul Objectif Month
        const month_fr = getMonthNameFrensh(getCurrentMonth());
        const month_cmd = getMonthNameCmd(getCurrentMonth());
        const date = Object.entries(result);
        date.map((items, keys) => {
          if (date[keys][0] === month_cmd) {
            setValueMonthCmd(date[keys][1]);
          }
          if (date[keys][0] === month_fr) {
            setValueMonth(date[keys][1]);
          }
        });
      })
      .catch((error) => console.log(error));

    //GET COMMANDE BY DAY
   getCommandes(date1,date2)
      .then((res) => {

        if (res === false) {
          toast(t('widget.cnxinternet'), {
            type: "warning", position: "bottom-center"
          });
          setAlert(true)
        } else if (res === 401) {
          toast(t('widget.messageAutorisation'), {
            type: "warning", position: "bottom-center"
          });
        }
        else if (res?.data.length !== 0) {
          setmonthCommande(res?.data[0].net_a_payer === null ? 0 : res?.data[0].net_a_payer)
        }
        setLoading(false)

      })
      .catch((err) => console.log(err))

    //GET COMMANDE BY DAY
    getCommandes(today,today)
      .then((res) => {

        if (res === false) {
          toast(t('widget.cnxinternet'), {
            type: "warning", position: "bottom-center"
          });
          setAlert(true)
        } else if (res === 401) {
          toast(t('widget.messageAutorisation'), {
            type: "warning", position: "bottom-center"
          });
        }
        else if (res?.data.length !== 0) {
          setDayCommande(res?.data[0].net_a_payer === null ? 0 : res?.data[0].net_a_payer)
        }

      })
      .catch((err) => console.log(err))
    //GET COMMANDE BY YEARS
    getEnteteCommercial()
      .then((res) => {
        if (res?.data?.length !== 0) {
          setyearCommande(res?.data?.commandes[0]?.net_a_payer);
          setloadingCMD(false)
        }
      })
      .catch((err) => console.log(err))




  }, []);

  //ON UPDATE VALUE MONTH VENTE / VALUE MONTH
  useEffect(() => {
    if (valueMonth !== 0) {
      const calcul_Month = (valueMonthCmd * 100) / valueMonth;
      setObjCmdMonth(calcul_Month);
    } else {
      setObjCmdMonth(0);
    }
  }, [valueMonth, valueMonthCmd]);


  return (
    <div>

      <div className="row">
        <div className=" col-md-4 col-lg-4 col-xl-4">
          <div className="card overflow-hidden">
            <div className="card-body">
              {loading ? <SquareLoader /> : (
                <>
                  <h4 className="card-title mb-4 text-primary">
                    {t("suivi.commandeaujourdhui")}
                  </h4>
                  <div className="d-flex  align-items-center">
                    <h4 className=" mb-0">
                      {numberWithSpaces(parseFloat(dayCommande).toFixed(3))}
                    </h4>
                    <div className="text-primary mt-0 mb-2  ml-auto">
                      <i
                        className="ti-package  fa-3x overflow-hidden mx-auto"
                        width={100}
                        height={100}
                      />
                    </div>
                  </div>
                  <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                  <p className="text-muted  mb-4  ">
                    <span className="font-weight-normal text-muted fs-13">
                      {" 0.00 ٪ "}  {t('dashboard.objectifdujour')}
                    </span>
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-4  col-lg-4 col-xl-4">
          <div className="card overflow-hidden">
            <div className="card-body">
              {loading ? <SquareLoader /> : (
                <>
                  <h4 className="card-title mb-4 text-primary">
                    {t('commandes.commande') + " (" + moment().format("MMMM") + ")"}

                  </h4>
                  <div className="d-flex  align-items-center">
                    <h4 className=" mb-0">
                      {numberWithSpaces(parseFloat(monthCommande).toFixed(3))}
                    </h4>
                    <div className="text-primary mt-0 mb-2  ml-auto">
                      <i
                        className="ti-money fa-3x overflow-hidden mx-auto"
                        width={100}
                        height={100}
                      />
                    </div>
                  </div>
                  <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                  <p className="text-muted  mb-4  ">
                    <span className="font-weight-normal text-muted fs-13">
                      {parseFloat(objCmdMonth) >= 100
                        ? "100 ٪"
                        : (isNaN(objCmdMonth) ? 0.00 + " ٪ " : parseFloat(objCmdMonth).toFixed(2) + " ٪ ")}
                      {" " + t("dashboard.objectif") + " " + moment().format("MMMM")}
                    </span>
                  </p>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="col-md-4  col-lg-4 col-xl-4">
          <div className="card overflow-hidden">
            <div className="card-body">
              {loadingCMD ? <SquareLoader /> : (
                <>
                  <h4 className="card-title mb-4 text-primary">
                    {t('commandes.commande') + " (" + moment().format("YYYY") + ")"}
                  </h4>
                  <div className="d-flex  align-items-center">
                    <h4 className=" mb-0">
                      {numberWithSpaces(parseFloat(yearCommande).toFixed(3))}
                    </h4>
                    <div className="text-primary mt-0 mb-2  ml-auto">
                      <i
                        className="ti-bar-chart fa-3x overflow-hidden mx-auto"
                        width={100}
                        height={100}
                      />
                    </div>
                  </div>
                  <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                  <p className="text-muted  mb-4  ">
                    <span className="font-weight-normal text-muted fs-13">
                      {parseFloat(objCmd) >= 100 ? 100 + " ٪ "
                        : (isNaN(objCmd) ? 0.00 + " ٪ " : parseFloat(objCmd).toFixed(2) + " ٪ ")}
                      {" " + t("dashboard.objectif") + " (" + moment().format("YYYY") + ") "}
                    </span>
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {alert ? <ErrorAlert t={t} /> : <></>}
    </div>
  );
}
