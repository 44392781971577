import React from 'react'

export default function VeilleConcurentielle({ notes }) {
    return (
        <>
       {notes.length !== 0 ?(
        <div className="card">
            <div className="card-header">
                <h3 className="card-title mt-3"> <i className="icon icon-bubble text-primary mr-2" /> Notes</h3>
            </div>
            <div className="card-body">
                <ul className="timelineleft pb-5">
                    {notes.map((items, keys) => (
                        <>
                            <li className="timeleft-label"><span className="bg-azure">{items.date}</span></li>
                            <li>
                                <i className="fa fa-envelope bg-warning" />
                                <div className="timelineleft-item">
                                    <h2 className="timelineleft-header"><b>{items.objet}</b> </h2>
                                    <div className="timelineleft-body">
                                        {items.message}
                                    </div>
                                </div>
                            </li>
                        </>
                    ))}
                </ul>
            </div>{/* col */}
        </div>
       ):<></>} 
        </>
    )
}
