/* eslint-disable no-unused-vars */
import React from "react";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const [t, i18n] = useTranslation("global");

  return (
    <footer className="footer-main">
      <div className="container">
        <div className="mt-2 mb-2 text-center mr-2">
        {t("copyright.title")+"  © "}
          <a href="https://nomadis.online/" className="fs-14 text-primary">
          {t("copyright.solution")+" "}
          </a>
           {" "+t("copyright.entreprise")+" "}
          <a href="http://clediss.ma/" className="fs-14 text-primary">
            CLEDISS
          </a>
          {" "+t("copyright.droits")}.
        </div>
      </div>
    </footer>
  );
}
