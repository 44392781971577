/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { OverlayTrigger } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { popover } from '../../../components/Popover/Popovertab';
import { numberWithSpaces } from '../../../utils/NumberWithSpaces';
import { sortdate, sortdatedesc } from '../../../utils/SearchUtils';

export default function CardCommandeCommercial({ commandes, setCommandes, savecommande, t, sendToClientProfile, commercial }) {

    // -------------- References ---------------
    const inputEl = useRef("")
    //------ History -----------------
    const history = useHistory();
    // -------------- FILTRE + SEARCH ----------
    const [search, setSearch] = useState('')
    const [searchList, setSearchlist] = useState([])
    const [value, setValue] = useState(-1);
    // ------------------- Loading --------------
    const [loading, setLoading] = useState(true);
    const currentPosts = search.length < 1 ? commandes : searchList



    //------- FILTRE ELEMENTS  ------------------
    function ChangeElement(e) {
        setLoading(true)
        if (e.target.value === "0") {
            setCommandes(savecommande)
        }
     
        else if (e.target.value === "3") {
            if (search.length < 2) {
                sortdate(commandes)
            } else {
                sortdate(searchList)
            }
        }
        else if (e.target.value === "4") {
            if (search.length < 2) {
                sortdatedesc(commandes)
            } else {
                sortdatedesc(searchList)
            }
        }
        setLoading(false)
    }
    // ---------- SEARCH -------------- 
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (search !== "") {
            const newList = commandes?.filter((el) => {
                return Object.values(el)
                    .join(" ")
                    .toLowerCase()
                    .includes(inputEl.current.value.toLowerCase())
            })
            setSearchlist(newList)

        } else {
            setCommandes(savecommande)
        }
    }
    //---------- Calculer Total Commandes
    function totalVentes () {
        const total = currentPosts.reduce(
        (totalsum, { net_a_payer }) => totalsum + parseFloat(net_a_payer), 0);
        return total
    }
  
    return (
        <>
            {commandes.length !== 0 ? (
                <div className="card">
                    {/**--------------------SLIDE ACCORDION FILTRE AND SEARCH  ------------------------- */}
                    <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true">
                        <div className="panel panel-default mt-2">
                            <div className="panel-heading" role="tab" id="headingthree">
                                <h4 className="panel-title">
                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseThree" aria-expanded="false" aria-controls="collapseTwo">
                                        <i className="icon icon-basket-loaded text-primary mr-2" />  {t('suivi.ReportingCommande')} 
                                        <span className="float-right"> 
                                             {currentPosts.length !== 0 ? <h6> Total : {(totalVentes().toFixed(3))} / Count : {commandes?.length}  <i className="fe fe-search  text-primary" />  </h6> :  <i className="fe fe-search  text-primary" />  }
                                           </span>                                    
                                    
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingthree">
                                <div className="panel-body">
                                    <div className="row" >
                                        <div className="col-lg-6 col-xl-6 col-12">
                                            {/* -------------------- RECHERCHE + FILTRE -------------------------------------- */}
                                            <div className="d-flex">
                                                <label className="mr-2 mt-1 mb-sm-1">{t('stock.trierpar')} <i className="ti-exchange-vertical" /></label>
                                                <select name="item" className="form-control nice-select mb-0 nice-select-md w-250 "
                                                    value={value}
                                                    onChange={(e) => {
                                                        setValue(e.target.value)
                                                        ChangeElement(e)
                                                    }}
                                                >
                                                    <option value={0}>------- {t('stock.trierpar')} -----</option>
                                                   {/*  <option value={1}> {t('suivi.planifiee')} </option>
                                                    <option value={2}>{t('suivi.nonplanifiee')} </option> */}
                                                    <option value={3}> Date Croissante</option>
                                                    <option value={4}> Date décroissante</option>

                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-6 col-12">
                                            <div className="search1 search-elements-sm ">
                                                <div className="form row no-gutters ">
                                                    <div className="form-group  col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 mb-0">
                                                        <input
                                                            type="text"
                                                            className="form-control br-tr-md-0 br-br-md-0"
                                                            ref={inputEl}
                                                            onChange={() => getSearchTerm()}
                                                            placeholder={t("stock.search")}
                                                        />
                                                    </div>
                                                    <div className="col-xl-2 col-lg-2 col-md-2 col-2 mb-0">
                                                        <button
                                                            className="br-tl-md-0 br-bl-md-0 btn btn-block btn-success fs-16"
                                                        >
                                                            <i className="fe fe-search" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/**-------------..-----END OF SLIDE ACCORDION FILTRE AND SEARCH  ------------------ */}

                    <div className="card-body">
                        {/** --------------- Tableau Card Commande Commercials -------------------------- */}
                        <div className="table-responsive">
                            <table id="contact-detail" className="table card-table table-striped tutorial-table" cellSpacing={0} width="100%">
                                <thead>
                                    <tr className="text-center">
                                        <th className="priority-1">{t('stock.date')}</th>
                                        <th className="priority-3">{t('suivi.societe')}</th>
                                        <th className="priority-1">{t('releve.client')}</th>
                                        <th className="priority-3">{t('commandes.commande')}</th>
                                        <th className="priority-3">{t('releve.remise')}</th>
                                        <th className="priority-1">{t('historique.montant')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentPosts.map((items, keys) => (
                                        <tr className="text-center" key={keys}>
                                            <td className="priority-1">{moment(items.date).format("DD-MM-YYYY")}</td>
                                            <td className="priority-3">{items.soussociete}</td>
                                            <td className="priority-1"> <b style={{ cursor: 'pointer' }} onClick={() => sendToClientProfile(items.client_code)} className="text-warning">{items.client}</b></td>
                                            <td className="priority-3"><a href={'/detail_releve/' + items.code}><u className="text-primary">{items.code}</u></a></td>
                                            <td className="priority-3">{parseFloat(items.remise).toFixed(3)}</td>
                                            <td className="priority-1" width="15%"  ><a href={'/detail_releve/' + items.code}><u className="text-primary">{parseFloat(items.net_a_payer).toFixed(3)}</u></a>
                                                <OverlayTrigger trigger="click" rootClose placement="left" overlay={popover(items.soussociete, items.code, items.in_tournee,t)}>
                                                    <i className="icon icon-plus text-success ml-2" />
                                                </OverlayTrigger>
                                            </td>                                         
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            ) : <></>}
        </>
    )
}
