import React from 'react'
import { numberWithSpaces } from '../../../utils/NumberWithSpaces'

export default function CardCredit({t,credit}) {
  return (
    <div className="card box-shadow-0 mb-lg-0">
      <div className="card-body">
        <div className="item-box text-center">
        <div className="bg-success-transparent icon-bg icon-service text-success">
        <i className="fa fa-credit-card" />
       </div>
          <div className="item-box-wrap">
            <p className="text-muted  pmobiletext mb-0">{isNaN(credit)? 0 : numberWithSpaces(parseFloat(credit).toFixed(3))}</p>
            <h6 className=" mobiletext mb-2"  style={{fontSize:"12px"}}> {t("todayCommercial.credit")} </h6>
          </div>
        </div>
      </div>
    </div>
  )
}
