import axios from "axios";

//GET URL FROM LOCALSTORAGE
const url = localStorage.getItem("url_nomadis");

const instance = axios.create({
  baseURL: url,
  withCredentials: true,
/*   SameSite: "None",
  Secure: true, */
  IsEssential: true,
});
/**
 * GET TROURNEE PAR COMMERCIALS
 * @returns
 */
export async function getTourneeComerciaux() {
  try {
    const response = await instance.get(
      "tournees/getRouteTourneeClient?type=bl"
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**GET LIST CLIENT OF TOURNEE DU COMMERCIAL */
export async function getTourneeClient(
  routing_code,
  tournee_code,
  user_code,
  frequence
) {
  try {
    const response = await instance.get(
      "tournees/" +
        routing_code +
        "/" +
        tournee_code +
        "/" +
        user_code +
        "/" +
        frequence
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**GET FREQUENCE TOURNEE */
export async function getAllVisite() {
  try {
    const response = await instance.get("tournees/getAllVisitesforTourne");
    return response;
  } catch (error) {
    if (error.response === undefined) {
      return false
    }
    else if (error.response.status === 401) {
      return 401
    } else {
      return error.response.data
    }
  }
}
/**GET CLIENT BY CODE TOURNEE */
export async function getTourneeClientByCode(code) {
  try {
    const response = await instance.get("tournees/clientstournees/" + code);
    return response;
  } catch (error) {
    console.error(error);
  }
}
