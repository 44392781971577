import axios from "axios";
import { toast } from "react-toastify";

//GET URL FROM LOCALSTORAGE
const url = localStorage.getItem("url_nomadis");

const instance = axios.create({
  withCredentials: true,
 /*  SameSite: "None",
  secure: true, */
  IsEssential: true,
});

/**
 * CONNAITRE LES INFORMATIONS DE VENTES
 * @returns  LISTE array
 */
export async function getListeVente() {
  try {
    const response = await instance.get(url + "entetecommercial/analyses");
    return response;
  } catch (error) {
    if (error.response === undefined) {
      return false
    } else if (error.response.status === 401) {
      return 401
    }
  }
}
export async function getVentesByDates(date1,date2) {
  try {
    const response = await instance.get(url + "entetecommercial/getEntetetoNomadis/vente/" +date1 + "/" + date2, {
      withCredentials: true,
      SameSite: "None",
      secure: true,
      IsEssential: true,
    });
    return response;
  } catch (error) {
    if (error.response === undefined) {
      return false
    } else if (error.response.status === 401) {
      return 401
    }
  }
}
export async function getObjectifVente() {
  try {
    const response = await instance.get(url + "objectifs/getObjectifDashboard", {
      withCredentials: true,
      SameSite: "None",
      secure: true,
      IsEssential: true,
    });
    return response;
  } catch (error) {
    if (error.response === undefined) {
      return false
    } else if (error.response.status === 401) {
      return 401
    }
  }
}
/**
 * GET STOCK BY COMMERCIAL
 * @param {string} code
 * @returns
 */
export async function getStockByCommer(code) {
  try {
    const response = await instance.get(url + "stocks/getByUser/" + code);
    return response;
  } catch (error) {
    if (error.response === undefined) {
      return false
    }
  }
}
/**GET TAUX DE VENTES */
export async function getTauxVente() {
  try {
    const response = await instance.get(
      url + "entetecommercial/calcul/tauxdeVenteCommande/vente"
    );
    return response;
  } catch (error) {
    console.error(error);
  }
} /**GET TAUX DE Commandes */
export async function getTauxCommandes() {
  try {
    const response = await instance.get(
      url + "entetecommercial/calcul/tauxdeVenteCommande/commande"
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

/** OBJECTIF COMMANDES ET VENTES  */
export async function getObjectifs() {
  try {
    const response = await instance.get(url + "objectifs/getObjectifDashboard");
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * get informations about COMMERCIAL FOR synch Bouton
 * @param {*} code
 * @returns
 */
export async function getCommercialInfo(code) {
  try {
    const response = await instance.get(
      url + "users/getSelectedUserByCode/" + code
    );
    return response;
  } catch (error) {
    console.error(error);
    if (error.response.data.success === false) {
      toast(
        "Votre Session a expiré.",
        { type: "error", position: "bottom-center" }
      );
    }
    return error.response.data
  }
}
/** Get ETAT PAIEMENT par commercial */
export async function getETatPaiementToday() {
  try {
    const response = await instance.get(url + "paiements/getEtatPaiement/date");
    return response;
  } catch (error) {
    console.error(error);
  }
}
/** Get ETAT PAIEMENT par commercial */
export async function getETatPaiementMonth() {
  try {
    const response = await instance.get(
      url + "paiements/getEtatPaiement/month"
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}
//GET ETAT PAIEMENT CHEQUE
export async function getcheques() {
  try {
    const response = await instance.get(
      url + "paiements/getEtatPaiement/recouvrement"
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**GET ALL STOCK */
export async function getAllStock(code) {
  try {
    const response = await instance.get(
      url+"stock_depot/stock_sup/"+code
    );
    return response;
  } catch (error) {
    if (error.response === undefined) {
      return false
    }
    else if (error.response.status === 422){
      return 422
    }
    else if (error.response.status === 401) {
      return 401
    } else {
      return error.response.data
    }
  }
}

/**  Get ALL COMMANDES */
export async function getAllBLByCommercial(code, today) {
  try {
    const response = await instance.get(
      url + "bl/getByDate/" + today + "/" + today + "/bl/" + code
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

