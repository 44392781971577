//objectifs/all
import axios from "axios";

//GET URL FROM LOCALSTORAGE
const url = localStorage.getItem("url_nomadis");

const instance = axios.create({
  baseURL: url,
  withCredentials: true,
/*   SameSite: "None",
  Secure: true, */
  IsEssential: true,
});
/**
 * GET LIST OF ALL OBJECTIFS
 * @returns
 */
export async function getAllObjectifs() {
  try {
    const response = await instance.get("objectifs/all");
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * GET OBJECTIFS BEETWEEN DATE
 * @param {date} d  // date debut
 * @param {date} f  // date fin
 * @returns
 */
export async function getObjectifsBeetween(d, f) {
  try {
    const response = await instance.get("objectifs/getObjGraph/"+d +"/"+f);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**Get graphe gamme par mois */
export  function getAllGammeMonth() {
  try {
    const response =  axios.get(url+"bl/lignes/byGammeYear1/month",{
      SameSite: "None",
      secure: true,
      IsEssential: true,
      withCredentials: true
    })
    return response
  } catch (error) {
    if (error.response === undefined) {
      return false
    } else if (error.response.status === 422) {
      return 422
    } else if (error.response.status === 404) {
      return 404
    }
    else {
      console.error(error);
      return error.response
    }
  }
}

export  function getSousFamilleByClient(code) {
  try {
    const response =  axios.get(url+"entetecommercial/getTopSousfamilleByClient/"+code,{
      SameSite: "None",
      secure: true,
      IsEssential: true,
      withCredentials: true
    })
    return response
  } catch (error) {
    if (error.response === undefined) {
      return false
    } else if (error.response.status === 422) {
      return 422
    } else if (error.response.status === 404) {
      return 404
    }
    else {
      console.error(error);
      return error.response
    }
  }
}

/**GET GRAPHE BY YEARS */
export  function getAllGammeYears() {
  try {
    const response =  axios.get(url+"bl/lignes/byGammeYear1/year",{
      SameSite: "None",
      secure: true,
      IsEssential: true,
      withCredentials: true
    })
    return response
  } catch (error) {
    if (error.response === undefined) {
      return false
    } else if (error.response.status === 422) {
      return 422
    } else if (error.response.status === 404) {
      return 404
    }
    else {
      console.error(error);
      return error.response
    }
  }
}
/** get graphe by day  */
export async function getAllGammeDay() {
  try {
    const response = await axios.get(url+"bl/lignes/byGammeYear1/day",{
      SameSite: "None",
      secure: true,
      IsEssential: true,
      withCredentials: true
    })
    return response
  } catch (error) {
    if (error.response === undefined) {
      return false
    } else {
      return error.response
    }
  }
}