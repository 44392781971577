/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import ErrorAlert from "../../../others/ErrorAlert";
import { getMouvementClient } from "../../../services/clients/ClientApi";
import { numberWithSpaces } from "../../../utils/NumberWithSpaces";
import { filterLibelleMouvement, SearchItems } from "../../../utils/SearchUtils";
import Loader from '../../../components/shared/Loader'

export default function Releve() {
  let { code } = useParams();
  //USE REF
  const inputEl = useRef("")
  const history = useHistory();
  const [t, i18n] = useTranslation("global");
  //LIST MOUVEMENTS CLIENTS
  const [listMouvement, setListMouvements] = useState([]);
  const [searchList, setSearchlist] = useState([])
  const [saveList, setSaveList] = useState([])
  //TOTAL DEBIT CREDIT ET SOLDE
  const [data, setData] = useState({
    debit: 0,
    credit: 0,
    solde: 0,
  });
  const [search, setSearch] = useState("");
  //ERROR
  const [error, setError] = useState(false);
  const [value, setValue] = useState(0);

  //PAGINATION
  const [currentPage, setCurrentPage] = useState(1);
  const [stocksPerPage, setStockPerPage] = useState(10);

  //Get current stock
  const indexOfLastPost = currentPage * stocksPerPage;
  const indexOfFirstPost = indexOfLastPost - stocksPerPage;
  const currentPosts = search.length < 3 ? listMouvement?.slice(indexOfFirstPost, indexOfLastPost) : searchList?.slice(indexOfFirstPost, indexOfLastPost)

  const [loading, setLoading] = useState(true)


  const getSearchTerm = () => {
    setSearch(inputEl.current.value)
    if (search !== "") {
      const newList = SearchItems(listMouvement, search)
      setSearchlist(newList)
    } else {
      setListMouvements(saveList)
    }
  }
  //Pagination Function =>Ajouter un nombre
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
       
        
  useEffect(() => {
    getMouvementClient(code)
      .then((res) => {
        if (res?.data) {
          setListMouvements(res.data);
          setSaveList(res.data)
          const credit = res.data
            .filter(({ sens_ecriture }) => sens_ecriture === "C")
            .reduce(
              (totalsum, { montant }) => totalsum + parseFloat(montant),
              0
            );
          const debit = res.data
            .filter(({ sens_ecriture }) => sens_ecriture === "D")
            .reduce(
              (totalsum, { montant }) => totalsum + parseFloat(montant),
              0
            );
          const total = res.data.reduce(
            (totalsum, { montant }) => totalsum + parseFloat(montant),
            0
          );
          setData({
            credit: credit,
            debit: debit,
            solde: total,
          });
          setLoading(false)
        }
      })
      .catch((error) => console.log(error))
  }, []);

  const selectParLibMouvement = (e) => {
    //  Activer ===> Loader
    setLoading(true)
    if (e.target.value === "0") {
      if (search.length < 2) {
        setListMouvements(saveList)
      } else {
        setListMouvements(searchList)
      }
    }
    else if (e.target.value === "1") {
      if (search.length < 2) {
        setListMouvements(filterLibelleMouvement(saveList, "Vente")
        )
      } else {
        setListMouvements(filterLibelleMouvement(searchList, "Vente"))
      }
    }
    else if (e.target.value === "2") {
      if (search.length < 2) {
        setListMouvements(filterLibelleMouvement(saveList, "Recouvrement"))
      } else {
        setListMouvements(filterLibelleMouvement(searchList, "Recouvrement"))
      }
    }
    else if (e.target.value === "3") {
      if (search.length < 2) {
        setListMouvements(filterLibelleMouvement(saveList, "Avoir"))
      } else {
        setListMouvements(filterLibelleMouvement(searchList, "Avoir"))
      }
    } else if (e.target.value === "4") {
      if (search.length < 2) {
        setListMouvements(filterLibelleMouvement(saveList, "Paiement"))
      } else {
        setListMouvements(filterLibelleMouvement(searchList, "Paiement"))
      }
    }
    else if (e.target.value === "5") {
      if (search.length < 2) {
        setListMouvements(filterLibelleMouvement(saveList, "Correction Solde Client"))
      } else {
        setListMouvements(filterLibelleMouvement(searchList, "Correction Solde Client"))
      }
    }
    setLoading(false)
  }
  return (

    <div>
      {loading ? <Loader /> : (
        <>
          <div className="section-title">
            <button className="btn bg-primary " onClick={() => history.goBack()}>
              <i className="fe fe-arrow-left" />
              {t("stock.retour")}
            </button>
          </div>
          <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
            <div className="panel panel-default active">
              <div className="panel-heading " role="tab" id="headingOne">
                <h4 className="panel-title">
                  <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    <i className="icon icon-grid text-primary mr-2" /> {t('detailClient.listereleve')+" : "+code} 
                    <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                  </a>
                </h4>
              </div>
              <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                <div className="panel-body">
                  <div className="item2-gl-nav d-flex" style={{ marginBottom: '20px', marginTop: '10px' }}>
                    <div className="col-md-6 col-12 ">
                      {/* -------------------- RECHERCHE + FILTRE -------------------------------------- */}
                      <div className="d-flex" >
                        <label className="mr-2 mt-1 mb-sm-1"> {t('releve.filtrer') + " : "} <i className="ti-exchange-vertical" /></label>
                        <select name="item" className="form-control  nice-select mb-0 nice-select-md w-170 "
                          value={value}
                          onChange={(e) => {
                            setValue(e.target.value)
                            selectParLibMouvement(e)
                          }}>
                          <option value={0}> {t('releve.filtrer') + " --- "}</option>
                          <option value={1}>{t('releve.vente')}</option>
                          <option value={2}>{t('todayCommercial.recouvrement')}</option>
                          <option value={3}>{t('releve.avoir')} </option>
                          <option value={4}>{t('releve.paiement')} </option>
                          <option value={5}>{t('todayCommercial.correction')} </option>

                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="search1 search-elements-sm ">
                        <div className="form row no-gutters ">
                          <div className="form-group  col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 mb-0">
                            <input
                              type="text"
                              ref={inputEl}
                              onChange={() => getSearchTerm()}
                              className="form-control br-tr-md-0 br-br-md-0"
                              placeholder={t("stock.search")}
                              value={search}
                               />
                          </div>
                          <div className="col-xl-2 col-lg-2 col-md-2 col-2 mb-0">
                            <button
                              className="br-tl-md-0 br-bl-md-0 btn btn-block btn-success fs-16"
                              onClick={() => getSearchTerm()}>
                              <i className="fe fe-search" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-4 col-xs-4 col-sm-4 col-4 text-center mobilecard">
              <div className="card">
                <div className="card-body">
                  <div className="item-box text-center">
                    <div className="stamp text-center stamp-lg bg-success"><i className="ti-pie-chart " /></div>
                    <div className="item-box-wrap">
                      <p className="text-muted mb-0 pmobiletext">{numberWithSpaces(parseFloat(data.debit).toFixed(3))}</p>
                      <h6 className="mb-2 mobiletext">{t("releve.totdebit")}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-xs-4 col-sm-4  col-4 text-center mobilecard">
              <div className="card">
                <div className="card-body">
                  <div className="item-box text-center">
                    <div className="stamp text-center stamp-lg bg-info"><i className="ti-server fa-2x" /></div>
                    <div className="item-box-wrap">
                      <p className="text-muted mb-0 pmobiletext">{numberWithSpaces(parseFloat(data.solde).toFixed(3))}</p>
                      <h6 className="mb-2 mobiletext">{t("releve.totsolde")} </h6>
                    </div>
                  </div>
                </div>
              </div>


            </div>
            <div className="col-lg-4 col-md-4 col-xs-4 col-sm-4 col-4 text-center mobilecard">
              <div className="card">
                <div className="card-body">
                  <div className="item-box text-center">
                    <div className="stamp text-center stamp-lg bg-danger"><i className="ti-credit-card fa-2x" /></div>
                    <div className="item-box-wrap">
                      <p className="text-muted mb-0 pmobiletext">{numberWithSpaces(parseFloat(data.credit).toFixed(3))}</p>
                      <h6 className="mb-2 mobiletext">{t("releve.totcredit")} </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         

          <div className="container" dir={i18n.dir('')}>
            {/** Table Relevee  */}
            <div className="row" style={{ marginTop: '10px' }}>
              {currentPosts?.map((items, keys) => (
                <div className="col-sm-6 col-xl-4 col-md-6 col-12" key={keys}>
                  <div className="pricing-table active">
                    <div className="price-header bg-primary">
                      <h6 className="title text-white" style={{ fontSize: "14px" }}> <b>{t("releve.ndoc") + " : "}</b>
                        <a className='text-white' href={"/detail_releve/" + items.entetcommercial_code}>
                          <u>{items.entetcommercial_code}</u>
                        </a></h6>
                      <span className="permonth text-primary" style={{fontSize:"11px"}}><b>{items.mouvement_libelle === "Vente" ? t('releve.vente') : items.mouvement_libelle === "Recouvrement" ? t('todayCommercial.recouvrement') : items.mouvement_libelle === "Avoir" ? t('releve.avoir') : items.mouvement_libelle === "Paiement" ? t('releve.paiement') : items.mouvement_libelle==="Correction Solde Client" ? t('todayCommercial.correction') :items.mouvement_libelle  } </b></span>
                    </div>
                    <div className="price-body">
                      <ul>
                        <li><b>{t("stock.date") + " : "}</b>{items.date_mouvemenet}</li>
                        <li><b> {t("releve.debit") + " : "}</b>
                          <b dir={i18n.dir('fr')}>{
                            items.sens_ecriture !== "C" ? (
                              parseFloat(items.montant).toFixed(3)
                            ) : ("0.00")
                          }</b> </li>
                        <li><b> {t("releve.credit") + " : "}</b>
                          <b dir={i18n.dir('fr')}>{
                            items.sens_ecriture === "C" ? (
                              parseFloat(items.montant).toFixed(3)
                            ) : ("0.00")
                          }</b></li>
                        <li><b> {t("releve.total") + " : "}</b>
                          <b dir={i18n.dir('fr')}>{
                            parseFloat(items.montant).toFixed(3)
                          }</b> </li>
                      </ul>
                    </div>
                    <div className="price-footer">
                    </div>
                  </div>
                </div>))}
            </div>
          </div>
        </>
      )}

      {error ? <ErrorAlert t={t} /> : <></>}
    </div>
  );
}
