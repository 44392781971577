/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function HeaderEmptyLayout() {
 
    //Light / DARK mode
  const [isOpen, setIsOpen] = useState(false);
  //Traduction
  const [t, i18n] = useTranslation("global");
  const language = Cookies.get("locale");
  const [lg, setLg] = useState(language === "fr" ? "Français" : "arabe");

  const handleClick = () => {
    if (isOpen) {
      document.body.classList.remove("dark-mode");
      document.body.classList.remove("app");
      setIsOpen(!isOpen);
    } else {
      document.body.classList.add("app");
      document.body.classList.add("dark-mode");
      setIsOpen(!isOpen);
    }
  };
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);

    if (language === "ar") {
      setLg("arabe");
    } else if (language === "fr") {
      setLg("Français");
    }

    Cookies.set("locale", language);
  };
  return (
    <div className="header-main header sticky">
    <div className="app-header header top-header navbar-collapse ">
      <div className="container">
      
        {/* sidebar-toggle*/}
        <div className="d-flex">
          <a className="header-brand" href="#">
            <img
              src="../assets/images/brand/logo.png"
              className="header-brand-img desktop-logo "
              alt="Dashlot logo"
            />
            <img
              src="../assets/images/brand/logo1.png"
              className="header-brand-img desktop-logo-1 "
              alt="Dashlot logo"
            />
            <img
               src="../assets/images/brand/logo.png"
              className="mobile-logo"
              alt="Dashlot logo"
            />
            <img
              src="../assets/images/brand/logo1.png"
              className="mobile-logo-1"
              alt="Dashlot logo"
            />
          </a>
          {/*Edit Language Section */}
          <div className="d-flex header-left left-header">
            <div className="d-none d-lg-block horizontal">
              <ul className="nav">
                <li></li>
                {/**END SETTING LANGUAGE */}
              </ul>
            </div>
          </div>
          <div className="d-flex header-right ml-auto">
            <div>
              <a
                href="#"
                className="nav-link icon full-screen-link"
                onClick={handleClick}
              >
                <i className="mdi mdi-lightbulb-on-outline fs-20" />
              </a>
            </div>
            <div className="nav-link">
            <select
              className="form-control select2 text-primary"
              value={language}
              onChange={(e) => changeLanguage(e.target.value)}
            >
              <option value="fr">Français</option>
              <option value="ar"> العربية</option>
            </select>
            </div>
        
            {/* Fullscreen */}
            <div id="bs-example-navbar-collapse-1">
             
            </div>
         
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}
