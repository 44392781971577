/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import Loader from "react-spinners/BarLoader";
import SquareLoader from "../../../components/Loader/SquareLoader";
import ErrorAlert from "../../../others/ErrorAlert";
import { getChequeMonth, getCredit, getEspeceToday, getPaiementEspece, getRecetteDay, getRecetteMonth, getRecouvrementByMonth, getTotalCreditCommercial } from "../../../services/commande/commandeApi";
import { getCurrentDate, sameDay } from "../../../utils/DateUtils";
import { numberWithSpaces } from "../../../utils/NumberWithSpaces";

export default function Paiements({ t, i18n }) {
  //RESULTS VALUES
  const [credit, setCredit] = useState("00");
  const [todaycredit, setTodayCredit] = useState("00");
  const [recette, setRecette] = useState("00");
  const [recettmONTH, setrecettmONTH] = useState("00")
  const [espece, setEspece] = useState("0");
  const [especeMonth, setespeceMonth] = useState("0")
  //ERRORsetespeceMonth
  const [error, setError] = useState(false);
  //Date
  const today = getCurrentDate();
  //Loader
  const [loading, setLoading] = useState(true);
  const [loadingRecette, setloadingRecette] = useState(true);
  const [loadingCredit, setloadingCredit] = useState(true);

  useEffect(() => {


      // 1 -SERVICE : GET RECETTE DAY
      getRecetteDay()
        .then((res) => {
          if (
            res?.data[0]?.recetteday !== null &&
            res?.data[0]?.recetteday !== 0
          ) {
            setRecette(res?.data[0]?.recetteday);
          }
          getRecetteMonth()
            .then((res) => {
              if (res?.data !== null) {
                setrecettmONTH(res?.data[0]?.recetteday)
                setloadingRecette(false)
              }
            }).catch((error) => console.log(error))
        })
        .catch((error) => console.log(error))

      // 2 -SERVICE : GET ESPECE DAY & MONTH
      getPaiementEspece()
        .then((res) => {
          if (res?.data[0].recette !== null) {
            setEspece(res?.data[0]?.recette);
          }
          getChequeMonth()
            .then((result) => {
              if (result?.data !== null) {
                setespeceMonth(result?.data[0]?.especes)
                setLoading(false)
              }
            }).catch((error) => {
              console.log(error)
            });
        })
        .catch((error) => console.log(error))
    

      // 3- SERVICE : GET ETAT CREDIT TOTAL
      getCredit()
        .then((res) => {
          if (res?.data !== null) {
            var result = Object.keys(res?.data).map((key) => [
              res?.data[key]?.solde,
            ]);
            const total = result?.reduce(
              (totalSum, solde) => totalSum + parseFloat(solde),
              0
            );
            setCredit(numberWithSpaces(total?.toFixed(3)));
          }
          // 4- GET CREDIT TODAY
          getTotalCreditCommercial()
            .then((res) => {
              if (res?.data !== null) {
                const total = res?.data
                .filter(({ date }) => date !== null && sameDay(date, today))
                .reduce((totalSum, { solde }) => totalSum + parseFloat(solde), 0);
                setTodayCredit(numberWithSpaces(total.toFixed(3)));
                setloadingCredit(false)
              }
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error))
      


   
  }, []);

  return (
    <div>
      {/** INFORMATIONS PAIEMENTS CREDIT / RECETTE/ ESPECE */}
      <div className="row">

        {/** CARD CREDIT */}
        <div className=" col-md-4 col-lg-4 col-xl-4">
          <div className="card bg-purple">
            <div className="card-body">
              {loadingCredit ? <SquareLoader /> : (
                <>
                  <h4 className="card-title mb-4 text-white">{t("listecmdvente.totalcredit")}</h4>
                  <div className="d-flex  align-items-center">
                    <h5 className=" mb-0">
                      {todaycredit}
                      <span className="font-weight-normal text-white fs-13">
                        /{t("commandes.today")}
                      </span>
                    </h5>
                    <div className="text-white mt-0 mb-2  ml-auto">
                      <i
                        className="fe fe-database  fa-3x overflow-hidden mx-auto"
                        width={100}
                        height={100}
                      />
                    </div>
                  </div>
                  <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                  <p className="text-white  mb-4 font-weight-normal float-left" dir={i18n.dir()}>
                    {t("listecmdvente.totalcredit") + " : " + numberWithSpaces(parseFloat(credit).toFixed(3))}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        {/** CARD RECETTE */}

        <div className=" col-md-4 col-lg-4 col-xl-4">
          <div className="card bg-purple">
            <div className="card-body">
              {loadingRecette ? <SquareLoader /> : (
                <>
                  <h4 className="card-title mb-4 text-white">{t("listecmdvente.totalrecette")}</h4>
                  <div className="d-flex  align-items-center">
                    <h5 className=" mb-0">
                      {parseFloat(recette).toFixed(3)} {t("listecmdvente.DT")}
                      <span className="font-weight-normal text-white fs-13">
                        / {t("commandes.today")}
                      </span>
                    </h5>
                    <div className="text-white mt-0 mb-2  ml-auto">
                      <i
                        className="fe fe-dollar-sign  fa-3x overflow-hidden mx-auto"
                        width={100}
                        height={100}
                      />
                    </div>
                  </div>
                  <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                  <p className="text-white  mb-4 font-weight-normal float-left">
                    {t("listecmdvente.totalrecette") + "(" + moment().format("MMMM") + ")"} : {recettmONTH===null ? 0 :  numberWithSpaces(parseFloat(recettmONTH).toFixed(3))}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        {/** CARD ESPECE */}
        <div className=" col-md-4 col-lg-4 col-xl-4">
          <div className="card bg-purple">
            <div className="card-body">
              {loading ? <SquareLoader /> : (
                <>
                  <h4 className="card-title mb-4 text-white">{t("listecmdvente.totalespece")}</h4>
                  <div className="d-flex  align-items-center">
                    <h5 className=" mb-0">
                      {parseFloat(espece).toFixed(3)} {t("listecmdvente.DT")}
                      <span className="font-weight-normal text-white fs-13">
                        / {t("commandes.today")}
                      </span>
                    </h5>
                    <div className="text-white mt-0 mb-2  ml-auto">
                      <i
                        className="ti-wallet fa-3x overflow-hidden mx-auto"
                        width={100}
                        height={100}
                      />
                    </div>
                  </div>
                  <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                  <p className="text-white  mb-4 font-weight-normal float-left">
                    {t("listecmdvente.totalespece") + "(" + moment().format("MMMM") + ")"} : {numberWithSpaces(parseFloat(especeMonth).toFixed(3))}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>

      </div>
      {error ? <ErrorAlert t={t} /> : <></>}

    </div>
  );
}
