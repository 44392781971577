/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { gestionCommadesBydate, getSituationPaiement, getTicketRestau } from '../../../services/commande/commandeApi'

export default function LivraisonInterface() {
  //------ History -----------------
  //const history = useHistory()
  const { bl, date } = useParams()
  const [t, i18n] = useTranslation('global')
  const [Objet, setObjet] = useState({})
  const [listeTicket, setListeTicket] = useState([])
  //----------Formulaire -------------
  const [paiementEspece, setPaiementEspece] = useState("")
  const [cheque, setcheque] = useState("")
  const [numCheque, setnumCheque] = useState("")
  const [banqueCheque, setbanqueCheque] = useState("")
  const [dateCheque, setdateCheque] = useState("")
  const [traite, settraite] = useState("")
  const [numTraite, setnumTraite] = useState("")
  const [banqueTraite, setbanqueTraite] = useState("")
  const [dateTraite, setdateTraite] = useState("")
  const [value, setValue] = useState(0) // Ticket Cadeaux
  const [qte, setqte] = useState(0) // Ticket Cadeaux
  const [commentaire, setcommentaire] = useState("") // Commentaire
  //---------- Loader  ----------------
  const [loading, setloading] = useState(false)

  useEffect(() => {
    if (date !== '') {
      setloading(true)
      getTicketRestau()
        .then((result) => {
          setListeTicket(result.data)
        }).catch((err) => {
          console.log(err)
        });
      gestionCommadesBydate(date)
        .then((result) => {
          if (result?.data) {
            //---- data ----
            const l = result?.data.cmd_current_day.filter(
              (element) => element.num_bl === bl,
            )
            setObjet(l[0])
          }
        })
        .catch((err) =>
          console.log(err))
      setloading(false)
    }
  }, [])

  const validerLaLivraison = () => {
    getSituationPaiement(Objet.client, Objet.client_id, Objet.code, Objet.commercial_code, Objet.date, Objet.depot_code, Objet.depot_id, Objet.libelle, Objet.num_bl, Objet.num_cmd, Objet.user_code)
      .then((result) => {
        // console.log(result.data)
      }).catch((err) => {
        //err
      });

  }

  //Calcul VALEUR ESOECE
  const CalculEspece = (montant, qte) => {
    if (montant !== 0 && qte !== 0) {
      const obj = listeTicket?.filter((element) => parseFloat(element.montant) === parseFloat(montant))
      //console.log(obj[0].pourcentage)
      const total = (montant * qte) - (((montant * obj[0].pourcentage) / 100) * qte)
      return total
    }

  }


  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header ">
            {/** TITRE RELEVE   */}
            <h3
              className="card-title text-center float-left"
              style={{ paddingTop: '15px' }}
            >
              {bl} , {'Le 27-10-2021'}

            </h3>
            <h3
              className="card-title text-center float-right"
              style={{ paddingTop: '15px' }}
            >
              <div
                className={'w-4 h-4 mr-1 bg-cyan'}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="BL"
              />

            </h3>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-4 ">
                <address className="fs-15" dir={i18n.dir('')}>
                  <p className="mb-0" style={{ fontSize: "12px" }}> <b> Heure de livraison </b> : {Objet.date_livraison} </p>

                  <p className="mb-0" style={{ fontSize: "12px" }}><b> Client </b>  : {Objet.client}</p>

                  <p className="mb-0" style={{ fontSize: "12px" }}> <b> Magasin </b> :{Objet.magasin} </p>

                  <p className="mb-0" style={{ fontSize: "12px" }}><b> Commercial </b> : {Objet.user}</p>
                </address>
              </div>
              <div className="col-lg-4 ">
                <h5 className="text-center">Num Bl : {bl}</h5>
              </div>
              <div className="col-lg-4 text-right">
                {/** ADRESSE+INFORMATIONS CLIENT   */}
                <address className="fs-15" dir={i18n.dir('')}>
                  <p className="mb-0" style={{ fontSize: "12px" }}> <b >Total Quantite </b> :  {Objet.sum_qte} </p>
                  <p className="mb-0" style={{ fontSize: "12px" }}>  <b>Total Carton </b> : {Objet.nbr_carton} </p>
                  <p className="mb-0" style={{ fontSize: "12px" }}><b> Net a payer </b> :{parseFloat(Objet.montant).toFixed(3)} </p>
                </address>
              </div>
            </div>
            <div className="row">

              <div className="col-lg-12">
                <div className="card box-shadow-0">
                  <div className=" card-body">
                    <div className="row">


                      <div className="col-md-6 col-lg-6">
                        {/** Paiement en Espece  */}
                        <div className="form-group">
                          <div className="form-label text-primary mb-2">
                            <i className="fa fa-dollar mr-2 text-primary"></i>
                            Paiement en espece
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            value={paiementEspece}
                            onChange={(e) => setPaiementEspece(e.target.value)}
                            placeholder="Paiement en espece"
                          />
                        </div>
                        {/** Paiement en Chéque  */}
                        <div className="form-label text-primary">
                          <i className="ti-pencil-alt mr-2 text-primary"></i>
                          Paiement en chéque
                        </div>
                        <div className="form-row">

                          <div className="form-group col-md-6 mb-0">
                            <div className="form-group">
                              <label className="form-label">
                                Chéque
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={cheque}
                                onChange={(e) => setcheque(e.target.value)}
                                placeholder="Chéque"
                              />
                            </div>
                          </div>
                          <div className="form-group col-md-6 mb-0">
                            <div className="form-group">
                              <label className="form-label">Num Chéque</label>
                              <input
                                type="text"
                                className="form-control"
                                value={numCheque}
                                onChange={(e) => setnumCheque(e.target.value)}
                                placeholder="Num Chéque"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6 mb-0">
                            <div className="form-group">
                              <label className="form-label">
                                Banque Chéque
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={banqueCheque}
                                onChange={(e) => setbanqueCheque(e.target.value)}
                                placeholder="Chéque"
                              />
                            </div>
                          </div>
                          <div className="form-group col-md-6 mb-0">
                            <div className="form-group">
                              <label className="form-label">Date Chéque</label>
                              <input
                                type="date"
                                className="form-control"
                                value={dateCheque}
                                onChange={(e) => setdateCheque(e.target.value)}
                                placeholder="Num Chéque"
                              />
                            </div>
                          </div>
                        </div>
                        {/** Paiement Traite  */}
                        <div className="form-label text-primary">
                          <i className="fa fa-file-text-o mr-2 text-primary"></i>
                          Paiement Traite
                        </div>
                        <div className="form-row">

                          <div className="form-group col-md-6 mb-0">
                            <div className="form-group">
                              <label className="form-label">
                                Traite
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={traite}
                                onChange={(e) => settraite(e.target.value)}
                                placeholder="Traite"
                              />
                            </div>
                          </div>
                          <div className="form-group col-md-6 mb-0">
                            <div className="form-group">
                              <label className="form-label">Num Traite</label>
                              <input
                                type="number"
                                className="form-control"
                                value={numTraite}
                                onChange={(e) => setnumTraite(e.target.value)}
                                placeholder="Num traite"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6 mb-0">
                            <div className="form-group">
                              <label className="form-label">
                                Banque Traite
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={banqueTraite}
                                onChange={(e) => setbanqueTraite(e.target.value)}
                                placeholder="Chéque"
                              />
                            </div>
                          </div>
                          <div className="form-group col-md-6 mb-0">
                            <div className="form-group">
                              <label className="form-label">Date Echéance Traite</label>
                              <input
                                type="date"
                                className="form-control"
                                value={dateTraite}
                                onchange={(e) => setdateTraite(e)}
                                placeholder="Num Chéque"
                              />
                            </div>
                          </div>
                        </div>

                      </div>
                      {/* col end */}
                      <div className="col-md-6 col-lg-6">
                        {/** Paiement Ticket Cadeaux  */}
                        <div className="form-label text-primary">
                          <i className="ti-gift mr-2 text-primary"></i>
                          Ticket Cadeaux
                        </div>
                        <div className="form-row">

                          <div className="form-group col-md-6 mb-0">
                            <div className="form-group">
                              <label className="form-label">
                                Type
                              </label>
                              <select className="form-control select2 w-100"
                                value={value}
                                onChange={(e) => {
                                  setValue(e.target.value)
                                }}>
                                <option value={0}>------- Tickets -----</option>
                                {listeTicket?.map((items, keys) => (
                                  <option key={keys} value={items.montant}> {items.type + " | " + items.montant + " DT | " + items.pourcentage + " %"}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="form-group col-md-3 mb-0">
                            <div className="form-group">
                              <label className="form-label">Quantité</label>
                              <input
                                type="number"
                                className="form-control"
                                value={qte}
                                onChange={(e) => setqte(e.target.value)}
                                placeholder="Qte"
                              />
                            </div>
                          </div>
                          <div className="form-group col-md-3 mb-0">
                            <div className="form-group">
                              <label className="form-label">Espece</label>
                              <input
                                type="number"
                                className="form-control"
                                value={CalculEspece(value, qte)}
                                readOnly
                                disabled
                                placeholder="Num Traite"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-row">

                          <div className="form-group col-md-12 mb-0">
                            <div className="form-group">
                              <div className="form-label text-primary">
                                <i className="ti-notepad fa-1x mr-2 text-primary"></i>
                                Commentaire
                              </div>
                              <textarea
                                type="text"
                                className="form-control"
                                value={commentaire}
                                onChange={(e) => setcommentaire(e.target.value)}
                                placeholder=" Commentaire .... "
                              />
                            </div>
                          </div>

                        </div>

                        {/* col end */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
            <div className="text-center  mt-4 mb-0 text-danger">
              (*)  Pour passer le BL en crédit laisser tous les champs vides
            </div>
            <div className="form-footer mt-2 text-center">
              <button href="#" className="btn btn-primary" onClick={() => validerLaLivraison()}>
                <i className="ti-credit-card mr-2" />
                Payer et passer a l'état livré
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
