/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import "../TodayCommercial/visites/map.css";
import { getSousFamilleByClient } from '../../services/Objectifs/ObjectifApi'
export default function TopProduit({ code ,t}) {
  
  const [data, setdata] = useState([])
  

  useEffect(() => {
    getSousFamilleByClient(code)
    .then((res) => {
      if (res?.data) {
        setdata(res.data)
      }
    })
    .catch((error) => console.log(error))

}, [])
  
  return (

    <div className="card">
      <div className="card-header">
        <h4 className=" text-primary text-center mt-3 my-3 ">
        {t('topgamme.titleGamme')}
        </h4>
        
      </div>
      <div className="card-body">
         
      <div className="table-responsive">
              <table
                className="table card-table table-striped tutorial-table"
                cellSpacing={0}
                width="100%"
              >
                <thead>
                  <tr className="text-center">
                    <th className="priority-1" width="15%">{t('stock.libelle')} </th>
                  
                    <th className="priority-3" width="15%">{t('stock.total')}</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((items, keys) => (
                    <tr className="text-center">
                       <td className="priority-1" width="15%">{items.libelle}</td>
                       <td className="priority-1" width="15%">{items.total}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
      </div>
    </div>
  
  
   

  );
}
