import moment from 'moment'
import React from 'react'
import { numberWithSpaces } from '../../../utils/NumberWithSpaces'

export default function ResultByClientCode({t, objVente, ObjmoyVente, Objrecouvrement, ObjCredit, ObjderFacture, ObjDerRecouvrement, ObjNbRec, numberOfMonth,}) {
  return (
    <div className="row">
      {/** --------------- CARD SITUATION MENSUELLE --------------------------------- */}
      <div className="col-md-3 col-12">
        <div className="card box-shadow-0 mb-lg-0">
          <div className="card-body">
            <div className="item-box text-center">
              <div className="bg-warning-transparent icon-bg icon-service text-warning">
                <i className="fa fa-calendar-check-o" />
              </div>
              <div className="item-box-wrap">
                <h6 className=" mobiletext mb-2" style={{ fontSize: '12px' }}>
                  {t('todayCommercial.situationmensuelle')}
                </h6>
                <p className="text-muted  pmobiletext mb-0">
                  <h6 style={{ fontSize: '11px' }}>
                   {t('todayCommercial.credit')+" "+ObjCredit?.credit}
                  </h6>

                  <h6 style={{ fontSize: '11px' }}>
                   { t('todayCommercial.recouvrement')+"/"+t('todayCommercial.vente')+":"}
                    {Objrecouvrement?.recouvrement!== 0? numberWithSpaces(
                      parseFloat(Objrecouvrement?.recouvrement).toFixed(3),
                    ) : 0.00} / { objVente?.vente !==0? Math.abs(parseFloat(objVente.vente).toFixed(3)): 0.00}
                  </h6>
                  <h6 style={{ fontSize: '11px' }}>
                  {t('todayCommercial.credit')+"/"+t('todayCommercial.moyenneVente')+" : "} 
                    {numberWithSpaces(
                      parseFloat(
                        ObjCredit?.credit / ObjmoyVente?.moyenne,
                      ).toFixed(2),
                    )}
                  </h6>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/** --------------- CARD DERNIERE FACTURE --------------------------------- */}
      <div className="col-md-3 col-12">
        <div className="card box-shadow-0 mb-lg-0">
          <div className="card-body">
            <div className="item-box text-center">
              <div className="bg-success-transparent icon-bg icon-service text-success">
                <i className="fa fa-file-text-o" />
              </div>
              <div className="item-box-wrap">
                <h6 className=" mobiletext mb-2" style={{ fontSize: '12px' }}>
                  {t('todayCommercial.denierefacture')}
                </h6>
                <p className="text-muted  pmobiletext mb-0">
                  <h6 style={{ fontSize: '11px' }}>
                  {t('historique.montant')+" : "}

                    {numberWithSpaces(
                      parseFloat(ObjderFacture?.vente).toFixed(3),
                    )}
                  </h6>
                  <h6 style={{ fontSize: '11px' }}>
                  {t('stock.date')+" : "}{moment(ObjderFacture?.last_date).format("DD-MM-YYYY")}
                  </h6>
                  <h6 style={{ fontSize: '11px' }}>
                  {t('todayCommercial.nbrjours')+" : "} {ObjderFacture?.nbr_jours}
                  </h6>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/** --------------- CARD DERNIER RECOUVREMENT --------------------------------- */}
      <div className="col-md-3 col-12">
        <div className="card box-shadow-0 mb-lg-0">
          <div className="card-body">
            <div className="item-box text-center">
              <div className="bg-primary-transparent icon-bg icon-service text-azure">
                <i className="fa fa-handshake-o" />
              </div>
              <div className="item-box-wrap">
                <h6 className=" mobiletext mb-2" style={{ fontSize: '12px' }}>
                  {t('todayCommercial.dernierrecouvrement')}
                </h6>
                <p className="text-muted  pmobiletext mb-0">
                  <h6 style={{ fontSize: '11px' }}>
                  {t('historique.montant')+" : "}
                    {objVente.vente !== 0
                      ? numberWithSpaces(parseFloat(objVente?.vente).toFixed(3))
                      : numberWithSpaces(
                          parseFloat(ObjDerRecouvrement?.vente).toFixed(3),
                        )}
                  </h6>
                  <h6 style={{ fontSize: '11px' }}>
                  {t('stock.date')+" : "}{ObjDerRecouvrement?.last_date}
                  </h6>
                  <h6 style={{ fontSize: '11px' }}>
                  {t('todayCommercial.nbrjours')+" : "}{ObjDerRecouvrement?.nbr_jours}
                  </h6>
                  <h6 style={{ fontSize: '11px' }}>
                  {t('todayCommercial.nbrrecouvrement')+" : "}{ObjNbRec?.nbr_recouvrement}
                  </h6>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/** -------------------- CARD VENTE X MONTH --------------------------------- */}
      <div className="col-md-3 col-12">
        <div className="card box-shadow-0 mb-lg-0">
          <div className="card-body">
            <div className="item-box text-center">
              <div className="bg-purple-transparent icon-bg icon-service text-purple">
                <i className="fa fa-calculator" />
              </div>
              <div className="item-box-wrap">
                <h6 className=" mobiletext mb-2" style={{ fontSize: '12px' }}>
                   {t('todayCommercial.vente')+" "+numberOfMonth+" "+t('todayCommercial.mois')}
                </h6>
                <p className="text-muted  pmobiletext mb-0">
                  <h6 style={{ fontSize: '11px' }}>
                   {t('todayCommercial.moyenne')+" : "}
                    {numberWithSpaces(
                      parseFloat(ObjmoyVente?.moyenne).toFixed(3),
                    )}
                  </h6>
                  {/* vente total */}
                  <h6 style={{ fontSize: '11px' }}>
                  {t('stock.TotalVentettc')+" : "}
                    {numberWithSpaces(
                      parseFloat(ObjmoyVente?.vente).toFixed(3),
                    )}
                  </h6>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
