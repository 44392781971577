/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
//------- History ------------------
import { useHistory } from 'react-router-dom'
//------------ Cookies ---------------
import Cookies from 'js-cookie'
//---------- TRADUCTION ---------------
import { useTranslation } from 'react-i18next'
//------------ Components -----------
import Loader from './Loader'
// ------------ UTILS --------------------------
import { getCurrentDate, sameDay } from '../../utils/DateUtils'
import axios from 'axios'
import moment from 'moment'
//--------- WEB SERVICES --------------
import { getListeSousSociete, affectationSousSociete, getAllSousSociete } from "../../services/commande/commandeApi"

import { isLogin, LogoutInterface } from '../../utils/SessionUtils'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { toast, ToastContainer } from 'react-toastify'
import { commandes, demande_chargement, getCountNotificationsNotes, getListNotesNotification } from '../../services/Notification/notificationApi'

function Header() {
  const handle = useFullScreenHandle()

  //------ History -----------------
  const history = useHistory()
  // ---- outil de Traduction ----------
  const [t, i18n] = useTranslation('global')
  // ----- Language global selectionné
  const language = Cookies.get('locale')
  const [lg, setLg] = useState(language === 'fr' ? 'Français' : 'arabe')
  // ----- Notifications ---------------------
  const [isOpen, setIsOpen] = useState(false) // theme dark/light
  const [notification, setNotification] = useState(0) // Nb notes
  const [notes, setNotes] = useState([]) // liste des notes
  const [countCommande, setCountCommandes] = useState(0) // nb commandes
  const [listecomd, setListComd] = useState([]) // Liste commandes
  const [chargement, setchargement] = useState(0) // nbr demande chargement
  const [listchargement, setListhargement] = useState([])
  const [listeSSociete, setListeSSociete] = useState([])
  const [listeSelectSS, setListeSelectSS] = useState([])
  const [valueSs, setValueSS] = useState(0) // value Ss
  //---------- LOADING ------------------------
  const [loading, setLoading] = useState(true)
  // ---------- DATE TODAY ------------------
  const today = getCurrentDate()
  const data = isLogin('authentification_nomadis')
    ? localStorage.getItem('authentification_nomadis')
    : []
  const infoUsers = data.length !== 0 ? JSON.parse(data) : {}
  const url = localStorage.getItem('url_nomadis')
  const [openHSidebar, setopenHSidebar] = useState(false)

const changeStatut= ()=>{
    console.log(openHSidebar);
    if (openHSidebar===true){
        document.body.classList.remove('app')
        document.body.classList.remove("sidebar-mini")
        document.body.classList.remove("sidebar-gone")
        document.body.classList.remove("active")
        setopenHSidebar(false)
    }else{
        document.body.classList.add('app')
        document.body.classList.add("sidebar-mini")
        document.body.classList.add("sidebar-gone")
        document.body.classList.add("active")
        setopenHSidebar(true)
    }
}
  const handleClick = () => {
    if (isOpen) {
      document.body.classList.remove('dark-mode')
      document.body.classList.remove('app')
      setIsOpen(!isOpen)
    } else {
      document.body.classList.add('app')
      document.body.classList.add('dark-mode')
      setIsOpen(!isOpen)
    }
  }

  //LOGOUT WEB SERVICE SUPP L ETAT ISCONNECTED
  const SeDeconnecter = () => {
    LogoutInterface(history)
    
  }

  // SELECT CHANGE LANGUAGES 
  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
    if (language === 'ar') {
      setLg('arabe')
    } else if (language === 'fr') {
      setLg('Français')
    }
    Cookies.set('locale', language)
  }

  useEffect(() => {
    try {
      axios.get(url+"soussocietes",{
        SameSite: "None",
        secure: true,
        IsEssential: true,
        withCredentials: true,
      })
        .then((res) => {
          if (res?.data !== null) {
            const data = res?.data
            axios.get(url+"soussocietes/GlobalfilterSousSociete",{
              SameSite: "None",
              secure: true,
              IsEssential: true,
              withCredentials: true,
            })
              .then((res) => {
                if (res?.data !== null) {
                  const liste = data?.map((items) => {
                    var y = res?.data?.all_soussocietes
                    const liste_found = y.find((element) => element.code === items.code)
                    if (liste_found !== undefined) {
                      return Object.assign({}, {
                        soussociete_code: items.code,
                        nom: items.nom,
                        actif: liste_found.actif
                      })
                    } else {
                      return Object.assign({}, {
                        soussociete_code: items.code,
                        nom: items.nom,
                        actif: 0
                      })
                    }
                  })
                  setListeSelectSS(liste)
                }
              })}
        })
      //Nombre de notes (Count)
      axios.get(url+`notes/getCountNonVu`,
        {
        SameSite: "None",
        secure: true,
        IsEssential: true,
        withCredentials: true,
        })
        .then((res) => {
          if (res?.data !== null) {
            setNotification(res?.data)
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            SeDeconnecter()
          } else if (error.response === undefined) {
          }
        })

      //Liste des notes
      axios.get(url+"notes/getAllNotesNotification",{
        SameSite: "None",
        secure: true,
        IsEssential: true,
        withCredentials: true,
       })
        .then((res) => {
          if (res?.data !== null) {
            setNotes(res?.data)
          }
        })
      //Liste commandes
      axios.get(url+"dashboard/commandes",{
        SameSite: "None",
        secure: true,
        IsEssential: true,
        withCredentials: true,
       })
        .then((res) => {
          if (res?.data !== null) {
            const commandes = res?.data.filter(({ date }) =>
              sameDay(date, today),
            )
            setListComd(commandes)
            setCountCommandes(commandes.length)
          }
        })

      //Demande chargement
      axios.get(url+"mouvements/dc",{
        SameSite: "None",
        secure: true,
        IsEssential: true,
        withCredentials: true,
       })
        .then((res) => {
          if (res?.data !== null) {
            setchargement(res.data.length)
            setListhargement(res.data)
          }
        })
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [])

  //AFFECTATION 
  const selectSousSociete = () => {

    if (listeSelectSS.length !== 0) {
      const data = {
        affectations: listeSelectSS
      }
      affectationSousSociete(data)
        .then((result) => {
          if (result?.data?.success === true) {
            toast(" Ajouter avec succée ", { type: "success", position: "bottom-center" })
            window.location.reload()
          } else {
            toast(" Probléme de l'application ! ", { type: "error", position: "bottom-center" })
          }
        }).catch((err) => {
          console.log(err)
        });
    }

  }
  const handleChange = (e, index) => {

    const clonedData = [...listeSelectSS]
    clonedData[index][e.target.name] = e.target.checked
    setListeSelectSS(clonedData)


  }
  return (
    <>
      <div className="header-main header sticky" id="navbar">
        {loading ? (
          <Loader />
        ) : (
          <div className="app-header header top-header navbar-collapse ">
            <div className="container">
            <a id="horizontal-navtoggle" className="animated-arrow hor-toggle" onClick={()=>changeStatut()}>
              <span />
            </a>
              {/* sidebar-toggle*/}
              <div className="d-flex">
                <a className="header-brand">
                  <img
                    src="../assets/images/brand/logo.png"
                    className="header-brand-img desktop-logo "
                    alt="Dashlot logo"
                  />
                  <img
                    src="../assets/images/brand/logo1.png"
                    className="header-brand-img desktop-logo-1 "
                    alt="Dashlot logo"
                  />
                  <img
                    src="../assets/images/brand/favicon.png"
                    className="mobile-logo"
                    alt="Dashlot logo"
                  />
                  <img
                    src="../assets/images/brand/favicon1.png"
                    className="mobile-logo-1"
                    alt="Dashlot logo"
                  />
                </a>
                {/*Edit Language Section */}
                <div className="d-flex header-left left-header">
                  <div className="d-none d-lg-block horizontal">
                    <ul className="nav">
                      <li></li>
                      {/**END SETTING LANGUAGE */}
                    </ul>
                  </div>
                </div>
                <div className="d-flex header-right ml-auto ">
                  <div className="dropdown header-fullscreen">
                    <a
                      className="nav-link icon full-screen-link"
                      id="fullscreen-button"
                    >
                      <i className="mdi mdi-arrow-collapse fs-20"></i>
                    </a>
                  </div>
                  <div>
                    <a
                      href="#"
                      className="nav-link icon full-screen-link"
                      onClick={handleClick}
                    >
                      <i className="mdi mdi-lightbulb-on-outline fs-20 " />
                    </a>
                  </div>

                  {/* Profile */}
                  <div className="sidebar-link">
                    <a
                      href="#"
                      className="nav-link icon"
                      data-toggle="sidebar-right"
                      data-target=".sidebar-right"
                    >
                      <i className="fe fe-align-right" />
                    </a>
                  </div>
                  <div className="sidebar sidebar-right sidebar-animate">
                    <div className="p-3">
                      <a
                        href="#"
                        className="text-right float-right"
                        data-toggle="sidebar-right"
                        data-target=".sidebar-right"
                      >
                        <i className="fe fe-x" />
                      </a>
                    </div>
                    <div className="tab-menu-heading  siderbar-tabs border-0">
                      <div className="tabs-menu ">
                        {/* Tabs */}

                        <nav className="nav nav-pills nav-justified">
                          <a
                            className="nav-item nav-link active"
                            data-toggle="tab"
                            href="#tab"
                          >
                            {t('widget.profile')}{' '}
                          </a>
                          <a
                            className="nav-item nav-link"
                            data-toggle="tab"
                            href="#tab1"
                          >
                            {' '}
                            <i className="typcn typcn-messages" />{' '}
                            <div className="dot-danger">{notification}</div>
                          </a>
                          <a
                            className="nav-item nav-link"
                            data-toggle="tab"
                            href="#tab2"
                          >
                            <i className="typcn typcn-bell" />{' '}
                            <div className="dot-success">{chargement}</div>
                          </a>
                          <a
                            className="nav-item nav-link"
                            data-toggle="tab"
                            href="#tab3"
                          >
                            {' '}
                            <i className="ti-package" />{' '}
                            <div className="dot-warning">{countCommande}</div>
                          </a>
                        </nav>
                      </div>
                    </div>
                    <div className="panel-body tabs-menu-body side-tab-body p-0 border-0 ">
                      <div className="tab-content border-top">
                        <div className="tab-pane active" id="tab">
                          <div className="card-body p-0">
                            <div className="header-user text-center mt-4 pb-4">
                              <span className="avatar avatar-xxl brround">
                                <img
                                  src="../assets/images/users/2.jpg"
                                  alt="Profile-img"
                                  className="avatar avatar-xxl brround"
                                />
                              </span>
                              <div className="dropdown-item text-center font-weight-semibold user h3 mb-0">
                                {infoUsers?.user?.prenom +
                                  ' ' +
                                  infoUsers?.user?.nom}
                              </div>
                              <small>{infoUsers?.user?.email}</small>
                              <p className="text-primary">
                                {i18n.language === 'ar'
                                  ? infoUsers?.role?.libelle
                                  : infoUsers?.role?.code}
                              </p>
                              <div className="card-body mb-6">
                                <div className="form-group mt-3">
                                  <label className="form-label text-left">
                                    <i className="zmdi zmdi-globe-alt mr-2" />{' '}
                                    {t('widget.language')}{' '}
                                  </label>
                                  <select
                                    className="form-control select2 text-primary "
                                    value={language}
                                    onChange={(e) =>
                                      changeLanguage(e.target.value)
                                    }
                                  >
                                    <option value="fr"> Français</option>
                                    <option value="ar"> العربية</option>
                                  </select>

                                  {listeSelectSS?.length !== 1 &&listeSelectSS?.length !== 0 ?
                                    <div className="row">
                                      <div className="col-12">
                                        <label className="form-label text-left mt-3">
                                          <i className="icon icon-organization mr-2" />{' '}
                                          {t('client.soussociete')}
                                        </label>
                                        <div className="form-group form-elements m-0">
                                          {listeSelectSS?.map((items, key) => (
                                            <div className="custom-controls-stacked" key={key}>
                                              <label className="custom-control custom-checkbox">
                                                <input type="checkbox" name="actif"
                                                  checked={items.actif} value={items.soussociete_code}
                                                  onChange={(e) => handleChange(e, key)}
                                                  className="custom-control-input" />
                                                <span className="custom-control-label">{items.nom}</span>
                                              </label>
                                            </div>
                                          ))}
                                        </div>
                                        <button className="btn btn-success mt-2 col-12" onClick={() => selectSousSociete()}><i className="ti-save mr-2" /> {t('widget.save')}</button>
                                      </div>
                                    </div>
                                    : null}


                                </div>
                              </div>
                            </div>
                            {/*  <a className="dropdown-item  border-top" href="#">
                              <i className="dropdown-icon mdi mdi-account-edit" /> Dashboard Nomadis
                            </a>
                            <a className="dropdown-item  border-top" href="#">
                              <i className="dropdown-icon mdi mdi-account-outline" /> Spruko technologies
                            </a>
                            <a className="dropdown-item border-top" href="#">
                              <i className="dropdown-icon  mdi mdi-account-plus" /> Add Another Account
                            </a>
                            <a className="dropdown-item  border-top" href="#">
                              <i className="dropdown-icon mdi mdi-comment-check-outline" /> Message
                            </a> */}

                            <div className="card-body border-top border-bottom ">
                              <div className="row align-center text-center">
                                <button
                                  className="btn btn-primary text-center col-12"
                                  onClick={() => SeDeconnecter()}
                                >
                                  <i className="mdi ti-power-off fs-20" />{' '}
                                  {t('widget.sedeconnecter')}{' '}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/** -------------------------------- LISTE NOTES TAB  ------------------------------------ */}
                        <div className="tab-pane" id="tab1">
                          <div className="chat">
                            <div className="contacts_card">
                              {notes?.length === 0 ? (
                                <ul className="contacts mb-0">
                                  <li className="active">
                                    <div className="d-flex bd-highlight w-100">
                                      <i className="mdi mdi-alert-circle fa-2x text-azure mr-2" />
                                      <div className="user_info">
                                        <h5 className="mt-1 mb-1">
                                          {t('messages.aucunenote')}
                                        </h5>
                                        <small className="text-muted">
                                          {t('messages.pasdenote')}
                                        </small>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              ) : (
                                <ul className="contacts mb-0">
                                  {notes?.map((items, keys) => (
                                    <li className="active" key={keys}>
                                      <div className="d-flex bd-highlight w-100">
                                        <div className="img_cont">
                                          <div className="notifyimg bg-secondary-transparent text-secondary-shadow">
                                            <i className="fa fa-envelope-o fs-18 text-secondary" />
                                          </div>
                                        </div>
                                        <div className="user_info">
                                          <h5 className="mt-1 mb-1">
                                            {items.objet}
                                          </h5>
                                          <h6 className="text-primary mt-1 mb-1">
                                            <a href={'/notes/' + items.id}>
                                            {t('notes.notes')+"...."} 
                                            </a>
                                          </h6>

                                          <small className="text-muted">
                                            {moment(items.date).format('LL')}
                                          </small>
                                        </div>
                                        <div className="float-right text-right ml-auto mt-auto mb-auto">
                                          <small></small>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          </div>
                        </div>
                        {/**  ----------------- CHARGEMENTS --------------------- */}
                        <div className="tab-pane" id="tab2">
                          {chargement === 0 ? (
                            <ul className="contacts mb-0">
                              <li className="active">
                                <div className="d-flex bd-highlight w-100">
                                  <i className="mdi mdi-alert-circle fa-2x text-azure mr-2" />
                                  <div className="user_info">
                                    <h5 className="mt-1 mb-1">
                                      {t('messages.aucunchargement')}
                                    </h5>
                                    <small className="text-muted">
                                      {t('messages.pasdechargement')}
                                    </small>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          ) : (
                            <>
                              {listchargement?.map((items, keys) => (
                                <div
                                  className="list d-flex align-items-center border-bottom p-4"
                                  key={keys}
                                >
                                  <div>
                                    <span
                                      className="avatar bg-purple brround avatar-md"
                                      style={{ fontSize: '10px' }}
                                    >
                                      {items.depot_code}
                                    </span>
                                  </div>
                                  <div className="wrapper w-100 ml-3">
                                    <p className="mb-0 d-flex">
                                      <a
                                        className="text-primary"
                                        href={'/chargement/' + items.numero}
                                      >
                                        <b>{items.numero}</b>
                                      </a>
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div className="d-flex align-items-center">
                                        <i className="fa fa-calendar text-muted mr-1" />
                                        <small className="text-muted ml-auto">
                                          {moment(items.date).format('LL')}
                                        </small>
                                        <p className="mb-0" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                              <div className="dropdown-divider mb-0" />
                              <a
                                href="/demande_chargement"
                                className="dropdown-item text-center br-br-6 br-bl-6"
                              >
                                <b className="text-primary">
                                  {t('notes.voirtoutechargement')}
                                </b>
                              </a>
                            </>
                          )}
                        </div>
                        {/** ----------------- Commandes ----------------------- */}
                        <div className="tab-pane" id="tab3">
                          <div className="chat">
                            <div className="contacts_card">
                              {countCommande === 0 ? (
                                <ul className="contacts mb-0">
                                  <li className="active">
                                    <div className="d-flex bd-highlight w-100">
                                      <i className="mdi mdi-alert-circle fa-2x text-azure mr-2" />
                                      <div className="user_info">
                                        <h5 className="mt-1 mb-1">
                                          {t('messages.aucunecommande')}
                                        </h5>
                                        <small className="text-muted">
                                          {t('messages.pasdecommande')}
                                        </small>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              ) : (
                                <ul className="contacts mb-0">
                                  {listecomd?.map((items, keys) => (
                                    <li className="active" key={keys}>
                                      <div className="d-flex bd-highlight w-100">
                                        <div className="img_cont">
                                          <div className="notifyimg bg-secondary-transparent text-secondary-shadow">
                                            <i className="icon icon-basket-loaded fs-18 text-secondary" />
                                          </div>
                                        </div>
                                        <div className="user_info">
                                          <h6 className="mt-1 mb-1">
                                            <a
                                              className="text-primary"
                                              href={
                                                '/detail_releve/' + items.code
                                              }>
                                              <b>{items.code}</b>
                                            </a>
                                          </h6>
                                          <h6 className="text-muted mt-1 mb-1">
                                            {t('stock.commercial') + ' ' +items.Commercial}
                                          </h6>
                                          <small className="text-muted">
                                            {t('releve.client') + ' ' + items.Client}
                                          </small>
                                        </div>
                                        <div className="float-right text-right ml-auto mt-auto mb-auto">
                                          <small></small>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                                  <div className="dropdown-divider mb-0" />
                                  <a
                                    href="/liste_commandes"
                                    className="dropdown-item text-center br-br-6 br-bl-6"
                                  >
                                    <b className="text-primary">
                                      {t('notes.voircommandes')}
                                    </b>
                                  </a>
                                </ul>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Right-side-toggle */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Header
