/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/shared/Loader";
import ErrorAlert from "../../others/ErrorAlert";
import {
  getImageClient,
  getUserByCode,
  getHistoriqueByMonth,
} from "../../services/clients/ClientApi";
import { numberWithSpaces } from "../../utils/NumberWithSpaces";
import { ValueFromList } from "../../utils/GetValueFromList";
import CardDerniereVisite from "./CardDerniereVisite";
import CardEditShow from "./CardEditShow";
import MapClient from "./MapClient";
import TopProduit from "./TopProduit";
import RapportVisite from "./RapportVisite";
import VenteParMarque from "./VenteParMarque";



export default function ClientInformations() {
  const history= useHistory()
  //Code Client
  const code = localStorage.getItem("codeclient");
  //Image Client
  const [image, setImage] = useState("");
  //List des informations clients
  const [users, setUsers] = useState([]);

  //Traduction
  const [t, i18n] = useTranslation("global");
  //ERROR
  const [error, setError] = useState(false);
  //Loading
  const [loading, setLoading] = useState(true)



  

  useEffect(() => {
      getUserByCode(code)
        .then((res) => {
          setUsers(res.data);
          if(res?.data.id===null){
            toast(t('messages.aucuneinformation'),{position:"bottom-center"})
            history.push('/code_client')
          }
        })
        .catch((err) => console.log(error));
      getImageClient(code)
        .then((res) => {
          //Type Profile
          if (res.data.length !== 0) {
            const result = res.data.filter(({ type }) => type === "profile");
            setImage(result[0]?.path);
            console.log(result[0]?.path);
          }
        })
        .catch((err) => console.log(error));

     
        setLoading(false)

    
  }, []);
  const updateElement = (element) => {
    console.log(element)
  }
  return (
    <>
    {loading? <Loader /> :<div>
      {users.length !== 0 ? (
        <div className="row">
          <div className="col-lg-5 col-xl-4 col-md-12 col-sm-12">
            {/** ---START CARD CLIENT LITTLE CARD */}
            <div className="card profile">
              <div className="user-wideget user-wideget-widget widget-user">
                <div className="widget-user-header " />
                <div className="widget-user-image">
                  <img
                    src={image === "" ? "../assets/images/users/2.png" : image}
                    className="avatar brround"
                    style={{height:'90px'}}
                    alt="User Avatar"
                  />
                </div>
              </div>
              <div className="card-body text-center">
                <div className="mb-1 mt-4 fs-20 font-weight-semibold">
                  {users.nom} {users.prenom}
                </div>
                <div className="text-primary fs-14">
                  <i className="ti-location-pin mr-2" />
                  {users.adresse_facturation}
                </div>
                <p>{t('releve.credit')} {numberWithSpaces(parseFloat(users.encours).toFixed(3))}</p>
                <p className="mt-4 mb-4">
                  {users.description !== ""
                    ? users.description
                    : t("detailClient.description")}
                </p>
                {/*  <div className="text-primary fs-14">
                  <i className="ti-home text-primary mr-2" />
                  Magasin : {users.magasin}
                </div> */}
                <div className="d-flex align-items-center justify-content-between mb-0">
                  <div className="text-center">
                    <h3 className="text-primary mb-1">
                      <i className="ti-mobile"></i>
                    </h3>
                    <div className="text-muted fs-14">{users.mobile}</div>
                  </div>
                  <div className="text-center">
                    <h3 className="text-primary mb-1">
                      <i className="icon icon-home"></i>
                    </h3>
                    <div className="text-muted fs-14"> {users.magasin}</div>
                  </div>
                  <div className="text-center">
                    <h3 className="text-primary mb-1">
                      <i className="ti-check-box"></i>
                    </h3>
                    <div className="text-muted fs-14">
                      {users.isactif === "1" ? t("detailClient.actif") : t("detailClient.inactif")}
                    </div>
                  </div>
                </div>
                <div className="form-group mb-0 mt-3  row justify-content-end">
                  <div className="col-md-12">
                    <a className="btn btn-success mr-1 mt-2"
                      href={"/historique/" + code}
                    >
                      <i className="pe-7s-note2 mr-1" />
                      {t("detailClient.historiques")}
                    </a>
                    <a className="btn btn-secondary  mr-1 mt-2" href={"/releve/" + code}>
                      <i className="pe-7s-news-paper mr-1" />
                      {t("detailClient.releve")}
                    </a>
                    <a className="btn bg-primary mt-2" href={"/txSuccee"}>
                      <i className="fe fe-percent mr-1" />
                      {t('detailClient.tauxsuccee')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/** ---END CARD CLIENT LITTLE CARD */}
            <CardDerniereVisite code={code} t={t} />
            <TopProduit
                    code={code} t={t}/>
          </div>
          {/** ---END CARD LOCALISATION */}
          {/** ---START CARD INFORMATIONS CLIENTS */}
          <div className="col-lg-7 col-xl-8 col-md-12 col-sm-12">
            <CardEditShow users={users} code={code} t={t} />
            {/** ---START CARD LOCALISATION */}
            <div className="card">
              <div className="card-header">
                <div className="card-title">
                  {t("detailClient.localisation")}
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className="card-body">
                  <MapClient
                    longitude={users.longitude}
                    latitude={users.latitude}
                    user={users.nom}
                    magasin={users.magasin}
                    t={t}
                  />
                </div>
              </div>
            </div> 
          </div>

          <RapportVisite
                    code={code} t={t}/>

          <VenteParMarque
                    code={code} t={t}/>
          
          {/** ---END CARD INFORMATIONS CLIENTS */}
        </div>
      ) : (
        <Loader/>
      )}
    </div>}
    {error ? <ErrorAlert t={t} /> : <></>}
</>
  );
}
