import React from 'react'
import { Popover } from 'react-bootstrap';

export const popover =(Societe,code,remise,t) => (
       
    <Popover id="popover-basic">
       <Popover.Header as="h3"> {t('suivi.societe')}: {Societe} 
    
        </Popover.Header>
       <Popover.Body>
        <p>{t('stock.code')} <a href={'/detail_releve/' + code}><u className="text-primary">{code}</u></a></p>
        <p>Remise : <b >{parseFloat(remise).toFixed(3)}</b></p>

       </Popover.Body>
     </Popover>
   );
   

   export const popover2 =(Societe,code,t) => (
       
    <Popover id="popover-basic">
       <Popover.Header as="h3"> {t('suivi.societe')} </Popover.Header>
       <Popover.Body>
        <p> {Societe}</p>
        <p>{t('stock.code')} <a href={'/detail_releve/' + code}><u className="text-primary">{code}</u></a></p>

       </Popover.Body>
     </Popover>
   );

   export const popover3 =(codebarre,distributeur,stk_depot,t) => (
       
    <Popover id="popover-basic">
       <Popover.Header as="h3"> {t('stock.codeabarre')+":"+ codebarre}</Popover.Header>
       <Popover.Body>
        <p> {t('releve.distributeur')+":"+ distributeur} </p>
        <p>{ t('todayCommercial.stockdepot')+":"+stk_depot} </p>

       </Popover.Body>
     </Popover>
   );
   export const popover4 =(id,type,cause,t) => (
       
    <Popover id="popover-basic">
       <Popover.Header as="h3">Id : {id}</Popover.Header>
       <Popover.Body>
        <p>{t('detailClient.type')} : {type} </p>
        <p>{t('historique.cause')} : {cause === null || cause.length === 0
        ? t("historique.indisponible"): cause} </p>

       </Popover.Body>
     </Popover>
   );
   export const popover5 =( keys, magasin, depot,t) => (
       
    <Popover id="popover-basic">
       <Popover.Header as="h3">Id : {keys+1}</Popover.Header>
       <Popover.Body>
        <p> Magasin : {magasin} </p>
        <p> Dêpot : {depot} </p>
       </Popover.Body>
     </Popover>
   );
   export const popover6 =( code, colisage, stk_piece) => (
       
    <Popover id="popover-basic" >
       <Popover.Header as="h3">Code Produit : {code}</Popover.Header>
       <Popover.Body>
        <p> Colisage : {colisage} </p>
        <p> Stock en piéce : {stk_piece} </p>
        <p> Stock en casier : {stk_piece=== null ?0 : parseFloat(stk_piece/colisage).toFixed(3)} </p>

       </Popover.Body>
     </Popover>
   );