/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import Loader from 'react-spinners/BounceLoader';
import SquareLoader from '../../../components/Loader/SquareLoader';
//import { getRecetteDay, getRecetteMonth } from '../../../services/commande/commandeApi';
import { numberWithSpaces } from '../../../utils/NumberWithSpaces';

export default function Recette({ t, url }) {

    const [recette, setRecette] = useState("00");
    const [recettmONTH, setrecettmONTH] = useState("00")
    //ERRORsetespeceMonth
    //const [error, setError] = useState(false);
    //Loader
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        setLoading(true)
        // 1 -SERVICE : GET RECETTE DAY
            axios.get(url + "paiements/recettetoday", {
            SameSite: "None",
            secure: true,
            IsEssential: true,
            withCredentials: true,
            })
            .then((res) => {
                if (
                    res?.data[0]?.recetteday !== null &&
                    res?.data[0]?.recetteday !== 0
                ) {
                    setRecette(res?.data[0].recetteday);
                }
            })
            .catch((error) => console.log(error));

        axios.get(url + "paiements/recetteMonth", {
            SameSite: "None",
            secure: true,
            IsEssential: true,
            withCredentials: true,
        })
            .then((res) => {
                if (res.data !== null) {
                    //console.log(res.data[0].recetteday)
                    setrecettmONTH(res?.data[0]?.recetteday)
                    setLoading(false)
                }
            })

    }, [])
    return (
        <>
            {loading ? <SquareLoader /> : (
                <div className="card overflow-hidden">
                    <div className="card-body">
                        <div className="d-flex">
                            <div >
                                <p className="mb-2 h6">{t("listecmdvente.totalrecette")}</p>
                                <h5 className="mb-1 ">{isNaN(recette) ? 0 : parseFloat(recette).toFixed(3)}
                                    <span className="font-weight-normal text-white fs-13">
                                        / {t("commandes.today")}
                                    </span>
                                </h5>
                                <hr style={{ marginTop: "10px", marginBottom: "10px", borderTop: "1px solid transparent" }} />

                                <p className="mb-0 text-muted mt-2">
                                    <span className="text-success">( {recettmONTH === null ? "0.00" : numberWithSpaces(parseFloat(recettmONTH).toFixed(3))})
                                    </span>({moment().format("MMMM")})</p>
                            </div>

                            <div className="my-auto ml-auto">
                                <div className="chart-wrapper">
                                    <i
                                        className="fe fe-dollar-sign text-primary fa-3x overflow-hidden mx-auto"
                                        width={100}
                                        height={100}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </>
    )
}
