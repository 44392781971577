/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'

export default function PaginationClients({postsPerPage, totalPosts, currentPage, setCurrentPage, paginate}) 
    {
    const pageNumbers = []
    const [page, setPage] = useState(1)
    const [pageNumberLimit, setpageNumberLimit] = useState(3)
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(3)
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0)
    
    const handleNextBtn = () => {
      setCurrentPage(currentPage+1)
      if(currentPage +1 >maxPageNumberLimit){
        setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
        setminPageNumberLimit(minPageNumberLimit +pageNumberLimit)
      }
    }
    const handlePrevBtn = () => {
      setCurrentPage(currentPage-1)
      if((currentPage -1)%pageNumberLimit ===0 ){
        setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
        setminPageNumberLimit(minPageNumberLimit - pageNumberLimit)
      }
    }
  
   
    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
      pageNumbers.push(i)
    }
  
    let pageIncrementBtn = null;
    if(pageNumbers.length>maxPageNumberLimit){
      pageIncrementBtn =  <li onClick={handleNextBtn} className="page-item disabled">
      <span className="page-link">...</span>
    </li>
    }
    let pageDecrementBtn = null;
    if(pageNumbers.length >maxPageNumberLimit){
      pageDecrementBtn =  <li  onClick={handlePrevBtn} className="page-item disabled">
      <span className="page-link">...</span>
    </li>
    }
    return (
        <nav aria-label="Page navigation">
        <ul className="pagination pagination-success mb-0">
         
          <li className="page-item">
            <button aria-label="Last" className="page-link" onClick={() => {paginate(1) ; setCurrentPage(1)}}>
              <i className="fa fa-angle-double-left" />
            </button>
          </li>
          <li className="page-item page-0">
            <button aria-label="Next" className="page-link" onClick={handlePrevBtn} 
            disabled={currentPage === pageNumbers[0] ? true : false}>
              <i className="fa fa-angle-left" />
            </button>
          </li>
          {pageDecrementBtn}
          {pageNumbers.map((number,keys) => (
            number < maxPageNumberLimit +1 && number > minPageNumberLimit ?(
              <li className={currentPage === number? "page-item active" : "page-item"}
              key={keys}
              onClick={() => {
               paginate(number);
               setPage(number);
             }}>
               <a className="page-link" href="#">
               {number}
               </a>
             </li>
            ) : null
         ))}
          {pageIncrementBtn}        
          <li className="page-item page-0">
            <button aria-label="Next" className="page-link" onClick={handleNextBtn}
            disabled={currentPage === pageNumbers[totalPosts-1] ? true : false}>
              <i className="fa fa-angle-right" />
            </button>
          </li>
          <li className="page-item">
            <button aria-label="Last" className="page-link" onClick={() => {paginate(pageNumbers.length) ;setCurrentPage(pageNumbers.length)}}>
              <i className="fa fa-angle-double-right" />
            </button>
          </li>
        </ul>
      </nav> 
    )
}
