/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import "../TodayCommercial/visites/map.css";
import {
  getVenteByMarques,getListMarque,
} from "../../services/clients/ClientApi";


export default function VenteParMarque({ code ,t}) {
  
  const [marques, setMarques] = useState([]);
  const [ventes, setVentes] = useState([]);
  const list=[1,2,3,4,5,6,7,8,9,10,11,12]



  const getvaluefromList= (code,marque, liste ) => {
    if (liste && liste.length !== 0) {
       
    const foundItem =liste.find(item => item.mois === code && item.libelle === marque);
    return foundItem? foundItem.total : 0;
    }else{
      return 0 ;
    }
   
  };

  useEffect(() => {
    getVenteByMarques(code)
    .then((res) => {
      //Type Profile
      if (res.data.length !== 0) {
        setVentes(res.data); 
       }
    })
    .catch((err) => console.log(err));


    getListMarque(code)
    .then((res) => {
   
      if (res.data.length !== 0) {
        setMarques(res.data); 
       }
    })
    .catch((err) => console.log(err));

}, [])

  return (

    <div className="card">
    <div className="card-header">
       
      <h4 className=" text-primary text-center mt-3 my-3 ">
      {t("suivi.ventemarque")}
        </h4>
    </div>
    <div className="d-flex mb-12">
      <div className="card-body">
      <h4>
     
      </h4>
      <div class="panel panel-white">

      <div className="table-responsive">
    <table
      className="table card-table table-striped tutorial-table"
      cellSpacing={0}
      width="100%"
    >
      <thead>
        <tr className="text-center">
          <th></th>
                 <th className="priority-1" width="15%"> {t("month.janvier")}</th>
                 <th className="priority-1" width="15%">{t("month.fevrier")}</th>
                 <th className="priority-1" width="15%">{t("month.mars")}</th>
                 <th className="priority-1" width="15%">{t("month.avril")}</th>
                 <th className="priority-1" width="15%">{t("month.mai")}</th>
                 <th className="priority-1" width="15%">{t("month.juin")}</th>
                 <th className="priority-1" width="15%">{t("month.juillet")}</th>
                 <th className="priority-1" width="15%">{t("month.aout")}</th>
                 <th className="priority-1" width="15%">{t("month.septembre")}</th>
                 <th className="priority-1" width="15%">{t("month.octobre")}</th>
                 <th className="priority-1" width="15%">{t("month.novembre")}</th>
                 <th className="priority-1" width="15%">{t("month.decembre")}</th>
         
        </tr>
      </thead>
      <tbody>

      {marques.map((item)=>(
               <tr className="text-center"> 
                <th className="priority-1" width="15%">{item.libelle}</th>

                 <td className="priority-1" width="15%">{getvaluefromList(1,item.libelle,ventes)} </td>
                 <td className="priority-1" width="15%">{getvaluefromList(2,item.libelle,ventes)}</td>
                 <td className="priority-1" width="15%">{getvaluefromList(3,item.libelle,ventes)}</td>
                 <td className="priority-1" width="15%">{getvaluefromList(4,item.libelle,ventes)}</td>
                 <td className="priority-1" width="15%">{getvaluefromList(5,item.libelle,ventes)}</td>
                 <td className="priority-1" width="15%">{getvaluefromList(6,item.libelle,ventes)}</td>
                 <td className="priority-1" width="15%">{getvaluefromList(7,item.libelle,ventes)}</td>
                 <td className="priority-1" width="15%">{getvaluefromList(8,item.libelle,ventes)}</td>
                 <td className="priority-1" width="15%">{getvaluefromList(9,item.libelle,ventes)}</td>
                 <td className="priority-1" width="15%">{getvaluefromList(10,item.libelle,ventes)}</td>
                 <td className="priority-1" width="15%">{getvaluefromList(11,item.libelle,ventes)}</td>
                 <td className="priority-1" width="15%">{getvaluefromList(12,item.libelle,ventes)}</td>

              </tr>
      ))}
        
          
      </tbody>
    </table>
   
  
  </div>

         
      </div>
      </div>
    </div>
  </div>

  );
}
