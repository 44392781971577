import React, { useEffect, useState } from 'react'
//--------- web services ------------------
import { commandes } from '../../services/Notification/notificationApi'
//---------- Components -------------------
import Loader from '../../components/shared/Loader'
import ErrorAlert from '../../others/ErrorAlert'
// -------- Traduction --------------------
import { useTranslation } from 'react-i18next'

export default function ListeCommandes() {
    
    //Liste des commandes 
     const [liste, setliste] = useState([])
    //Loader
    const [loading, setloading] = useState(true)
    //Alerte 
    const [alerte, setAlerte] = useState(false)
    // ----- Traduction ----------
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
    /** Liste DES COMMANDES passé aujourd'hui */
    commandes()
        .then(res => {
        setliste(res?.data)
        })
        .catch((error) => {
        console.log(error)
        //setAlerte(true)
        })
        setloading(false)
    }, [])

    return (
        <div dir={i18n.dir()}>
            {loading ? <Loader /> : (
                <div className="row">
                    {liste?.map((items, keys) => (
                        <div className="col-md-12 col-lg-4 col-xl-4 col-sm-12 col-12" key={keys}>
                            <div className="card">
                                {/** ---------  CARD STATUS + TITLE -----------------*/}
                                <div className="card-status bg-blue br-tr-7 br-tl-7" />
                                <div className="card-header">
                                    <h3 className="card-title" style={{ marginTop: "10px" }}> {t("stock.commercial")+" : "+items.Commercial}</h3>
                                </div>
                                <div className="card-body">
                                    {/** ---------  Table -----------------*/}
                                    <table class="table table-bordered  table-striped">
                                        <tbody>
                                            <tr>
                                                <th scope="row" className='text-justify'> {t("releve.client")} </th>
                                                <td> {items.Client} </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className='text-justify'>{t("stock.code")}</th>
                                                <td> <a className='text-success' href={"/detail_releve/" + items.code}><b>{items.code}</b></a> </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className='text-justify'>{t("detailClient.datecreation")}</th>
                                                <td >{items.date} </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className='text-justify'>{t("detailClient.datelivraison")}</th>
                                                <td >{items.date_prevu_livraison} </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className='text-justify'>{t("releve.netapayer")} </th>
                                                <td >{parseFloat(items.net_a_payer).toFixed(3)} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {alerte ? <ErrorAlert t={t} /> : <></>}
        </div>
    )

}
