/* eslint-disable no-redeclare */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getListDistributeur, getListeProduit, getListeRetour, getListeUsers } from '../../services/commande/commandeApi'
import ErrorCnx from '../../others/ErrorCnx'
import { OverlayTrigger } from 'react-bootstrap'
import { popover6 } from '../../components/Popover/Popovertab'
import Loader from '../../components/shared/Loader'
import { getDetailsUser, sendListProducts } from '../../services/Inventaire/Api.Inventaire'
import moment from 'moment'
import { toast, ToastContainer } from 'react-toastify'
import { SearchItems } from '../../utils/SearchUtils'
import ModalInventaireExcel from './ModalInventaireExcel'

export default function PageInventaire() {
  //USE REF
  const inputEl = useRef('')
  //----------- REFERENCE INPUT ------------
  const input = useRef('')
  //Search
  const [search, setSearch] = useState('')
  const [searchList, setSearchlist] = useState([])
  //------------- CODE CHARGEMENT -----------
  const [code, setCode] = useState('')
  const [dontShow, setDontShow] = useState(false)
  //------------- CODE DEPOT ----------------
  const [codeDepot, setCodeDepot] = useState('')
  // ---------- LIST DISTRIBUTEURS -----------
  const [distributeurs, setDistributeur] = useState([])
  // ---------- LIST DISTRIBUTEURS -----------
  const [commerciaux, setCommerciaux] = useState([])
  // ---------- LIST -----------
  const [liste, setliste] = useState([])
  const [save, setsave] = useState([])
  // ---------- LIST -----------
  const [listeRetour, setListeRetour] = useState([])
  // ---------------- ERROR -------------------
  const [error, setError] = useState(false)
  // ------------------- Loading --------------
  const [loading, setLoading] = useState(true)
  // ----------------- Languages --------------
  const [t, i18n] = useTranslation('global')
  //----------------- TYPE /Commercial / SOUS SOCIETE ---------
  const [value, setValue] = useState('0') // Valeur distributeur
  const [value1, setValue1] = useState('0') //valeur type
  const [value2, setValue2] = useState('0') // valeur commercial

  const currentList = search.length < 2 ? liste : searchList
  //Modal 
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    // ----------- GET LIST DISTRIBUTEURS ---------------
    getListDistributeur()
      .then((res) => {
        if (res === 422) {
          setError(true)
        } else if (res?.data) {
          setDistributeur(res.data)
          if (res?.data.length === 1) {
            setValue(res?.data[0].code)
            getListeProduit(res?.data[0].code)
              .then((res) => {
                if (res === 422) {
                  //user non autorisé
                } else if (res?.data?.produits !== null) {
                  setCode('CH-' + res?.data?.countch)
                  const test = res?.data?.produits.map((items, keys) => {
                    var obj = Object.assign(
                      {},
                      {
                        produit_code: items.code,
                        code_a_barre: items.code_a_barre,
                        famille_code: items.famille_code,
                        fournisseur_code: items.fournisseur_code,
                        chargement: items.chargement,
                        gamme_code: items.gamme_code,
                        hors_box: items.hors_box,
                        prix_achat_ht: items.prix_achat_ht,
                        prix_achat_ttc: items.prix_achat_ttc,
                        prix_ht: items.prix_ht,
                        prix_ttc: items.prix_ttc,
                        sousfamille_code: items.sousfamille_code,
                        tva: items.tva,
                        ordre: items.ordre,
                        libelle: items.libelle,
                        colisage: items.colisage,
                        stk_quantite: items.stk_quantite,
                        quantite: 0,
                        quantite_casse: 0,
                        quantite_dlc: 0,
                      },
                    )
                    return obj
                  })
                  setliste(test)
                  setsave(test)
                }
              }).catch((error) => console.log(error))
          }
        }
      })

    getListeUsers()
      .then((result) => {
        if (result === 404) {
          setError(true)
        } else if (result?.data) {
          setCommerciaux(result.data)
        }
      })
      .catch((err) => { console.log(err) })
    setLoading(false)
  }, [])

  //SEARCH ITEMS
  const getSearchTerm = () => {
    setSearch(inputEl.current.value)
    if (search !== '') {
      const newList = SearchItems(liste, search)
      setSearchlist(newList)
    } else {
      setliste(save)
    }
  }

  const handleChange = (e, index) => {
    if (search.length < 2) {
      const clonedData = [...liste]
      clonedData[index][e.target.name] = parseInt(e.target.value)
      setliste(clonedData)
      setsave(clonedData)
    } else {
      const clonedData = [...searchList]
      clonedData[index][e.target.name] = parseInt(e.target.value)
      setSearchlist(clonedData)
    }

  }
  const trash = (name) => {
    const clonedData = [...liste]
    clonedData?.map((items, index) => {
      clonedData[index][name] = parseInt(0)
    })
    setliste(clonedData)
    setsave(clonedData)
  }
  //--------- SELECT COMMERCIAL ---------
  const onSelectCommercial = (e) => {
    if (value === "0") {
      toast(t('messages.distributeur'), { position: "bottom-center", type: "warning" })
    } else {
      setValue2(e.target.value)

      getDetailsUser(e.target.value)
        .then((result) => {
          setCodeDepot(result?.data?.trace?.depot_code)
        })
        .catch((err) => {
          console.log(err)
        })

    }
  }
  
  //---------- SELECT SOUS SOCIETE --------------
  const onSelectSousSociete = (e) => {
    setValue(e.target.value)

    getListeProduit(e.target.value).then((res) => {
      if (res === 422) {
        //user non autorisé
      } else if (res?.data?.produits !== null) {
        setCode('CH-' + res?.data?.countch)
        const test = res?.data?.produits.map((items, keys) => {
          // console.log(items);
          var obj = Object.assign(
            {},
            {
              produit_id: items.id,
              produit_code: items.code,
              code_a_barre: items.code_a_barre,
              famille_code: items.famille_code,
              fournisseur_code: items.fournisseur_code,
              chargement: items.chargement,
              gamme_code: items.gamme_code,
              hors_box: items.hors_box,
              prix_achat_ht: items.prix_achat_ht,
              prix_achat_ttc: items.prix_achat_ttc,
              prix_ht: items.prix_ht,
              prix_ttc: items.prix_ttc,
              sousfamille_code: items.sousfamille_code,
              tva: items.tva,
              ordre: items.ordre,
              libelle: items.libelle,
              colisage: items.colisage,
              stk_quantite: items.stk_quantite,
              quantite: 0,
              quantite_casse: 0,
              quantite_dlc: 0,
            },
          )
          return obj
        })
        setliste(test)
        setsave(test)
      }
    })
  }
  //--------------- ON SELECT TYPE ----------
  const onSelectType = (e) => {

    if (value === "0" || value2 === "0") {
      toast(t('messages.distributeuravanttype'), { position: "bottom-center", type: "warning" })
    }
    else {
      setValue1(e.target.value)
      if (e.target.value === 'retour') {
        getListeRetour(value2)
          .then((result) => {
            const bigListe = []
            liste.map((items, keys) => {
              const x = result.data.find(
                (element) => element.produit_code === items.produit_code,
              )
              if (x !== undefined) {
                var obj = Object.assign(
                  {},
                  {
                    produit_id: items.produit_id,
                    retour_hier_p: 0,
                    produit_code: items.produit_code,
                    demande_chargement: 0,
                    demande_chargement_p: 0,
                    hors_box: items.hors_box,
                    prix_achat_ht: items.prix_achat_ht,
                    prix_achat_ttc: items.prix_achat_ttc,
                    prix_ht: items.prix_ht,
                    prix_ttc: items.prix_ttc,
                    tva: items.tva,
                    libelle: items.libelle,
                    colisage: items.colisage,
                    stk_quantite: items.stk_quantite,
                    quantite: parseInt(x.quantite_retour),
                    quantite_casse: 0,
                    quantite_dlc: 0,
                  },
                )
                bigListe.push(obj)
              } else if (x === undefined) {
                var obj = Object.assign(
                  {},
                  {
                    produit_id: items.produit_id,
                    retour_hier_p: 0,
                    produit_code: items.produit_code,
                    demande_chargement: 0,
                    demande_chargement_p: 0,
                    hors_box: items.hors_box,
                    prix_achat_ht: items.prix_achat_ht,
                    prix_achat_ttc: items.prix_achat_ttc,
                    prix_ht: items.prix_ht,
                    prix_ttc: items.prix_ttc,
                    tva: items.tva,
                    libelle: items.libelle,
                    colisage: items.colisage,
                    stk_quantite: items.stk_quantite,
                    quantite: 0,
                    quantite_casse: 0,
                    quantite_dlc: 0,
                  },
                )
                bigListe.push(obj)
              }
            })
            //console.log(bigListe)
            setliste(bigListe)
            setListeRetour(result.data)
          })
          .catch((err) => { })
      } else if (e.target.value === 'inventaire') {
        setliste(save)
      }
    }

  }
  //----------- On Click Button SAVE ---------
  const onUpdateListe = () => {

    var update = Object.assign({}, {
      ch: {
        produits: liste,
        numero: code,
        commercial: value2,
        type: value1,
        envoi: "non",
        retour_hier: false,
        demande_chargement: false,
        colisage: false,
        date: moment().format("YYYY-MM-DD"),
        soussociete_code: value
      },
      multi_camion: false,
      date_debut: "",
      date_fin: "",
      caisse: "",
      caisse_code: ""
    })
    //console.log(update)
    sendListProducts(update)
      .then((result) => {
        // console.log(result)
        if (result.status === 200) {
          toast(t('messages.ajoutsuccee'), { type: "success", position: "bottom-center" })
          window.location.reload()
        } else if (result === 422) {
          toast(t('messages.erreurmodification'), { type: "error", position: "bottom-center" })
        }
      })
      .catch((err) => {
        console.log(err)
      })


  }

  return (
    <div>
      <div
        className="panel-group"
        id="accordion1"
        role="tablist"
        aria-multiselectable="true"
        style={{ marginBottom: '10px' }}
      >
        <div className="panel panel-default active">
          <div className="panel-heading " role="tab" id="headingOne">
            <h4 className="panel-title">
              <a
                role="button"
                data-toggle="collapse"
                data-parent="#accordion1"
                href="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <i className="icon icon-grid text-primary mr-2" /> {t('todayCommercial.retour') + " / " + t('todayCommercial.inventaire')}
              </a>
            </h4>
          </div>
          <div
            id="collapseOne"
            className="panel-collapse collapse"
            role="tabpanel"
            aria-labelledby="headingOne"
          >
            <div className="panel-body">
              <div className="row">
                <div className="col-12">
                  <div className="form row no-gutters">
                    <div className="form-group  col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 mb-0">
                      <select
                        className="form-control select2 text-primary w-100"
                        value={value}
                        onChange={(e) => onSelectSousSociete(e)}
                      >
                        <option value={'0'}>{t('stock.distributeurs')}</option>
                        {distributeurs?.map((items, i) => (
                          <option value={items.code}>{items.nom}</option>
                        ))}
                      </select>
                    </div>
                    <ToastContainer />

                    <div className="col-xl-4 col-lg-4 col-md-4 col-4 mb-0">
                      <select
                        className="form-control select2 text-primary w-100"
                        value={value2}
                        onChange={(e) => onSelectCommercial(e)}
                      >
                        <option value={'0'}> {t('stock.commerciaux')} </option>
                        {commerciaux?.map((items, i) => (
                          <option value={items.code}>
                            {items.nom + ' ' + items.prenom}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-4 mb-0">
                      <select
                        className="form-control select2 text-primary w-100"
                        value={value1}
                        onChange={(e) => onSelectType(e)}
                      >
                        <option value={'0'}> {t('detailClient.type')} </option>
                        <option value={'retour'}>{t('todayCommercial.retour')}</option>
                        <option value={'inventaire'}>{t('todayCommercial.inventaire')}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="card">
          <div className="panel-body">
            <div className="row">

              <div className="col-xl-8 col-md-8  col-12">
                <div className="search1 search-elements-sm ">
                  <div className="form row no-gutters ">
                    <div className="form-group  col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 mb-2">
                      <input
                        type="text"
                        ref={inputEl}
                        className="form-control br-tr-md-0 br-br-md-0"
                        id="text1"
                        placeholder={t('todayCommercial.chercherunproduit')}
                        value={search}
                        onChange={() => getSearchTerm()}
                      />
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-2 col-2 mb-2">
                      <button className="br-tl-md-0 br-bl-md-0 btn btn-block btn-primary fs-16">
                        <i className="fe fe-search" />
                      </button>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-2 col-2 mb-2">


                    </div>
                  </div>

                </div>
              </div>
              <div className="col-xl-4 col-md-4 col-12">
                <button className="br-tl-md-0 br-bl-md-0 mr-2 btn btn-success float-right "
                  variant="primary" onClick={handleShow}>
                  <i className="fa fa-file-excel-o" />
                </button>
                <button
                  className="btn btn-primary float-right"
                  onClick={() => onUpdateListe()}
                >
                  <i className="ti-pencil-alt mr-2" />
                  {t("widget.save")}
                </button>
              </div>

            </div>
            <table className="table table-bordered tutorial-table table-responsive mr-2" >

              <thead>
                <tr>
                  <th colSpan="8" className="text-center  bg-purple text-white">
                    {t('todayCommercial.retour') + " / " + t('todayCommercial.inventaire')}
                    {code !== '' ? (
                      <span className="float-right">{t('stock.code') + " : " + code}</span>
                    ) : (
                      <></>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center mobiletext priority-3" width="2%">
                    {t('stock.code')}
                  </td>
                  <td className="text-center mobiletext priority-3" width="15%">
                    {t('releve.produit')}
                  </td>
                  <td className="text-center mobiletext priority-3" width="2%">
                    {t('stock.colisage')}
                  </td>
                  <td
                    className="text-center mobiletext priority-3 bg-danger"
                    width="10%"
                  >
                    {t('stock.stockpiece')}
                  </td>
                  <td
                    className="text-center mobiletext bg-green priority-3"
                    width="10%"
                  >
                    {t('stock.Stockcasier')}
                  </td>
                  <td className="text-center mobiletext priority-1" width="15%">
                    {t('stock.Quantite')}  <button className="btn btn-danger btn-sm btn-pill ml-2 " onClick={() => trash("quantite")}><i className="ti-trash" /></button>
                  </td>
                  {value1 !== 'inventaire' && value1 !== '0' ? (
                    <>
                      <td
                        className="text-center mobiletext priority-1"
                        width="15%"
                      >
                        {t('stock.DLC')} <button className="btn ml-2 btn-sm  btn-pill btn-danger " onClick={() => trash("quantite_dlc")}><i className="ti-trash" /></button>
                      </td>
                      <td
                        className="text-center mobiletext priority-1"
                        width="20%"
                      >
                        {t('stock.Casse')}  <button className="btn ml-2 btn-sm btn-pill btn-danger" onClick={() => trash("quantite_casse")}><i className="ti-trash" /></button>
                      </td>
                    </>
                  ) : (
                    <></>
                  )}
                </tr>
                {/** --------- LIGNE : portières----------------- */}
                {currentList?.map((items, keys) => (
                  <>
                    <tr>
                      <th colSpan="8" className="text-center showtd">{items.produit_code} : {items.libelle}
                        <OverlayTrigger
                          trigger="click" rootClose
                          placement="auto-start"
                          overlay={popover6(
                            items.code,
                            items.colisage,
                            items.stk_quantite,
                          )}
                        >
                          <i className="icon icon-plus text-success ml-2" />
                        </OverlayTrigger></th>
                    </tr>
                    <tr key={keys}>

                      <td
                        className="mobiletext priority-3 text-center"
                        width="2%"
                      >
                        {items.produit_code}
                      </td>
                      <td className="mobiletext priority-3" width="15%">
                        {items.libelle}
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          placement="right"
                          overlay={popover6(
                            items.code,
                            items.colisage,
                            items.stk_quantite,
                          )}
                        >
                          <i className="icon icon-plus text-success ml-2" />
                        </OverlayTrigger>
                      </td>
                      <td
                        className="mobiletext priority-3 text-center"
                        width="2%"
                      >
                        {items.colisage}{' '}
                      </td>
                      <td
                        className="priority-3 text-center bg-danger"
                        width="10%"
                      >
                        {items.stk_quantite === null ? 0 : items.stk_quantite}
                      </td>
                      <td className="priority-3 text-center bg-green" width="10%">
                        {items.stk_quantite === null
                          ? 0
                          : parseFloat(
                            items.stk_quantite / items.colisage,
                          ).toFixed(3)}
                      </td>
                      <td className="priority-1" width="15%">
                        <input
                          type="number"
                          name="quantite"
                          value={items.quantite}
                          onChange={(e) => handleChange(e, keys)}
                          className="form-control text-primary text-center mobiletext"
                        />
                      </td>
                      {value1 !== 'inventaire' && value1 !== '0' ? (
                        <>
                          <td className="priority-1" width="20%">
                            <input
                              type="number"
                              name="quantite_dlc"
                              onChange={(e) => handleChange(e, keys)}
                              value={items.quantite_dlc}
                              className="form-control text-primary text-center mobiletext"
                            />
                          </td>
                          <td className="priority-1" width="20%">
                            <input
                              type="number"
                              name="quantite_casse"
                              value={items.quantite_casse}
                              onChange={(e) => handleChange(e, keys)}
                              className="form-control text-primary text-center mobiletext"
                            />
                          </td>
                        </>
                      ) : (
                        <></>
                      )}
                    </tr>
                  </>
                ))}
              </tbody>
              {value !== "0" && value1 !== "0" && value2 !== "0" ? (
                <tfoot>
                  <tr>
                    <td colSpan="8">
                      <button
                        className="btn btn-primary float-right col-2"
                        onClick={() => onUpdateListe()}
                      >
                        <i className="ti-pencil-alt mr-2" />
                        {t("widget.save")}
                      </button>
                    </td>
                  </tr>
                </tfoot>
              ) : (
                <></>
              )}

            </table>


          </div>
        </div>
      )}
      <ModalInventaireExcel show={show} handleClose={handleClose} code={code} t={t} value={value} value1={value1} value2={value2} currentList={currentList} handleChange={handleChange} />
      {error ? <ErrorCnx /> : <></>}
    </div>
  )
}
