import React from "react";
import { Redirect, Route } from "react-router-dom";
import { isLogin } from "../utils/SessionUtils";

function AppRoute({ component: Component, layout: Layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          {isLogin() ? <Redirect to={{pathname: '/'}}  /> : <Component {...props}></Component>}
        </Layout>
      )}
    ></Route>
  );
}

export default AppRoute;
