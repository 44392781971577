import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Loader from "../../../components/shared/Loader";
import ErrorAlert from "../../../others/ErrorAlert";
import {
  getUserByCode,
  livraisonClient,
  montantVenteClient,
  nbrVisites,
} from "../../../services/clients/ClientApi";

export default function TxSucee() {
  //History pour le retour a la page précedente
  const history = useHistory();
  //GET CLIENT CODE FROM LOCALSTORAGE
  const code = localStorage.getItem("codeclient");
  //Client Object
  const [client, setClient] = useState([]);
  //Montant vente
  const [montant, setMontant] = useState(0);
  //Liste Livraison
  const [livraison, setLivraison] = useState([]);
  //Visites
  const [visites, setVisites] = useState([]);
  //Loader
  const [loading, setLoading] = useState(true);
  //Error
  const [error, setError] = useState(false);
  //Traduction
  const [t] = useTranslation("global");

  useEffect(() => {
      //Informations Catégorie / Nom / ACTIVITE Client
      getUserByCode(code)
        .then((res) => {
          setClient(res.data);
        })
        .catch((error) => console.log(error));
      //Get Livraison Client Informations
      livraisonClient(code)
        .then((res) => {
          setLivraison(res.data);
        })
        .catch((error) => console.log(error));
      //Montant Total Ventes Clients
      montantVenteClient(code)
        .then((res) => {
          setMontant(res.data[0].total);
        })
        .catch((error) => console.log(error));
      //Calculer Tx Sucée Client
      nbrVisites(code)
        .then((res) => {
          setVisites(res.data);
        })
        .catch((error) => console.log(error));
        setLoading(false);

   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //Chercher dans la Liste Livrasion si le jour existe
  const findElement = (items) => {
    const elements = livraison?.find((el) => el.code_jour === items);
    return elements;
  };
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
      
        <div className="row">
        <div className="container form-group">
                      <button
                        className="btn bg-purple"
                        onClick={() => history.goBack()}
                      >
                        <i className="fe fe-arrow-left" />
                        {t("stock.retour")}
                      </button>
                    </div>
          <div className="col-lg-12">
            <div className="card">
              <div className=" card-body">
                <div className="row">
                  <div className="col-md-4 col-lg-4">
                  {/*   <div className="form-group ">
                      <div className="form-label">
                        <i className="ti-agenda fa-1x mr-2 text-primary"></i>
                       {t('client.codeclient')}
                      </div>
                      <input
                        type="text"
                        value={client.code}
                        readOnly
                        className="form-control text-primary"
                      />
                    </div> */}
                    <div className="form-group">
                      <div className="form-label">
                        <i className="ti-user fa-1x mr-2 text-primary"></i>
                        {t('client.nomprenom')}
                      </div>
                      <input
                        type="text"
                        value={client.nom + " " + client.prenom}
                        readOnly
                        className="form-control text-primary"
                      />
                    </div>
                    <div className="form-group">
                      <div className="form-label">
                        <i className="ti-briefcase fa-1x mr-2 text-primary"></i>
                        {t('detailClient.activite')}
                      </div>
                      <input
                        type="text"
                        value={client.activite}
                        readOnly
                        className="form-control text-primary"
                      />
                    </div>
                    <div className="form-group">
                      <div className="form-label">
                        <i className="ti-star fa-1x mr-2 text-primary"></i>
                        {t('tournee.categorie')}
                      </div>
                      <input
                        type="text"
                        value={client.categorie}
                        readOnly
                        className="form-control text-primary"
                      />
                    </div>
                    <div className="form-group">
                      <div className="form-label">
                        <i className="ti-home fa-1x mr-2 text-primary"></i>
                        {t('releve.Magasin')}
                      </div>
                      <input
                        type="text"
                        value={client.magasin}
                        readOnly
                        className="form-control text-primary"
                      />
                    </div>
                  </div>
                  {/* col end */}
                  <div className="col-md-8 col-lg-8">
                    <div className="form-group ">
                      <label className="form-label">
                        <i className="ti-truck text-primary mr-2" /> {t('client.livraison')}
                      </label>
                      <div className="selectgroup selectgroup-pills">
                        <label className="selectgroup-item">
                          <input
                            type="checkbox"
                            value="Lundi"
                            className="selectgroup-input"
                            checked={findElement("Lundi")}
                            disabled
                            readOnly
                          />
                          <span className="selectgroup-button">{t('client.lundi')}</span>
                        </label>
                        <label className="selectgroup-item">
                          <input
                            type="checkbox"
                            value="Mardi"
                            checked={findElement("Mardi")}
                            className="selectgroup-input"
                            readOnly
                            disabled
                          />
                          <span className="selectgroup-button">{t('client.mardi')}</span>
                        </label>
                        <label className="selectgroup-item">
                          <input
                            type="checkbox"
                            value="Mercredi"
                            checked={findElement("Mercredi")}
                            className="selectgroup-input"
                            readOnly
                            disabled
                          />
                          <span className="selectgroup-button">{t('client.mardi')}</span>
                        </label>
                        <label className="selectgroup-item">
                          <input
                            type="checkbox"
                            value="Jeudi"
                            checked={findElement("Jeudi")}
                            className="selectgroup-input"
                            readOnly
                            disabled
                          />
                          <span className="selectgroup-button">{t('client.jeudi')}</span>
                        </label>
                        <label className="selectgroup-item">
                          <input
                            type="checkbox"
                            value="Vendredi"
                            checked={findElement("Vendredi")}
                            className="selectgroup-input"
                            readOnly
                            disabled
                          />
                          <span className="selectgroup-button">{t('client.vendredi')}</span>
                        </label>
                        <label className="selectgroup-item">
                          <input
                            type="checkbox"
                            value="Samedi"
                            checked={findElement("Samedi")}
                            className="selectgroup-input"
                            readOnly
                            disabled
                          />
                          <span className="selectgroup-button">{t('client.samedi')}</span>
                        </label>
                        <label className="selectgroup-item">
                          <input
                            type="checkbox"
                            value="Dimanche"
                            checked={findElement("Dimanche")}
                            className="selectgroup-input"
                            readOnly
                            disabled
                          />
                          <span className="selectgroup-button">{t('client.dimanche')}</span>
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                     
                      <input
                        type="text"
                        value={parseFloat(montant).toFixed(3)}
                        readOnly
                        className="form-control text-primary"
                      />
                       <div className="form-label">
                        <i className="ti-money fa-1x mr-2 text-primary"></i>
                        {t('client.TotalVente')}
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-4 col-lg-4 col-sm-4 col-4">
                          <input
                            type="text"
                            value={visites?.visites?.visites}
                            readOnly
                            className="form-control text-primary"
                          />
                           <div className="form-label">
                            <i className="ti-car fa-1x mr-2 text-primary"></i>
                            {t('client.nbVisite')}
                          </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-sm-4 col-4">
                          <input
                            type="text"
                            value={visites?.bls?.bls}
                            readOnly
                            className="form-control text-primary"
                          />
                          <div className="form-label">
                            <i className="ti-notepad fa-1x mr-2 text-primary"></i>
                             {t('client.nbBl')}
                          </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-sm-4 col-4">
                          <input
                            type="text"
                            value={visites?.commandes?.commandes}
                            readOnly
                            className="form-control text-primary"
                          />
                           <div className="form-label">
                            <i className="ti-package fa-1x mr-2 text-primary"></i>
                            {t('client.nbCmd')}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                     {/* 
                      <input
                        type="text"
                        value={(
                          (parseFloat(visites?.bls?.bls) /
                            parseFloat(visites?.visites?.visites)) *
                          100
                        ).toFixed(2)+" % "}
                        readOnly
                        className="form-control text-primary"
                      /> */}
                       <div className="form-label">
                        <i className="ti-stats-up fa-1x mr-2 text-primary"></i>
                        <b className="text-primary"> {(
                          (parseFloat(visites?.bls?.bls) /
                            parseFloat(visites?.visites?.visites)) *
                          100
                        ).toFixed(2)+" % "}</b> {t('client.tauxsuccee')} 
                      </div>
                    </div>
                   
                  </div>
                  {/* col end */}
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
      )}
      {error ? <ErrorAlert t={t} /> : <></>}
    </div>
  );
}
