/* eslint-disable no-unused-vars */
import React, { Suspense } from "react";
//Traduction
import { useTranslation } from "react-i18next";
import Loader from "../../../components/shared/Loader";
//PAGES
import Cards from '../Informations/Cards'
import Paiements from '../Informations/Paiements'
import Recouvrement from '../Informations/Recouvrement'
import Clients from '../Informations/Clients'

export default function ListCommandes() {
  //Traduction 
  const [t, i18n] = useTranslation("global");

  return (
    <div>
      <Cards  t={t}/>
      <Paiements  t={t}  i18n={i18n}/>
      <Recouvrement  t={t} />
      <Clients   t={t}/>
    </div>
  );
}
