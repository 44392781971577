/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
//COOKIES
import Cookies from "js-cookie";
// ERROR ALERTE
import ErrorAlert from "../../../others/ErrorAlert";
//WEB SERVICES
import { getEntetComm } from "../../../services/clients/ClientApi";
//UTILS
import { jsUcfirst } from "../../../utils/Verif";
//Traduction Global
import { useTranslation } from "react-i18next";
//Traduction chiffre to lettre 
import n2words from 'rosaenlg-n2words' // version arabe
import { NumberToLetter } from "convertir-nombre-lettre"; // Lettre français
//Loader
import Loader from "../../../components/shared/Loader"

export default function DetailRelevé() {

      // CODE EN PARAMETERE 
      let { code } = useParams();
      //Langues
      const language = Cookies.get("locale");
      // HISTORIQUES
      const history = useHistory();
      const [t, i18n] = useTranslation("global");
      // Donnee web services
      const [data, setData] = useState({});
      const [lignes, setLignes] = useState([]);
      const [paiement, setPaiement] = useState([]);
      //Loader
      const [loading,setLoading]=useState(true)
      //ERROR
      const [error, setError] = useState(false);

      useEffect(() => {
        //GET ELEMENTS BY CODE
        if (code !== null) {
        getEntetComm(code)
        .then((res) => {
          if(res?.data !== null){
          //LISTE PAIEMENT
          setPaiement(res?.data.paiements[0])
          //LIGNES BL
          setLignes(res?.data.lignes);
          //INFORMATIONS GLOBAL BL
          setData(res.data);
          setLoading(false)
          }
        })
        .catch((error) => //setError(true))
          console.log(error)
        )
        }
      }, []);

      //CALCUL TOTAL REMISE
      const sumRemise = () => {
      const sum = lignes?.reduce((totalsum, { remise }) =>
      totalsum + parseFloat(remise),0);
      return sum
      }

  return (
    <div>
      {loading ? <Loader /> :(
        <>
        {/** BOUTON RETOUR A LA PAGE PRECEDENTE  */}
       <div className="section-title">
        <button className="btn bg-primary " onClick={() => history.goBack()}>
          <i className="fe fe-arrow-left" />
          {t("stock.retour")}
        </button>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header">
               {/** TITRE RELEVE   */}
              <h3 className="card-title float-left" style={{ paddingTop: "15px" }}>
              {code} , {t("releve.le")} : {data?.date}
              </h3>
            </div>
            <div className="card-body">
              <div className="row" >
                <div className="col-lg-6 ">
                  <p className="h4">
                    {/** TITRE SOCIETE AVEC MAJ A LA PREMIERE LETTRE   */}
                    {jsUcfirst(data?.societe?.nom ? data?.societe?.nom : "")}
                  </p>
                  {/** ADRESSE+INFORMATIONS SOCIETE   */}
                  <address className="fs-15" dir={i18n.dir('')}>
                  {t("releve.adresse")} : {data?.societe?.adresse}
                    <br />
                    {t("releve.mf")} : {data?.societe?.matricule_fiscale}
                    <br />
                    {t("releve.tel")} : {data?.societe?.telephone}
                    <br />
                    {t("releve.fax")} :{data?.societe?.fax}
                    <br />
                    {t("login.email")}:{data?.societe?.email}
                  </address>
                </div>
                <div className="col-lg-6 text-right">
                  <p className="h4">
                  {t("releve.client")}  : {data?.client?.nom} {data?.client?.prenom}
                  </p>
                 {/** ADRESSE+INFORMATIONS CLIENT   */}
                  <address className="fs-15" dir={i18n.dir('')}>
                  {t("releve.Magasin")} : {data?.client?.magasin}
                    <br />
                    {t("releve.adresse")}: {data?.client?.adresse_facturation}
                    <br />
                    {t("stock.code")} :{data?.client?.code}
                    <br />
                    {t("detailClient.type")} : {data?.client?.activite}
                    <br />
                    {t("detailClient.mobile")} : {data?.client?.mobile}
                    <br />
                    {t("releve.mf")} : {data?.client?.matricule_fiscale}
                  </address>
                </div>
              </div>
            {/** Tableau des lignes Releve   */}
              <div className="table-responsive push" dir={i18n.dir('')}>
                <table className="table table-bordered table-striped table-hover">
                  <tbody>
                    <tr className="text-center">
                      <th className="text-center ">{t("releve.num")}</th>
                      <th className='text-center'>  {t("releve.ref")}</th>
                      <th className="text-center">{t("releve.produit")}</th>
                      <th>{t("stock.prix")}</th>
                      <th>{t("stock.qte")}</th>
                      <th>{t("stock.total")}</th>
                      <th>{t("releve.remise")} (%)</th>
                      <th>{t("stock.totalchiffre")}</th>
                    </tr>
                    {lignes?.map((items, keys) => (
                      <tr className="text-center" key={keys}>
                        <td className="text-center">{keys+1}</td>
                        <td className="text-center">{items.produit_code}</td>
                        <td>
                          <p className="font-w600 mb-1">
                            {items.produit_libelle}
                          </p>
                        </td>
                        <td className="text-center">
                          {parseFloat(items.pu_ttc).toFixed(3)}
                        </td>
                        <td>{items.quantite}</td>
                        <td>{parseFloat(items.total).toFixed(3)}</td>
                        <td>{items.remise}</td>
                        <td>{parseFloat(items.total_net).toFixed(3)}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="7" className="font-w600 text-right">
                      {t("releve.totalttc")}
                      </td>
                      <td className="text-right">
                        {parseFloat(data.montant_total_ttc).toFixed(3)}
                      </td>
                    </tr>
                    {sumRemise() !== 0 ? (
                      <tr>
                      <td colSpan="7" className="font-w600 text-right">
                      {t("todayCommercial.totalremise")}
                      </td>
                      <td className="text-right">
                        {sumRemise()}
                      </td>
                    </tr>
                    ):(<></>)}
                    <tr>
                      <td colSpan="7" className="font-w600 text-right">
                      {t("releve.netapayer")}
                      </td>
                      <td className="text-right">
                        {parseFloat(data.net_a_payer).toFixed(3)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="row ">
                <div className="col-lg-6 "dir={i18n.dir('')}>
                  <address className="fs-15 text-danger">
                  {t('todayCommercial.credit')+" : " }{parseFloat(paiement?.credit)?.toFixed(3) !== undefined && !isNaN(parseFloat(paiement?.credit)) ?parseFloat(paiement?.credit).toFixed(3): "00"}
                    <br />
                    {t('todayCommercial.echeance')+" : " }{parseFloat(paiement?.date_echeance_credit)?.toFixed(3) !== undefined && !isNaN(parseFloat(paiement?.date_echeance_credit))?parseFloat(paiement?.date_echeance_credit).toFixed(3): "00"} 
                  </address>
                </div>
              </div> 
              <h6 className="text-muted text-center">
              {t("releve.textfacture")+" : "} 
              {language === "ar" ? (
               <p className="mb-0 fs-16 ">
                 {n2words(parseFloat(data?.net_a_payer), {lang: 'ar'})}
               </p>
                ) : (
               <p className="mb-0 fs-16">
                 {n2words(parseFloat(data?.net_a_payer), {lang: 'fr'})}
               </p> )}
              </h6>
              {/** CARD  DEPOT/SOUS SOCIETE/COMMERCIAL */}
              <div className="row">
                <div className="col-lg-4 col-md-4 col-xs-4 col-sm-4">
                  <div className="card box-shadow-0 overflow-hidden">
                    <div className="card-body p-4">
                      <div className="text-center">
                        <i className="ti-package fa-2x text-primary text-primary-shadow" />
                        <h5 className="mt-3 mb-0 ">{t("releve.distributeur")}</h5>
                        <small className="text-muted">
                          {data?.soussociete?.nom}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-xs-4 col-sm-4">
                  <div className="card box-shadow-0 overflow-hidden">
                    <div className="card-body p-4">
                      <div className="text-center">
                        <i className="ti-truck fa-2x text-primary text-primary-shadow" />
                        <h5 className="mt-3 mb-0 ">{t("releve.camionlivraison")}</h5>
                        <small className="text-muted">
                          {data?.depot?.libelle}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-xs-4 col-sm-4">
                  <div className="card box-shadow-0 overflow-hidden">
                    <div className="card-body p-4">
                      <div className="text-center">
                        <i className="ti-id-badge fa-2x text-primary text-primary-shadow" />
                        <h5 className="mt-3 mb-0 ">{t("releve.livreur")}</h5>
                        <small className="text-muted">
                          {data?.commercial?.nom} {data?.commercial?.prenom}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
      )}
      {error ? <ErrorAlert  t={t}/> : <></>}
    </div>
  );
}
