/* eslint-disable no-unused-vars */
import React, { useState } from "react";
//History
import { useHistory } from "react-router-dom";
//WEB SERVICE
import { Login } from "../../services/Login/SignIn";
//Traduction
import { useTranslation } from "react-i18next";
//Loader
import Loader from "../../components/shared/Loader";
//ERROR
import ErrorAlert from "../../others/ErrorAlert";
//ALERTE
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function SignIn() {
  //History
  const history = useHistory();
  // Formulaire Login
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //ALERT
  const [alert, setAlert] = useState(false);
  //CONTENUE ALERT
  const [error, setError] = useState("");
  //Loading
  const [loading, setLoading] = useState(false);
  //Languages
  const [t] = useTranslation("global");

  // Authentification Function
  const authentification = () => {

    setAlert(false);
   
      if (email !== "" && password !== "") {
        Login(email, password, t)
        .then((res) => {
          if (res === false) {
            setAlert(true)
          }
          else if (res?.data?.status === true) {
            window.location.reload()       
               /*  history.push({
              pathname: "/",
              state: localStorage.getItem("url_nomadis"),
            }); */
          }
        }).catch((error)=>console.log(error))
      }
      else {
        toast(t("widget.mailetmotdepasse"), {
          type: "warning", position: "bottom-center"
        });
      }
 
  };

  return (
    <div className="h-100vh">
      <ToastContainer />
      {/* Page opened */}
      <div className="page">
        <div>
          <div className="col col-login mx-auto">
       
          </div>
          {/* container opened */}
          <div className="container" >
            <div className="row">
              <div className="col-xl-9 justify-content-center mx-auto text-center">
                <div className="card">
                  <div className="row">
                    <div className="col-md-12 col-lg-7 pr-0 d-none d-lg-block">
                      <img
                        src="../assets/images/photos/login.jpg"
                        alt="img"
                        className="br-tl-2 br-bl-2 "
                      />
                    </div>
                    <div className="col-md-12 col-lg-5 pl-0 ">
                      <div className="card-body p-6 about-con pabout">
                        <div className="card-title text-center  mb-4">
                          {t("login.text")}
{/*                           <p className='text-muted'>{t('widget.versiongratuite')}</p>
 */}                        </div>
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            placeholder={t("login.email")}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control"
                            id="exampleInputPassword1"
                            placeholder={t("login.password")}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                      {/*   <div className="form-group">
                          <label className="custom-control custom-checkbox">
                            <a
                              href="forgot-password.html"
                              className="float-right small text-info"
                            >
                              {t("login.motoublie")}
                            </a>
                          </label>
                        </div> */}
                        <div className="form-footer mt-1">
                            <button
                              type="button"
                              onClick={() => authentification()}
                              className="btn btn-success btn-block"
                            >
                              {t("login.login")}
                            </button>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* container closed */}
        </div>
      </div>
      {alert ? <ErrorAlert t={t} /> : <></>}
    </div>
  );
}

export default SignIn;
