/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useHistory } from "react-router-dom";
import image from "./2.png";

export default function CardClientTournee({ el, t, visite }) {
  //HISTORY
  const history = useHistory();
  //Chercher le type tournée (bl , visite , etc.)
  const findElement = (items) => {
    const element = visite?.filter(
      (element) => element.client_code === items.code
    );
    return element;
  };
    //Envoyer vers la page client par le code saisie
    const sendToClientProfile = (code) => {
      if (code !== null) {
          localStorage.setItem("codeclient", code);
          history.push({
              pathname: "/clients",
          });
      }}
      
  return (
    <div className="col-xl-4 col-lg-6 col-md-12">
      <div className="card profile ">
        <div className="user-wideget user-wideget-widget widget-user">
          <div className="widget-user-header " />
          <div className="widget-user-image">
            <img src={image} className="avatar brround" alt="User Avatar" />
          </div>
        </div>
        <div className="card-body text-center">
          <h4 className=" text-primary mb-1 mt-5">
            {el.nom + " " + el.prenom}
          </h4>
          <div className="text-muted fs-14"><a onClick={() => sendToClientProfile(el.code)} className="text-warning" ><u>{t("stock.code")+" "+el.code}</u></a></div>
          <div className="text-primary fs-14">
            <i className="ti-location-pin mr-2" />
            {el.adresse_facturation}
          </div>
          <p className="mt-4 mb-5">
            <i className="ti-home text-primary" />
            <div className="text-muted mb-0"> {t("releve.Magasin")+" "+el.magasin}</div>
          </p>
          <div className="d-flex align-items-center justify-content-between mt-4"></div>
          <div className="row">
            <div className="col-sm-12">
              <div className="description-block">
                <h5 className="description-header">
                  {/**CODE TOURNEE */}
                  {findElement(el).length === 0 ? (
                    <div className="align-content-center mb-3 mt-3"
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "baseline",
                      }}>
                      <div
                        className="w-4 h-4 bg-danger mr-1"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Non Visité"
                      />
                    </div>
                  ) : (
                    <div className="align-content-center mb-3 mt-3"
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "baseline",
                      }}>
                      {findElement(el).map((e, i) =>
                        e.type === "bl" ? (
                          <div
                            className="w-4 h-4 bg-lime mr-1"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="BL"
                          />
                        ) : e.type === "visite" ? (
                          <div
                            className="w-4 h-4 bg-primary mr-1"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Visite"
                          />
                        ) : e.type === "commande" ? (
                          <div
                            className="w-4 h-4 bg-success mr-1"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Commande"
                          />
                        ) : e.type === "recouvrement" ? (
                          <div
                            className="w-4 h-4 bg-cyan mr-1"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Recouvrement"
                          />
                        ) : (
                          <></>
                        )
                      )}
                    </div>
                  )}
                </h5>
                <span className="text-muted ">{t('todayCommercial.visites')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
