import React from 'react'
import { numberWithSpaces } from '../../../utils/NumberWithSpaces'

export default function CorrectionSolde({t,correction}) {
    return (
        <div className="card box-shadow-0 mb-lg-0">
        <div className="card-body">
          <div className="item-box text-center">
          <div className="bg-primary-transparent icon-bg icon-service text-primary">
          <i className="fa fa-pencil-square-o" />
         </div>
            <div className="item-box-wrap">
              <p className="text-muted  pmobiletext mb-0">{correction !== undefined ? numberWithSpaces(parseFloat(correction).toFixed(3)) : 0}</p>
              <h6 className=" mobiletext mb-2"  style={{fontSize:"12px"}}> {t('todayCommercial.correction')} </h6>
            </div>
          </div>
        </div>
      </div>
    )
}
