/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { gestionCommadesBydate } from '../../../services/commande/commandeApi'
import Loader from '../../../components/shared/Loader'

export default function DetailsCamions() {
  const { codecamion, date } = useParams('')
  const [liste, setListe] = useState([])
 // const [numBl, setNumBl] = useState('')

  //---------- Loader  ----------------
  const [loading, setloading] = useState(true)
  const data = {
    Chargé: 'bg-lime',
    Sortie: 'bg-azure',
    Livré: 'bg-success',
    Annulé: 'bg-danger',
    Preparation: 'bg-yellow',
  }
  const [t, i18n] = useTranslation('global')

  useEffect(() => {
    if (date !== '') {
      setloading(true)
      gestionCommadesBydate(date)
        .then((result) => {
          if (result?.data) {
            //---- data ----
            const L = result?.data?.cmd_current_day?.filter(
              (element) => element.libelle === codecamion,
            )
            setListe(L)
            setloading(false)
          }
        })
        .catch((err) => {console.log(err)})
       
    }
  }, [])
  
  return (
    <> 
    {loading ? (
      <Loader />
    ) : (
      <div className="row">
      {liste?.map((items, keys) => (
        <div className="col-xl-4 col-lg-6 col-md-12" key={keys}>
          <div className="card profile ">
            <div className="user-wideget user-wideget-widget widget-user">
              <div className="widget-user-header " >
             </div>  
              <div className="widget-user-image">
                <img
                  alt="User Avatar"
                  className="rounded-circle"
                  src="../assets/images/users/2.png"
                />
              </div>
            </div>
            <div className="card-body text-center">
              <h4 className=" text-primary mb-1 mt-5">{items.client}</h4>
              <div className="text-primary fs-14">
                <i className="ti-location-pin mr-2" />
                {items.adresse_livraison}
              </div>
             
              <div className="d-flex align-items-center justify-content-between mt-4"></div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="description-block">
                    <span className="text-muted ">{t('commandes.status')}</span>
                  </div>
                  <div
                    className="align-content-center mb-3 mt-3"
                    style={{
                      justifyContent: 'center',
                      display: 'flex',
                      alignItems: 'baseline',
                    }}>
                     <div
                       className={'w-4 h-4 mr-1 ' + data[items.status]}
                       data-bs-toggle="tooltip"
                       data-bs-placement="top"
                       title="BL"
                     /> 
                     <p className="mt-2"> : {items.status}</p>
                  </div>
                </div>
              </div>
              <div className="row">
             <div className="col-sm-6  col-6 border-right border-top">
               <div className="description-block">
                 <h5 className="description-header">
                   <a
                     className="card-link text-primary"
                     href={'/detail_releve/' + items.num_cmd}
                     style={{ fontSize: '13px' }}
                   >
                     <u>{items.num_cmd}</u>{' '}
                   </a>
                 </h5>
                 <span className="text-muted">{t('commandes.numcmd')}</span>
               </div>
             </div>
             <div className="col-sm-6 col-6 border-top">
               <div className="description-block">
                 <h5 className="description-header">
                   <a
                     className="card-link text-primary"
                     href={'/detail_releve/' + items.num_bl}
                     style={{ fontSize: '13px' }}
                   >
                     <u>{items.num_bl}</u>{' '}
                   </a>
                 </h5>
                 <span className="text-muted">{t('commandes.numbl')}</span>
               </div>
             </div>
           </div>
            </div>
          </div>
       </div>
        
      ))}
    </div>
    )}
   
    </>
  )
}
