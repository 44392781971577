import axios from "axios";

//GET URL FROM LOCALSTORAGE
const url = localStorage.getItem("url_nomadis");

const instance = axios.create({
  baseURL: url,
  withCredentials: true,
/*   SameSite: "None",
  secure: true, */
  IsEssential: true,
});

/**
 * GET INVENTAIRE LISTE 
 * @returns Liste inventaire
 */
 export async function getListeInventaire(pagedeb,pagefin) {
    try {
      const response = await instance.get("inventaire?page="+pagedeb+"&per_page="+pagefin);
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * UPDATE LISTE PRODUITS INVENTAIRE
   * @param {array} data 
   * @returns 
   */
  export async function sendListProducts (data){
    try {
      const response = await instance.post("mouvements/chargementStore",data);
      return response;
    } catch (error) {
      if (error.response === undefined) {
        return false
      } else if (error.response.status === 422) {
        return 422
      } else if (error.response.status === 404) {
        return 404
      }
      else {
        console.error(error);
        return error.response
      }
    }
  }

  /**
   * GET DETAILS USER BY CODE 
   * @param {string} code 
   * @returns 
   */
  export async function getDetailsUser (code) {
    try {
      const response = await instance.get("users/byCode/"+code);
      return response;
    } catch (error) {
      console.error(error);
    }
  }