/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Chart from "react-apexcharts";

export default function Graphe({t, realise }) {
  const [options, setOptions] = useState({
    chart: {
      stacked: true,
      stackType: "100%",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      dropShadow: {
        enabled: true,
      },
    },
    stroke: {
      width: 0,
    },
    xaxis: {
      categories: [""],
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    fill: {
      opacity: 1,
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0.35,
        gradientToColors: undefined,
        inverseColors: false,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [90, 0, 100],
      },
    },

    legend: {
      position: "bottom",
      horizontalAlign: "right",
    },
  });
  const [serie, setSeries] = useState([
    {
      name: t('objectifs.realise'),
      data: [parseFloat(realise)],
      
    },
    {
      name: t('objectifs.pasencorerealise'),
      data: [parseFloat(realise)>=100 ? 0 :  100 - parseFloat(realise)],
    },
  
  ]);
  return (
    <div>
      <Chart
        options={options}
        height={100}
        series={serie}
        type="bar"
        width={250}
      />
    </div>
  );
}
