import React from "react";
import Graphe from "./Graphe";

export default function CardObjectifs({ el, t, keys }) {
    return (
        <div className="col-xl-4 col-lg-6 col-md-6">
            <div className="card profile ">
                <div className="user-wideget user-wideget-widget widget-user">
                    <div className="widget-user-header " >
                        <h6 className=" text-white mb-1 mt-2 text-center"> {el.user}</h6>
                    </div>
                    <div className="widget-user-image">
                        <img
                            src="../assets/images/users/2.png"
                            className="avatar brround"
                            alt="User Avatar"
                        />
                    </div>
                </div>
                <div className="card-body text-center">
                    <div className="d-flex align-items-center justify-content-between mt-4"></div>
                    <div className="row">
                        <div className="col-sm-4 col-4 border-right border-top">
                            <div className="description-block">
                                <h6 className=" text-primary">
                                    {/** Libelle CODE */}
                                    {el.code}
                                </h6>
                                <span className="text-muted ">{t("stock.libelle")}</span>
                            </div>
                        </div>

                        {el.type_objectif ==="vente" ? (
                        <>
                            <div className="col-sm-4 col-4 border-right border-top">
                            <div className="description-block">
                                <h6 className=" text-primary">
                                    {/** TOTAL VENTE */}
                                    {el.type_objectif === "vente" ? el.chiffre : 0}
                                </h6>
                                <span className="text-muted ">{t("todayCommercial.vente")}</span>
                            </div>
                        </div>
                       
                        </>
                        ):
                        (<>
                       
                        <div className="col-sm-4 col-4 border-top">
                            <div className="description-block">
                                <h6 className=" text-primary">
                                    {/** TOTAL VENTE */}
                                    {el.type_objectif === "commande" ? (el.qte !== undefined ? el.qte : el.objectifqte) : 0}
                                </h6>
                                <span className="text-muted ">{t("todayCommercial.commande")}</span>
                            </div>
                        </div>
                      
                        </>)}
                        <div className="col-sm-4 col-4 border-top">
                            <div className="description-block">
                                <h6 className=" text-primary">
                                   {el.type_objectif}
                                </h6>
                                <span className="text-muted ">{t("objectifs.typeobjectifs")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-6 border-top border-right  border-bottom">
                            <div className="description-block">
                                <h6 className=" text-primary">
                                    {/** Date DEBUT */}
                                    {el.date_debut}
                                </h6>
                                <span className="text-muted ">{t("objectifs.datedebut")}</span>
                            </div>
                        </div>
                        <div className="col-sm-6  col-6 border-top border-bottom ">
                            <div className="description-block">
                                <h6 className=" text-primary">
                                    {/** Date Fin */}
                                    {el.date_fin}
                                </h6>
                                <span className="text-muted ">{t("objectifs.datefin")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-12">
                    <div className="description-block">
                                <h6 className=" text-primary">
                                    {/** Date Fin */}
                                    {el.sousfamille === null && el.famille===null && el.gamme === null && el.marque === null ?t('topgamme.categorienonspecifie'): ""}
                                    {el.sousfamille !== null ? t('topgamme.sousfamille')+" : "+el.sousfamille : ""}
                                    {el.famille !== null ? t('topgamme.famille')+" : "+el.famille : ""}
                                    {el.gamme !== null ? t('topgamme.gamme')+" : "+el.gamme : ""}
                                    {el.marque !== null ? t('topgamme.marque')+" : "+el.marque : ""}


                                </h6>
                            </div>
                    </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4  col-4 border-top border-bottom ">
                            <div className="description-block">
                                <h6 className=" text-primary">
                                    {/** Nbr de jour */}
                                    {el.nbr_jour}
                                </h6>
                                <span className="text-muted ">{t("topgamme.nbrjour")+"("+t('objectifs.objecifs')+")"} </span>
                            </div>
                        </div>
                        <div className="col-sm-4  col-4 border-top border-bottom ">
                            <div className="description-block">
                                <h6 className=" text-primary">
                                    {/** Nbr de jour */}
                                    {el.nbr_jour_travaille}
                                </h6>
                                <span className="text-muted ">{t("topgamme.nbrjour")+"("+t('objectifs.travaillé')+")"}  </span>
                            </div>
                        </div>
                        <div className="col-sm-4  col-4 border-top border-bottom ">
                            <div className="description-block">
                                <h6 className=" text-primary">
                                    {/** Nbr de jour */}
                                    {el.type==="chiffre" ? (el.realise_chiffre !== null ? parseFloat(el.realise_chiffre) :0) :(el.realise_qte !== null ? parseFloat(el.realise_qte) :0)}
                                </h6>
                                <span className="text-muted ">{t("topgamme.realise")} </span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="panel-group" id="accordion2" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                                <div className="panel panel-default active">
                                    <div className="panel-heading " role="tab" id={"heading" + keys}>
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion2" href={"#collapse" + keys} aria-expanded="true" aria-controls={"collapse" + keys}>
                                                <i className="icon icon-graph text-primary mr-2 " />  <u className=" text-primary">{t("objectifs.message")}</u>

                                            </a>
                                        </h4>
                                    </div>
                                    <div id={"collapse" + keys} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"heading" + keys}>
                                        <div className="panel-body">
                                            {/** ---------------- SEARCH FORM ------------------  */}
                                            <div className="description-block">
                                                <Graphe t={t} realise={el.realise} />
                                                <span className="text-muted ">{t("objectifs.taux")}</span>
                                            </div>{/** ------------ CARD (qte / total / nbr produits) -----  */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*      <div className="description-block">
                                <Graphe t={t} realise={el.realise} />
                                <span className="text-muted ">{t("objectifs.taux")}</span>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
