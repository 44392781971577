//objectifs/all
import axios from "axios";

//GET URL FROM LOCALSTORAGE
const url = localStorage.getItem("url_nomadis");

const instance = axios.create({
    withCredentials: true,
    IsEssential: true,
});

/**
 * COUNT NOTES
 * @returns response
 */
export  function getCountNotificationsNotes() {
    
    try {
        const response = axios.get(url+`notes/getCountNonVu`,
        {
         withCredentials: true,
         IsEssential: true,
        })
        return response
    } catch (error) {
        console.log(error.response)

        if (error.response === undefined) {
            return false
        }else if (error.response.status=== 401){
            return 401
        } else {
            return error.response
        }
    }
}

/**
 * List Notes Notifications
 * @returns response
 */
export function getListNotesNotification() {
    try {
        
        const response = axios.get(url+"notes/getAllNotesNotification",{
            withCredentials: true,
            IsEssential: true,
           })
        return response
    } catch (error) {
        if (error.response === undefined) {
            return false
        } else {
            return error.response
        }
    }
}

/**
 * 
 * @param {integer} id 
 * @returns response
 */
export async function getNotes(id) {
    try {
        
        const response = await instance.get(url+"notes/getOne/" + id)
        return response
    } catch (error) {
        if (error.response === undefined) {
            return false
        } else {
            return error.response
        }
    }
}

/**
 * Changer Etat
 * @param {integer} id 
 * @returns response
 */
export async function changeEtat(id) {
    try {
        const response = await instance.put(url+"notes/changeEtat/" + id)
        return response
    } catch (error) {
        if (error.response === undefined) {
            return false
        } else {
            return error.response
        }
    }
}
//GET LIST OF COMMANDES
export function commandes() {
    try {
        
        const response = axios.get(url+"dashboard/commandes",{
            withCredentials: true,
            IsEssential: true,
           })
        return response
    } catch (error) {
        if (error.response === undefined) {
            return false
        } else {
            return error.response
        }
    }
}

//GET LIST OF COMMANDES
export function demande_chargement() {
    try {
        const response = axios.get(url+"mouvements/dc",{
            withCredentials: true,
            IsEssential: true,
           })
        return response
    } catch (error) {
        if (error.response === undefined) {
            return false
        } else {
            return error.response
        }
    }
}

/**
 * DEMANDE CHARGEMENT  
 * @param {string} code 
 * @returns 
 */

export function detailchargement(code) {
    try {
        const response = instance.get(url+"mouvements/dc/" + code)
        return response
    } catch (error) {
        if (error.response === undefined) {
            return false
        } else {
            return error.response
        }
    }
}