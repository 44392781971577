/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router'
//components
import PaginationTable from '../../../components/Pagination/PaginationTable'
import { SearchItems } from '../../../utils/SearchUtils'
import ResultByClientCode from './ResultByClientCode'

export default function TableCredits({t, listeClient, setlisteClient, saveListClient, listeVentes, listeMoyVente, listeRecouvrement, listeDerFacture, listeDernierRecouvrement, listeCredit, nbrRecouvrement,numberOfMonth}) {
  //------ History -----------------
  const history = useHistory()
  //USE REF
  const inputEl = useRef('')
  //Search
  const [search, setSearch] = useState('')
  const [searchList, setSearchlist] = useState([])
  const [action, setAction] = useState(false)
  const [row, setRow] = useState(-1)
  const [codeclient, setCodeClient] = useState(-1)
  //Element Informations
  const [objVente, setobjVente] = useState({})
  const [ObjmoyVente, setObjMoyVente] = useState({})
  const [Objrecouvrement, setObjrecouvrement] = useState({})
  const [ObjCredit, setObjCredit] = useState({})
  const [ObjderFacture, setObjderFacture] = useState({})
  const [ObjDerRecouvrement, setObjDerRecouvrement] = useState({})
  const [ObjNbRec, setObjNbRec] = useState({})
  //PAGINATION
  const [currentPage, setCurrentPage] = useState(1)
  const [stocksPerPage, setStockPerPage] = useState(10)
  //Get current stock
  const indexOfLastPost = currentPage * stocksPerPage
  const indexOfFirstPost = indexOfLastPost - stocksPerPage
  //Current Liste
  const currentListe =
    search.length < 3
      ? listeClient.slice(indexOfFirstPost, indexOfLastPost)
      : searchList.slice(indexOfFirstPost, indexOfLastPost)
  //Envoyer vers la page client par le code saisie
  const sendToClientProfile = (code) => {
    if (code !== null) {
      localStorage.setItem('codeclient', code)
      history.push({
        pathname: '/clients',
      })
    }
  }
  //SEARCH ITEMS
  const getSearchTerm = () => {
    setSearch(inputEl.current.value)
    if (search !== '') {
      const newList = SearchItems(listeClient, search)
      setSearchlist(newList)
    } else {
      setlisteClient(saveListClient)
    }
  }

  const onRowClick = (key, index) => {
    setRow(key)
    if (codeclient === index) {
      setAction(!action)
    } else {
      setAction(true)
    }
    setCodeClient(index)
    //alert('inde ' + index)
    const obj = listeVentes.find((element) => element.client_code === index)
    if (obj !== undefined) {
      setobjVente(obj)
    } else {
      setobjVente({
        client: '',
        client_code: '',
        commercial_code: '',
        magasin: '',
        vente: 0,
      })
    }
    //console.log(obj)
    if (listeMoyVente[index] !== undefined) {
      setObjMoyVente(listeMoyVente[index])
    } else {
      setObjMoyVente({
        client: '',
        client_code: '',
        commercial_code: '',
        magasin: '',
        moyenne: 0,
        vente: 0,
      })
    }
    //console.log(listeMoyVente[index])

    const recouvrement = listeRecouvrement.find(
      (element) => element.client_code === index,
    )
    if (recouvrement !== undefined) {
      setObjrecouvrement(recouvrement)
    } else {
      setObjrecouvrement({ recouvrement: 0 })
    }
    //console.log(recouvrement)
    const credit = listeCredit.find((element) => element.client_code === index)
    if (credit !== undefined) {
      setObjCredit(credit)
    } else {
      setObjCredit({ credit: 0 })
    }
    //                        ObjCredit?.credit / ObjmoyVente?.moyenne
    //console.log(credit)
    if (listeDerFacture[index] !== undefined) {
      setObjderFacture(listeDerFacture[index])
    } else {
      setObjderFacture({
        vente: 0,
        last_date: '',
        nbr_jours: 0,
      })
    }
    if (listeDernierRecouvrement[index] !== undefined) {
      setObjDerRecouvrement(listeDernierRecouvrement[index])
    } else {
      setObjDerRecouvrement({
        last_date: '',
        vente: 0,
        nbr_jours: 0,
      })
    }
    //console.log(listeDernierRecouvrement[index])
    const nbRec = nbrRecouvrement.find(
      (element) => element.client_code === index,
    )
    if (nbRec !== undefined) {
      setObjNbRec(nbRec)
    } else {
      setObjNbRec({
        nbr_recouvrement: 0,
      })
    }
  }
  return (
    <div className="col-12">
      <div className="card">
        {/**--------------------SLIDE ACCORDION FILTRE AND SEARCH  ------------------------- */}
        <div
          className="panel-group"
          id="accordion1"
          role="tablist"
          aria-multiselectable="false"
        >
          <div className="panel panel-default mt-2">
            <div className="panel-heading" role="tab" id="headingthree">
              <h4 className="panel-title">
                <a
                  className="collapsed"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordion1"
                  href="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <i className="fa fa-address-book-o text-primary mr-2" /> {t("client.listeClients")}
                  <span className="float-right"></span>
                  
                </a>
              </h4>
            </div>
            <div
              id="collapseThree"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingthree"
            >
              <div className="panel-body">
                <div className="row">
                  <div className="col-xl-6 col-md-6 col-12"></div>
                  <div className="col-xl-6 col-md-6  col-12">
                  <div className="search1 search-elements-sm ">
                      <div className="form row no-gutters ">
                        <div className="form-group  col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 mb-0">
                          <input
                            type="text"
                            ref={inputEl}
                            className="form-control br-tr-md-0 br-br-md-0"
                            id="text1"
                            placeholder="Chercher ..."
                            value={search}
                            onChange={() => getSearchTerm()}
                          />
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-2 col-2 mb-0">
                          <button className="br-tl-md-0 br-bl-md-0 btn btn-block btn-success fs-16">
                            <i className="fe fe-search" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                   
                </div>
                {/**-------------..-----END OF SLIDE ACCORDION FILTRE AND SEARCH  ------------------ */}

                <div className="card-body">
                  {/** --------------- Tableau Card Commande Commercials -------------------------- */}
                  <div className="table-responsive">
                    <table
                      id="contact-detail"
                      className="table card-table table-striped tutorial-table"
                      cellSpacing={0}
                      width="100%"
                    >
                      <thead>
                        <tr className="text-center">
                          <th className="priority-1" widh="15%">
                           {t('client.codeclient')}
                          </th>
                          <th className="priority-1" widh="15%">
                          {t('releve.client')}
                          </th>
                          <th className="priority-3" widh="15%">
                          {t('releve.Magasin')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentListe.map((items, key) => (
                          <>
                            <tr
                              className={
                                action === true && key === row
                                  ? 'text-center  bg-green '
                                  : 'text-center'
                              }
                              key={key}
                              onClick={() => onRowClick(key, items.client_code)}
                            >
                              <td className="priority-1" widh="15%">
                                <u
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    sendToClientProfile(items.client_code)
                                  }
                                  className={
                                    action === true && key === row
                                      ? 'text-white'
                                      : 'text-success'
                                  }
                                >
                                  {items.client_code}
                                </u>
                              </td>
                              <td className="priority-1" widh="15%">
                                {items.client}
                              </td>
                              <td className="priority-3" widh="15%">
                                {items.magasin}
                              </td>
                            </tr>
                            {action === true &&
                            items.client_code === codeclient ? (
                              <tr>
                                <td colSpan="3">
                                <ResultByClientCode  
                                t={t}
                                objVente={objVente}
                                ObjmoyVente={ObjmoyVente}
                                Objrecouvrement={Objrecouvrement}
                                ObjCredit={ObjCredit}
                                ObjderFacture={ObjderFacture}
                                ObjDerRecouvrement={ObjDerRecouvrement}
                                ObjNbRec={ObjNbRec}
                                numberOfMonth={numberOfMonth}
                                />
                                </td>
                              </tr>
                            ) : null}
                          </>
                        ))}
                      </tbody>
                    </table>
                    {/**-----------------  Pagination ------------  */}
                    <div
                      className="col-12"
                      style={{ paddingTop: '15px', paddingBottom: '15px' }}
                    >
                      <PaginationTable
                        postsPerPage={stocksPerPage}
                        totalPosts={
                          search.length < 2
                            ? listeClient.length
                            : searchList.length
                        }
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  {action === true ? (
        <ResultByClientCode
          code={codeclient}
          objVente={objVente}
          ObjmoyVente={ObjmoyVente}
          Objrecouvrement={Objrecouvrement}
          ObjCredit={ObjCredit}
          ObjderFacture={ObjderFacture}
          ObjDerRecouvrement={ObjDerRecouvrement}
          ObjNbRec={ObjNbRec}
        />
      ) : null} */}
    </div>
  )
}
