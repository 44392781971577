/* eslint-disable no-unused-vars */
import React from "react";
//Traduction
import { useTranslation } from "react-i18next";
//PAGES
import Paiements from '../../commandes/Informations/Paiements'
import Recouvrement from '../Informations/Recouvrement'
import ClientsVente from '../Informations/ClientsVente'
import CardsVente from "../Informations/CardsVente";

export default function ListVentes() {

  const [t, i18n] = useTranslation("global");

  return (
    <div>
      <div className="row"></div>
        <CardsVente t={t} />
        <Paiements t={t} i18n={i18n} />
        <Recouvrement t={t} />
        <ClientsVente t={t} />
    </div>
  );
}
