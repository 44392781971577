import React from "react";

function Loader() {
  return (
    <div id="loading">
      <img src="../assets/images/other/loader.svg" className="loader-img" alt="Loader" />
    </div>
  );
}

export default Loader;
