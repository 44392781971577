/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
//Outils
import Loader from "react-spinners/BeatLoader";
import ErrorAlert from "../../others/ErrorAlert";
//web services
import {
  getAllVisite,
  getTourneeClientByCode,
} from "../../services/Tournee/TourneeApi";
import { SearchItems } from "../../utils/SearchUtils";
//Components
import CardClientTournee from "./CardClientTournee";

export default function DetailsTournee() {
  const history = useHistory()
  const inputEl = useRef("")
  //url params
  const { routing_code, tournee_code, usercode, codejour } = useParams();
  //Liste des tournées
  const [tournee, setTournee] = useState([]);
  const [searchList, setSearchlist] = useState([])
  const [saveList, setSaveList] = useState([])
  //Liste Visites
  const [visite, setVisites] = useState([]);
  //Search
  const [search, setSearch] = useState("");
  //Traduction
  const [t, i18n] = useTranslation("global");
  //ERROR
  const [error, setError] = useState(false);
  //Loader
  const [loading, setLoading] = useState(true);
  const currentListe = search.length < 3 ? tournee : searchList

  useEffect(() => {
      //LISTE DES TOURNEES CLIENT PAR CODE
      getTourneeClientByCode(tournee_code)
        .then((res) => {
          setTournee(res.data);
          setSaveList(res.data)

        })
        .catch((error) => console.log(error));
      //LISTE DES VISITES EFFECTUEE
      getAllVisite()
        .then((res) => {
          if (res?.data?.length !== 0) {
            const listeVisite = res?.data;
            setVisites(listeVisite);
            setLoading(false);

          }
        })
        .catch((error) => setError(true));
   
  }, []);
  //SEARCH ITEMS
  const getSearchTerm = () => {
    setSearch(inputEl.current.value)
    if (search !== "") {
    const newList = SearchItems(tournee, search)
    setSearchlist(newList)
    } else {
    setTournee(saveList)
    }
    }
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          {/**Bouton :  Retour a la page précedente */}
          <div className="section-title">
            <button
              className="btn bg-primary "
              onClick={() => history.goBack()}
            >
              <i className="fe fe-arrow-left" />
              {t("stock.retour")}
            </button>
          </div>
          {/**Section : Informations sur les couleurs des carreaux + Titre + Recherche */}
          <div className="row">
            <div className="col-md-12">
                 {/**  ------------------------ CARD Recherche & Filtre Tournee ---------------- */}
                 <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                  <div className="panel-heading " role="tab" id="headingOne">
                    <h4 className="panel-title">
                      <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <i className="icon icon-grid text-primary mr-2 " />                     {t('tournee.detailtournee')}

                        <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                      </a>
                    </h4>
                  </div>
                  <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                    <div className="panel-body">
                      {/** ---------------- SEARCH FORM ------------------  */}
                      <div className="search1 search-elements-sm">
                    <div className="form row no-gutters">
                      <div className="form-group  col-xl-10 col-lg-10 col-md-10 col-8 mb-0">
                        <input
                          type="text"
                          className="form-control br-tr-md-0 br-br-md-0"
                          id="text1"
                          ref={inputEl}
                          placeholder={t('stock.search')}
                          value={search}
                          onChange={() => getSearchTerm()}
                          />
                      </div>

                      <div className="col-xl-2 col-lg-2 col-md-2 col-4 mb-0">
                        <button
                          className="br-tl-md-0 br-bl-md-0 btn btn-block btn-primary fs-16"
                          onClick={() => searchList()}
                        >
                         {t('stock.search')}
                        </button>
                      </div>
                      
                    </div>
                  </div>
                  
                  <div className="row push container">
                    <div className="d-flex align-items-center mb-3 mt-3 mr-2">
                      <div className="w-4 h-4 bg-success  mr-2" />
                      <div>
                        <strong> {t('commandes.title')}</strong>
                        <br />
                      </div>
                    </div>{" "}
                    <div className="d-flex align-items-center mb-3 mt-3 mr-2">
                      <div className="w-4 h-4 bg-danger  mr-2" />
                      <div>
                        <strong>{t('todayCommercial.nonVisite')}</strong>
                        <br />
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-3 mt-3 mr-2">
                      <div className="w-4 h-4 bg-cyan  mr-2" />
                      <div>
                        <strong>{t('todayCommercial.recouvrement')}</strong>
                        <br />
                      </div>
                    </div>
                    <div className="d-flex align-items-center  mb-3 mt-3 mr-2">
                      <div className="w-4 h-4 bg-lime  mr-2" />
                      <div>
                        <strong>{t('todayCommercial.bl')}</strong>
                        <br />
                      </div>
                    </div>
                    <div className="d-flex align-items-center  mb-3 mt-3 mr-2">
                      <div className="w-4 h-4 bg-primary  mr-2" />
                      <div>
                        <strong>{t('todayCommercial.visite')}</strong>
                        <br />
                      </div>
                    </div>
                  </div>
               {/** ------------ CARD (qte / total / nbr produits) -----  */}
                    </div>
                  </div>
                </div>
              </div>
         
            </div>
          </div>
          {/**Liste des Tournees PAR CLIENT */}
          <div className="row">
            {currentListe.length!== 0 ? currentListe?.map((items, key) => (
              <CardClientTournee el={items} t={t} visite={visite} />
            )):(
              <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-12">
                  <div className="alert alert-info">
                    <button
                      type="button"
                      className="close text-white"
                      data-dismiss="alert"
                      aria-hidden="true"
                    >
                      ×
                    </button>
                    <strong>{t('stock.vide')}</strong>
                    <hr className="message-inner-separator" />
                    <p>{t('stock.vide')} </p>
                  </div>
                </div>
              </div>
              </div>
            )}
          </div>
        </>
      )}
      {error ? <ErrorAlert t={t} /> : <></>}
    </div>
  );
}
