/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
// --------------- Traduction --------------------
import { useTranslation } from 'react-i18next';
// -------- Historiques + Parametre URL ---------
import { useHistory, useParams } from 'react-router'
import ErrorAlert from '../../others/ErrorAlert';
// -------- WEB SERVICES ---------
import { detailchargement } from '../../services/Notification/notificationApi'
//--------- Components --------------------------
import Loader from '../../components/shared/Loader';
import { SearchItems, sortPricettcAsc, sortPricettcDesc, TrierAZlibelle } from '../../utils/SearchUtils';
import { OverlayTrigger } from 'react-bootstrap';
import { popover3 } from "../../components/Popover/Popovertab"

export default function DetailChargement() {

  const { code } = useParams("")
  //------ REF INPUT -----------
  const inputEl = useRef("")
  // ----- HISTORY ------------
  const history = useHistory();
  // ----- LISTE ----------- 
  const [chargement, setchargement] = useState([])//Liste chargement
  const [searchList, setSearchlist] = useState([])// RESULTAT RECHERCHE
  const [saveList, setSaveList] = useState([])// sauvegarde initial liste
  //-------Total Chargement-----------
  const [total, setTotal] = useState(0)
  //------- Loading -------------
  const [loading, setloading] = useState(true)
  //------- Alerte -------------
  const [alerte, setalerte] = useState(false)
  // ----- Traduction ----------
  const [t, i18n] = useTranslation("global");
  //Search
  const [search, setSearch] = useState("");
  // Value of SSelect Trier
  const [value, setValue] = useState(0)
  //current posts
  const currentPosts = search.length < 2 ? chargement : searchList

  useEffect(() => {
    /** Liste DETAILS CHARGEMENTS par code */
    detailchargement(code)
      .then((res) => {
        //calcul total de la somme Qte * prix ttc 
        const total = res?.data.reduce((totalsum, { quantite, prix_ttc }) =>
          totalsum + (parseFloat(quantite) * parseFloat(prix_ttc)),0)
        setTotal(total)
        console.log(res?.data)
        // Liste chargement
        setchargement(res?.data)
        setSaveList(res?.data)
      })
      .catch((error) => {
        console.log(error)
      })
      setloading(false)


  }, [])


// Search Function
const getSearchTerm = () => {
  setSearch(inputEl.current.value)
  if (search !== "") {
    const newList = SearchItems(chargement, search)
    setSearchlist(newList)
     //calcul total de la somme Qte * prix ttc 
     const total = newList.reduce((totalsum, { quantite, prix_ttc }) =>
     totalsum + (parseFloat(quantite) * parseFloat(prix_ttc)),
     0)
   setTotal(total)
  } else {
    setchargement(saveList)
    const total = saveList.reduce((totalsum, { quantite, prix_ttc }) =>
    totalsum + (parseFloat(quantite) * parseFloat(prix_ttc)),
    0)
  setTotal(total)
    
  }
}

function ChangeElement (e) {
  setloading(true)   
  if(e.target.value==="1"){
    if(search.length <2){
      sortPricettcAsc(chargement) 
    }else{
      sortPricettcAsc(searchList) 
    }
  }
  else if(e.target.value==="2"){
    if(search.length <2){
      sortPricettcDesc(chargement) 
    }else{
      sortPricettcDesc(searchList) 
    }
  }
  else if(e.target.value==="3"){
    if(search.length <2){
      TrierAZlibelle(chargement) 
    }else{
      TrierAZlibelle(searchList) 
    }
  }
  setloading(false)
}
  return (
    <div dir={i18n.dir()}>
      {loading ? <Loader /> : (
        <>
          {/** BOUTON RETOUR A LA PAGE PRECEDENTE  */}
          <div className="section-title">
            <button className="btn bg-primary " onClick={() => history.goBack()}>
              <i className="fe fe-arrow-left" />
              {t("stock.retour")}
            </button>
          </div>
          <div className="row">
          <div className="col-md-12">
          <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                  <div className="panel-heading " role="tab" id="headingOne">
                    <h4 className="panel-title mobiletext" >
                      <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <i className="icon icon-grid text-primary mr-2 " />  {t('chargement.detailchargement') + ' ' }
                        <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                      </a>
                    </h4>
                  </div>
                  <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                    <div className="panel-body">
                    <div className="item2-gl-nav bg-white d-flex" style={{ marginBottom: '20px' }}>
              <div className="col-md-6 col-12">
                <div className="d-flex">
                  <label className="mr-2 mt-1 mb-sm-1">{t('stock.trierpar')} <i className="ti-exchange-vertical" /></label>
                  <select name="item" className="form-control nice-select mb-0 nice-select-md w-170 "
                    value={value} 
                    onChange={(e) =>{
                    setValue(e.target.value)
                    ChangeElement(e)}}>
                    <option value={0}>------- {t('stock.trierpar')} -----</option>
                    <option value={1}>{t('stock.prixcroissant')}</option>
                    <option value={2}>{t('stock.prixdecroissant')}</option>
                    <option value={3}> A-Z</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="search1 search-elements-sm ">
                  <div className="form row no-gutters ">
                    <div className="form-group  col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 mb-0">
                      <input
                        type="text"
                        ref={inputEl}
                        className="form-control br-tr-md-0 br-br-md-0"
                        id="text1"
                        placeholder={t("stock.search")}
                        value={search}
                        onChange={() => getSearchTerm()} />
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-2 col-2 mb-0">
                      <button
                        className="br-tl-md-0 br-bl-md-0 btn btn-block btn-success fs-16"
                        onClick={() => getSearchTerm()}
                      >
                        <i className="fe fe-search" />
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
         
                    </div>
                  </div>
                </div>
              </div>

            </div>
            </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  {/** Tableau des lignes Releve   */}
                  <div className="table-responsive push">
                    <table className="table table-bordered table-striped table-hover tutorial-table">
                      <tbody>
                        <tr className="text-center">
                          <th className="priority-3 text-center" width="15%">{t("releve.num")}</th>
                          <th className="priority-3 text-center" width="15%"> {t('stock.codeabarre')} </th>
                          <th className="priority-1" width="15%">{t('releve.produit')} </th>
                          <th className="priority-3 text-center" width="15%">{t('releve.distributeur')} </th>
                          <th className="priority-3 text-center" width="15%">{t('todayCommercial.stockdepot')}</th>
                          <th className="priority-1" width="15%">{t('stock.qte')}</th>
                          <th className="priority-1" width="15%">{t('stock.total')}</th>
                        </tr>
                        {currentPosts?.map((items, keys) => (
                          <tr className="text-center" key={keys}>
                            <td className="priority-3 text-center" width="15%">{keys + 1}</td>
                            <td className="priority-3 text-center" width="15%">
                              <p className="font-w600 mb-1">
                                {items.code_a_barre}
                              </p>
                            </td>
                            <td className="priority-1" width="15%">
                              <p className="font-w600 mb-1">
                                {items.libelle}
                              </p>
                            </td>
                            <td className="priority-3 text-center" width="15%">
                              <p className="font-w600 mb-1">
                                {items.soussociete}
                              </p>
                            </td>
                            <td className="priority-3 text-center" width="15%">
                              <p className="font-w600 mb-1">
                                {items.stk_depot}
                              </p>
                            </td>
                            <td className="priority-1" width="15%">
                              <p className="font-w600 mb-1">
                                {items.quantite}
                              </p>
                            </td>
                            <td className="priority-1" width="15%">
                              <p className="font-w600 mb-1">
                                {(items.quantite * items.prix_ttc).toFixed(3)}
                              </p>
                              <OverlayTrigger trigger="click" placement="left" overlay={popover3(items.code_a_barre, items.soussociete,items.stk_depot,t)}>
                                 <i className="icon icon-plus text-success ml-2" />
                             </OverlayTrigger>
                            </td>
                          </tr>
                        ))}
                        <tr className="priority-3" width="15%">
                          <td colSpan="6" className="font-w600 text-right">
                            {t("releve.totalttc")}
                          </td>
                          <td className="text-right">
                            {(total).toFixed(3)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {alerte ? <ErrorAlert t={t} /> : <></>}
    </div>
  )
}
