/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import SquareLoader from '../../../components/Loader/SquareLoader';
import { numberWithSpaces } from '../../../utils/NumberWithSpaces';

export default function Espece({ t, url }) {
    const [espece, setEspece] = useState(0);
    const [especeMonth, setespeceMonth] = useState(0)
    //Loader
    const [loading, setLoading] = useState(true);
    // const [error, setError] = useState(false);

    useEffect(() => {

        setLoading(true)
        // 2 -SERVICE : GET ESPECE DAY
        axios.get(url + "paiements/TotalespecesAujourdhui", {
            SameSite: "None",
            secure: true,
            IsEssential: true,
            withCredentials: true,
        })
            .then((res) => {
                if (res?.data[0]?.recette !== null) {
                    setEspece(res?.data[0].recette);
                }
            })
            .catch((error) => console.log(error));

        axios.get(url + "paiements/sum_paiements_mois", {
            SameSite: "None",
            secure: true,
            IsEssential: true,
            withCredentials: true,
        })
            .then((result) => {
                if (result?.data !== null) {
                    setespeceMonth(result?.data[0]?.especes)
                    setLoading(false)
                }
            }).catch((err) => {
                console.log(err)
            })

    }, [])

    return (
        <>
            {loading ? (
                <SquareLoader />
            ) : (
                <div className="card overflow-hidden">
                    <div className="card-body">
                        <div className="d-flex">
                            <div>
                                <p className="mb-2 h6">{t("listecmdvente.totalespece")}</p>
                                <h5 className="mb-1 "> {parseFloat(espece).toFixed(3)}
                                    <span className="font-weight-normal text-white fs-13">
                                        / {t("commandes.today")}
                                    </span></h5>
                                <hr style={{ marginTop: "10px", marginBottom: "10px", borderTop: "1px solid transparent" }} />

                                <p className="mb-0 text-muted">
                                    <span className="text-success">({especeMonth === null ? " 0.00" : numberWithSpaces(parseFloat(especeMonth).toFixed(3))})</span>
                                    ({moment().format("MMMM")})
                                </p>
                            </div>
                            <div className=" my-auto ml-auto">
                                <div className="chart-wrapper">
                                    <i
                                        className="ti-wallet text-primary fa-3x overflow-hidden mx-auto"
                                        width={100}
                                        height={100}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
        </>
    )
}
