/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

export default function CardStockCommercial({index, t, i18n, el }) {
    return (
        <div className="card testimonial-card mb-3" dir={i18n.dir()} key={index}>
            {/** ------------- CARD HEADER + image ---------------------------- */}
            <div className="card-up bg-purple" />
            <div className="test-img mx-auto white">
                <img src={el.image !== null ? el.image : '../assets/images/users/1.jpg'} style={{ width: "120px", height: '120px' }} className="img-thumbnail rounded-circle" />
            </div>
            {/**CARD BODY */}
            <div className="card-body pt-3 text-center">
                {/** ----------- CARD Libelle + code + colisage + DATE --------- */}
                <h6 className="font-weight-bold mt-0 mb-2">{el.produit}</h6>
                <h6 className="font-weight-normal blue-text ">{t('stock.reference')+" : "+el.code}</h6>
                <div className="row">
                    <div className="col-sm-6  col-6 border-right border-left border-top">
                        <div className="description-block">
                            <h6 className="description-header text-primary" style={{ fontSize: '12px' }}>{el.colisage}</h6>
                            <span className="text-muted">{t('stock.colisage')}</span>
                        </div>
                    </div>
                    <div className="col-sm-6 col-6  border-right  border-left border-top">
                        <div className="description-block">
                            <h6 className="description-header text-primary" style={{ fontSize: '12px' }}>{el.date}</h6>
                            <span className="text-muted">{t('stock.date')}</span>
                        </div>
                    </div>
                </div>
                {/** ------------ CARD prix + quantite + total  */}
                <div className="row">
                    <div className="col-sm-4 col-4 border-right border-left border-top border-bottom">
                        <div className="description-block">
                            <h6 className="description-header text-primary" style={{ fontSize: '12px' }}>{el.prix}</h6>
                            <span className="text-muted">{t('stock.prixunitaire')}</span>
                        </div>
                    </div>
                    <div className="col-sm-4 col-4 border-right border-top border-bottom">
                        <div className="description-block">
                            <h6 className="description-header text-primary" style={{ fontSize: '12px' }}>{el.quantite}</h6>
                            <span className="text-muted">{t('stock.qte')}</span>
                        </div>
                    </div>
                    <div className="col-sm-4 col-4  border-top border-right border-bottom">
                        <div className="description-block">
                            <h6 className="description-header text-primary" style={{ fontSize: '12px' }}>
                                {(parseFloat(el.quantite) * parseFloat(el.prix)).toFixed(3)}
                            </h6>
                            <span className="text-muted">{t('stock.total')}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
