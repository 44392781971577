import React from 'react'
import {Modal,Button, OverlayTrigger} from 'react-bootstrap'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { popover6 } from '../../components/Popover/Popovertab';

export default function ModalInventaireExcel({show,handleClose,code,t,value,value1,value2,currentList,handleChange}) {
    return (
        <Modal show={show} onHide={handleClose}  
        size="lg" 
        aria-labelledby="example-modal-sizes-title-lg" > 
        <Modal.Header >
          <Modal.Title>
          <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="btn btn-primary text-center "
                table="table-to-xls"
                filename="Inventaire_NOMADIS"
                sheet="tablexls"
                buttonText={ <span className=" text-center"> {t('widget.saveexcel')} <i className="fa fa-file-excel-o" /> </span>} />
               
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <table className="table table-bordered table-responsive "id="table-to-xls" width='15%' >
             
             <thead>
               <tr>
                 <th colSpan="8" className="text-center  bg-purple text-white">
                 {t('todayCommercial.retour')+" / "+t('todayCommercial.inventaire')}
                   {code !== '' ? (
                     <span className="float-right">{t('stock.code')+" : "+code}</span>
                   ) : (
                     <></>
                   )}
                 </th>
               </tr>
             </thead>
             <tbody>
               <tr>
                 <td className="text-center mobiletext " width="2%">
                 {t('stock.code')}
                 </td>
                 <td className="text-center mobiletext " width="15%">
                 {t('releve.produit')}
                 </td>
                 <td className="text-center mobiletext " width="2%">
                 {t('stock.colisage')}
                 </td>
                 <td
                   className="text-center mobiletext  bg-danger"
                   width="10%"
                 >
                   {t('stock.stockpiece')}
                 </td>
                 <td
                   className="text-center mobiletext bg-green "
                   width="10%"
                 >
                   {t('stock.Stockcasier')}
                 </td>
                 <td className="text-center mobiletext " width="15%">
                 {t('stock.Quantite')}
                 </td>
                 {value1 !== 'inventaire' && value1 !== '0' ? (
                   <>
                     <td
                       className="text-center mobiletext "
                       width="15%"
                     >
                       {t('stock.DLC')}
                     </td>
                     <td
                       className="text-center mobiletext "
                       width="20%"
                     >
                       {t('stock.Casse')}
                     </td>
                   </>
                 ) : (
                   <></>
                 )}
               </tr>
               {/** --------- LIGNE : portières----------------- */}
               {currentList?.map((items, keys) => (
                 <>
                 <tr>
             
                 </tr>
                 <tr key={keys}>
                   
                   <td
                     className="mobiletext  text-center"
                     width="2%"
                   >
                     {items.produit_code}
                   </td>
                   <td className="mobiletext " width="15%">
                     {items.libelle}
                     <OverlayTrigger
                       trigger="click"
                       rootClose
                       placement="right"
                       overlay={popover6(
                         items.code,
                         items.colisage,
                         items.stk_quantite,
                       )}
                     >
                       <i className="icon icon-plus text-success ml-2" />
                     </OverlayTrigger>
                   </td>
                   <td
                     className="mobiletext  text-center"
                     width="2%"
                   >
                     {items.colisage}{' '}
                   </td>
                   <td
                     className=" text-center bg-danger"
                     width="10%"
                   >
                     {items.stk_quantite === null ? 0 : items.stk_quantite}
                   </td>
                   <td className=" text-center bg-green" width="10%">
                     {items.stk_quantite === null
                       ? 0
                       : parseFloat(
                           items.stk_quantite / items.colisage,
                         ).toFixed(3)}
                   </td>
                   <td className="" width="15%">
                   {items.quantite}
                   </td>
                   {value1 !== 'inventaire' && value1 !== '0' ? (
                     <>
                       <td className="" width="20%">
                       {items.quantite_dlc}
                       </td>
                       <td className="" width="20%">
                       {items.quantite_casse}
                       </td>
                     </>
                   ) : (
                     <></>
                   )}
                 </tr>
                 </>
               ))}
             </tbody>
             {value !== "0" && value1 !== "0" && value2 !=="0" ? (
             <tfoot>
             <tr>
               <td colSpan="8">
               
               </td>
             </tr>
             </tfoot>
             ):(
               <></>
             )}
           </table>
        </Modal.Body>
        <Modal.Footer>
    <Button variant="secondary"  onClick={handleClose}>Fermer</Button>
  </Modal.Footer>
      
      </Modal>
    )
}
