/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";
import { TileLayer, MapContainer, Popup } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import './map.css'
import img from './marker.png'

import Marker from 'react-leaflet-enhanced-marker'
export default function MapCommande({ visites }) {
  const [center, setCenter] = useState({
    lat: visites[0].latitude !== null ?visites[0].latitude :0 ,
    lng: visites[0].longitude!== null ?visites[0].longitude :0,
  });
  const ZOOM_LEVEL = 8;
  const mapRef = useRef();
  
  return (
    <div>
      <div className="left-container">
        <MapContainer center={center} zoom={ZOOM_LEVEL} map={mapRef}>
          <TileLayer
            attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
            url="https://api.mapbox.com/styles/v1/olfamah/ckr5572fg13mr17nzhlm83yew/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoib2xmYW1haCIsImEiOiJja3IxMTZ3aXoxZ254Mm9ueGhtdXdxcXNhIn0.Ua3HPE8z1QpRMeXbbtUeig"
          />
          {visites.map((items, index) => (
            <Marker position={[items.latitude  !== null ? items.latitude: 0, items.longitude !== null ? items.longitude: 0]}icon={<img height="50px" src={img} alt='icon'/>/* <i className="ti-location-pin text-secondary fa-3x"  /> */} key={index} className="marker" >
              <Popup>
                <b>
                 Nom Client :  {items.nomclient}
                 <hr className='hr1'/>
                 Magasin :  {items.magasin} 
                 <hr className='hr1'/>
                 Type :  {items.type} 
                 <hr className='hr1'/>
                 Cause :  {items.cause !==null ? items.cause : ""} 
                 <hr className='hr1'/>
                 Horaire :  {items.heure_debut} - {items.heure_fin}
                </b>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      
      </div>
    </div>
  );
}
