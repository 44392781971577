/**
 * return value from local storage
 */

import { Logout } from "../services/Login/SignIn";

export function getSession(value) {
  return localStorage.getItem(value);
}

/**
 * check if user is connected
 * @returns
 */
export const isLogin = () => {
  if (getSession("authentification_nomadis") !== null) {
    return true;
  }
  else{ return false};
};

/** IS CODE CLIENT EXIST */
export const isClient = () => {
  if (getSession("codeclient") !== null) {
    return true;
  }
  return false;
};

/**SE DECONNECTER */

export const LogoutInterface = (history) =>{
  
  Logout()
  .then((res) => {
    localStorage.removeItem("authentification_nomadis");
    localStorage.removeItem("url_nomadis");
    history.push({
      pathname: '/code',
    })
    window.location.reload()
  });

}