/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Loader from 'react-spinners/BarLoader'
import { toast, ToastContainer } from 'react-toastify'
import ErrorAlert from '../../others/ErrorAlert'
import { getListeCongee } from '../../services/Conge/CongeApi'

export default function DemancheConge() {
    //  Liste des congées
    const [liste, setliste] = useState([])
    //Loader
    const [loading, setloading] = useState(true)
    //ERROR
    const [error, seterror] = useState(false)
    //Traduction Object
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        getListeCongee()
        .then((res) => {
            
            if(res===false){
            seterror(true)
            }
            else if(res===422){
                toast(t('messages.autorisation'),{position:'bottom-center',type:'warning'})
            }
            else if (res===404){
                seterror(true)
            }
            else if (res.data !== undefined){
            setliste(res.data)
            }
            setloading(false)
        })
        .catch((error) => console.log(error))
        
    }, [])

    return (
        <div className="h-100vh">
            {loading ? (<Loader />) : (
                <div className="row">
                    {liste?.length !== 0 ? liste?.map((items, key) => (
                        <div className="col-md-6 col-lg-6 col-sm-12 col-12" key={key}>
                            <ToastContainer />
                            <div className="card box-widgt widget-user">
                                <div className="widget-user-header bg-teal">
                                    <h3 className="widget-user-username">{items.user}</h3>
                                    <h6 className="widget-user-desc"> {t('topgamme.motif')} : {items.motif}</h6>
                                </div>
                                <div className="widget-user-image"><img alt="User Avatar" className="rounded-circle" src="../assets/images/users/2.png" /></div>
                                <div className="box-footer">
                                    <div className="row">
                                        <div className="col-sm-4 border-right border-top">
                                            <div className="description-block">
                                                <h5 className="description-header">{items.nbr_jour}</h5><span className="text-muted">{t('topgamme.nbrjour')}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 border-right border-top">
                                            <div className="description-block">
                                                <h5 className="description-header">{items.date_debut}</h5><span className="text-muted">{t('objectifs.datedebut')}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 border-top">
                                            <div className="description-block">
                                                <h5 className="description-header"> {moment(items.date_debut).add(items.nbr_jour, 'days').format("YYYY-MM-DD")}</h5><span className="text-muted">{t('objectifs.datefin')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )) : (
                        <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                            <div className="alert bg-success mb-5 py-4" role="alert">
                                <div className="d-flex">
                                    <i className="fe fe-check-circle mr-3 fs-30"></i>
                                    <div>
                                        <h4 className="alert-heading">{t('topgamme.messageconge')}</h4>
                                        <p>{t('topgamme.detailconge')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                )}
                </div>)}
        {error ? <ErrorAlert t={t} /> : <></>}
        </div>
    )
}
