/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";
import { TileLayer, MapContainer, Popup } from "react-leaflet";
import Marker from "react-leaflet-enhanced-marker";
import "leaflet/dist/leaflet.css";
import "../TodayCommercial/visites/map.css";
import img from "../TodayCommercial/visites/marker.png";

export default function MapClient({ longitude, latitude, user, magasin ,t}) {
  const [center, setCenter] = useState({
    lat: latitude !== null? latitude: 0,
    lng: longitude!== null? longitude: 0,
  });
  const ZOOM_LEVEL = 12;
  const mapRef = useRef();
  return (
    <div>
      <div className="left-container1">
        <MapContainer center={center} zoom={ZOOM_LEVEL} map={mapRef}>
          <TileLayer
            attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
            url="https://api.mapbox.com/styles/v1/olfamah/ckr5572fg13mr17nzhlm83yew/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoib2xmYW1haCIsImEiOiJja3IxMTZ3aXoxZ254Mm9ueGhtdXdxcXNhIn0.Ua3HPE8z1QpRMeXbbtUeig"
          />
          <Marker
            position={[latitude, longitude]}
            icon={<img height="50px" src={img} alt="icon" />}
            className="marker"
          >
            <Popup>
              <b>
                {user}, {magasin}
              </b>
            </Popup>
          </Marker>
        </MapContainer>
      </div>
    </div>
  );
}
