//import React, { useEffect, useState } from 'react'
import React, { useEffect, useState } from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import axios from 'axios'
import Loader from "../../components/shared/Loader";
import ErrorAlert from '../../others/ErrorAlert'
import { useTranslation } from 'react-i18next'
import { toast, ToastContainer } from 'react-toastify'
import moment from 'moment'
import './designCalendrier.css'
import arLocale from '@fullcalendar/core/locales/ar-ly';
import frLocale from '@fullcalendar/core/locales/fr';


export default function ChequierCalendar() {
    //--------- EVENENT ----------------
    const [event, setEvent] = useState([])
    //ERROR
    const [error, setError] = useState(false);
    //Loader
    const [loading, setLoading] = useState(true);
    //Languages
    const [t, i18n] = useTranslation("global");
    function getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    useEffect(() => {
        // setEvent([])
        const url = localStorage.getItem("url_nomadis");
        // 1- GET CHEQUIER
        axios.get(url + `calendar/getEcheance?date_debut=2021-01-01&date_fin=2021-12-31`, {
            withCredentials: true,
            SameSite: "None",
            secure: true,
            IsEssential: true,
        })
            .then((res) => {
                if (res?.data !== null) {
                    const events = res.data.map(data => {
                        return ({
                            title: data.commercial + '(' + data.cheque + ') \n Client ' + data.client,
                            start: data.date,
                            end: data.date,
                            color: getRandomColor(),
                        })
                    })
                    setEvent(events)
                }
                setLoading(false)
            })
            .catch((error) => console.log(error))
    }, [])

    return (
        <div className="row">
            {loading ?
                <Loader /> :
                (
                    <div className="col-md-12 text-center">

                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title" style={{ marginTop: "10px" }}>
                                    {t("suivi.CalendrierChequier")}
                                </h4>
                            </div>
                            <div className="card-body p-xl-5 p-lg-5 p-0">
                                {/* row */}
                                <ToastContainer />
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card box-shadow-0 mb-0">
                                            <div className="card-body">
                                                <FullCalendar direction={i18n.dir()}

                                                    plugins={[dayGridPlugin, listPlugin]}
                                                    initialView="dayGridMonth"
                                                    themeSystem='bootstrap'
                                                    headerToolbar={{
                                                        left: 'title',
                                                        right: 'prev,today,dayGridDay,dayGridWeek,dayGridMonth,listWeek,next'
                                                    }}
                                                    eventClick={
                                                        function (arg) {
                                                            toast(arg.event.title + " " + t('objectifs.datedebut') + "  : " + moment(arg.event.start).format("DD-MM-YYYY"), { position: 'bo', type: 'info', icon: 'true' })
                                                            //alert("Titre : "+arg.event.title+" Date debut :"+arg.event.start)
                                                        }
                                                    }
                                                    editable={true}
                                                    events={event}
                                                    locales={[arLocale, frLocale]}
                                                    locale={i18n.language === "ar" ? 'ar-ly' : 'fr'}
                                                    loading={loading}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            {error ? <ErrorAlert t={t} /> : <></>}
        </div>


    )
}
