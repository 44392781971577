/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
//----------- TRADUCTION ----------------------------
import { useTranslation } from 'react-i18next';
//----------- History ----------------------------
import { useHistory, useParams } from 'react-router';
//----------- ALERTE ----------------------------
import { toast, ToastContainer } from 'react-toastify';
//----------- WEB SERVICES  --------------------------
import { Logout } from '../../../services/Login/SignIn';
import { getTotalRecouvrement,getTotalCommandes,getNomCommercials, getReglementsCommercial, getReportingCommercial, getTOTALVentes, getTourneeCommercials, getVeilleCommercial } from '../../../services/Reporting/ReportingApi';
//----------- COMPONENTS ----------------------------
import Loader from '../../../components/shared/Loader'
import ErrorAlert from '../../../others/ErrorAlert';
import CardVenteCommercial from './CardVenteCommercial';
import CardCommandeCommercial from './CardCommandeCommercial';
import ReglementCommercial from './ReglementCommercial';
import VeilleConcurentielle from './VeilleConcurentielle';
//import Reportingvisites from '../ReportingVisites/Reportingvisites'

export default function Reportingcommercial() {
    //------ History -----------------
    const history = useHistory();
    const {commercial,debut,fin} = useParams()
    //------ DATE SEARCH (debut / fin) -----------
    const [datedebut, setDateDebut] = useState(debut);
    const [dateFin, setDateFin] = useState(fin);
    //-----------  Filtre Value ------------------
    const [value, setValue] = useState(commercial);//-1
    //----------- TRADUCTION --------------------x
    const [t, i18n] = useTranslation("global");
    // ---------- Loading ------------------
    const [loading, setLoading] = useState(true)
    // -------- ERROR ------------------
    const [error, setError] = useState(false);
    const [ok,setOk] = useState(false)
    //--------- LISTE COMMERCIAL ---------------
    const [liste, setListe] = useState([])
    //-------  Reporting COMMERCIAL
    const [lignes, setlignes] = useState([])       // Ventes
    const [saveVente,setSaveVente] = useState([])
    const [commandes, setCommandes] = useState([]) // Commandes
    const [savecommande,setSaveCommande] = useState([])
    const [reglement, setreglement] = useState([]) //reglement
    const [regSavelist, setSaveListReglement] = useState([])
    const [notes, setNotes] = useState([]) //  veille concurentielle
    //-------  Reporting COMMERCIAL
    const [tournee, settournee] = useState([]) // tournee
    // TOTAL VISITE ET CLIENTS VISITEE
    const [totalvisite, setTotalVisite] = useState(0)
    const [clientvisite, setTotalClientVisite] = useState(0)

    useEffect(() => {
        try {
            // Liste DES COMMERCIAUX POUR LE FILTRE par commercial
         getNomCommercials().then((res) => {
             if (res === 401) {
                 toast(t('messages.vousreconnecter'), { position: "bottom-center", type: "warning" })
                 SeDeconnecter()
             }
             if (res?.data !== null) {
                 setListe(res?.data)
             }
         })
         if(commercial !=='-1' && datedebut !== "" && dateFin !==""){
             //Recherche par date
             if (datedebut <= dateFin) {
                setOk(true)
                //WEB SERVICES GET TOTAL VENTES
                getTOTALVentes(datedebut, dateFin, value)
                .then((result) => {
                    //console.log(result.data);
                    if(result?.data){
                        setlignes(result?.data)
                        setSaveVente(result?.data)
                    }
                }).catch((err) => {
                    console.log(err)
                });
                // GET TOTAL COMMANDES 
                getTotalCommandes(datedebut, dateFin, value)
                .then((result) => {
                    if(result?.data){
                        setCommandes(result?.data)
                        setSaveCommande(result?.data)
                    }
                }).catch((err) => {
                    console.log(err)
                });
                //Get TOTAL RECOUVREMENTS
                getTotalRecouvrement(datedebut, dateFin, value)
                .then((result) => {
                    if(result?.data){
                        setreglement(result?.data)
                        setSaveListReglement(result?.data)
                    }
                }).catch((err) => {
                    console.log(err)
                });
                getReportingCommercial(datedebut, dateFin, value)
                    .then((res) => {
                        //setLoading(true)
                        if (res.data !== null) {
                            setTotalVisite(Object.entries(res.data)[3][1])//total_visite_planifiee
                            setTotalClientVisite(Object.entries(res.data)[4][1])//total_client_visite
                        }
                    })
                    .catch((error) => console.log(error))
             
           /*      getTourneeCommercials(datedebut, dateFin, value)
                    .then((res) => {
                        //setLoading(true)
                        if (res.data !== null) {
                            //console.log(res.data)
                            settournee(res.data)
                        }
                    }).catch((error) => setError(true)) */
                getVeilleCommercial(datedebut, dateFin, value)
                    .then((res) => {
                        if (res?.data !== null) {
                            setNotes(res?.data)
                        }
                        setLoading(false)
                    }).catch((error) => setError(true))
             }
         }
        } catch (error) {
            setError(true)
        } finally {
            setLoading(false)
        }
    }, [])
    //Envoyer vers la page client par le code saisie
    const sendToClientProfile = (code) => {
        if (code !== null) {
            localStorage.setItem("codeclient", code);
            history.push({
                pathname: "/clients",
            });
        }
    }
    //----- RECHERHCE PAR DATE DEBUT - DATE FIN
    const rapportReportingCommercial = () => {
        
        if (datedebut !== "" && dateFin !== "" && value !== -1) {
            if (datedebut <= dateFin) {
                setOk(true)
                history.push({
                    pathname:"/reportingCommercial/commercial="+value+"&datedebut="+datedebut+"&datefin="+dateFin
                })
                getTOTALVentes(datedebut, dateFin, value)
                .then((result) => {
                    //console.log(result.data);
                    if(result?.data){
                        setlignes(result?.data)
                        setSaveVente(result?.data)
                    }
                }).catch((err) => {
                    
                });
                // GET TOTAL COMMANDES 
                getTotalCommandes(datedebut, dateFin, value)
                .then((result) => {
                    //console.log(result.data);
                    if(result?.data){
                        setCommandes(result?.data)
                        setSaveCommande(result?.data)
                    }
                }).catch((err) => {
                    
                });
                //Get TOTAL RECOUVREMENTS
                getTotalRecouvrement(datedebut, dateFin, value)
                .then((result) => {
                    //console.log(result.data);
                    if(result?.data){
                        setreglement(result?.data)
                        setSaveListReglement(result?.data)
                    }
                }).catch((err) => {
                    
                });
                getReportingCommercial(datedebut, dateFin, value)
                    .then((res) => {
                        //setLoading(true)
                        if (res.data !== null) {
                            setTotalVisite(Object.entries(res.data)[3][1])//total_visite_planifiee
                            setTotalClientVisite(Object.entries(res.data)[4][1])//total_client_visite
                        }
                    })
                    .catch((error) => setError(true))
         
                getVeilleCommercial(datedebut, dateFin, value)
                    .then((res) => {
                        if (res?.data !== null) {
                            setNotes(res?.data)
                        }
                        setLoading(false)
                    }).catch((error) => console.log(error))
            } else {
                toast(t('messages.datedebut_fin'), { type: 'error', position: 'bottom-center' });
            }
        } else if (datedebut === "") {
            toast(t('messages.selc_datedebut'), { type: 'warning', position: 'bottom-center' });
        } else if (dateFin === "") {
            toast(t('messages.selc_datefin'), { type: 'warning', position: 'bottom-center' });
        } else if (value === -1) {
            toast(t('messages.selc_commercial'), { type: 'warning', position: 'bottom-center' });
        }
    }
    const SeDeconnecter = () => {
        Logout()
            .then((res) => {
                localStorage.removeItem("authentification_nomadis");
                localStorage.removeItem("url_nomadis");
            });
        history.push({
            pathname: "/code",
        });
    };

    return (
        <div>
            {loading ? <Loader /> : (
                <>
                    <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                        <div className="panel panel-default active">
                            <div className="panel-heading " role="tab" id="headingOne">
                                <h4 className="panel-title">
                                    <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        <i className="icon icon-grid text-primary mr-2" /> {t("suivi.ReportingCommercial")}
                                        <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                                <div className="panel-body">
                                    <div className="form row no-gutters">
                                        <div className="form-group  col-xl-4 col-lg-4 col-md-4 col-6 mb-0">
                                            <input
                                                className="form-control fc-datepicker"
                                                type="date"
                                                value={datedebut}
                                                onChange={(e) => setDateDebut(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group  col-xl-4 col-lg-4 col-md-4 col-6 mb-0">
                                            <input
                                                className="form-control fc-datepicker"
                                                type="date"
                                                value={dateFin}
                                                onChange={(e) => setDateFin(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group  col-xl-3 col-lg-3 col-md-4 mb-0">
                                            <div className="d-flex" >
                                                <select className="form-control"
                                                    value={value}
                                                    onChange={(e) => {
                                                        setValue(e.target.value)
                                                    }}>
                                                    <option value={"-1"}> {"Commercial  --- "}</option>
                                                    {liste?.map((items, keys) => (
                                                        <option value={items.code} key={keys}> {items.user} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-xl-1 col-lg-1 col-md-12 col-12 mb-0">
                                            <button
                                                className="br-tl-md-0 br-bl-md-0 btn btn-block btn-success fs-16" onClick={() => rapportReportingCommercial()}>
                                                <i className="fe fe-search" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {ok ? (
                                <>
                            <ToastContainer />
                            {/** ------- CARD END ------------- */}
                            {/** CARD VENTE */}
                            <CardVenteCommercial ventes={lignes} setlignes={setlignes} visite={totalvisite} client={clientvisite} t={t} sendToClientProfile={sendToClientProfile} saveVente={saveVente} commercial={value}/>
                            {/** CARD Commandes */}
                            <CardCommandeCommercial commandes={commandes} setCommandes={setCommandes} savecommande={savecommande} t={t} sendToClientProfile={sendToClientProfile} />
                            {/** REGELEMENT DE CHEQUES  */}
                            <div className="row">
                                <div className="col-lg-7 col-xl-7 col-md-7 col-sm-12 col-12">
                                    <ReglementCommercial reglement={reglement} setreglement={setreglement} savelist={regSavelist} sendToClientProfile={sendToClientProfile} />
                                </div>
                                <div className='col-lg-5 col-xl-5 col-md-5 col-sm-12 col-12'>
                                    <VeilleConcurentielle notes={notes} />
                                </div>
                            </div>
                            {/*                         <Reportingvisites tournee={tournee}/>*/} 
                             </>
                            ):( 
                            <div className="row">
                            <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                                <div className="alert bg-purple mb-5 py-4" role="alert">
                                    <div className="d-flex">
                                        <i className="icon icon-info mr-3 fs-30"></i>
                                        <div>
                                            <h4 className="alert-heading">{t('suivi.objet')}</h4>
                                            <p> {t('suivi.message')}  </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )}
                         
                        </div>
                    </div>
                </>
            )}
            {error ? <ErrorAlert t={t} /> : <></>}
        </div>
    )
}
