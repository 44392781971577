import React from 'react'

export default function CardAgeDette({t,ageDette}) {
  return (
    <div className="card box-shadow-0 mb-lg-0">
      <div className="card-body">
        <div className="item-box text-center">
        <div className="bg-warning-transparent icon-bg icon-service text-warning">
        <i className="fa fa-money" />
       </div>
          <div className="item-box-wrap">
            <p className="text-muted  pmobiletext mb-0">{parseFloat(ageDette).toFixed(2)}</p>
            <h6 className=" mobiletext mb-2"  style={{fontSize:"12px"}}> {t("todayCommercial.agedette")} </h6>
          </div>
        </div>
      </div>
    </div>
  )
}
