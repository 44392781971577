import React from 'react'

export default function SquareLoader() {
    return (
        <div className="card-body text-center">
            <div className="lds-css ng-scope">
                <div className="lds-bars"><div />
            <div />
            <div /><div /></div></div>
        </div>

    )
}
