import React from 'react'
import PageUnderConstruction from '../../PageUnderConstruction'
/* import Autourduvehicule from './Autourduvehicule'
import Autres from './Autres'
import Danslevehicule from './Danslevehicule'
import Souslecapot from './Souslecapot'
import Souslevehicule from './Souslevehicule'
import Systemedefreins from './Systemedefreins'
 */
/**
 * PAGE Détails Véhicule
 * @returns 
 */
export default function DetailsVehicule() {
    return (
        <div>
           {/*  <Danslevehicule />
            <Autourduvehicule />
            <Souslevehicule />
            <Systemedefreins />
            <div className="row">
                <div className="col-7">
                    <Souslecapot />
                </div>
                <div className="col-5">
                    <Autres />
                </div>
            </div> */}
            <PageUnderConstruction />
           {/*  <PageNotFound /> */}
        </div>
    )
}
