/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { OverlayTrigger } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {  popover2 } from '../../../components/Popover/Popovertab';
import { numberWithSpaces } from '../../../utils/NumberWithSpaces';
import { sortdate, sortdatedesc, sortMontant } from '../../../utils/SearchUtils';

export default function ReglementCommercial({ reglement, savelist, setreglement ,sendToClientProfile}) {

    // -------------- References ---------------
    const inputEl = useRef("")
    // ---------- Loading ------------------
    const [loading, setLoading] = useState(true)
    //-----------  Filtre Value ------------------
    const [value, setValue] = useState(-1);
    //----------- TRADUCTION --------------------x
    const [t, i18n] = useTranslation("global");
    // -------- ERROR ------------------
    const [error, setError] = useState(false);
    // -------------- FILTRE + SEARCH ----------
    const [search, setSearch] = useState('')
    const [searchList, setSearchlist] = useState([])

    const currentPosts = search.length < 1 ? reglement : searchList

 
    //------- FILTRE ELEMENTS  ------------------
    function ChangeElement(e) {
        setLoading(true)
        if (e.target.value === 0) {
            setreglement(savelist)
        }
        else if (e.target.value === "1") {
            if (search.length < 2) {
                //Trier par date
                sortdate(reglement)
            } else {
                //Trier par date
            }
        }
        else if (e.target.value === "2") {
            if (search.length < 2) {
                sortdatedesc(reglement)
                //Trier par A-Z
            } else {
                //Trier par A-Z        }
            }
        }
        else if (e.target.value === "3") {
            if (search.length < 2) {
                //Trier par montant
                sortMontant(reglement)
            } else {
                //Trier par montant
            }
        }
        setLoading(false)
    }

    // Total Ventes 
    function totalVentes () {
        const total = currentPosts.reduce(
        (totalsum, { montant }) => totalsum + parseFloat(montant), 0);
        return total
    }

    // ---------- SEARCH -------------- 
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (search !== "") {
            const newList = reglement?.filter((el) => {
                return Object.values(el)
                    .join(" ")
                    .toLowerCase()
                    .includes(inputEl.current.value.toLowerCase())
            })
            setSearchlist(newList)

        } else {
            setreglement(savelist)
        }
    }
    return (
        <>
            {reglement.length !== 0 ? (
                <div className="card">
                    {/**--------------------SLIDE ACCORDION FILTRE AND SEARCH  ------------------------- */}
                    <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true">
                        <div className="panel panel-default mt-2">
                            <div className="panel-heading" role="tab" id="headingFour">
                                <h4 className="panel-title">
                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        <i className="icon icon-wallet text-primary mr-2" /> {t('suivi.Reportingrecouvrement')}
                                        <span className="float-right" dir={i18n.dir("fr")}> 
                                             {currentPosts.length !== 0 ? <h6>  {t("stock.valeur")+" : "+(totalVentes().toFixed(3))} / Count : {reglement?.length}   <i className="fe fe-search  text-primary" />  </h6> :  <i className="fe fe-search  text-primary" />  }
                                           </span>            
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseFour" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                                <div className="panel-body">
                                    <div className="row" >
                                        <div className="col-lg-6 col-xl-6 col-12">
                                            {/* -------------------- RECHERCHE + FILTRE -------------------------------------- */}
                                            <div className="d-flex">
                                                <label className="mr-2 mt-1 mb-sm-1">Trier <i className="ti-exchange-vertical" /></label>
                                                <select name="item" className="form-control nice-select mb-0 nice-select-md w-250 "
                                                    value={value}
                                                    onChange={(e) => {
                                                        setValue(e.target.value)
                                                        ChangeElement(e)
                                                    }}
                                                >
                                                    <option value={0}>------- {t('stock.trierpar')} -----</option>
                                                    <option value={1}> Date récente </option>
                                                    <option value={2}> Date ancienne </option>
                                                    <option value={3}> Montant </option>

                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-6 col-12">
                                            <div className="search1 search-elements-sm ">
                                                <div className="form row no-gutters ">
                                                    <div className="form-group  col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 mb-0">
                                                        <input
                                                            type="text"
                                                            ref={inputEl}
                                                            onChange={() => getSearchTerm()}
                                                            className="form-control br-tr-md-0 br-br-md-0"
                                                            placeholder={t("stock.search")}
                                                        />
                                                    </div>
                                                    <div className="col-xl-2 col-lg-2 col-md-2 col-2 mb-0">
                                                        <button
                                                            className="br-tl-md-0 br-bl-md-0 btn btn-block btn-success fs-16"
                                                        >
                                                            <i className="fe fe-search" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/**-------------..-----END OF SLIDE ACCORDION FILTRE AND SEARCH  ------------------ */}
                    <div className="card-body">
              

                        <div className="table-responsive">
                        <table id="contact-detail" className="table card-table table-striped tutorial-table" cellSpacing={0} width="100%">
                                <thead>
                                    <tr className="text-center">
                                        <th className="priority-1">{t('stock.date')}</th>
                                        <th className="priority-3">{t('suivi.societe')}</th>
                                        <th className="priority-1">{t('releve.client')}</th>
                                        <th className="priority-1">{t('historique.montant')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentPosts.map((items, keys) => (
                                        <tr className="text-center" key={keys} >
                                            <td  className="priority-1">{moment(items.date).format("DD-MM-YYYY")}</td>
                                            <td className="priority-3" >{items.soussociete}</td>
                                            <td className="priority-1"> <b style={{cursor:'pointer'}} onClick={() => sendToClientProfile(items.client_code)} className="text-warning">{items.client}</b></td>
                                            <td className="priority-1" width="15%"  ><a href={'/detail_releve/' + items.code}><u className="text-primary">{parseFloat(items.montant).toFixed(3)}</u></a>
                                                <OverlayTrigger trigger="click"  rootClose placement="left" overlay={popover2(items.soussociete,items.code,t)}>
                                                    <i className="icon icon-plus text-success ml-2" />
                                                </OverlayTrigger>
                                            </td>      
                                        </tr>)
                                    )}
                                    {/* <tr>
                                        <td colSpan="3" className="font-w600 text-right ">
                                            Total
                                        </td>
                                        <td className="text-center">
                                            {numberWithSpaces(parseFloat(total).toFixed(3))}
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            ) : <></>}

        </>
    )
}
