/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import CardVente from './Cards/CardVente'
import CardRecouvrement from './Cards/CardRecouvrement'
import CardAgeDette from './Cards/CardAgeDette'
import CardCredit from './Cards/CardCredit'
import CardCreditTotal from './Cards/CardCreditTotal'
import { getNomCommercials, getRapportCommercialClient} from '../../services/Reporting/ReportingApi'
import { toast } from 'react-toastify'
import { Logout } from '../../services/Login/SignIn'
import TableCredits from './Tables/TableCredits'
import Loader from '../../components/shared/Loader'
import CorrectionSolde from './Cards/CorrectionSolde'
import ErrorCnx from '../../others/ErrorCnx'
import { useTranslation } from 'react-i18next'

export default function SuiviCommClient() {
  //------ History -----------------
  const history = useHistory()
  const { commercial, month } = useParams()
  //Valeur option commercial
  const [emloyee, setemployee] = useState(commercial)
  //Value Month
  const [dateMonth, setDateMonth] = useState(month) //Format 2021-11
  //--------- LISTE COMMERCIAL ---------------
  const [liste, setListe] = useState([])
  const [listeClient, setlisteClient] = useState([])
  const [saveListClient, setSaveListClient] = useState([])
  // --------- Liste des objets -------------------
  const [listeVentes, setlisteVentes] = useState([]) //Vente par mois
  const [listeMoyVente, setlisteMoyVente] = useState({}) //Vente Moyenne vente 
  const [listeRecouvrement, setlisteRecouvrement] = useState([])
  const [listeDerFacture, setlisteDerFacture] = useState({})
  const [listeDernierRecouvrement, setlisteDernierRecouvrement] = useState({})
  const [listeCredit, setlisteCredit] = useState([])
  const [nbrRecouvrement, setnbrRecouvrement] = useState([]) 
  const [numberOfMonth,setNumberOfMonth] = useState(0)
  // Cards Values
  const [vente, setvente] = useState(0)
  const [moyenne, setmoyenne] = useState(0)
  const [credit, setcredit] = useState(0)
  const [creditotal, setcreditotal] = useState(0)
  const [recouvrement, setrecouvrement] = useState(0)
  const [ageDette, setageDette] = useState(0)
  const [correction, setCorrection] = useState(0)
  // ----------------- Languages --------------
  const [t, i18n] = useTranslation('global')
  //---------- Loader
  const [loading, setloading] = useState(true)
  // -------- ERROR ------------------
  const [error, setError] = useState(false);
  const [ok,setOk] = useState(false)


  const SeDeconnecter = () => {
    Logout().then((res) => {
      localStorage.removeItem('authentification_nomadis')
      localStorage.removeItem('url_nomadis')
    })
    history.push({
      pathname: '/code',
    })
  }

  const traitement = (result) => {
    // Total Vente (CARD)
    setvente(result?.data?.vente_mois?.vente)
    // Total Crédit (CARD) => Month
    setcredit(result?.data?.credit_mois?.credit)
    // Total Moyenne (CARD)
    const moy = parseFloat((result?.data?.recouvrement_mois?.recouvrement/result?.data?.vente_mois?.vente)*100).toFixed(2)
    setmoyenne(moy)
    // Crédit Total (Card ) => ALL
    setcreditotal(result?.data?.creditAllForCommercial?.credit)
    //Correction Solde 
    setCorrection(result?.data.correction_mois?.correction)
    //Total Recouvrement
    setrecouvrement(result?.data?.recouvrement_mois?.recouvrement)
    setNumberOfMonth(result?.data?.last7Month)
    //Traitement d'une liste indexé => Transformation en un objet simple NON INDEXéé
    
    const li = Object.entries(result?.data?.creditMonth)
   
    const LISTE_CL = li.map(items=>{
      return ({
        client: items[1].client,
          client_code: items[1].client_code,
          magasin: items[1].magasin,
      })
    })
   
    // Liste Client Compléte
    setlisteClient(LISTE_CL) // initial
    setSaveListClient(LISTE_CL) // SAUEVAGRDE Dans le cas de récupération
    //Charget les listes d'informations Client : tels que ( recouvrement ,crédit , ventes ...etc)
    setlisteCredit(result?.data?.creditAll)
    setlisteMoyVente(result?.data?.vente7Month)
    setlisteVentes(result?.data?.venteMois)
    setlisteRecouvrement(result?.data?.recouvrementMonth)
    setlisteDerFacture(result?.data?.dernier_facture)
    setlisteDernierRecouvrement(result?.data?.dernier_recouvrement)
    setnbrRecouvrement(result?.data?.nbr_recouvrement_mois)
    const total = result?.data?.creditAll?.reduce(
      (totalsum, { credit }) => totalsum + parseFloat(credit),
      0,
    )
    setageDette(total/result?.data?.sommeMoyenneVente?.moyenne)
  }
  
  useEffect(() => {
    if (dateMonth !== '' && emloyee !== '-1') {
      setloading(true)
      getRapportCommercialClient(dateMonth, emloyee)
        .then((result) => {
          traitement(result)
        })
        .catch((err) => {
          console.log(err)
          if(err?.response=== undefined){
            setError(true)
          }
        })
        setloading(false)
    }
    // Liste DES COMMERCIAUX POUR LE FILTRE par commercial
    getNomCommercials().then((res) => {
      if (res === 401) {
        toast('Vous devez vous reconnecter ', {
          position: 'bottom-center',
          type: 'warning',
        })
        SeDeconnecter()
      }
      if (res?.data !== null) {
        setListe(res?.data)
      }
    })
  }, [])

  const RapportCommercialClient = () => {
    if (dateMonth !== '' && emloyee !== '-1') {
      history.push({
        pathname:
          '/suiviCommercial/commercial=' + emloyee + '&month=' + dateMonth,
      })
      setloading(true)
      getRapportCommercialClient(dateMonth, emloyee)
        .then((result) => {
          traitement(result)
        })
        .catch((err) => console.log(err))
        setloading(false)
    }
  }

  return (
    <div>
      <div
        className="panel-group"
        id="accordion1"
        role="tablist"
        aria-multiselectable="true"
        style={{ marginBottom: '10px' }}
      >
        <div className="panel panel-default active">
          <div className="panel-heading " role="tab" id="headingOne">
            <h4 className="panel-title">
              <a
                role="button"
                data-toggle="collapse"
                data-parent="#accordion1"
                href="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <i className="fa fa-file-text-o text-primary mr-2" />{t("suivi.suivicommclient")}
                <span className="float-right">
                  <i className="fe fe-search  text-primary" />
                </span>
              </a>
            </h4>
          </div>
          <div
            id="collapseOne"
            className="panel-collapse collapse"
            role="tabpanel"
            aria-labelledby="headingOne"
          >
            <div className="panel-body">
              <div className="form row no-gutters">
                <div className="form-group  col-xl-5 col-lg-5 col-md-5 col-5 mb-0">
                  <input
                    className="form-control fc-datepicker"
                    type="month"
                    value={dateMonth}
                    onChange={(e) => setDateMonth(e.target.value)}
                  />
                </div>
                <div className="form-group  col-xl-5 col-lg-5 col-md-5   col-5 mb-0">
                  <div className="d-flex">
                    <select
                      className="form-control"
                      value={emloyee}
                      onChange={(e) => setemployee(e.target.value)}
                    >
                      <option value={'-1'}> {t('stock.commerciaux')+' --- '}</option>
                      {liste?.map((items, keys) => (
                        <option value={items.code} key={keys}>
                          {items.user}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-2 col-2 mb-0">
                  <button
                    className="br-tl-md-0 br-bl-md-0 btn btn-block btn-success fs-16"
                    onClick={() => RapportCommercialClient()}
                  >
                    <i className="fe fe-search" />
                  </button>
                </div>
              </div>
              <p className="mt-2 text-info">{t('suivi.selectionner')} </p>

              </div>
          </div>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <p className="mt-1 text-center">{emloyee !== "-1" && dateMonth !== "" ? <b className="text-danger text-center"> {t('todayCommercial.moyenne')} {isNaN(moyenne) ? 0 : Math.round(moyenne)} % </b> : null }</p>
          <div className="row">
            <div className="col-lg-2 col-xl-2 col-md-2 col-4">
              <CardVente t={t} vente={vente} />
            </div>
            <div className="col-lg-2 col-xl-2 col-md-2 col-4">
              <CardRecouvrement t={t} recouvrement={recouvrement} />
            </div>
            <div className="col-lg-2 col-xl-2 col-md-2 col-4">
              <CardAgeDette t={t} ageDette={ageDette} />
            </div>
            <div className="col-lg-2 col-xl-2 col-md-2 col-4">
              <CorrectionSolde  t={t} correction={correction} />
            </div>
            <div className="col-lg-2 col-xl-2 col-md-2 col-4">
              <CardCredit  t={t} credit={credit} />
            </div>
            <div className=" col-lg-2 col-xl-2 col-md-2 col-4">
              <CardCreditTotal t={t} creditotal={creditotal} />
            </div> 
          </div>

          <div className="row mt-2">
            <TableCredits 
            t={t}
            listeClient={listeClient} 
            setlisteClient={setlisteClient} 
            saveListClient={saveListClient}
            listeVentes={listeVentes}
            listeMoyVente={listeMoyVente}
            listeRecouvrement={listeRecouvrement}
            listeDerFacture={listeDerFacture}
            listeDernierRecouvrement={listeDernierRecouvrement}
            listeCredit={listeCredit}
            nbrRecouvrement={nbrRecouvrement}
            numberOfMonth={numberOfMonth}
             />
          </div>
        </>
      )}
        {error ? <ErrorCnx  /> : <></>}
    </div>
  )
}
