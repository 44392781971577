/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
//------------ ALERTE PACKAGE ----------------
import { ToastContainer } from 'react-toastify'
import Loader from '../../components/shared/Loader';
import ErrorAlert from '../../others/ErrorAlert';

/**
 * PAGE accée Voiture : Matricule Voiture
 * @returns 
 */
export default function EntretienVehicule() {
      //----------HISTORY---------
     const history = useHistory();
    //-------- Matricule ----------------
    const [code, setCode] = useState("");
    //-------- Loading ----------------
    const [loading, setLoading] = useState(false);
    //---------- Languages -----------------
    const [t, i18n] = useTranslation("global");
   //------------ ALERTE ---------------
   const [alert, setAlert] = useState(false);

   const valideMatricule = ()=>{
       history.push({
        pathname: "/details_vehicule",
      });
   }
    return (
        <div className="h-75vh">
        <ToastContainer />
        {/* Page opened */}
        <div className="page">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-10 justify-content-center mx-auto text-center">
                <div className="card">
                  <div className="row">
                    <div className="col-md-12 col-xl-6 col-lg-6 pr-0 d-none d-lg-block">
                      <img
                        src="../assets/images/photos/login4.jpg"
                        alt="img"
                        className="br-tl-2 br-bl-2 " style={{height:"100%"}}
                      />
                    </div>
                    <div className="col-md-12 col-xl-6 col-lg-6 pl-0 mt-8">
                    <i className="ti-panel fa-2x text-center text-primary mb-0" />
                      <div className="card-body  about-con pabout">
                
                        <div className="card-title text-center mb-5 mt-0">
                                Entretien Véhicule
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Matricule Véhicule"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                          />
                        </div>
                        {loading ? (
                          <div className="form-footer mt-1">
                            <button
                             className="btn bg-purple btn-block"
                            >
                              {t("client.valider")} <Loader/>
                            </button>
                          </div>
                        ) : (
                          <div className="form-footer mt-1">
                            <button
                              className="btn bg-purple btn-block"
                              onClick={()=>valideMatricule()}
                            >
                              {t("client.valider")}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {alert ? <ErrorAlert  t={t} /> : <></>}
      </div>
    )
}
