/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
//Moments
import moment from 'moment'
//Loader
import SquareLoader from '../../../components/Loader/SquareLoader'
//UTILS
import { getCurrentDate } from '../../../utils/DateUtils'
//WEB SERVICES
import { getRecrutementClient } from '../../../services/clients/ClientApi'
import { countVisite, countVisiteMonth, getClientVisite, getCommandes } from '../../../services/commande/commandeApi'
//ERROR
import ErrorAlert from '../../../others/ErrorAlert'

export default function Clients({ t }) {
    //DATE TODAY

  const today = getCurrentDate();
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  var date1 = firstDay.getFullYear() + "-" + (firstDay.getMonth() + 1) + "-" + firstDay.getDate()
  var date2 = lastDay.getFullYear() + "-" + (lastDay.getMonth() + 1) + "-" + lastDay.getDate()

  //RESULTS VALUES
  const [bl, setBL] = useState('00')
  const [blMonth, setMonthBl] = useState('00')

  const [clientVisite, setClientVisite] = useState('00')
  const [clientMonthV, setClientMonthV] = useState('00')

  const [clientRecru, setClientRecru] = useState('00')
  const [clientRecruToday, setClientRecruToday] = useState('00')
  //Loader
  const [loading, setLoading] = useState(true)
  const [loadingVisite, setloadingVisite] = useState(true)
  const [loadingRecrutement, setloadingRecrutement] = useState(true)
  //ERROR
  const [error, setError] = useState(false)

  useEffect(() => {
    // 1- GET N°= VISITES CLIENTS
    countVisite()
      .then((res) => {
        if (res?.data) {
          setClientVisite(res.data)
        }
        // 4- GET MONTH VISITE
        countVisiteMonth()
          .then((res) => {
            if (res?.data) {
              setClientMonthV(res.data)
              setloadingVisite(false)

            }
          })
          .catch((error) => console.log(error))
      })
      .catch((error) => console.log(error))
    

    // 2- GET  RECRUTEMENT CLIENTS

    //RECRUTEMENT CLIENT TODAY
    getRecrutementClient()
      .then((res) => {
        setClientRecruToday(res?.data[0]?.nbrclient)
        getClientVisite()
          .then((res) => {
            if (res?.data) {
              setClientRecru(res?.data)
              setloadingRecrutement(false)

            }
          })
          .catch((error) => console.log(error))
      })
      .catch((error) => console.log(error))
     // 3- GET BL Aujourd'hui
    
     getCommandes(today,today)
     .then((res) => {
       if (res?.data) {
         setBL(res?.data[0].nbr_bl )
       }
     })
     .catch((error) => console.log(error))
    // 3- GET BL Aujourd'hui
    getCommandes(date1,date2)
      .then((res) => {
        if (res?.data) {
          setMonthBl(res?.data[0].nbr_bl )
       
        }
        setLoading(false)
      })
      .catch((error) => console.log(error))

  }, [])

  return (
    <div>
      {/** INFORMATIONS PAIEMENTS CREDIT / RECETTE/ ESPECE */}
      <div className="row">

        {/** COMMANDES */}
        <div className=" col-md-4 col-lg-4 col-xl-4">
          <div className="card bg-blue">
            <div className="card-body">
              {loading ? <SquareLoader /> : (
                <>
                  <h4 className="card-title mb-4 text-white">
                    {t('todayCommercial.nbcmd')}
                  </h4>
                  <div className="d-flex  align-items-center">
                    <h5 className=" mb-0">
                      {bl}
                      <span className="font-weight-normal text-white fs-13">
                        / {t('commandes.today')}
                      </span>
                    </h5>
                    <div className="text-white mt-0 mb-2  ml-auto">
                      <i
                        className="icon icon-layers  fa-3x overflow-hidden mx-auto"
                        width={100}
                        height={100}
                      />
                    </div>
                  </div>
                  <hr style={{ marginTop: '10px', marginBottom: '10px' }} />
                  <p className="text-white  mb-4  ">
                    {t('todayCommercial.nbcmd')} ({moment().format('MMMM')}) :{' '}
                    {blMonth}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        {/** CLIENTS VISITES */}
        <div className=" col-md-4 col-lg-4 col-xl-4">
          <div className="card bg-blue">
            <div className="card-body">
              {loadingVisite ? <SquareLoader /> : (
                <>
                  <h4 className="card-title mb-4 text-white">
                    {t('listecmdvente.clientvisite')}
                  </h4>
                  <div className="d-flex  align-items-center">
                    <h5 className=" mb-0">
                      {clientVisite}
                      <span className="font-weight-normal text-white fs-13">
                        / {t('commandes.today')}
                      </span>
                    </h5>
                    <div className="text-white mt-0 mb-2  ml-auto">
                      <i
                        className="icon icon-people fa-3x overflow-hidden mx-auto"
                        width={100}
                        height={100}
                      />
                    </div>
                  </div>
                  <hr style={{ marginTop: '10px', marginBottom: '10px' }} />
                  <p className="text-white  mb-4  ">
                    {t('listecmdvente.clientvisite') + " (" + moment().format("MMMM") + ") : " + clientMonthV}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        {/** CClients Recrutés*/}
        <div className=" col-md-4 col-lg-4 col-xl-4">
          <div className="card bg-blue">
            <div className="card-body">
              {loadingRecrutement ? <SquareLoader /> : (
                <>
                  <h4 className="card-title mb-4 text-white">
                    {t('listecmdvente.clientrecrute')}
                  </h4>
                  <div className="d-flex  align-items-center">
                    <h5 className=" mb-0">
                      {clientRecruToday}
                      <span className="font-weight-normal text-white fs-13">
                        / {t('commandes.today')}
                      </span>
                    </h5>
                    <div className="text-white mt-0 mb-2  ml-auto">
                      <i
                        className="icon icon-user-following fa-3x overflow-hidden mx-auto"
                        width={100}
                        height={100}
                      />
                    </div>
                  </div>
                  <hr style={{ marginTop: '10px', marginBottom: '10px' }} />
                  <p className="text-white  mb-4  ">
                    {t('listecmdvente.clientrecrute')} : {clientRecru}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>

      </div>
      {error ? <ErrorAlert t={t} /> : <></>}
    </div>
  )
}
