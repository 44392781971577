/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
//TRADUCTION
import { useTranslation } from "react-i18next";
//Error and Loader Components
import Loader from "react-spinners/BarLoader";
import ErrorAlert from "../../../others/ErrorAlert";
//components
import PaginationTable from "../../../components/Pagination/PaginationTable";
//Web Services
import { getHistoriques } from "../../../services/clients/ClientApi";
//Functions Search Auto
import { SearchItems } from "../../../utils/SearchUtils";
import { OverlayTrigger } from "react-bootstrap";
import { popover4 } from "../../../components/Popover/Popovertab";

export default function Historiques() {
  //Code from URL
  let { code } = useParams();
  //USE REF
  const inputEl = useRef("")
  //History
  const history = useHistory();
  //Liste Historiques
  const [listHisto, setListHisto] = useState([]);
  const [searchList, setSearchlist] = useState([])
  const [saveList, setSaveList] = useState([])
  //Search 
  const [search, setSearch] = useState('')
  //Traduction
  const [t, i18n] = useTranslation("global");
  //ERROR
  const [error, setError] = useState(false);
  //PAGINATION
  const [currentPage, setCurrentPage] = useState(1);
  const [stocksPerPage, setStockPerPage] = useState(5);
  //Get current stock
  const indexOfLastPost = currentPage * stocksPerPage;
  const indexOfFirstPost = indexOfLastPost - stocksPerPage;
  //Current Liste 
  const currentListe = search.length < 3 ? listHisto.slice(indexOfFirstPost, indexOfLastPost) : searchList.slice(indexOfFirstPost, indexOfLastPost)
  //Loader
  const [loading, setLoading] = useState(true)



  //SEARCH ITEMS
  const getSearchTerm = () => {
    setSearch(inputEl.current.value)
    if (search !== "") {
      const newList = SearchItems(listHisto, search)
      setSearchlist(newList)
    } else {
      setListHisto(saveList)
    }
  }

  useEffect(() => {
 
      setLoading(true)
      //Liste de l'historique client 
      getHistoriques(code)
        .then((res) => {
          if (res?.data) {
            setListHisto(res.data);
            setSaveList(res.data)
            setLoading(false)

          }
        }).catch((error)=>console.log(error))
    
  }, []);

  return (
    loading ? (<Loader />) : (
      <>
        <div>
          {/**------ Bouton de retour à la page précedente ------------  */}
          <div className="section-title">
            <button className="btn bg-primary " onClick={() => history.goBack()}>
              <i className="fe fe-arrow-left" />
              {t("stock.retour")}
            </button>
          </div>

          {/**-----------------  SECTION TABLEAU HISTORIQUES ------------  */}
          <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
            <div className="panel panel-default active">
              <div className="panel-heading " role="tab" id="headingOne">
                <h4 className="panel-title">
                  <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    <i className="icon icon-grid text-primary mr-2" />      {t("detailClient.historiques")+" "+code} 
                    <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                  </a>
                </h4>
              </div>
              <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                <div className="panel-body">
                  {/**-----------------  SECTION RECHERCHE ------------  */}
                  <div className="search1 search-elements-sm">
                    <div className="form row no-gutters">
                      <div className="form-group  col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 mb-0">
                        <input
                          type="text"
                          ref={inputEl}
                          className="form-control br-tr-md-0 br-br-md-0"
                          id="text1"
                          placeholder={t("stock.search")}
                          value={search}
                          onChange={() => getSearchTerm()}
                        />
                      </div>
                      <div className="col-xl-2 col-lg-2 col-md-2 col-2 mb-0">
                        <button
                          className="br-tl-md-0 br-bl-md-0 btn btn-block btn-success fs-16"
                        >
                          <i className="fe fe-search" />
                        </button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">

            <div className="card-body">
              {/**-----------------   TABLEAU HISTORIQUES BL/Vente/Recouvrement------------  */}
              <div className="table-responsive push">
                <table className="table card-table table-striped tutorial-table" cellSpacing={0} width="100%">
                  <thead>  <tr className="text-center">
                    <th className="priority-3">#</th>
                    <th className="priority-1" width="15%">{t("stock.date")}</th>
                    <th className="priority-1" width="15%">{t("historique.document")}</th>
                    <th className="priority-3" width="15%">{t("detailClient.type")}</th>
                    <th className="priority-3" width="15%">{t("historique.cause")}</th>
                    <th className="priority-1" width="15%">{t("historique.montant")}</th>
                  </tr>
                  </thead>
                  <tbody>

                    {currentListe?.map((items, keys) => (
                      <tr className="text-center">
                        <td className="priority-3 text-center" width="15%">{keys + 1}</td>
                        <td className="text-center priority-1" width="15%">{items.date_visite}</td>
                        <td className="priority-1" width="15%">
                          <a href={"/detail_releve/" + items.visite_code}>
                            <u className='text-primary'>{items.visite_code}</u>
                          </a>
                        </td>
                        <td className="priority-3 text-center">{items.type}</td>
                        <td className="priority-3" width="15%">
                          {items.cause === null || items.cause.length === 0
                            ? t("historique.indisponible")
                            : items.cause}
                        </td>
                        <td className="priority-1" width="15%">
                          {items?.montant !== null
                            ? parseFloat(items.montant).toFixed(3)
                            : "00"}
                          <OverlayTrigger trigger="click" placement="left" overlay={popover4(keys+1, items.type, items.cause,t)}>
                            <i className="icon icon-plus text-success ml-2" />
                          </OverlayTrigger>
                        </td>
                      
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/**-----------------  Pagination ------------  */}
                <div className="col-12"
                  style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                  <PaginationTable
                    postsPerPage={stocksPerPage}
                    totalPosts={currentListe.length}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}                  />
                </div>
              </div>
            </div>
          </div>
          {error ? <ErrorAlert t={t} /> : <></>}
        </div>
      </>
    )
  );
}
