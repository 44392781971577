/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
// -------------- GET COOKIES -----------------
import Cookies from "js-cookie";
//------------- SHARED ( HEADER / Footer / Navbar )
import TopNavbar from "../components/shared/TopNavbar";
import Header from "../components/shared/Header";
import Footer from "../components/shared/Footer";
//-------------- UTILS -------------------
import { isLogin } from "../utils/SessionUtils";

export default function OthersPages(props) {
  const language = Cookies.get("locale");
  const save = language==="fr"?'fr':"ar-tn"
  require('moment/locale/'+save )


  return (
    <div>
      {/* -------------- PAGE ----------------------- */}
      {isLogin("authentification_nomadis")  && (
       
        <div className="page " > 
          <div className="page-main">
            {/* -------- Top-header opened ----- */}
            <Header/>
            {/*-------------- Horizontal-menu  --------------*/}
            <TopNavbar/>
            <div className="pagespace "></div>
            {/*--------------- Horizontal-menu end ---------------*/}
            {/*------------------  App-content opened ---------- */}
            <div className="app-content">
              <div className="container">
                <React.Fragment>{props.children}</React.Fragment>
              </div>
            </div>
          </div>
          {/*-------------- Footer opened -----------------*/}
          <Footer />
          {/*----------------- Footer closed ----------------*/}  
        </div>
    
      )}
    </div>
  );
}
