/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
// --------- TRADUCITON ------------------------
import { useTranslation } from 'react-i18next';
// --------- HISTORY -------------------------
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// --------- ALERTE ----------------------
import { toast, ToastContainer } from 'react-toastify';
// --------- Components --------------------
import Loader from '../../../components/shared/Loader';
import ErrorAlert from '../../../others/ErrorAlert';
import CardCommandes from './CardCommandes';
// --------- WEB SERVICES --------------------
import { getListeCommerciaux, getStatus } from '../../../services/commande/commandeApi';
import { Logout } from '../../../services/Login/SignIn';
// ------------ UTILS -------------------------
import { getCurrentDate } from '../../../utils/DateUtils';
import { SearchItems } from '../../../utils/SearchUtils';
import { getAllVisite, getTourneeComerciaux } from '../../../services/Tournee/TourneeApi';

/**
 * Liste Commercial Aujourd'hui (Commande) rassemble les informations 
 * liée a la journée des commerciaux 
 * @returns 
 * 
 */
export default function ListeCommercialToday() {

    //----------- HISTORY ----------------------
    const history = useHistory();
    // ------------- USE REF -------------------
    const inputEl = useRef("")
    // ---------- LISTE + SEARCH + SAVE ---------
    const [liste, setListe] = useState([])
    const [searchList, setSearchlist] = useState([])
    const [saveList, setSaveList] = useState([])
    //Tournee
    const [tournee, setTournee] = useState([]);
    const [visite, setVisites] = useState([]);
    // ------------  Loading -----------------
    const [loading, setLoading] = useState(true)
    // -----------  Error ------------------
    const [error, setError] = useState(false)
    //-------- All Elements ----------------- 
    const [all, setAll] = useState([])
    //-------------- DATE -------------------
    const today = getCurrentDate();
    // ---------- Traduction -----------------
    const [t, i18n] = useTranslation("global");
    // ---------- Search  value-------------------
    const [search, setSearch] = useState('')


    //---------- Current Liste ---------------
    const currentListe = search.length < 3 ? liste : searchList

    useEffect(() => {
        //TOUT LES INFOMRATIONS DES COMMERCIAUX
            setTimeout(() => {
                setLoading(true)
            }, 500);
            //GET Tournee par comerciaux
            getAllVisite()
                .then((res) => {
                    if (res === false) {
                        setError(true)
                    } else if (res === 401) {
                        Logout().then((res) => {
                            localStorage.removeItem("authentification_nomadis");
                            localStorage.removeItem("url_nomadis");
                        });
                        history.push({ pathname: "/code" });
                    }
                    else if (res?.data?.length !== 0) {
                        const listeVisite = res?.data;
                        setVisites(listeVisite);
                    }
                })
                .catch((error) =>console.log(error));
            
                getTourneeComerciaux()
                .then((res) => {
                    if (res?.data !== null) {
                        setTournee(res?.data);
                    }

                })
                .catch((error) =>console.log(error));
            
                getListeCommerciaux(today)
                .then((res) => {
                    if (res === false) {
                        setError(true)
                    } else if (res === 401) {
                        Logout().then((res) => {
                            localStorage.removeItem("authentification_nomadis");
                            localStorage.removeItem("url_nomadis");
                        });
                        history.push({ pathname: "/code" });
                    } else if (res?.data) {
                        setAll(res.data)
                        setListe(res.data.users)
                        setSaveList(res.data.users)
                    }
                }).catch((error) =>console.log(error));
     
    }, [])

    // Synchronisation
    const synchronisation = () => {
        const code = "all";
        getStatus(code)
            .then((res) => {
                if (res) {
                    toast(t("todayCommercial.synchronisation") + code, { type: "success", position: "bottom-center" })
                }
            }).catch((error) => console.log(error));
    };

    //SEARCH ITEMS
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (search !== "") {
            const newList = SearchItems(liste, search)
            setSearchlist(newList)
        } else {
            setListe(saveList)
        }
    }
    //VALEUR VISITE COMMERCIAL
    const getVisiteCommercial = (code) => {
        for (const [key, value] of Object.entries(all?.visites)) {
            if (key === code) {
                return value.nbr_visite
            }
        }
    }
    //VALEUR CREDIT COMMERCIAL
    const getCreditCommercial = (code) => {
        for (const [key, value] of Object.entries(all?.credit)) {
            if (key === code) {
                return value.credit
            }
        }
    }
    //VALEUR Recouvrement COMMERCIAL
    const getRecouvrementCommercial = (code) => {
        for (const [key, value] of Object.entries(all?.credit)) {
            if (key === code) {
                return value.recouvrement
            }
        }
    }
    // valeur congee commercial
    const getCongeCommercial = (code) => {
      
        if(all?.conges !== undefined){
            for (const [key, value] of Object.entries(all?.conges)) {
                if (key === code) {
                    return 1
                }
            }
        }
        
    }
    //VALEUR Commandes COMMERCIAL
    const getCommandesCommercial = (code) => {
        for (const [key, value] of Object.entries(all?.ventes)) {
            if (key === code) {
                return value.chiffre_cmd
            }
        }
    }
    //VALEUR Nb COMMANDES COMMERCIAL
    const getNBCommandesCommercial = (code) => {
        for (const [key, value] of Object.entries(all?.ventes)) {
            if (key === code) {
                return value.nbr_cmd
            }
        }
    }

    return (
      <div>
        {all?.length === 0 && loading ? (
          <Loader />
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="panel-group"
                  id="accordion1"
                  role="tablist"
                  aria-multiselectable="true"
                  style={{ marginBottom: "10px" }}
                >
                  <div className="panel panel-default active">
                    <div className="panel-heading " role="tab" id="headingOne">
                      <h4 className="panel-title">
                        <button
                          className="btn bg-secondary btn-square h-7  float-right"
                          onClick={() => synchronisation()}
                        >
                          <i className="ion-ios7-loop-strong" />
                        </button>
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion1"
                          href="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <h6 style={{ fontSize: "12px" }}>
                            {" "}
                            <i className="icon icon-grid text-primary mr-2 " />
                            {t("todayCommercial.entetCmd")}
                          </h6>
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapseOne"
                      className="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="headingOne"
                    >
                      <div className="panel-body">
                        {/** ---------------- SEARCH FORM ------------------  */}
                        {/**  ----------------     Recherche FORM  ------------------------------- */}
                        <div className="search1 search-elements-sm">
                          <div className="form row no-gutters" dir={i18n.dir()}>
                            <div className="form-group  col-xl-10 col-lg-10 col-md-2 col-2 mb-0">
                              <input
                                type="text"
                                ref={inputEl}
                                className="form-control br-tr-md-0 br-br-md-0"
                                id="text1"
                                value={search}
                                onChange={() => getSearchTerm()}
                                placeholder={t("stock.search")}
                              />
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-2  col-2 mb-0">
                              <button
                                onClick={() => getSearchTerm()}
                                className="br-tl-md-0 br-bl-md-0 btn btn-block btn-primary fs-16"
                                dir={i18n.dir("fr")}
                              >
                                <i className="ion-ios7-search-strong" />
                              </button>
                            </div>
                          </div>
                        </div>
                        {/** -----------------  Explications Bouttons -------------------------------*/}
                        <div className="row push container">
                          <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                            <div className="d-flex align-items-center mb-3 mt-3 mr-2">
                              <div className="w-4 h-4 bg-lime  mr-2" />
                              <div>
                                <strong className="mr-1">
                                  {" "}
                                  {t("todayCommercial.commercialsynch")}
                                </strong>
                                <br />
                              </div>

                              <div className="w-4 h-4 bg-secondary  mr-2" />
                              <div>
                                <strong className="mr-1">
                                  {t("todayCommercial.toutsynch")}{" "}
                                </strong>
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/** ------------ CARD (qte / total / nbr produits) -----  */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {/** TOAST CONTAINER : pour l'affichage des alerte (toast) */}
              <ToastContainer />
              {currentListe?.map((items, keys) => (
                // -------------- Card Commandes -----------------------------
                <CardCommandes
                  key={keys}
                  keys={keys}
                  items={items}
                  t={t}
                  i18n={i18n}
                  getRecouvrementCommercial={getRecouvrementCommercial}
                  getCommandesCommercial={getCommandesCommercial}
                  getCreditCommercial={getCreditCommercial}
                  getNBCommandesCommercial={getNBCommandesCommercial}
                  getVisiteCommercial={getVisiteCommercial}
                  getCongeCommercial={getCongeCommercial}
                  tournee={tournee}
                  visite={visite}
                />
              ))}
            </div>
          </div>
        )}
        {/** --------------- CRASH APPLICATION : ALERTE -------------------- */}
        {error ? <ErrorAlert t={t} /> : <></>}
      </div>
    );
}
