
//Verif si un objet est vide
export function isObjEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }
  
    return true;
  }

//First Letter UpperCase
export function jsUcfirst(string) 
{
    return string.charAt(0).toUpperCase() + string.slice(1);
} 