/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { isLogin } from '../../utils/SessionUtils';
import { weatherIcon } from '../../layouts/WeatherIcons'
import {  getTemperatureApi2 } from '../../services/Access/Access';
import { calCelsius } from '../../utils/Temperature';
import moment from 'moment';

export default function HeaderWeather() {
    const [t, i18n] = useTranslation("global");
    const language = Cookies.get("locale");
    const [weather, setWeather] = useState([])
    const [temperature, settemperature] = useState(0)
    const [tempmax, settempmax] = useState(0)
    const [tempmin, settempmin] = useState(0)
    const [humidity, setHumudity] = useState(0)

    const [icon, setIcon] = useState("")
    const [description, setdescription] = useState("")
    const data = isLogin("authentification_nomadis")
        ? localStorage.getItem("authentification_nomadis")
        : [];
    const infoUsers = data.length !== 0 ? JSON.parse(data) : {};
    { language === "ar" ? require('moment/locale/ar-ly') : require('moment/locale/' + language) }
    const [loading, setLoading] = useState(true)


    const get_WeatherIcon = (rangeId) => {
        switch (true) {
            case rangeId >= 200 && rangeId <= 232:
                setIcon(weatherIcon.Thunderstorm)
                break;
            case rangeId >= 300 && rangeId <= 321:
                setIcon(weatherIcon.Dizzle)
                break;
            case rangeId >= 500 && rangeId <= 531:
                setIcon(weatherIcon.Rain)
                break;
            case rangeId >= 600 && rangeId <= 622:
                setIcon(weatherIcon.Snow)
                break;
            case rangeId >= 701 && rangeId <= 781:
                setIcon(weatherIcon.Atmosphere)
                break;
            case rangeId === 800:
                setIcon(weatherIcon.Clear)
                break;
            case rangeId >= 801 && rangeId <= 804:
                setIcon(weatherIcon.Clouds)
                break;
            default: setIcon(weatherIcon.Clouds)
        }
    }
    useEffect(() => {
        navigator.geolocation.watchPosition(function(position) {
            console.log(position);
         getTemperatureApi2(position.coords.latitude, position.coords.longitude,language)
             .then((res) => {
                 if (res.data !== null) {
                     //console.log(res.data)
                     setWeather(res.data)
                     settemperature(Math.round(calCelsius(res.data.main.temp)))
                     setHumudity(res.data.main.humidity)
                     settempmax(Math.round(calCelsius(res.data.main.temp_max)))
                     settempmin(Math.round(calCelsius(res.data.main.temp_min)))
                     setdescription(res.data.weather[0].description)
                     get_WeatherIcon(res.data.weather[0].icon)
                 }
                }).catch((error) => console.log(error))
        setLoading(false)})
    }, [])
    return (
        <div>

            <div className="row">
                <div className="col-xl-6 col-md-6 col-lg-6 col-sm-12 col-12 heigthmobile" >
                    <div className="banner  mt-0 text-center card-design2" >
                        <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-2 ">
                            <img
                                src="../assets/images/svg/welcome.svg"
                                alt="image2"
                                max-width="40%"
                            />
                        </div>
                        <div className=" col-xl-8 col-lg-8 col-md-8  col-sm-8 col-12 text-white float-left mt-4">
                            <h3 className="mb-1"> {t("widget.title")} ,
                                {infoUsers?.user.nom !== undefined ? infoUsers?.user.nom : ""}
                            </h3>
                            {language === "ar" ? (
                                <p className="mb-0 fs-16">
                                    {moment().format("LLLL")}
                                </p>
                            ) : (
                                <p className="mb-0 fs-16">
                                    {moment().format("LLLL")}
                                </p>
                            )}
                        </div>
                        </div>
                     
                    </div>
                </div>
             
                  
                <div className="col-xl-6  col-md-6 col-lg-6 col-sm-12 col-12">
                    <div className="card card-design" >
                        <div className="card-body container">
                            <h6 className="text-center"><i className={`wi ${icon} fa-3x mb-2 display-1 text-white `} /></h6>
                            <h6  className="text-center text-white"> 
                            {t("suivi.aujourdhui") + " " + description + " ," + weather?.name + " , " + weather?.sys?.country}
                            </h6>
                            <div className="row mb-0">
                                <div className="col-sm-3 col-3 border-rightweather  ">
                                    <div className="description-block">
                                        <h5 className=" text-white">
                                            {temperature + "°C"} <i className="wi wi-thermometer text-white ml-1" />
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-sm-3 col-3 border-rightweather  ">
                                    <div className="description-block">
                                        <h5 className=" text-white">
                                            {tempmin + "Min"} <i className="fe fe-arrow-down text-white ml-1" />
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-sm-3 col-3 border-rightweather  ">
                                    <div className="description-block">
                                        <h5 className=" text-white">
                                            {tempmax + "Max"} <i className="fe fe-arrow-up text-white ml-1" />
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-sm-3  col-3  ">
                                    <div className="description-block">
                                        <h5 className=" text-white">
                                            {humidity + "%"} <i className="wi wi-wi wi-humidity text-white ml-1" />
                                        </h5>
                                    </div>
                                </div>
                            </div>



                        </div>


                    </div>
                </div>

            </div>


        </div>
    )
}
