import React from "react";
import Footer from "../components/shared/Footer";
import HeaderEmptyLayout from "../components/shared/HeaderEmptyLayout";
import Cookies from "js-cookie";

function EmptyLayout(props) {
  const language = Cookies.get("locale");
  const save = language==="fr"?'fr':"ar-tn"
  require('moment/locale/'+save )
  return (
    <>
      <HeaderEmptyLayout />
      <React.Fragment>{props.children}</React.Fragment>
      <Footer/>
    </>
  );
}

export default EmptyLayout;
