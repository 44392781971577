import React from "react";
import Loader from "../components/shared/Loader";

function PageNotFound() {
  return (
    <div className="h-100vh">
      {/*Loader*/}
      <Loader></Loader>
      <div className="page">
        {/* PAGE-CONTENT OPEN */}
        <div className="page-content error-page">
          <div className="container text-center">
            <img
              src="../assets/images/svg/error.svg"
              alt="error"
              className="w-50 floating"
            />
            <h1 className="h2 mt-4 mb-5">Oops! Page Indisponible</h1>
            <a className="btn btn-outline-primary" href="/">
              Retour accueil
            </a>
          </div>
        </div>
        {/* PAGE-CONTENT OPEN CLOSED */}
      </div>
    </div>
  );
}

export default PageNotFound;
