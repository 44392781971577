/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { toast, ToastContainer } from 'react-toastify'
//------------------ Components ------------------
import Loader from '../../../components/shared/Loader'
//--------------- WEB SERVICES -------------------
import { addClient, getDelegations, getListActivity, getListCategorie, GetListeGouvernorats, getListeLocalite, GetListeRegions, getListeRoutes, getListeSecteurs, getListeZone, getSousSociete } from '../../../services/clients/ClientApi'
import { Logout } from '../../../services/Login/SignIn'
//---------- PACKAGE ---------------
import ReactJsAlert from "reactjs-alert";
import ErrorCnx from '../../../others/ErrorCnx'

export default function AjouterClient() {
      //------ History -----------------
    const history = useHistory();
    //--------- Liste SSociete -------------
    const [sousSociete, SetSousSociete] = useState([])
    //-------- Liste Gouvernorat ----------
    const [gouvernorat, setGouvernorat] = useState([])
    //-------- Liste Zone -----------------------
    const [zone, setZone] = useState([])
    //-------- Liste Localité ----------
    const [localite, setLocalite] = useState([])
    //-------- Secteurs -------------
    const [secteur, setSecteur] = useState([])
    //----------ACTIVITE ------------------
    const [activite, setActivite] = useState([])
    //--------- Catégorie -----------------
    const [categorie, setCategorie] = useState([])
    //------------ Regions ------------------
    const [regions, setRegions] = useState([])
    //------------ Regions ------------------
    const [routes, setRoutes] = useState([])
    //------------ DELEGATIONS ------------
    const [delegations, setDelegations] = useState([])
    //------------ TRADUCTION --------------
    const [t, i18n] = useTranslation("global");
    //* ------------ FORMULAIRE ------------------ */
    /**Informations generales */
    const [email, setEmail] = useState("")
    const [code, setcode] = useState("")
    const [codeabarre, setcodeabarre] = useState("")
    const [nom, setnom] = useState("")
    const [prenom, setprenom] = useState("")
    const [cin, setcin] = useState("")
    const [fix, setfix] = useState("")
    const [mobile, setmobile] = useState("")
    const [actif, setactif] = useState(-1)
    const [remise, setremise] = useState(-1)
    /** SECTEURS */
    const [magasin, setmagasin] = useState("")
    const [codeactivite, setcodeactivite] = useState("")
    const [codecategorie, setcodecategorie] = useState("")
    const [coderp, setcoderp] = useState("")
    const [description, setdescription] = useState("")
    const [descriptionRc, setdescriptionRc] = useState("")
    const [regimefiscale, setregimefiscale] = useState("")
    const [matriculeF, setmatriculeF] = useState("")
    const [RIB, setRIB] = useState("")
    const [ticket, setticket] = useState("")
    /** Localisation */
    const [adressefacturation, setadressefacturation] = useState("")
    const [logitude, setlogitude] = useState("")
    const [latitude, setLatitude] = useState("")
    const [Coderoute, setCodeRoute] = useState("")
    const [coderegion, setCodeRegion] = useState("")
    const [codeZone, setCodeZone] = useState("")
    const [codeGouvernorat, setCodeGouvernorat] = useState("")
    const [codedelegation, setCodeDelegation] = useState("")
    const [codeLocalite, setcodeLocalite] = useState("")
    const codeStatus = "Prospect"
    /** Autroisation et Plafond */
    const [autCredit, setAutCredit] = useState(0)
    const [plafondCredit, setPlafondCredit] = useState("")
    const [authCheque, setAuthCheque] = useState(0)
    const [plafondCheque, setPlafondCheque] = useState("")
    const [authTraite, setAuthTraite] = useState(0)
    const [plafondTraite, setPlafondTraite] = useState("")
    const [passager, setPassager] = useState(0)
    const [codeTarif, setCodeTarif] = useState("")
    const [acceMetrage, setAcceMetrage] = useState("")
    const [codeSousSociete, setCodeSousSociete] = useState("")
    //--------------- ERROR --------------------
    const [errorCnx,setErrorCnx] = useState(false)
    //------------ Loading -----------------
    const [loading, setLoading] = useState(true)
    //----------- Error -----------------
  //LOGOUT WEB SERVICE SUPP L ETAT ISCONNECTED 
  
  const SeDeconnecter = () => {
    Logout()
      .then((res) => {
        localStorage.removeItem("authentification_nomadis");
        localStorage.removeItem("url_nomadis");
      });
    history.push({
      pathname: "/code",
    });
  };

    useEffect(() => {
        // GET Sous societe 
        getSousSociete()
            .then((result) => {
                if(result===422){
                    toast(t('messages.plusconnecte'),{type:"error"})
                }
                else if (result===undefined){
                    setErrorCnx(true)
                    
                }
                else if (result.data !== null) {
                    SetSousSociete(result.data)
                }
            }).catch((err) => {
                console.log(err)
                setErrorCnx(true)
            });
        // GET Liste activité
        getListActivity()
            .then((result) => {
                if (result.data !== null) {
                    setActivite(result.data)
                }
            }).catch((err) => {
               

            });
        // GET Liste Catégorie
        getListCategorie()
            .then((result) => {
                if (result.data !== null) {
                    setCategorie(result.data)
                }
            }).catch((err) => {
               

            });
        // GET Liste zone
        getListeZone()
            .then((result) => {
                if (result.data !== null) {
                    setZone(result.data)
                }
            }).catch((err) => {
               

            });
        // GET Liste Region
        GetListeRegions()
            .then((result) => {
                if (result.data !== null) {
                    setRegions(result.data)
                }
            }).catch((err) => {
               

            });
        // GET Liste Gouvernorats
        GetListeGouvernorats().then((result) => {
            if (result.data !== null) {
                setGouvernorat(result.data)
            }
        }).catch((err) => {
           

        });
        //GET DELEGATIONS
        getDelegations().then((result) => {
            if (result.data !== null) {
                setDelegations(result.data)
            }
        }).catch((err) => {
           

        });
        getListeSecteurs().then((result) => {
            if (result.data !== null) {
                setSecteur(result.data)
            }
        }).catch((err) => {
           

        });
        getListeRoutes().then((result) => {
            if (result.data !== null) {
                setRoutes(result.data)
            }
        }).catch((err) => {
           

        });
        setLoading(false)
    }, [])

    /**
     * ON SELECT CODE DELEGATIONS 
     * @param {event} e 
     */
    const onSelectDelegations = (e) => {
        setCodeDelegation(e.target.value)
        getListeLocalite()
            .then((result) => {
                if (result.data !== null) {
                    const total = result?.data.filter((element) => element.delegation_code === e.target.value);
                    setLocalite(total)
                }
            }).catch((err) => {

            });
    }
    /**
     * ADD CLIENT 
     */
    const Ajouter_clients = () => {

        if (code !== "" && codeabarre !== "" && nom !== "" && prenom !== "" && mobile !== "" && actif !== -1 && remise !== "" && codeactivite !== "" && codecategorie !== "" && adressefacturation !== "" && Coderoute !== "" && coderegion !== ""
            && codeZone !== "" && codeGouvernorat !== "" && codeSousSociete !== "") {
            const data = {
                isactif: actif,
                remise_client: parseInt(remise),
                magasin: magasin,
                activite_code: codeactivite,
                categoriecommercial_code: codecategorie,
                adresse_facturation: adressefacturation,
                routing_code: Coderoute,
                region: coderegion,
                zone: codeZone,
                gouvernorat: codeGouvernorat,
                statut: codeStatus,
                autorisation: autCredit,
                autorisation_cheque: authCheque,
                autorisation_traite: authTraite,
                passager: passager,
                soussociete_code: codeSousSociete,
                mobile: mobile,
                plafond_credit: plafondCredit,
                plafond_credit_cheque: plafondCheque,
                plafond_credit_traie: plafondTraite,
                tarification: codeTarif,
                code: code,
                code_a_barre: codeabarre,
                nom: nom,
                prenom: prenom,
                cin: cin,
                email: email,
                fixe: fix,
                longitude: logitude,
                latitude: latitude,
                description: description,
                code_erp: coderp,
                acces_metrage: acceMetrage,
                rib: RIB,
                regime_fiscale: regimefiscale,
                ticket_cadeaux: ticket,
                rc: descriptionRc
            }
            console.log(data)
            //ADD Client
            addClient(data).then((result) => {
                console.log(result.data.success)
                if (result.data.success === false) {
                    toast(t('messages.codeexistant'), { position: "bottom-center", type: "error" })
                } else if (result.data.success === true) {
                    toast(t('messages.clientajouter'), { position: "bottom-center", type: "success" })
                }
            }).catch((err) => {
                console.log(err)
            });
        } else if (code === "") {
            toast(t('messages.codeclient'), { position: "bottom-center", type: "warning" })
        } else if (codeabarre === "") {
            toast(t('messages.codebarreclient'), { position: "bottom-center", type: "warning" })
        } else if (nom === "" && prenom === "") {
            toast(t('messages.saisienomPrenom'), { position: "bottom-center", type: "warning" })
        } else if (mobile === "") {
            toast(t('messages.saisieMobile'), { position: "bottom-center", type: "warning" })
        } else if (actif === -1) {
            toast(t('messages.clientactif'), { position: "bottom-center", type: "warning" })
        } else if (remise === "") {
            toast(t('messages.clientremise'), { position: "bottom-center", type: "warning" })
        } else if (codeactivite === "") {
            toast(t('messages.activiteclient'), { position: "bottom-center", type: "warning" })
        } else if (codecategorie === "") {
            toast(t('messages.codecategorie'), { position: "bottom-center", type: "warning" })
        } else if (adressefacturation === "") {
            toast(t('messages.adressefacturation'), { position: "bottom-center", type: "warning" })
        }
        else if (Coderoute === "") {
            toast(t('messages.Coderoute'), { position: "bottom-center", type: "warning" })
        } else if (coderegion === "") {
            toast(t('messages.coderegion'), { position: "bottom-center", type: "warning" })
        }
        else if (codeZone === "") {
            toast(t('messages.codeZone'), { position: "bottom-center", type: "warning" })
        } else if (codeGouvernorat === "") {
            toast(t('messages.codeGouvernorat'), { position: "bottom-center", type: "warning" })
        } else if (codeSousSociete === "") {
            toast(t('messages.codeSousSociete'), { position: "bottom-center", type: "warning" })
        }
    }
    return (
        <div>
            {loading ? <Loader /> : (
                <div className="row">
                    <div className="col-md-12">
                        <div className="card overflow-hidden">
                            <div className="card-header">
                                <h3 className="card-title mt-4">
                                    <i className="icon icon-people mr-1" /> {t("client.ajouterClient")}
                                </h3>
                            </div>
                            <div>
                                <div className="panel-group1" id="accordion11">
                                    <div className="panel panel-default  mb-4">
                                        <div className="panel-heading1 bg-primary ">
                                            <h4 className="panel-title1">
                                                <a className="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion11" href="#collapseFour1" aria-expanded="false">
                                                    <i className="fe fe-arrow-right mr-2" />{t('client.infgenerale')}</a>
                                            </h4>
                                        </div>
                                        <ToastContainer />
                                        {/* ------------------------------------------- PARTIE 1 : INFORMATIONS GENERALES  ---------------------------------------------------- */}
                                        <div id="collapseFour1" className="panel-collapse collapse" role="tabpanel" aria-expanded="false">
                                            <div className="panel-body border">
                                                {/* ------------------------------------------- INFORMATIONS GENERALES ---------------------------------------------------- */}
                                                <div className="row">
                                                    <div className="col-12 col-sm-6 col-lg-6 col-xl-6">
                                                        <label className="form-label"> {t("client.codeclient") + " : "} <span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control" placeholder="Code Client " style={{ background: "#4f86ff17" }}
                                                            value={code} onChange={(e) => setcode(e.target.value)} />
                                                        <label className="form-label mt-1">  {t("stock.codeabarre") + " : "} <span className="text-danger">*</span> </label>
                                                        <input type="text" className="form-control" placeholder="Code à barre "
                                                            value={codeabarre} onChange={(e) => setcodeabarre(e.target.value)} style={{ background: "#4f86ff17" }} />
                                                        <label className="form-label mt-1">{t("client.nom") + " : "}  <span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control" placeholder="Nom Client " style={{ background: "#4f86ff17" }}
                                                            value={nom} onChange={(e) => setnom(e.target.value)} />
                                                        <label className="form-label mt-1"> {t("client.prenom") + " : "} <span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control" placeholder="Prénom Client " style={{ background: "#4f86ff17" }}
                                                            value={prenom} onChange={(e) => setprenom(e.target.value)} />
                                                        <label className="form-label mt-1"> {t("client.cin") + " : "} </label>
                                                        <input type="text" className="form-control" placeholder="CIN "
                                                            value={cin} onChange={(e) => setcin(e.target.value)} />
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-6 col-xl-6">
                                                        <label className="form-label"> {t("login.email") + " : "} </label>
                                                        <input type="email" className="form-control" placeholder="E-mail "
                                                            value={email} onChange={(e) => setEmail(e.target.value)} />
                                                        <label className="form-label mt-1"> {t("client.fixe") + " : "} </label>
                                                        <input type="number" className="form-control" placeholder="FIXE "
                                                            value={fix} onChange={(e) => setfix(e.target.value)} />
                                                        <label className="form-label mt-1"> {t("detailClient.mobile") + " : "}  <span className="text-danger">*</span> </label>
                                                        <input type="number" className="form-control" placeholder="Mobile " style={{ background: "#4f86ff17" }}
                                                            value={mobile} onChange={(e) => setmobile(e.target.value)} />
                                                        <label className="form-label">{t("detailClient.actif") + " : "}   <span className="text-danger">*</span></label>
                                                        <select name="country" className="form-control select2 custom-select"
                                                            value={actif} onChange={(e) => setactif(e.target.value)} style={{ background: "#4f86ff17" }}>
                                                            <option value={-1}> ---- {t('detailClient.actif')} ---- </option>
                                                            <option value={0}>{t('detailClient.inactif')}</option>
                                                            <option value={1}>{t('detailClient.actif')}</option>
                                                        </select>
                                                        <label className="form-label mt-1"> {t('detailClient.remiseclient') + ": "}  <span className="text-danger">*</span> </label>
                                                        <select name="country" className="form-control select2 custom-select" style={{ background: "#4f86ff17" }}
                                                            value={remise} onChange={(e) => setremise(e.target.value)}>
                                                            <option value={-1}> ---- {t('detailClient.remiseclient')} ---- </option>
                                                            <option value={0}>{t('select.non')}</option>
                                                            <option value={1}>{t('select.oui')}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* ------------------------------------------- PARTIE 2 : SECTEURS  ---------------------------------------------------- */}
                                    <div className="panel panel-default mb-4">
                                        <div className="panel-heading1  bg-primary">
                                            <h4 className="panel-title1">
                                                <a className="accordion-toggle mb-0 collapsed" data-toggle="collapse" data-parent="#accordion11" href="#collapseFive2" aria-expanded="false"><i className="fe fe-arrow-right mr-2" />{t('client.secteur')}</a>
                                            </h4>
                                        </div>
                                        <div id="collapseFive2" className="panel-collapse collapse" role="tabpanel" aria-expanded="false">
                                            <div className="panel-body border">
                                                {/* ------------------------------------------- SECTEURS  ---------------------------------------------------- */}
                                                <div className="row">
                                                    <div className="col-12 col-sm-6 col-lg-6 col-xl-6">
                                                        <label className="form-label"> {t('releve.Magasin') + " : "} </label>
                                                        <input type="text" className="form-control" placeholder="Magasin "
                                                            value={magasin} onChange={(e) => setmagasin(e.target.value)} />
                                                        <label className="form-label mt-1">  {t('detailClient.activite') + " : "}  <span className="text-danger">*</span></label>
                                                        <select name="country" id="select-countries" class="form-control select2 custom-select"
                                                            value={codeactivite} onChange={(e) => setcodeactivite(e.target.value)} style={{ background: "#4f86ff17" }}>
                                                            <option value={""}> ---- {t('detailClient.activite')} ---- </option>
                                                            {activite?.map((items, keys) => (
                                                                <option key={keys} value={items.code}>{items.libelle}</option>
                                                            ))}
                                                        </select>
                                                        <label className="form-label mt-1"> {t('client.categoriecommercial') + " : "}   <span className="text-danger">*</span></label>
                                                        <select name="country" className="form-control select2 custom-select"
                                                            value={codecategorie} onChange={(e) => setcodecategorie(e.target.value)} style={{ background: "#4f86ff17" }}>
                                                            <option value={-1}> ---- {t('client.categoriecommercial')}  ---- </option>
                                                            {categorie?.map((items, keys) => (
                                                                <option key={keys} value={items.code}>{items.libelle} </option>
                                                            ))}
                                                        </select>
                                                        <label className="form-label mt-1"> {t('client.codeerp') + " : "}  </label>
                                                        <input type="text" className="form-control" placeholder="Code ERP "
                                                            value={coderp} onChange={(e) => setcoderp(e.target.value)} />
                                                        <label className="form-label mt-1"> {t('client.description') + " : "}</label>
                                                        <input type="text" className="form-control" placeholder="description  "
                                                            value={description} onChange={(e) => setdescription(e.target.value)} />
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-6 col-xl-6">
                                                        <label className="form-label ">{t('client.descriptionrc') + " : "} </label>
                                                        <input type="text" className="form-control" placeholder="description RC "
                                                            value={descriptionRc} onChange={(e) => setdescriptionRc(e.target.value)} />
                                                        <label className="form-label mt-1"> {t('client.regimefiscale') + " : "} </label>
                                                        <select className="form-control select2 custom-select"
                                                            value={regimefiscale} onChange={(e) => setregimefiscale(e.target.value)}>
                                                            <option value={-1}> ---- {t('client.regimefiscale')} ---- </option>
                                                            <option value={"Réel"}>{t('client.reel')}</option>
                                                            <option value={"Forfaitaire"}>{t('client.forfaitaire')}</option>
                                                        </select>

                                                        <label className="form-label mt-1"> {t('client.mf')} </label>
                                                        <input type="text" className="form-control" placeholder="Matricule Fiscale "
                                                            value={matriculeF} onChange={(e) => setmatriculeF(e.target.value)} />
                                                        <label className="form-label mt-1">{t('client.rib') + " : "} </label>
                                                        <input type="text" className="form-control" placeholder="RIB "
                                                            value={RIB} onChange={(e) => setRIB(e.target.value)} />
                                                        <label className="form-label">{t('client.ticketcadeau') + " : "}</label>
                                                        <select name="country" className="form-control select2 custom-select"
                                                            value={ticket} onChange={(e) => setticket(e.target.value)}
                                                        >
                                                            <option value={-1}> ---- {t('client.ticketcadeau')} ---- </option>
                                                            <option value={0}>{t("select.non")}</option>
                                                            <option value={1}>{t("select.oui")}</option>
                                                        </select>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* ------------------------------------------- PARTIE 3 : LOCALISATION  ---------------------------------------------------- */}
                                    <div className="panel panel-default mb-4">
                                        <div className="panel-heading1  bg-primary">
                                            <h4 className="panel-title1">
                                                <a className="accordion-toggle mb-0 collapsed" data-toggle="collapse" data-parent="#accordion11" href="#collapseFive3" aria-expanded="false"><i className="fe fe-arrow-right mr-2" />{t('detailClient.localisation')}</a>
                                            </h4>
                                        </div>
                                        <div id="collapseFive3" className="panel-collapse collapse" role="tabpanel" aria-expanded="false">
                                            <div className="panel-body border">
                                                {/* ------------------------------------------- Localisation  ---------------------------------------------------- */}
                                                <div className="row">
                                                    <div className="col-12 col-sm-6 col-lg-6 col-xl-6">
                                                        <label className="form-label"> {t('client.adressefacture') + " : "}  <span className="text-danger">*</span> </label>
                                                        <input type="text" className="form-control" placeholder="Adresse de Facturation " style={{ background: "#4f86ff17" }}
                                                            value={adressefacturation} onChange={(e) => setadressefacturation(e.target.value)}
                                                        />

                                                        <div className="row">
                                                            <div className="col-6 col-sm-6 col-lg-6 col-xl-6">
                                                                <label className="form-label mt-1"> {t('detailClient.longitude') + " : "}  </label>
                                                                <input type="text" className="form-control" placeholder="Longitude "
                                                                    value={logitude} onChange={(e) => setlogitude(e.target.value)} />
                                                            </div>
                                                            <div className="col-6 col-sm-6 col-lg-6 col-xl-6">
                                                                <label className="form-label mt-1"> {t('detailClient.latitude') + " : "} </label>
                                                                <input type="text" className="form-control" placeholder="Latitude "
                                                                    value={latitude} onChange={(e) => setLatitude(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <label className="form-label mt-1"> {t('client.route') + " : "} <span className="text-danger">*</span> </label>
                                                        <select name="country" className="form-control select2 custom-select" style={{ background: "#4f86ff17" }}
                                                            value={Coderoute} onChange={(e) => setCodeRoute(e.target.value)}>
                                                            <option value={""}> ---- {t('client.route')} ---- </option>
                                                            {routes.map((items, key) => (
                                                                <option key={key} value={items.code}>{items.code}</option>

                                                            ))}
                                                        </select>
                                                        <label className="form-label mt-1"> {t('detailClient.region') + " : "} <span className="text-danger">*</span> </label>
                                                        <select name="country" className="form-control select2 custom-select" style={{ background: "#4f86ff17" }}
                                                            value={coderegion} onChange={(e) => setCodeRegion(e.target.value)} >
                                                            <option value={""}> ---- {t('detailClient.region')} ---- </option>
                                                            {regions.map((items, keys) => (
                                                                <option value={items.code} key={keys}>{items.libelle} </option>
                                                            ))}
                                                        </select>

                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-6 col-xl-6">
                                                        <label className="form-label "> {t('detailClient.zone') + " : "}:  <span className="text-danger">*</span> </label>
                                                        <select name="country" className="form-control select2 custom-select"
                                                            value={codeZone} onChange={(e) => setCodeZone(e.target.value)} style={{ background: "#4f86ff17" }}>
                                                            <option value={""}> ---- {t('detailClient.zone')} ---- </option>
                                                            {zone?.map((items, keys) => (
                                                                <option value={items.code} key={keys}>{items.libelle} </option>
                                                            ))}
                                                        </select>
                                                        <label className="form-label mt-1"> {t('client.gouvernorat') + " : "} <span className="text-danger">*</span> </label>
                                                        <select name="country" class="form-control select2 custom-select"
                                                            value={codeGouvernorat} onChange={(e) => setCodeGouvernorat(e.target.value)} style={{ background: "#4f86ff17" }}>
                                                            <option value={""}> ----  {t('client.gouvernorat')}  ---- </option>
                                                            {gouvernorat.map((items, keys) => (
                                                                <option value={items.code} key={keys}>{items.libelle} </option>
                                                            ))}
                                                        </select>
                                                        <label className="form-label mt-1">  {t('client.delegation') + " : "} : </label>
                                                        <select name="country" className="form-control select2 custom-select"
                                                            value={codedelegation} onChange={(e) => onSelectDelegations(e)}>
                                                            <option value={""}> ----  {t('client.delegation')}  ---- </option>
                                                            {delegations?.map((items, keys) => (
                                                                <option value={items.code} key={keys}>{items.libelle} </option>
                                                            ))}
                                                        </select>

                                                        <label className="form-label"> {t('client.localite') + " : "} </label>
                                                        <select name="country" className="form-control select2 custom-select"
                                                            value={codeLocalite} onChange={(e) => setcodeLocalite(e.target.value)}>
                                                            <option value={""}> ----  {t('client.localite') + " : "}  ---- </option>
                                                            {localite?.map((items, keys) => (
                                                                <option key={keys} value={items.code}>{items.libelle}</option>
                                                            ))}
                                                        </select>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    {/* ------------------------------------------- PARTIE 4 : AUTORISATION ET PLAFOND  ---------------------------------------------------- */}
                                    <div className="panel panel-default mb-4">
                                        <div className="panel-heading1  bg-primary">
                                            <h4 className="panel-title1">
                                                <a className="accordion-toggle mb-0 collapsed" data-toggle="collapse" data-parent="#accordion11" href="#collapseFive4" aria-expanded="false"><i className="fe fe-arrow-right mr-2" />{t('client.autetplafond')}</a>
                                            </h4>
                                        </div>
                                        <div id="collapseFive4" className="panel-collapse collapse" role="tabpanel" aria-expanded="false">
                                            <div className="panel-body border">
                                                {/* ------------------------------------------- AUTROISATION ET PLAFOND  ---------------------------------------------------- */}
                                                <div className="row">
                                                    <div className="col-12 col-sm-6 col-lg-6 col-xl-6">
                                                        <label className="form-label">  {t('detailClient.autorisationcredit') + " : "}   <span className="text-danger">*</span> </label>
                                                        <select
                                                            className="form-control select2 custom-select"
                                                            value={autCredit} onChange={(e) => setAutCredit(e.target.value)} style={{ background: "#4f86ff17" }}
                                                        >
                                                            <option value={0}>{t("select.non")}</option>
                                                            <option value={1}>{t("select.oui")}</option>
                                                        </select>
                                                        {autCredit === "1" ? (
                                                            <>
                                                                <label className="form-label mt-1"> {t('detailClient.plafondcredit') + " : "} </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control text-primary mt-1"
                                                                    placeholder={t("detailClient.plafondcredit")}
                                                                    value={plafondCredit}
                                                                    onChange={(e) =>
                                                                        setPlafondCredit(e.target.value)}
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <label className="form-label mt-1"> {t('detailClient.plafondcredit') + " : "}  </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control text-primary"
                                                                    id="exampleInputname"
                                                                    placeholder={t("detailClient.plafondcredit")}
                                                                    value={plafondCredit}
                                                                    disabled
                                                                />
                                                            </>
                                                        )}

                                                        <label className="form-label mt-1">{t('detailClient.autorisationcheque') + " : "}    <span className="text-danger">*</span></label>
                                                        <select
                                                            className="form-control select2 custom-select"
                                                            value={authCheque} onChange={(e) => setAuthCheque(e.target.value)}
                                                            style={{ background: "#4f86ff17" }}
                                                        >
                                                            <option value={0}>{t("select.non")}</option>
                                                            <option value={1}>{t("select.oui")}</option>
                                                        </select>
                                                        {authCheque === "1" ? (
                                                            <>
                                                                <label className="form-label mt-1"> {t('detailClient.plafondcheque') + " : "}  </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control text-primary mt-1"
                                                                    placeholder={t("detailClient.plafondcredit")}
                                                                    value={plafondCheque} onChange={(e) => setPlafondCheque(e.target.value)}
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <label className="form-label mt-1"> {t('detailClient.plafondcheque') + " : "}  </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control text-primary"
                                                                    id="exampleInputname"
                                                                    placeholder={t("detailClient.plafondcredit")}
                                                                    value={plafondCheque}
                                                                    disabled
                                                                />
                                                            </>
                                                        )}
                                                        <label className="form-label mt-1">{t('detailClient.autorisationtraite') + " : "}  <span className="text-danger">*</span></label>
                                                        <select
                                                            className="form-control select2 custom-select"
                                                            value={authTraite} onChange={(e) => setAuthTraite(e.target.value)}
                                                            style={{ background: "#4f86ff17" }}
                                                        >
                                                            <option value={0}>{t("select.non")}</option>
                                                            <option value={1}>{t("select.oui")}</option>
                                                        </select>
                                                        {authTraite === "1" ? (
                                                            <>
                                                                <label className="form-label mt-1">  {t('detailClient.plafondtraite') + " : "}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control text-primary mt-1"
                                                                    placeholder={t("detailClient.plafondcredit")}
                                                                    value={plafondTraite} onChange={(e) => setPlafondTraite(e.target.value)} />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <label className="form-label mt-1">  {t('detailClient.plafondtraite') + " : "}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control text-primary"
                                                                    id="exampleInputname"
                                                                    placeholder={t("detailClient.plafondcredit")}
                                                                    value={plafondTraite}
                                                                    disabled
                                                                />
                                                            </>
                                                        )}

                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-6 col-xl-6">
                                                        <label className="form-label"> {t('client.passager') + " : "} </label>
                                                        <select
                                                            className="form-control select2 custom-select"
                                                            value={passager} onChange={(e) => setPassager(e.target.value)}
                                                        >
                                                            <option value={0}>{t("select.non")}</option>
                                                            <option value={1}>{t("select.oui")}</option>
                                                        </select>
                                                        <label className="form-label mt-1"> {t('detailClient.tarification') + " : "} </label>
                                                        <select name="country" className="form-control select2 custom-select"
                                                            value={codeTarif} onChange={(e) => setCodeTarif(e.target.value)}
                                                        >
                                                            <option value={-1}> ---- {t('detailClient.tarification')}  ---- </option>
                                                            <option value={"Tarif"}>Tarif</option>
                                                            <option value={"Tarif1"}>Tarif 1</option>
                                                            <option value={"Tarif2"}>Tarif 2</option>

                                                        </select>
                                                        <label className="form-label mt-1"> {t('client.acceemetrage') + " : "} </label>
                                                        <input type="text" className="form-control" placeholder="Accée Métrage "
                                                            value={acceMetrage} onChange={(e) => setAcceMetrage(e.target.value)} />
                                                        <label className="form-label">{t('client.soussociete') + " : "} <span className="text-danger">*</span></label>
                                                        <select name="country" class="form-control select2 custom-select"
                                                            value={codeSousSociete} onChange={(e) => setCodeSousSociete(e.target.value)}
                                                            style={{ background: "#4f86ff17" }}
                                                        >
                                                            <option value={""}> ----{t('client.soussociete')} ---- </option>
                                                            {sousSociete?.map((items, keys) => (
                                                                <option value={items.code} key={keys}>{items.nom}</option>
                                                            ))}

                                                        </select>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* ------------------------------------------- OPTIONS : AJOUTER CLIENT  ---------------------------------------------------- */}
                            <div className="card-footer">
                                <button className="btn btn-primary float-right" onClick={() => Ajouter_clients()}> <i className="icon icon-user-follow mr-1 " /> {t("client.ajouterClient")} </button>
                            </div>
                        </div>
                    </div>
                </div>

            )}
        {errorCnx ? <ErrorCnx t={t} /> : <></>}
        </div>
    )
}
