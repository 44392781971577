/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
//------------ TRADUCTION ---------------------
import { useTranslation } from 'react-i18next'
//----------- COMPONENTS -----------------------
import Loader from '../../components/shared/Loader'
//------------ WEB SERVICES -------------------
import { getAllBLByCommercial } from '../../services/ventes/venteApi'
//----------- UTILS -------------------------
import { getCurrentDate } from '../../utils/DateUtils'
import { SearchItems } from '../../utils/SearchUtils'
import { OverlayTrigger } from 'react-bootstrap'
import { popover5 } from '../../components/Popover/Popovertab'

export default function DetailsBl() {
  //---------- Historique ---------
  const history = useHistory()
  //---------- URL Parametre ------
  const { code } = useParams()
  //------ identifiant INPUT ------
  const inputEl = useRef('')
  //------ Outils de traduction ------
  const [t, i18n] = useTranslation('global')
  //---------- Liste BL ------------
  const [liste, setListe] = useState([])
  const [searchList, setSearchlist] = useState([])
  const [saveList, setSaveList] = useState([])
  //--------- Total BL -------------
  const [totalBl, setTotBL] = useState(0)
  //Date of today Format (yyyy-mm-dd)
  const today = getCurrentDate()
  //---- Search  -----------------
  const [search, setSearch] = useState('')
  const currentItems = search.length < 1 ? liste : searchList
  //-------- LOADER ------------------
  const [loading, setLoading] = useState(true)

  useEffect(() => {
      getAllBLByCommercial(code, today).then((res) => {
        if (res?.data) {
          setListe(res?.data)
          setSaveList(res?.data)
          const total_ht = res?.data?.reduce(
            (totalsum, { net_a_payer }) => totalsum + parseFloat(net_a_payer),
            0,
          )
          setTotBL(total_ht)
          setLoading(false)
        }
      }).catch((error)=>console.log(error))
   
  }, [])

  //SEARCH ITEMS
  const getSearchTerm = () => {
    setSearch(inputEl.current.value)
    if (search !== '') {
      const newList = SearchItems(liste, search)
      setSearchlist(newList)
    } else {
      setListe(saveList)
    }
  }
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="section-title">
            <button
              className="btn bg-primary "
              onClick={() => history.goBack()}
            >
              <i className="fe fe-arrow-left" />
              {t('stock.retour')}
            </button>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div
                className="panel-group"
                id="accordion1"
                role="tablist"
                aria-multiselectable="true"
                style={{ marginBottom: '10px' }}
              >
                <div className="panel panel-default active">
                  <div className="panel-heading " role="tab" id="headingOne">
                    <h4 className="panel-title">
                      <a
                        role="button"
                        data-toggle="collapse"
                        data-parent="#accordion1"
                        href="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <i className="icon icon-grid text-primary mr-2 " />{' '}
                        {t('releve.titreBl')}
                        <span className="float-right">
                          {' '}
                          <i className="fe fe-search  text-primary" />{' '}
                        </span>
                      </a>
                    </h4>
                  </div>
                  <div
                    id="collapseOne"
                    className="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingOne"
                  >
                    <div className="panel-body">
                      {/** ---------------- SEARCH FORM ------------------  */}
                      <div className="search1 search-elements-sm">
                        <div className="form row no-gutters">
                          <div className="form-group  col-xl-10 col-lg-10 col-md-10 col-sm-10 col-8 mb-0">
                            <input
                              type="text"
                              ref={inputEl}
                              value={search}
                              onChange={() => getSearchTerm()}
                              className="form-control br-tr-md-0 br-br-md-0"
                              id="text1"
                              placeholder={t('stock.search')}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-2 col-md-2 col-4 mb-0">
                            <button
                              onClick={() => getSearchTerm()}
                              className="br-tl-md-0 br-bl-md-0 btn btn-block btn-primary fs-16"
                            >
                              <i className="fe fe-search" />
                            </button>
                          </div>
                          <div className="row push container float-right">
                            <div className="d-flex align-items-center mb-3 mt-3 mr-2">
                              <i className="fa fa-money  text-primary mr-2" />{' '}
                              {t('releve.totalbl')}
                              <div>
                                <strong className="mr-1 text-primary">
                                  {parseFloat(totalBl).toFixed(3)}
                                </strong>
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/** ------------ CARD (qte / total / nbr produits) -----  */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body p-3">
              {liste?.length !== 0 ? (
                <>
                  <table
                    className="table table-bordered border-top table-responsive-md table-striped text-center mb-0 text-nowrap tutorial-table"
                    cellSpacing={0}
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th className="priority-3" width="15%">
                          #
                        </th>
                        <th className="priority-1 mobiletext" width="15%">
                          {t('stock.code')}
                        </th>
                        <th className="priority-1 mobiletext" width="15%">
                          {t('releve.client')}
                        </th>
                        <th className="priority-3" width="15%">
                          {t('releve.Magasin')}
                        </th>
                        <th className="priority-3" width="15%">
                          {t('releve.depot')}
                        </th>
                        <th className="priority-1 mobiletext" width="15%">
                          {t('releve.netapayer')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems?.map((items, keys) => (
                        <tr key={keys}>
                          <th scope="row" className="priority-3" width="15%">
                            {keys + 1}
                          </th>
                          <td className="priority-1 mobiletext" width="15%">
                            <a
                              style={{ cursor: 'pointer' }}
                              href={'/detail_releve/' + items.code}
                            >
                              <u className="text-primary">{items.code}</u>
                            </a>
                          </td>
                          <td className="priority-1 mobiletext" width="15%">
                            {items.client}
                          </td>
                          <td className="priority-3" width="15%">
                            {items.magasin}
                          </td>
                          <td className="priority-3" width="15%">
                            {items.depot}
                          </td>
                          <td className="priority-1 mobiletext" width="15%">
                            {parseFloat(items.net_a_payer).toFixed(3)}
                            <OverlayTrigger
                              trigger="click"
                              placement="left"
                              overlay={popover5( keys, items.magasin, items.depot,t)}
                            >
                              <i className="icon icon-plus text-success ml-2" />
                            </OverlayTrigger>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-12">
                    <div className="alert alert-info">
                      <button
                        type="button"
                        className="close text-white"
                        data-dismiss="alert"
                        aria-hidden="true"
                      >
                        ×
                      </button>
                      <strong>{t('stock.vide')}</strong>
                      <hr className="message-inner-separator" />
                      <p>{t('stock.infovide')}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
