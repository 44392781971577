import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isClient, isLogin } from "../utils/SessionUtils";

export default function PrivateRouteClient({
  component: Component,
  layout: Layout,
  ...rest
}) {

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          {isLogin() && isClient() ? <Component {...props}></Component> : <Redirect to="/code_client" />}
        </Layout>
      )}
    ></Route>
  );
}
