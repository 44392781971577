/***RECHERCHER UN ELEMENT DANS UNE LISTE */
export function SearchItems(array,search) {
    const newList = array?.filter((el) => {
        return Object.values(el)
        .join(" ")
        .toLowerCase()
        .includes(search.toLowerCase())
        
        })
    return newList
  }

/**Trier par letter A-Z une liste  */
export function TrierAZ (array){
    const liste= array.sort((a, b) =>{
    return a.produit.localeCompare(b.produit)
    })
    return liste
}/**Trier par letter A-Z libelle une liste  */
export function TrierAZlibelle (array){
  const liste= array.sort((a, b) =>{
  return a.libelle.localeCompare(b.libelle)
  })
  return liste
}

export const sortPriceAsc = (arrayObject) => {
    return arrayObject.sort(function (a, b) {
      return a.prix - b.prix;
    });
  };
  export const sortPriceDesc = (arrayObject) => {
    return arrayObject.sort(function (a, b) {
      return b.prix - a.prix;
    });
  };
  export const sortPricettcAsc = (arrayObject) => {
    return arrayObject.sort(function (a, b) {
      return a.prix_ttc - b.prix_ttc;
    });
  };
  export const sortPricettcDesc = (arrayObject) => {
    return arrayObject.sort(function (a, b) {
      return b.prix_ttc - a.prix_ttc;
    });
  };
  export const filterLibelleMouvement = (arrayObject,libelle) =>{
   return  arrayObject
            .filter(({ mouvement_libelle }) => mouvement_libelle === libelle)
            
  }

  export const sortpriceMontant = (arrayObject) => {
    return arrayObject.sort(function (a, b) {
      return a.prix_ttc - b.prix_ttc;
    });
  };

  export const sortdate = (arrayObject) => {
    const liste= arrayObject.sort((a, b) =>{
      return a.date.localeCompare(b.date)
      })
      return liste
  };

  export const sortdatedesc = (arrayObject) => {
    const liste= arrayObject.sort((a, b) =>{
      return b.date.localeCompare(a.date)
      })
      return liste
  };

  export const sortMontant = (arrayObject) => {
    return arrayObject.sort(function (a, b) {
      return a.montant - b.montant;
    });
  };

  export const sortbyDateRecente = (arrayObject)=>{
   const liste= arrayObject.sort((a, b) =>{
      return b[0].localeCompare( a[0])
      })
    return liste
  }
  export const sortbyDateAncienen = (arrayObject)=>{
    const liste= arrayObject.sort((a, b) =>{
       return a[0].localeCompare( b[0])
       })
     return liste
   }