/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
//----------- TRADUCTION ----------------------------
import { useTranslation } from 'react-i18next';
//----------- History ----------------------------
import { useHistory } from 'react-router';
//----------- ALERTE ----------------------------
import { toast, ToastContainer } from 'react-toastify';
//----------- COMPONENTS ----------------------------
import Loader from '../../../components/shared/Loader'
import ErrorAlert from '../../../others/ErrorAlert';
//----------- WEB SERVICES  --------------------------
import { Logout } from '../../../services/Login/SignIn';
import { getNomCommercials, getSuiviCommercial } from '../../../services/Reporting/ReportingApi';
//----------- UTILS ----------------------------
import { SearchItems } from '../../../utils/SearchUtils';
import {numberWithSpaces} from '../../../utils/NumberWithSpaces'

export default function Suivicommercial() {
    //------ History -----------------
    const history = useHistory();
    //------ Reference --------------
    const inputEl = useRef("")
    //------ DATE SEARCH (debut / fin) -----------
    const [datedebut, setDateDebut] = useState("");
    const [dateFin, setDateFin] = useState("");
    //------------ SEARCH -----------------
    const [search, setSearch] = useState("");
    //-----------  Filtre Value ------------------
    const [value, setValue] = useState(-1);
    //-------------- TRADUCTION ---------------
    const [t, i18n] = useTranslation("global");
    //--------- LISTE COMMERCIAL ---------------
    const [liste, setListe] = useState([])
    //------- LIGNES RAPPORT COMMERCIAL
    const [lignes, setlignes] = useState([])
    const [searchList, setSearchlist] = useState([])
    const [saveList, setSaveList] = useState([])
    // ---------- Loading ------------------
    const [loading, setLoading] = useState(true)
    // -------- ERROR ------------------
    const [error, setError] = useState(false);
    const currentPosts = search.length < 3 ? lignes : searchList

    useEffect(() => {
            // Liste DES COMMERCIAUX POUR LE FILTRE par commercial
            getNomCommercials()
            .then((res) => {
                if (res === 401) {
                    toast(t('messages.vousreconnecter'), { position: "bottom-center", type: "warning" })
                    SeDeconnecter()
                }
                if (res?.data !== null) {
                    setListe(res?.data)
                }
                setLoading(false)

            }).catch((error)=>console.log(error))

    }, [])

    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (search !== "") {
            const newList = SearchItems(lignes, search)
            setSearchlist(newList)
        } else {
            setlignes(saveList)
        }
    }
    const SeDeconnecter = () => {
        Logout()
            .then((res) => {
                localStorage.removeItem("authentification_nomadis");
                localStorage.removeItem("url_nomadis");
            });
        history.push({
            pathname: "/code",
        });
    };

    //----- RECHERHCE PAR DATE DEBUT - DATE FIN
    const rapportSuiviCommercial = () => {
        console.log(value)
        if (datedebut !== "" && dateFin !== "" && value !== -1) {
            if (datedebut <= dateFin) {
                getSuiviCommercial(datedebut, dateFin, value)
                    .then((res) => {
                        setLoading(true)
                        setlignes(res?.data.entete)
                        setSaveList(res?.data.entete)
                        setLoading(false)            
                    })
                    .catch((error) => console.log(error))
                } else {
                toast(t('messages.datedebut_fin'), { type: 'warning', position: 'bottom-center' });
            }
        } else if (datedebut === "") {
            toast(t('messages.selc_datedebut'), { type: 'warning', position: 'bottom-center' });
        } else if (dateFin === "") {
            toast(t('messages.selc_datefin'), { type: 'warning', position: 'bottom-center' });
        } else if (value === -1) {
            toast(t('messages.selc_commercial'), { type: 'warning', position: 'bottom-center' });
        }
    }

    return (
        <div>
            {loading ? <Loader /> : (
                <div className="row">
                    <div className="col-md-12 col-12">
                        <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                            <div className="panel panel-default active">
                                <div className="panel-heading " role="tab" id="headingOne">
                                    <h4 className="panel-title">
                                        <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <i className="icon icon-grid text-primary mr-2" />  {t('suivi.SuiviCommercial')}
                                            <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                                    <div className="panel-body">
                                        <div className="form row no-gutters">
                                            <div className="form-group  col-xl-4 col-lg-4 col-md-4 col-6 mb-0">
                                                <input
                                                    className="form-control fc-datepicker"
                                                    type="date"
                                                    value={datedebut}
                                                    onChange={(e) => setDateDebut(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group  col-xl-4 col-lg-4 col-md-4 col-6 mb-0">
                                                <input
                                                    className="form-control fc-datepicker"
                                                    type="date"
                                                    value={dateFin}
                                                    onChange={(e) => setDateFin(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group  col-xl-3 col-lg-3 col-md-4 mb-0">
                                                <div className="d-flex" >
                                                    <select className="form-control"
                                                        value={value}
                                                        onChange={(e) => {
                                                            setValue(e.target.value)
                                                        }}>
                                                        <option value={-1}> {"Commercial  --- "}</option>
                                                        {liste?.map((items, keys) => (
                                                            <option value={items.code} key={keys}> {items.user} </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-xl-1 col-lg-1 col-md-12 col-12 mb-0">
                                                <button
                                                    className="br-tl-md-0 br-bl-md-0 btn btn-block btn-success fs-16"
                                                    onClick={() => rapportSuiviCommercial()} >
                                                    <i className="fe fe-search" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <ToastContainer/>
                        {/**------------  LISTE rapport  ------------- */}
                        {lignes.length !== 0 ? (
                            <div className="row">
                                {currentPosts?.map((items, keys) => (
                                    <div className="col-md-12 col-lg-4 col-xl-4 col-sm-12 col-12" key={keys} >
                                        <div className="card" >
                                            <div className="card- br-tr-7 br-tl-7" />
                                            {/** ------------ TITRE HEADER ---------------------------- */}

                                            <div className="card-header">
                                                <h3 className="card-title text-primary text-center" style={{ marginTop: "10px" }}> {t('stock.date') + ' ' + items.date}</h3>
                                            </div>
                                            {/** ---------- Tableau ----------------------------------  */}
                                            
                                           <div className="card-body">
                                               <h6 className="mb-0 fs-20 text-center">{items.vente !== null ?  numberWithSpaces(parseFloat(items.vente).toFixed(3)) : "0"} {t('ventes.ventes')}</h6>
                                                <div className="row no-gutters justify-content-center mt-6">
                                                
                                                    <div className="col-4 col-xl-4">
                                                        <div className="text-center ">
                                                            <i className="ti-receipt text-secondary fs-25" />
                                                            <h4 className="mb-0 fs-20">{items.count_bl}</h4>
                                                            <p className="pt-2 pb-2 mb-0 text-muted">{t('client.nbBl')}</p>

                                                        </div>
                                                    </div>
                                                    <div className="col-4 col-xl-4">
                                                        <div className="text-center">
                                                            <i className="icon icon-wallet text-warning fs-25" />
                                                            <h4 className="mb-0 fs-20">{items.count_bl_paye}</h4>
                                                            <p className="pt-2 pb-2 mb-0 text-muted">{t('client.nbBlpayee')}</p>

                                                        </div>
                                                    </div>
                                                    <div className="col-4 col-xl-4">
                                                        <div className="text-center">
                                                        <i className="icon icon-close text-primary fs-25" />
                                                            <h4 className="mb-0 fs-20">{items.count_bl_impaye}</h4>
                                                            <p className="pt-2 pb-2 mb-0 text-muted"> {t('client.nbBlimpayee')}  </p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <h6 className="mb-2 fs-20 text-center mt-2">{items.sum_espece_cheque !== null ? parseFloat(items.sum_espece_cheque).toFixed(3) : "0"}  {t('listecmdvente.recette')}</h6>
                                                <div className="row no-gutters justify-content-center mt-6">
                                                    <div className="col-4 col-xl-4">
                                                        <div className="text-center">
                                                            <i className="ti-car text-primary fs-25 fa-2x" />
                                                            <h4 className="mb-0 fs-20">{items.visite}</h4>
                                                            <p className="pt-2 pb-2 mb-0 text-muted">{t('todayCommercial.visite')}</p>

                                                        </div>
                                                    </div> 
                                                    <div className="col-4 col-xl-4">
                                                        <div className="text-center ">
                                                            <i className="ti-share-alt text-secondary fs-25" />
                                                            <h4 className="mb-0 fs-20">{items.count_avoir}</h4>
                                                            <p className="pt-2 pb-2 mb-0 text-muted">{t('client.nbavoir')}</p>

                                                        </div>
                                                    </div>
                                                    <div className="col-4 col-xl-4">
                                                        <div className="text-center">
                                                            <i className="fa fa-dollar text-info fs-25" />
                                                            <h4 className="mb-0 fs-20">{items.recouvrement !== null ? parseFloat(items.recouvrement).toFixed(3) : "0"}</h4>
                                                            <p className="pt-2 pb-2 mb-0 text-muted">{t('todayCommercial.recouvrement')} </p>
                                                        </div>
                                                    </div>
                                                 
                                                </div>
                                           </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        ) : (
                            <div className="row">
                                <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                                    <div className="alert bg-purple mb-5 py-4" role="alert">
                                        <div className="d-flex">
                                            <i className="icon icon-info mr-3 fs-30"></i>
                                            <div>
                                                <h4 className="alert-heading">{t('suivi.objet')}</h4>
                                                <p> {t('suivi.message')}  </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>

            )}
            {error ? <ErrorAlert t={t} /> : <></>}

        </div>
    )
}
